

























































































.custom-opacity{
    &:before{
        opacity: 0.3 !important;
    }
}
.test-opacity0{
    &:before{
        opacity: .8 !important;
    }
}
.test-opacity1{
    &:before{
        opacity: .7 !important;
    }
}
.test-opacity2{
    &:before{
        opacity: .6 !important;
    }
}
.test-opacity3{
    &:before{
        opacity: .5 !important;
    }
}
.test-opacity4{
    &:before{
        opacity: .4 !important;
    }
}
.test-opacity5{
    &:before{
        opacity: .3 !important;
    }
}
.test-opacity6{
    &:before{
        opacity: .2 !important;
    }
}
