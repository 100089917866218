
































































.nd_single_news_page{
    .single_main_block{
        margin-top: 60px;
        margin-bottom: 250px;

        .single_news_header{
            margin-top: 77px;
            margin-bottom: 60px;
            font-weight: 700;
            font-size: 2.3rem;
            line-height: 2.8rem;  
            color: #353B48;              
        }

        .single_news_description{
            font-weight: 400;
            font-size: 1.8rem;
            line-height: 2.3rem;
            margin-bottom: 25px;

            p{
                margin: 0;
            }
        }
    }
}
