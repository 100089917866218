.admin-contacts{
    width: 99%;
    padding: 20px;
    overflow: auto;
    max-height: 91vh;

    .admin-index_header{
        position: relative;

        .save_btn{
            position: fixed;
            top: 70px;
            right: 20px;
            z-index: 100;
        }
    }

    .admin-contacts-content{
        .addresses{
            .forms{
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
            }
        }
    }

    .singleAddress{
        width: 515px;
        margin-top: 50px;
        padding: 20px;

        .checkboxes{
            display: flex;
            
            .checkbox-plus{
                span{
                    padding-left: 40px;
                    padding-right: 15px;
                    margin-top: 6px;
                }
            }
        }
        .region{
            display: flex;
            margin-top: 10px;
        }

        .buttons{
            display: flex;
            justify-content: space-between;
            
            .edit_button{
                background-color: #ED1C24;
                color: #fff;
                border: none;
                padding-top: 5px;
                padding-bottom: 5px;
                font-size: 15px;
                height: 55px;
            }

            .delete_button{
                background-color: #ED1C24;
                color: #fff;
                border: none;
                padding-top: 5px;
                padding-bottom: 5px;
                font-size: 15px;
                height: 55px;
            }
        }
    }

    .singleAddress_edited{
        border: 2px solid red;
        border-radius: 20px;
    }
}
