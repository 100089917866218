


































































.admin-widget_content{
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    
    .code_area{
        width: 80%;
    }
    
    .button{
        width: 20%;
        margin-top: 20px;
    }

    .current_code{
        padding: 20px;
        border-radius: 15px;
        background-color: #dfd7d7;
    }
}
.area_label{
    margin-top: 25px;
    margin-bottom: 15px;
}
