.breadcrumbs {
	font-size: 1.2rem;
	color: $color-6;
	display: inline-flex;
	vertical-align: top;

	svg {
		fill: $color-6;
		width: 16px;
		height: 16px;
		display: inline-block;
		vertical-align: middle; }

	.list-item {
		&::after {
			content: '';
			margin: 0 21px;
			width: 7px;
			height: 14px;
			background: url('../img/breadcrumbs-arrow.svg') 0 0 no-repeat;
			display: inline-block;
			vertical-align: middle; }

		&:last-child {
			&::after {
				display: none; } } }

	.list-link {
		display: inline-block;

		&:hover {
			color: $color-2;
			cursor: pointer;

			svg {
				fill: $color-2; } } }

	.list-current {
		font-weight: bold;
		color: $color-7; }

	&.breadcrumbs--light {
		@media (min-width: $mml + 1px) {
			color: #fff;

			svg {
				fill: #fff; }

			.list-item {
				&::after {
					background-image: url('../img/breadcrumbs-arrow-light.svg'); } }

			.list-current {
				color: #fff; } } }

	@media (max-width: $mml) {
		flex-wrap: nowrap;
		white-space: nowrap;
		margin-left: -15px;
		margin-right: -15px;
		overflow: hidden;
		padding-left: 15px;
		padding-right: 15px;

		.list-item {
			flex: none;

			&::after {
				margin: 0 17px; } }

		&.breadcrumbs--overflow {
			justify-content: flex-end;
			display: flex; } } }
