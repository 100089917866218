













































































































































































































































































































































































































































































































































































.mx-calendar-panel-date{
    .mx-btn-icon-double-left, .mx-btn-icon-double-right{
        display: none;
    }
    
}

.mx-calendar{
    padding: 24px 36px;
    width: 400px;
    height: 410px;

    .mx-calendar-header{

        .mx-btn-icon-left, .mx-btn-icon-right{
            background-color: #718093;
            color: #fff;
            border-radius: 16px;
            height: 32px;
            width: 32px;
            text-align: center;

            i{
                &:before{
                    border-width: 3px 0 0 3px;
                }
            }
        }

        .mx-btn-icon-left{
            float: unset;
        }

        .mx-calendar-header-label{
            float: left;

            button{
                color: black;
                font-weight: 700;
                font-size: 22px;
                line-height: 33px;
                font-family: 'Montserrat', serif !important;
            }
        }
    }

    .mx-calendar-content{
        .mx-table{
            th{
                color: #696969;
                font-weight: 400;
                font-size: 14px;
                line-height: 21px;
                font-family: 'Montserrat', serif !important;
            }

            td{
                color: black;
                font-weight: 400;
                font-size: 22px;
                line-height: 33px;
                font-family: 'Montserrat', serif !important;
                width: 45px;
                height: 50px;


                &:hover{
                    background-color: #F5F6FA;
                    border-radius: 100px;
                }
            }

            .active{
                background-color: #DFE3F0;
                border-radius: 100px;
            }

            .in-range{
                background-color: #F5F6FA;
                border-radius: 100px;
            }
            .hover-in-range{
                background-color: #F5F6FA;
                border-radius: 100px;
            }
        }
    }
}
