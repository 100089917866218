



























































































































































































































































































































































































































































































.lk-page_booking{
    .lk-page_booking-header{
        margin-top: 50px;
        margin-bottom: 10px;

        font-weight: 700;
        font-size: 32px;
        line-height: 48px;
        color: black;
        margin-bottom: 30px;
    }
    .lk-page_booking-filters{
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 30px;

        .lk-page_booking-filters_ones{
            position: relative;

            .filter_label{
                font-weight: 700;
                font-size: 16px;
                line-height: 20px;
                margin-bottom: 10px;
            }

            input{
                width: 100%;
                color: #718093;
                border: 1px solid #D6D6D6;
                border-radius: 10px;
                padding: 11px 12px;
                height: 48px;
            }

            select{
                width: 100%;
                color: #718093;
                border: 1px solid #D6D6D6;
                border-radius: 10px;
                padding: 11px 12px;
                height: 48px;
                background-color: #fff;

                &:active{
                    border-radius: 10px !important;
                }
            }

            select::after{
                color: red !important;
            }

            .mx-datepicker{
                width: 100%;
            }
            
            .filter_button{
                display: flex;
                flex-wrap: nowrap;
                align-items: center;
                justify-content: space-between;

                background-color: #F5F6FA;
                color: black;

                padding: 8px 20px;
                border-radius: 10px;
                margin-top: 10px;

                p{
                    margin-bottom: 0;
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 20px;
                }

                &:hover{
                    cursor: pointer;
                }

                .svg_filter{
                    margin-right: 4px;
                }

                .status_filter_block{
                    display: flex;
                    flex-wrap: nowrap;
                    align-items: center;
                }
            }
            .filter_list{
                display: flex;
                flex-direction: column;
                background-color: #fff;
                border: 1px solid #D6D6D6;
                border-radius: 10px;
                padding: 8px 12px;
                width: 120%;

                position: absolute;
                z-index: 5;

                .filter_list_row{
                    display: flex;
                    flex-wrap: nowrap;
                    align-items: center;

                   .svg_checkbox{
                        margin-right: 10px;

                        &:hover{
                            cursor: pointer;
                        }
                   }
                }
            }

            .custom_select{
                position: relative;

                .choosen_item{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    border: 1px solid #D6D6D6;
                    border-radius: 10px;
                    padding: 2px 12px;

                    &:hover{
                        cursor: pointer;
                    }

                    p{
                        margin-bottom: 0;
                        color: #757575;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 20px;
                    }
                }

                .items{
                    position: absolute;
                    border: 1px solid #D6D6D6;
                    border-radius: 10px;
                    background-color: #fff;
                    max-height: 300px;
                    overflow-x: auto;

                    p{
                        padding: 6px 12px;
                        margin-bottom: 0;
                        color: #353B48;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 20px;

                        &:hover{
                            background-color: #eaeaea;
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }
    .lk-page_booking-list{
        display: flex;
        flex-direction: column;

        overflow-x: auto;
        max-height: 710px;

        .lk-page_booking-ones{
            display: flex;
            flex-wrap: wrap;
            border-radius: 5px;
            background-color: #F5F6FA;
            padding: 24px;
            margin-bottom: 20px;

            .lk-page_booking-ones_left-part{
                display: flex;
                flex-wrap: wrap;
                flex-direction: column;

                width: 80%;
                margin-right: auto;
                margin-bottom: 30px;

                .first_row{
                    display: flex;
                    flex-wrap: nowrap;
                    gap: 12px;

                    font-weight: 400;
                    color: black;
                    font-size: 16px;
                    line-height: 24px;
                }

                .book_header{
                    font-weight: 700;
                    color: black;
                    font-size: 22px;
                    line-height: 33px;
                    margin-bottom: 10px;
                }

                .book_subheader{
                    color: #718093;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 20px;
                    margin-bottom: 10px;
                }

                .book_volume{
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 24px;
                    color: #718093;
                    margin-bottom: 30px;
                }

                .order-number{
                    color: black;
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 19px;
                    margin-bottom: 12px;
                    width: 100%;
                }

                .company-name{
                    color: #718093;
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 19px;
                    width: 100%;
                }
            }

            .lk-page_booking-ones_right-part{
                width: 20%;
                margin-left: auto;
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                margin-bottom: 30px;

                .right-part_subheader{

                    p{
                        font-weight: 700;
                        font-size: 16px;
                        line-height: 24px;
                        color: black;
                        margin-bottom: 10px;
                        text-align: end;
                    }
                }

                .right-part_manager{

                    p{
                        color: black;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 14px;
                        margin-bottom: 10px;
                        text-align: end;
                    }
                }

                .order-status-button{
                    color: #fff;
                    padding: 4px 10px;
                    border-radius: 100px;
                    font-weight: 600;
                    font-size: 15px;
                    line-height: 18px;
                    margin-bottom: 19px;
                }

                .order-status-button_green{
                    background-color: #039B24;
                }
                .order-status-button_lightgreen{
                    background-color: #05DB41;
                }
                .order-status-button_lightblue{
                    background-color: #4BB5E3;
                }
                .order-status-button_blue{
                    background-color: #0071B5;
                }
                .order-status-button_purple{
                    background-color: #BD01DC;
                }
                .order-status-button_black{
                    background-color: black;
                }
                .order-status-button_red{
                    background-color: #ED1C24;
                }
                .order-status-button_yellow{
                    background-color: #C9A901;
                }
                .order-status-button_orange{
                    background-color: #D59009;
                }
                .order-status-button_grey{
                    background-color: #718093;
                }
                .order-status-button_darkgrey{
                    background-color: #343b47;
                }
            }

            .order_button_block{
                width: 100%;
                display: flex;
                justify-content: flex-end;

                .order-button{
                    color: #fff;
                    background-color: black;
                    padding: 10px 30px;
                    border-radius: 10px;

                    font-weight: 700;
                    font-size: 16px;
                    line-height: 24px;

                    display: flex;
                    flex-wrap: nowrap;
                    align-items: center;
                    gap: 20px;

                    &:hover{
                        cursor: pointer;
                    }
                }
            }

            .booking_grey_line{
                background-color: #0000001A;
                height: 1px;
                width: 100%;
                margin-bottom: 30px;
            }

            .button_details{
                display: flex;
                gap: 20px;
                width: 30%;
                margin-bottom: 20px;

                &:hover{
                    cursor: pointer;
                }

                p{
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 24px;
                    color: black;
                    margin-bottom: 0;
                }
            }

            .booking_nomen{
                width: 100%;

                .booking_nomen_once{
                    margin-bottom: 20px;

                    p{
                        margin-bottom: 0;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 24px;
                        color: black;
                    }
                }
                
            }
        }
    }
}
