.background-grid{
    &:before{
        border-left: unset;
    }
    &:after{
        border-left: unset;
    }

    .background-grid-outer{
        &:before{
            border-left: unset;
        }
        &:after{
            border-left: unset;
        }

        .background-grid-inner{
            &:before{
                border-left: unset;
            }

            &:after{
                border-left: unset;
            }
        }
    }
}
.header{
    .header-container--desktop{
        .header-bottom{
            border-top: unset;
            border-bottom: unset;
        }
    }
}