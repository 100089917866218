/* HEADER DESKTOP
 * -------------------------------------------------- */

.header {
	position: relative;
	z-index: 200;
	margin-bottom: 22px;

	&-container {
		&.header-container--desktop {
			display: block;

			@media (max-width: $mtm) {
				display: none; } }

		.header-bottom {
			@include lt();
			@include lb();
			padding: 25.5px 0 22px 0;

			.container {
				display: flex;
				align-items: center; } }

		.header-logotype,
		.header-contacts {
			flex: 0 0 20%; }

		.header-logotype {
			text-align: center; }

		.header-search {
			flex: 1;
			text-align: center; }

		.header-phone {
			margin-bottom: 7px; }

		.header-location {
			display: flex;
			flex-wrap: wrap;
			align-items: center; }

		.header-city {
			margin-right: 18px;
			font-size: 0; }

		.header-address {
			font-size: 1.4rem; }

		&.header-container--mobile {
			display: none;
			height: 46px;
			background: #fff;
			position: fixed;
			top: 0;
			left: 0;
			right: 0;
			z-index: 100;
			@include lb();

			@media (max-width: $mtm) {
				display: block; } } }

	&-controls {
		.container {
			position: relative; }

		.dropdown {
			position: absolute;
			right: 62px;
			top: 0;
			min-width: 360px; } }

	@media (max-width: $mdm) {
		.header-container {
			&.header-container--desktop {
				.header-address {
					font-size: 1.2rem; } } }

		&-controls {
			.dropdown {
				right: auto;
				left: 50%;
				//width: 290px
				//min-width: 0
				margin-left: -180px;

				&::before {
					left: 50%;
					margin-left: -7.5px; } } } }

	@media (max-width: $mtm) {
		margin-bottom: 0; }

	@media (max-width: $mmm) {
		&-controls {
			.dropdown {
				width: 290px;
				min-width: 0;
				margin-left: -145px;
				top: -16px;

				&-group {
					padding: 18px; }

				.layout-buttons {
					.list-item + .list-item {
						margin-top: 0; } } } } } }

/* HEADER MOBILE
 * -------------------------------------------------- */

.header {
	.header-container {
		&.header-container--mobile {
			.header-bar {
				padding-left: 15px;
				display: flex;
				justify-content: space-between; }

			.header-dropdown-toggle {
				width: 46px;
				height: 46px;
				background: $color-6;
				padding: 0;
				margin: 0;
				border: 0;
				position: relative;

				&::before,
				&::after {
					@include content();
					left: 50%;
					top: 50%;
					transform: translate(-50%, -50%); }

				&::before {
					width: 18px;
					height: 11px;
					background: url('../img/navigation-toggle.svg') 0 0 /  100% 100% no-repeat;
					opacity: 1; }

				&::after {
					width: 14px;
					height: 14px;
					background: url('../img/navigation-close.svg') 0 0 /  100% 100% no-repeat;
					opacity: 0; } }

			.header-dropdown {
				//overflow: hidden
				display: none;
				position: absolute;
				top: 46px;
				left: 0;
				width: 100%;
				//max-width: 450px
				right: 0;
				height: calc(100vh - 46px);
				background: $color-7;
				color: #fff;
				z-index: 10;

				&-main {
					width: 100%;
					height: 100%;
					display: flex;
					flex-direction: column;

					&-scroll {
						@include scroll();
						overflow: auto;
						height: calc(100% - 46px); }

					.header-navigation-main {
						&-search {
							height: 46px;
							background: $color-6;
							color: #fff;

							.search-mobile {
								@include flex();

								&-field {
									flex: 1;
									width: 100%;
									padding: 14px 17px 14px 17px;
									border: 0;
									margin: 0;
									background: transparent;
									font-size: 1.2rem;
									border-radius: 0;

									&:focus {
										font-weight: bold;
										background: $color-4;
										color: $color-6;

										& + button {
											background: $color-4;

											svg {
												fill: $color-6; } } } }

								&-button {
									@include width(46px);
									height: 46px;
									padding: 0;
									margin: 0;
									border: 0;
									background: none;

									svg {
										width: 16px;
										height: 16px;
										fill: #fff; } } } }

						&-body {
							flex: 1; }

						&-footer {
							padding: 37px 19px 45px 19px;

							.phone,
							.email {
								color: #fff;
								font-size: 1.8rem;
								display: block; }

							.email {
								margin-top: 27px; } }

						&-catalog-button {
							font-size: 1.4rem;
							color: #fff;
							background: $color-2;
							padding: 0 0 0 60px;
							margin: 0;
							width: 100%;
							height: 76px;
							line-height: 76px;
							font-weight: bold;
							border: 0;
							text-align: left;
							position: relative;

							svg {
								left: 18px;
								top: 50%;
								width: 12px;
								height: 9px;
								margin-top: -4.5px;
								position: absolute;
								fill: #fff;
								transform: rotate(180deg); } }

						&-type {
							margin-top: 32px; }

						&-city {
							margin-top: 32px;

							.choices {
								color: $color-7;

								.choices__list--single {
									font-size: 1.8rem;
									color: #fff; }

								.choices__list--dropdown {
									bottom: 100%;
									top: auto;
									margin-top: 0;
									margin-bottom: 14px; } }

							address {
								margin-top: 18px;
								font-size: 1.4rem; } }

						&-list {
							padding: 17px 0;

							.navigation-link {
								display: flex;
								align-items: center;
								padding: 17px 0;
								font-size: 1.4rem;

								&-icon {
									@include width(46px);
									text-align: center; }

								&-title {
									padding-left: 14px; }

								svg {
									display: block;
									fill: #fff;
									margin: 0 auto;
									width: 18px;
									height: 18px; } } }

						&-sub {
							background: $color-6;
							padding: 17px 0;
							font-size: 1.4rem;

							.list-link {
								padding: 17px 0 17px 60px;
								position: relative;

								&::before {
									@include content();
									width: 4px;
									height: 4px;
									border-radius: 50%;
									background: #fff;
									left: 21px;
									top: 50%;
									margin-top: -2px; } } } } }

				&-sub {
					display: none;
					width: 100%;
					background: $color-2;
					color: #fff;
					position: absolute;
					top: -46px;
					left: 0;
					bottom: 0;
					width: calc(100% - 46px);

					&-header {
						height: 46px;
						padding-left: 15px;
						margin-bottom: 46px;
						display: flex;
						align-items: center;

						img {
							display: block; } }

					&-scroll {
						height: calc(100% - 92px);
						@include scroll();
						overflow: auto; }

					&-group + .header-dropdown-sub-group {
						margin-top: 34px; }

					&-group {
						&-title {
							font-size: 1.6rem;
							padding-left: 15px; } }

					.header-navigation-sub-list {
						padding: 17px 0;

						.navigation-link {
							display: flex;
							align-items: center;
							padding: 17px 0;
							font-size: 1.4rem;

							&-icon {
								@include width(46px);
								text-align: center; }

							&-title {
								padding-left: 14px; }

							svg {
								display: block;
								fill: #fff;
								margin: 0 auto;
								width: 18px;
								height: 18px; } } } }

				&-search {
					display: none;
					@include scroll();
					width: 100%;
					overflow: auto;
					position: absolute;
					top: 46px;
					bottom: 0;
					background: #fff;
					padding: 15px;

					&.-active {
						display: block; } } } } } }

.-navigation-dropdown-main-opened {
	position: fixed;
	overflow: hidden;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;

	.header {
		.header-container.header-container--mobile {
			.header-bar {
				background: $color-7; }

			.logotype-mobile-image,
			.logotype-mobile-content {
				display: none; }

			.logotype-mobile-dropdown {
				display: block; }

			.header-dropdown {
				display: block !important; }

			.header-dropdown-toggle {
				&::before {
					opacity: 0 !important; }

				&::after {
					opacity: 1 !important; } } } } }

.-navigation-dropdown-sub-opened {
	.header {
		.header-container.header-container--mobile {
			.header-bar,
			.header-dropdown-main {
				transform: translateX(calc(100% - 46px)); }

			.header-dropdown-sub {
				display: block;
				box-shadow: 0 3px 24px rgba(#000, .67);
				transform: translateX(0); }

			.header-navigation-main-catalog-button {
				svg {
					transform: none; } } } } }

.logotype-mobile {
	@include flex();
	height: 46px;
	align-items: center;
	white-space: nowrap;

	&-image {
		@include width(40px);
		margin-right: 5px; }

	&-content {
		overflow: hidden;
		flex: 1;
		font-size: 1.4rem;
		font-weight: bold;
		color: $color-7;
		text-transform: uppercase;
		height: 46px;
		line-height: 46px; }

	&-title {
		padding-top: 17px; }

	&-dropdown {
		display: none; }

	&.-changed {
		.logotype-mobile-title {
			display: none; } } }

/* LOGOTYPE
 * -------------------------------------------------- */

.logotype {
	display: block;

	img {
		max-width: 72.3%;
		width: 100%; } }

/* NAVIGATION
 * -------------------------------------------------- */

.navigation-header {
	align-items: center;
	font-size: 1.6rem;

	.list-item {
		flex: 0 0 20%; }

	.list-link {
		padding: 15px 0;

		&:hover {
			color: $color-2; } }

	@media (max-width: $mdm) {
		font-size: 1.4rem; } }

/* SITE TYPE
 * -------------------------------------------------- */

.site-type {
	display: block;
	position: relative;
	line-height: 24px;

	&::before,
	&::after {
		@include content(); }

	&::before {
		left: 0;
		top: 0;
		width: 39px;
		height: 24px;
		background: $color-2;
		border-radius: 12px; }

	&::after {
		width: 18px;
		height: 18px;
		border-radius: 50%;
		background-color: #fff;
		z-index: 1;
		top: 3px;
		left: 3px; }

	&-label {
		padding-left: 57px;
		font-size: 1.6rem; }

	&.site-type--toggle-left {
		&::after {
			left: 3px; } }

	&.site-type--toggle-right {
		&::after {
			left: 18px; } }

	@media (max-width: $mdm) {
		&-label {
			font-size: 1.4rem; } } }
