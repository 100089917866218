/* CART
 * -------------------------------------------------- */

.cart {
	background: #fff;
	border-radius: 4px;
	box-shadow: 0 3px 45px rgba(#AAAAAA, .16);

	&-header {
		padding: 19px 0;
		@include lb();
		@include flex();
		align-items: center; }

	&-footer {
		@include lt();
		padding: 64px 0;
		@include flex();
		align-items: flex-end;
		justify-content: flex-end; }

	&-title {
		padding-left: 35px;
		font-size: 2.4rem;
		font-weight: bold;
		@include width(40%);
		padding-right: 20px; }

	&-search {
		@include width(40%);
		padding-right: 55px; }

	&-clear {
		@include width(20%); }

	&-total-price {
		margin-right: 35px;

		&-label {
			font-size: 1.2rem;
			margin-bottom: 5px; }

		&-value {
			font-weight: bold;
			font-size: 4.4rem;
			line-height: 1; } }

	&-button-submit {
		margin-right: 50px; }

	&-button-quick {
		@include width(20%); }

	@media (max-width: $mdxs) {
		&-title {
			font-size: 1.8rem;
			@include width(100%);
			padding-left: 20px;
			margin-bottom: 32px; }

		&-search {
			flex: 1;
			max-width: none;
			width: auto;
			padding-right: 20px;
			padding-left: 20px; }

		&-clear {
			padding-right: 20px;
			@include width(220px); }

		&-button-submit {
			margin-right: 20px;
			@include width(200px); }

		&-button-quick {
			padding-right: 20px;
			@include width(220px); }

		.button {
			width: 100%; } }

	@media (max-width: $mtm) {
		&-header {
			border-bottom: 0; }

		&-footer {
			padding: 32px 20px;
			display: block;
 }			//text-align: center

		&-total-price {
			margin-right: 0; }

		&-button-submit,
		&-button-quick {
			margin-right: 0;
			@include width(100%);
			padding-right: 0;
			margin-top: 16px;

			.button {
				width: auto;
				min-width: 200px; } } }

	@media (max-width: $mmm) {
		box-shadow: none;
		margin-left: -15px;
		margin-right: -15px;
		background: none;

		&-header {
			display: block;
			padding: 32px 15px;
			@include lt(); }

		&-title {
			font-size: 1.6rem;
			padding: 0; }

		&-search {
			padding: 0;
			margin-bottom: 32px; }

		&-clear {
			@include width(100%);
			padding: 0; }

		&-footer {
			padding: 32px 15px;
			@include lb(); }

		&-total-price {
			margin-bottom: 32px; }

		&-remove {
			width: 100%; }

		&-button-submit,
		&-button-quick {
			.button {
				width: 100%;
				min-width: 0; } } } }

.cart {
	&-item {
		padding: 32px 0;

		&.cart-item--labels {
			@media (min-width: $mtm + 1px) {
				padding-bottom: 0;

				+ .cart-item {
					padding-top: 0;
					border-top: 0 !important; } } } }

	&-row {
		@include flex();

		&.cart-row--labels {
			margin-bottom: 12px; } }

	&-cell {
		flex: 1;
		padding-left: 20px;

		&-label {
			display: none; }

		&:first-child {
			@include width(40%);
			padding-left: 35px; }

		&.cart-cell--remove {
			@include width(64px);
			padding-left: 0; } }

	&-list {

		.cart-row:not(.cart-row--labels) {
			align-items: center; }

		.cart-item + .cart-item {
			@include lt(); }

		.cart-item {
			&:not(:first-child) {
				.cart-row--labels {
					display: none; } } } }

	&-status {
		color: $color-6; }

	&-count {
		font-size: 1.6rem; }

	&-price {
		font-size: 1.8rem; }

	&-product {
		display: inline-flex;
		align-items: center;
		font-size: 1.6rem;

		&-image {
			@include width(39%);

			img {
				width: 100%; } }

		&-title {
			flex: 1;
			padding-left: 20px;
			text-align: left; } }

	&-remove {
		width: 14px;
		height: 14px;
		padding: 0;
		margin: 0;
		border: 0;
		font-size: 0;
		display: block;
		background: url('../img/remove.svg') 0 0 / 100% 100% no-repeat; }

	@media (max-width: $mdxs) {
		&-cell {
			&:first-child {
				padding-left: 20px;
				@include width(33%); } } }

	@media (max-width: $mtm) {
		&-list {
			.cart-item {
				&.cart-item--labels {
					display: none; }

				&:not(:first-child) {
					.cart-row--labels {
						display: block; } } } }

		&-row {
			display: block;
 }			//text-align: center

		&-cell {
			@include width(100%);
			padding: 0 20px;

			&:first-child {
				@include width(100%); }

			&-label {
				display: block;
				margin-bottom: 16px; }

			& + .cart-cell {
				margin-top: 32px; }

			&.cart-cell--remove {
				@include width(100%);
				padding-left: 20px;
				padding-right: 20px; } }

		&-remove {
			background: $color-4;
			display: inline-block;
			text-decoration: none;
			padding: 13px 27px 14px 27px;
			border: 0;
			border-radius: 0;
			text-align: center;
			transition: color .25s, background-color .25s, opacity .25s, border-color .25s;
			font-size: 1.4rem;
			font-weight: bold;
			width: auto;
			height: auto; }

		.counter {
			display: inline-flex; } }

	@media (max-width: $mmm) {
		&-product {
			font-size: 1.4rem; }

		&-cell {
			@include width(100%);
			padding: 0 15px; } } }

.cart-empty {
	padding-left: 20%;

	img {
		margin-bottom: 24px; }

	h4 {
		margin-bottom: 10px; }

	.layout-buttons {
		margin-top: 53px; }

	@media (max-width: $mtm) {
		padding-left: 0; } }

/* SEARCH
 * -------------------------------------------------- */

.search-cart {
	width: 100%;
	margin: 0 auto;
	position: relative;

	&-field {
		position: relative;
		width: 100%;
		z-index: 1; }

	&-input {
		width: 100%;
		height: 48px;
		padding: 17px 0 17px 23px;
		background: $color-4;
		color: $color-7;
		font-size: 1.2rem;
		margin: 0;
		border: 0;
		border-radius: 4px;
		font-weight: bold;

		&::-webkit-input-placeholder {
			font-weight: normal;
			color: $color-6;
			opacity: 1; }

		&:-moz-placeholder {
			font-weight: normal;
			color: $color-6;
			opacity: 1; }

		&::-moz-placeholder {
			font-weight: normal;
			color: $color-6;
			opacity: 1; }

		&:-ms-input-placeholder {
			font-weight: normal;
			color: $color-6;
			opacity: 1; } }

	&-submit {
		position: absolute;
		width: 18px;
		height: 18px;
		background: url('../img/search-button.svg') 0 0 / 100% 100% no-repeat;
		border: 0;
		padding: 0;
		margin: 0;
		top: 50%;
		margin-top: -9px;
		right: 26px; } }
