





















































































.order_decline_modal{
    .modal-container{
        padding: 24px !important;
        max-width: 549px !important;
        position: relative;

        .modal-button-close{
            position: absolute;
            top: 24px;
            right: 24px;

            &:hover{
                cursor: pointer;
            }
        }

        .form{
            .modal_header{
                font-weight: 700;
                font-size: 22px;
                line-height: 33px;
                margin-bottom: 24px;
                color: black;
            }

            .modal_subheader{
                font-weight: 700;
                font-size: 16px;
                line-height: 24px;
                color: black;
                margin-bottom: 12px;
            }

            .modal_message{
                font-weight: 400;
                font-size: 14px;
                line-height: 21px;
                margin-bottom: 24px;
                color: black;
            }

            .button{
                border-radius: 10px;
                padding: 12px 31px;
                margin-bottom: 12px;

                float: inline-end;
            }

            textarea{
                width: 100%;
                margin-bottom: 24px;

                font-weight: 400;
                font-size: 16px;
                line-height: 22px;
                color: #353B48;

                border: 1px solid #D6D6D6;
                border-radius: 10px;
                padding: 10px;
            }
        }
    } 
}
