






































































































































































































































































































































































































































































.contacts_link{
    color: #3384FF;
    font-weight: 700;
    font-size: 1.6rem;
    line-height: 2.4rem;
    
    &:hover{
        cursor: pointer;
    }
}

.cities_filter_block{
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-bottom: 40px;

    .city_filter_button{
        padding: 10px 20px;
        border: unset;
        border-radius: 20px;

        background-color: #F5F6FA;
        color: black;

        // font-weight: 400;
        font-weight: 700;
        font-size: 1.8rem;
        line-height: 2.4rem;

        &:hover{
            background-color: #ed1c24;
            color: white;
            font-weight: 700;
        }
    }

    .-active{
        background-color: #ed1c24;
        color: white;
        font-weight: 700;
    }
}

.map-contact{
    box-shadow: 0px 4px 10px 0px #0000000D;
    border: 1px solid #0000001A;
    margin-bottom: 20px;
    padding: 20px;

    .map-contact-block{
        display: flex;
        flex-wrap: nowrap;

        .map-contact-left-part{
            width: 50%;

            .workin_hours_text{
                margin-bottom: 0;
            }
        }

        .map-contact-right-part{
            width: 50%;
        }
    }
}

.regions_contacts_block{
    .single_region_block{
        .region_block_header{
            font-weight: 700;
            font-size: 2.2rem;
            line-height: 2.8rem;
            color: #718093;
            margin-bottom: 20px;
        }

        .city_block_header{
            font-weight: 700;
            font-size: 2.2rem;
            line-height: 2.8rem;
            margin-bottom: 20px;
        }
    }
}

.contacts-front-page{
    margin-top: 50px;

    .offer_contact_block{
        margin-bottom: 50px;
        img{
            width: 100%;
        }
    }
}
