.helper {
	background: $color-2;
	padding-left: 56px;
	position: relative;

	&::before {
		@include content();
		width: 16px;
		height: 17px;
		background: url('../img/helper.svg') 0 0 no-repeat;
		top: 11px;
		left: 34px; }

	&-section {
		background: $color-4;
		padding: 7px 22px;
		font-size: 1.4rem; }

	&-title {
		font-weight: bold; } }
