.offers{
        width: 99%;
        padding: 20px;
        overflow: auto;
        max-height: 91vh;

        .admin-index_header{
            position: relative;

            .save_btn{
                position: fixed;
                top: 70px;
                right: 20px;
                z-index: 100;
            }
        }
}
