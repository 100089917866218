.group + .group {
	margin-top: 24px; }

.group {
	background: #fff;
	border-radius: 4px;
	overflow: hidden;
	box-shadow: 0 3px 45px rgba(#AAAAAA, .16);

	&-header {
		background: $color-4;
		padding: 32px 35px;
		@include flex();
		align-items: center; }

	&-body {
		padding: 32px 35px; }

	&-body + &-body {
		@include lt(); }

	&-footer {
		@include lt();
		padding: 32px 35px; }

	&-title {
		font-weight: bold;
		font-size: 1.8rem; }

	&-edit {
		margin-left: 18px;

		.edit {
			display: block; } }

	.cart {
		box-shadow: none;
		margin: -32px -35px;

		.cart-cell {
			&:first-child {
				@include width(55%); }

			&.cart-cell--remove {
				@include width(34px); } } }

	@media (max-width: $mdxs) {
		&-body {
			padding: 20px; }

		.cart {
			margin: -20px; } }

	@media (max-width: $mtm) {
		.cart {
			.cart-cell {
				&:first-child {
					@include width(100%); }

				&.cart-cell--remove {
					@include width(100%); } } } }

	@media (max-width: $mmm) {
		margin-left: -15px;
		margin-right: -15px;
		box-shadow: none;
		@include lb();

		&-header {
			padding-left: 15px;
			padding-right: 15px; }

		&-body {
			padding: 24px 15px;

			.cart {
				margin: -24px -15px;

				&-cell {
					padding-left: 15px;
					padding-right: 15px; } } }

		&-footer {
			padding-left: 15px;
			padding-right: 15px; } } }
