[data-select-float] {
	display: inline-block; }

.choices {
	position: relative;
	width: 100%;
	outline: none;
	text-align: left;

	.is-hidden {
		display: none; }

	&.is-open {
		z-index: 2;

		.choices__list--single {
			transition: none;

			> .choices__item {

				&::after {
					transform: rotate(180deg); } } }

		.choices__list--dropdown {
			display: block; } }

	&.is-disabled {
		.choices__list--single {
			opacity: .4; } }

	&__inner {}

	&__list--single {
		position: relative;
		z-index: 1;
		cursor: pointer;
		font-size: 1.4rem;

		> .choices__item {
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis; } }

	&__placeholder {
		color: #989898; }

	&__list--dropdown {
		position: absolute;
		padding: 0;
		top: 100%;
		left: -14px;
		width: 100%;
		display: none;
		background: #fff;
		box-shadow: 0 3px 22px rgba(#000000, .16);
		min-width: 228px;
		margin-top: 14px;
		border-radius: 4px;

		> .choices__input {
			padding: 17px 14px;
			font-size: 1.4rem;
			@include lb(); }

		.choices__item {
			padding: 17px 14px;
			font-size: 1.4rem;

			&.is-highlighted {
				background: $color-4; } }

		.choices__input {
			border: 0;
			width: 100%;
			appearance: none;
			border-radius: 0; }

		.choices__list {
			overflow: auto;
			max-height: 200px;
			-webkit-overflow-scrolling: touch;
			@include scroll(); }

		.choices__item {
			cursor: pointer;

			&.is-highlighted {} }

		.choices__placeholder {
			display: none !important; } }

	&.choices--default {
		width: 100%;

		.choices__list--single {
			> .choices__item {
				line-height: 55px;
				height: 57px;
				border: 1px solid $color-6;
				color: $color-6;
				padding: 0 14px;

				&::after {
					@include content();
					position: absolute;
					right: 14px;
					top: 50%;
					margin-top: -3.5px;
					width: 11px;
					height: 7px;
					background: url('../img/select-basic.svg') 0 0 / 100% 100% no-repeat; } } }

		.choices__list--dropdown {
			left: 0;

			> .choices__input {
				display: none; }

			.choices__item {
				display: flex;
				align-items: center;

				&__value {
					flex: 1; }

				&__label {
					background: $color-2;
					color: #fff;
					padding: 4px 17px;
					font-size: 1.2rem;
					font-weight: bold;
					margin: -4px 0; } } }

		&.is-open {
			.choices__list--single {
				> .choices__item {
					color: $color-7;
					border-color: $color-7;

					&::after {
						background-image: url('../img/select-basic-hover.svg');
						transform: none; } } } } }

	&.choices--float {
		display: inline-block;
		vertical-align: top;
		width: auto;

		.choices__list--single {
			> .choices__item {
				padding-right: 23px;
				font-weight: bold;

				&::after {
					@include content();
					position: absolute;
					right: 0;
					top: 50%;
					margin-top: -3.5px;
					width: 11px;
					height: 7px;
					background: url('../img/select-float.svg') 0 0 / 100% 100% no-repeat; } } } }

	&.choices--location {
		display: inline-block;
		vertical-align: top;
		width: auto;

		.choices__list--single {
			> .choices__item {
				padding-right: 23px;

				&::after {
					@include content();
					position: absolute;
					right: 0;
					top: 50%;
					margin-top: -8px;
					width: 16px;
					height: 16px;
					background: url('../img/select-location.svg') 0 0 / 100% 100% no-repeat; } } } }

	@media (max-width: $mdm) {
		&__list--single {
			font-size: 1.2rem; }

		&__list--dropdown {
			> .choices__input {
				font-size: 1.2rem; }

			.choices__item {
				font-size: 1.2rem; } }

		&.choices--default {
			.choices__list--single {
				> .choices__item {
					line-height: 46px;
					height: 48px; } } } } }
