/* TYPO
 * -------------------------------------------------- */

$baseSize: 10px;

$textMargin: 30px;
$headMargin: 30px;
$textHeadMargin: 30px;

$textFontFamily: sans-serif;
$headFontFamily: sans-serif;

$gap: 20px;

/* COLORS
 * -------------------------------------------------- */

$baseColor: #353B48;
$headColor: inherit;

$color-1: #BE1E2D;
$color-2: #ED1C24;
$color-3: #FBC531;
$color-4: #F5F6FA;
$color-5: #DCDDE1;
$color-6: #718093;
$color-7: #353B48;
$color-8: #58595B;
$color-9: #39342E;
$color-10: #FC4A51;
$color-11: #DDDEE1;

/* BREAKPOINTS
 * -------------------------------------------------- */

// desktop
$mdl: 1920px;
$mdm: 1600px;
$mds: 1200px;
$mdxs: 1140px;

// tablet
$mtl: 1024px;
$mtm: 960px;
$mts: 768px;

// mobile
$mml: 700px;
$mmm: 600px;
$mms: 450px;

/* MIXINS
 * -------------------------------------------------- */

/* Common */

@mixin list-scroll($type: '') {
	overflow-y: none;
	overflow-x: auto;
	-webkit-overflow-scrolling: touch;
	flex-wrap: nowrap;
	margin: -30px -15px !important;
	padding-top: 30px !important;
	padding-bottom: 30px !important;

	> .list-item {
		@if ($type == 'object') {
			padding: 0 0 0 15px !important;
			flex: 0 0 100vw !important;
			max-width: 100vw !important;
			width: 100vw !important; }
		@else {
			padding: 0 0 0 15px !important;
			flex: 0 0 calc(100vw - 40px) !important;
			max-width: calc(100vw - 40px) !important;
			width: calc(100vw - 40px) !important; }

		& + .list-item {
			margin-top: 0 !important; }

		&:last-child {
			@if ($type == 'object') {}
			@else {
				flex: 0 0 100vw !important;
				width: 100vw !important;
				max-width: 100vw !important;
				padding-right: 40px !important; } } } }

@function vw($size, $width: 1920) {
	$vw: ($width * 0.01) * 1px;
	@return ($size / $vw) * 1vw; }

@mixin content() {
	content: '';
	display: block;
	position: absolute; }

@mixin flex() {
	display: flex;
	flex-wrap: wrap;

	& > * {
		min-width: 0; } }

@mixin width($width) {
	flex: 0 0 $width;
	max-width: $width; }

@mixin clearfix() {
	&::before,
	&::after {
		content: ' ';
		display: table; }

	&::after {
		clear: both; } }

@mixin hidden() {
	position: absolute;
	left: 0;
	top: 0;
	width: 0;
	height: 0;
	visibility: hidden;
	overflow: hidden; }

@mixin link() {
	border-bottom: 1px solid currentColor;

	&:hover {
		border-bottom-color: transparent; } }

@mixin lb() {
	border-bottom: 1px solid $color-4; }

@mixin lt() {
	border-top: 1px solid $color-4; }

@mixin lr() {
	border-right: 1px solid $color-4; }

@mixin ll() {
	border-left: 1px solid $color-4; }

@mixin scroll() {
	-webkit-overflow-scrolling: touch;

	&::-webkit-scrollbar {
		width: 4px; }

	&::-webkit-scrollbar-track {
		background: none; }

	&::-webkit-scrollbar-thumb {
		background: $color-5;
		border-radius: 2px; } }

@mixin no-inner() {
	margin-left: -39px;
	margin-right: -39px;

	@media (max-width: $mdm) {
		margin-left: -31px;
		margin-right: -31px; }

	@media (max-width: $mds) {
		margin-left: -16px;
		margin-right: -16px; }

	@media (max-width: $mtm) {
		margin-left: 0;
		margin-right: 0; } }

@mixin inner-left() {
	padding-left: 39px;

	@media (max-width: $mdm) {
		padding-left: 31px; }

	@media (max-width: $mds) {
		padding-left: 16px; }

	@media (max-width: $mtm) {
		padding-left: 0; } }

@mixin inner-right() {
	padding-right: 39px;

	@media (max-width: $mdm) {
		padding-right: 31px; }

	@media (max-width: $mds) {
		padding-right: 16px; }

	@media (max-width: $mtm) {
		padding-right: 0; } }

/* Font-face */

@mixin font-face($family, $filename: $family, $weight: normal, $style: normal) {
	@font-face {
		font-family: "#{$family}";
		font-display: swap;
		src: url('../fonts/#{$filename}.woff2') format('woff2'), url('../fonts/#{$filename}.woff') format('woff');
		font-weight: $weight;
		font-style: $style; } }

/* Grid */

@mixin grid($column: 2, $vgap: $gap, $hgap: $gap, $top: 0, $bottom: 0) {
	& {
		margin-top: $top - $vgap;
		margin-left: -$hgap;
		margin-bottom: $bottom; }

	& > * {
		padding-left: $hgap;
		padding-top: $vgap;
		flex: 0 0 100%/$column;
		max-width: 100%/$column; } }
