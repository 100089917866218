









































































































.admin-header{
    height: 100%;
}
.admin-navbar{
    display: flex;
    border: 2px solid #a0a0a0;
    

    .logo{
        border-right: 2px solid #a0a0a0;
        width: 18%;
        padding: 10px;
        height: 51px;
    }

    .bar{
        width: 82%;
        display: flex;
        justify-content: space-between;
        
        .head{
            padding: 15px;
            font-size: 14px;
            font-weight: 600;
        }

        .user_block{
            padding: 4px;
            font-size: 14px;
            font-weight: 600;
            position: relative;
            display: flex;
            // width: 200px;

            &:hover{
              cursor: pointer;
            }

            .avatar{
              padding-right: 15px;
              
              img{
                height: 40px;
                width: 40px;
                border-radius: 50%;
              }
            }

            .about_user{
              p{
                margin: 0;
              }
            }
        }
    }
}
.user_block-content{
    position: absolute;
    top: 53px;
    right: 0;
    border: 2px solid #a0a0a0;
    width: 200px;
    z-index: 1000;
    background-color: #fff;

    .admin-list{
      list-style: none;
      padding: 10px;
      font-weight: 600;
      font-size: 14px;

      li:hover{
        cursor: pointer;
        color: #a0a0a0;
      }
    }
}
