.tabs--pay-delivery {
	@include flex();

	.tabs-title {
		display: block;
		@include width(20%);
		@include inner-left();

		.tabs-item {
			font-size: 1.8rem;
			font-weight: bold;
			position: relative;
			padding-left: 14px;
			transition: color .25s ease;

			&::before {
				@include content();
				left: 0;
				top: 0;
				bottom: 0;
				border-left: 4px solid $color-5;
				transition: border-color .25s ease; }

			&.-active {
				color: $color-2;

				&::before {
					border-color: $color-2; } } }

		.tabs-item + .tabs-item {
			margin-top: 28px; } }

	.tabs-content {
		flex: 1; }

	@media (max-width: $mdxs) {
		.tabs-title {
			@include width(25%); } }

	@media (max-width: $mtm) {
		padding-left: 0;
		display: block;

		.tabs-title {
			flex: none;
			width: auto;
			max-width: none;
			padding: 0;
			display: flex;
			flex-wrap: nowrap;
			white-space: nowrap;
			margin-left: -16px;
			margin-right: -16px;
			margin-bottom: 32px;
			overflow-y: hidden;
			overflow-x: auto;
			-webkit-overflow-scrolling: touch;

			.tabs-item {
				flex: none;
				padding-left: 16px;
				padding-right: 16px;

				&::before {
					display: none; } }

			.tabs-item + .tabs-item {
				margin-top: 0; } } }

	@media (max-width: $mml) {
		.tabs-title {
			.tabs-item {
				color: $color-5;

				&.-active {
					color: $color-7; } } } } }

.row--pay-delivery {
	.col {
		&:nth-child(1) {
			@include width(25%);
			padding-left: 70px; } }

	@media (max-width: $mdxs) {
		display: block;
		padding-left: 20px;

		.col {
			&:nth-child(1) {
				@include width(100%);
				padding-left: 0;
				margin-bottom: 32px;
				display: none; } } }

	@media (max-width: $mtm) {
		padding-left: 0; } }
