/* FONT
 *
 * http://www.fontsquirrel.com/fontface/generator
 * http://onlinefontconverter.com
 * http://www.font2web.com (EOT)
 * https://fonts.google.com/
 * https://transfonter.org/
 * -------------------------------------------------- */

/*
 * 100 = thin
 * 200 = extra-light
 * 300 = light
 * 400 = normal, book
 * 500 = medium
 * 600 = semi-bold
 * 700 = bold
 * 800 = heavy
 * 900 = black
 */

@include font-face('Montserrat', 'Montserrat-Light', 300);
@include font-face('Montserrat', 'Montserrat-Regular', 400);
@include font-face('Montserrat', 'Montserrat-Medium', 500);
@include font-face('Montserrat', 'Montserrat-Semibold', 600);
@include font-face('Montserrat', 'Montserrat-Bold', 700);
@include font-face('Montserrat', 'Montserrat-Black', 900);

.-fonts-ready {
	body {
		font-family: 'Montserrat', serif; } }
