.slider--advantages {}

.layout-advantages {
	&.-col-3 {
		@include grid(3, 60px, 0); }

	&.-col-4 {
		@include grid(4, 60px, 0); }

	@media (max-width: $mtm) {
		&.-col-4 {
			@include grid(3, 60px, 0); } }

	@media (max-width: $mmm) {
		@include list-scroll(); } }

.advantage {
	padding-right: 31px;

	&-icon {
		height: 60px;
		margin-bottom: 24px;

		img {
			width: auto !important; } }

	&-title {
		font-weight: bold;
		font-size: 2.3rem; }

	&-description {
		margin-top: 16px; }

	@media (max-width: $mdm) {
		&-title {
			font-size: 1.8rem; } } }
