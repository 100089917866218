






















































































































































































.cart-product{
    .color{
        width: 7.9rem;
        height: 4.2rem;
        margin-right: 2.5rem;
        cursor: unset;
    }
}
