// .section--reviews
// 	overflow: hidden

// .slider--reviews
// 	overflow: visible

.layout-reviews {
	margin: -16px 0 0 -16px;

	.list-item {
		@include width(33.33%);
		padding-left: 16px;
		padding-top: 16px; }

	&:not(.-all) {
		.list-item {
			display: none;

			&:nth-child(1),
			&:nth-child(2),
			&:nth-child(3) {
				display: block; } } }

	@media (max-width: $mtm) {
		.list-item {
			@include width(100%);
			padding-top: 0;

			& + .list-item {
				margin-top: 16px; } } }

	@media (max-width: $mmm) {
		@include list-scroll(); } }

.review {
	padding: 48px 39px 32px 39px;
	background: #fff;
	box-shadow: 0 3px 45px rgba(#AAAAAA, .16);

	&-author {
		font-weight: bold;
		margin-top: 32px;
		font-size: 1.8rem; }

	&-source {
		color: $color-2;
		margin-top: 12px; }

	@media (max-width: $mtm) {
		padding: 32px 15px; } }
