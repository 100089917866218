.switch {
	overflow: hidden;
	position: relative;
	display: inline-block;

	input {
		@include hidden();

		&:checked + span::after {
			left: 18px;
			background: #fff; }

		&:checked + span::before {
			background: $color-2;
			border-color: $color-2; } }

	span {
		display: block;
		position: relative;
		padding-left: 57px;
		font-size: 1.6rem;

		&::before {
			@include content();
			left: 0;
			top: 0;
			width: 39px;
			height: 24px;
			border-radius: 12px;
			border: 1px solid #708392;
			transition: .25s ease; }

		&::after {
			@include content();
			width: 18px;
			height: 18px;
			border-radius: 50%;
			background-color: #708392;
			z-index: 1;
			top: 3px;
			left: 3px;
			transition: .25s ease; } } }
