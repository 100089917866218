































































































































































































































































































































































.cl-prod-item__list{
    li{
        &:last-child{
            border-right: unset !important;
        }
    }
}
