


































































































































































































































.input-for-id{
    width: 30% !important;
    border-right: 1px solid red !important;
    border-radius: 0 !important;
}
.input-for-name{
    width: 70% !important;
}
