.row--catalog-constructor {
	margin-bottom: 27px;

	.col {
		&:nth-child(1) {
			padding-top: 60px;
			@include width(calc(25% - 12px)); }

		&:nth-child(2) {
			padding-left: 7.751%;

			img {
				width: 100%; } } }

	@media (max-width: $mds) {
		align-items: center;

		.col {
			&:nth-child(1) {
				padding-top: 0;
				@include width(calc(33.33% - 12px)); } } }

	@media (max-width: $mts) {
		margin-bottom: 96px;
		.col {
			&:nth-child(1) {
				@include width(calc(50% - 8px)); } } }

	@media (max-width: $mmm) {
		.col {
			display: block;

			&:nth-child(1) {
				@include width(100%);
				padding-right: 0; }

			&:nth-child(2) {
				display: none; } } } }

.catalog-controls {
	margin-bottom: 34px !important;
	position: relative;
	z-index: 2;

	&-inner {
		@include flex(); }

	&:not(.catalog-controls--free) {
		.catalog-controls-inner {
			@include grid(3, 16px, 16px); } }

	.catalog-control {
		@include flex();
		font-size: 1.2rem;

		&-label {
			margin-right: 10px;
			color: $color-6; }

		.choices__list--single {
			color: $color-6; } }

	&.catalog-controls--free {
		margin-left: -55px;

		.catalog-control {
			padding-left: 55px; } }

	@media (max-width: $mds) {
		.catalog-control {
			display: block; } }

	@media (max-width: $mmm) {
		.catalog-controls-inner {
			//flex-wrap: nowrap
			display: block;
			white-space: nowrap;

			.catalog-control {
				display: inline-block;
				flex: none;
				max-width: none;
				width: auto;
				//+width(100%)
				//margin-right: 0

				&-label {
					white-space: nowrap; } } } } }

.row--button {
	align-items: center;
	margin-top: 32px;

	.col {
		&:nth-child(1) {
			@include width(20%); } }

	@media (max-width: $mdxs) {
		.col {
			&:nth-child(1) {
				width: auto;
				flex: none;
				max-width: none;
				padding-right: 20px; }

			&:nth-child(2) {
				margin-top: 24px; } } }

	@media (max-width: $mmm) {
		display: block;

		.col {
			&:nth-child(1) {
				padding-right: 0; } } } }


.row--advantages {
	.col {
		&:nth-child(1) {
			@include width(40%);
			@include inner-left(); } }

	@media (max-width: $mds) {
		display: block;

		.col {
			&:nth-child(1) {
				@include width(100%);
				margin-bottom: $headMargin; }

			h1,
			h2,
			h3,
			h4,
			h5,
			h6 {
				br {
					display: none; } }

			&:nth-child(2) {
				@include inner-left(); } } } }

.tabs.tabs--cards {
	.tabs-title {
		justify-content: flex-start;

		br {
			display: none; }

		.tabs-item {
			font-size: 1.2rem;
			margin-right: 0;
			padding: 0 18px;
			margin-right: 18px;

			&.-active {
				font-size: 1.4rem; } } }

	@media (max-width: $mdm) {
		.tabs-title {
			justify-content: space-between;

			.tabs-item {
				margin-right: 0; } } }

	@media (max-width: 1420px) {
		.tabs-title {
			justify-content: flex-start;
			margin-top: -10px;

			.tabs-item {
				margin-right: 16px;
				margin-top: 10px; } } }

	@media (max-width: $mml) {
		.tabs-title {
			flex-wrap: nowrap;
			margin-left: -15px;
			margin-right: -15px;
			overflow-x: auto;
			overflow-y: hidden;
			-webkit-overflow-scrolling: touch;
			//white-space: nowrap
			justify-content: flex-start;
			margin-top: 0;
			padding-bottom: 15px;
			margin-bottom: 17px;

			br {
				display: block; }

			.tabs-item {
				padding: 0 15px;
				background: none;
				font-size: 1.8rem;
				color: $color-5;
				flex: none;
				margin: 0;
				line-height: 1.2;
				height: auto;
				text-align: left;

				&:hover {
					background: none;
					color: $color-5; }

				&.-active {
					font-size: 1.8rem;
					color: $color-6;
					background: none; } } } } }

.form--calculator {
	padding-left: calc(20% - 2px);

	.form-row {
		.form-item {
			@include width(33.333%); } }

	@media (max-width: $mds) {
		@include inner-left(); }

	@media (max-width: $mml) {
		.form-row {
			.form-item {
				@include width(100%); } } } }

.section--catalog-calculator {
	@media (max-width: $mml) {
		display: none; } }
