










































































































































































































































































































































































































































































































































































































































































































































.header-address{
    position: relative;   
}
.addresses-list-block{
    position: absolute;
    top: 20px;
    right: 0;
    background-color: white;
    padding: 20px;
    border: 1px solid #e7e7e7;
    border-radius: 10px;

    .address-item{
        margin-bottom: 10px;
    }
}
.navbar_link_email{
    text-decoration: unset;
    &:hover{
        color: #ED1C24;
    }
}

.right-part{
    display: flex;
    flex-wrap: nowrap;
    position: relative;
}

.navbar_city_block{
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    padding-top: 28px;
    margin-right: 18px;
    position: relative;
    justify-content: flex-end;

    .open_region_block{
        position: relative;

        .open_region_row{
            display: flex;
            align-items: center;
            cursor: pointer;
        }
    }

    .point_icon{
        height: 24px;
        width: 24px;
        margin-right: 4px;
    }

    .arrow_icon{
        height: 16px;
        width: 16px;
    }

    .navbar_city_name{
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        margin: 0;
        margin-right: 10px;
    }

    .work_button{
        border-radius: 10px;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        padding: 4px 20px;
        margin-left: 18px;
    }
}

.header-bottom-block{
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    width: 100%;

    .header-logotype-nd{
        margin-right: 18px;

        .logotype{
            // height: 27px;
            height: 100%;
            width: 240px;
            float: right;
            text-align: center;

            img{
                // width: 184px;
                // height: 26px;
                height: 63px;
                width: 158px;
                max-width: 100% !important;
            }
        }
    }

    .header-search-nd{
        margin-right: 20px;
        .search{
            max-width: 100%;
        }
    }

    .header-contacts-nd{
        .right-part{
            align-items: center;

            .header-phone{
                display: flex;
                flex-wrap: nowrap;
                align-items: center;
                cursor: pointer;

                img{
                    padding-left: 10px;
                    padding-right: 11px;
                }
            }
        }

        .full_basket_icon{
            height: 35px;
            margin-right: 20px;
            cursor: pointer;
        }

        .lk_icon{
            height: 35px;
            cursor: pointer;
        }
    }
}

.regions_list_block{
    position: absolute;
    top: 40px;
    left: 0;
    background-color: white;
    box-shadow: 0px 4px 20px 0px #0000001A;
    padding-top: 25px;
    padding-left: 25px;
    padding-right: 40px;
    padding-bottom: 50px;
    border-radius: 10px;

    .lists{
        display: flex;
    }

    .regions_header{
        font-weight: 700;
        font-size: 2.2rem;
        line-height: 2.4rem;

        margin-bottom: 39px;
    }

    .regions_list{
        margin-left: 0;
        margin-right: 60px;
        list-style-type: none;

        font-weight: 500;
        font-size: 1.8rem;
        line-height: 2.4rem;

        li{
            margin-bottom: 20px;
            display: flex;
            align-items: center;
            
            img{
                margin-left: 10px;
            }

            &:hover{
                color: #ED1C24;
                cursor: pointer;
            }
        }
        .-active{
            color: #ED1C24;
            font-weight: 600;
        }
    }

    .cities_list{
        margin-left: 0;
        list-style-type: none;

        font-weight: 500;
        font-size: 1.8rem;
        line-height: 2.4rem;

        li{
            margin-bottom: 10px;

            &:hover{
                color: #ED1C24;
                cursor: pointer;
            }
        }

        .-active{
            font-weight: 600;
            color: #ED1C24;
        }
    }
}

.offices_block{
    position: absolute;
    top: 43px;
    right: 110px;
    background-color: white;
    box-shadow: 0px 4px 20px 0px #0000001A;
    border-radius: 10px;
    width: 100%;
    min-width: 400px;
    padding-left: 25px;
    padding-top: 22px;
    padding-right: 34px;
    padding-bottom: 21px;
    // display: flex;
    // flex-direction: column;
    // justify-content: start;

    .phone_icon{
        margin-right: 10px;
    }

    .call_button{
        border-radius: 10px;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        padding: 8px 10px;
        margin-bottom: 22px;
    }

    .offices_subblock{
        margin-bottom: 20px;
    }

    .offices_block_header{
        font-weight: 600;
        font-size: 1.8rem;
        line-height: 2.4rem;
        margin-bottom: 9px;
    }

    .offices_block_text{
        font-weight: 400;
        font-size: 1.8rem;
        line-height: 2.4rem;
        margin-bottom: 9px;
        color: grey;
    }
}

.navbar_offices_ul{
    li{
        // margin-left: -20px;

        &::marker{
            color: #ED1C24;
        }
    }
}
