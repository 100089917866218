






























































































































































































































































































































































































































































.admin-contacts-list-block{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
