.subhero {
	min-height: 490px;
	height: vw(490px);
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	color: inherit;
	padding-top: 105px;

	@media (max-width: $mtm) {
		margin-top: 50px;

		&.section--cards-tabs {
			margin-top: 50px; } }

	@media (max-width: $mml) {
		height: 440px;
		min-height: 0;
		padding-top: 80px;

		& + .section--cards {
			margin-top: 50px;
			overflow: visible; } } }

.section--breadcrumbs + .subhero {
	margin-top: -56px;

	@media (max-width: $mml) {
		margin-top: 0; } }
