.description {
	padding: 34px 30px 41px 30px;
	background: $color-4;

	hr {
		margin: 31px 0;
		background: $color-5; }

	@media (max-width: $mtm) {
		margin-left: -15px;
		margin-right: -15px;
		padding-left: 15px;
		padding-right: 15px; } }
