.radio,
.checkbox {
	overflow: hidden;
	position: relative;
	display: inline-block;

	input {
		@include hidden();

		&:checked + span {
			&::before {
				border-color: $color-2; }

			&::after {
				opacity: 1; } } }

	span {
		display: block;
		line-height: 24px;
		padding-left: 40px;
		position: relative;
		font-size: 1.2rem;
		color: $color-6;

		&::before {
			@include content();
			width: 24px;
			height: 24px;
			background: transparent;
			top: 0;
			left: 0;
			transition: background-color .25s, opacity .25s, border-color .25s;
			border: 1px solid $color-6; }

		&::after {
			@include content();
			width: 14px;
			height: 14px;
			background: $color-2;
			left: 5px;
			top: 5px;
			opacity: 0;
			transition: background-color .25s, opacity .25s, border-color .25s; } }

	&.checkbox--primary,
	&.radio--primary {
		span {
			font-size: 1.6rem; } } }

.radio {
	span {
		&::before,
		&::after {
			border-radius: 50%; } } }

.checkbox-plus {
	//overflow: hidden
	position: relative;
	display: inline-block;

	input {
		@include hidden();

		&:checked + b::before,
		&:checked + b::after {
			opacity: 1 !important; } }

	span {
		display: block;
		padding-left: 67px;
		position: relative;
		font-size: 1.2rem;
		color: $color-6; }

	b {
		display: block;
		position: absolute;
		width: 32px;
		height: 32px;
		border: 1px solid $color-6;
		top: 0;
		left: 0;

		&::before,
		&::after {
			@include content();
			opacity: 0;
			top: 50%;
			left: 50%;
			background: $color-6;
			transition: opacity .25s ease; }

		&::before {
			width: 18px;
			height: 1px;
			margin-left: -9px;
 }			//margin-top: -0.5px

		&::after {
			width: 1px;
			height: 18px;
			margin-top: -9px;
 } } }			//margin-left: -0.5px
