.admin-documents{
    .admin-documents_single-document{
        .document-image{
            max-width: 35rem !important;
        }

        .default-pdf-iamge{
            width: 180px;
        }
    }
    
}
