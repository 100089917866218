.admin-settings{
    width: 82%;
    padding: 20px;
    overflow: auto;
    max-height: 91vh;

    .admin-index_header{
        position: relative;

        .save_btn{
            position: fixed;
            top: 70px;
            right: 20px;
            z-index: 100;
        }
    }
    .admin-documents_single-document{
        .default-pdf-iamge{
            width: 180px;
        }
    }
    .content{
        .setting-cards{
            margin-bottom: 40px;

            .table{
                display: flex;
                margin-bottom: 40px;

                .category{
                    border: 1px solid #dddddd;
                    padding-left: 10px;
                    padding-right: 10px;

                    height: 100%;
                    min-height: 5rem;

                    display: flex;
                    align-items: center;

                    &:hover{
                        background-color: #dddddd;
                        cursor: pointer;
                    }

                    p{
                        margin-left: 0;
                        padding-top: 0;
                    }
                }
                .-active {
                    font-weight: bold;
                    background-color: #dddddd;

                    &:hover{
                        background-color: #cdcdcd;
                    }
                }
            }

            .form{
                width: 900px;

                .header{
                    display: flex;
                    margin-bottom: 10px;

                    p{
                        margin: 0;
                    }
                }

                .list{
                    .list-item{
                        display: flex;
                        margin-bottom: 10px;

                        .position{
                            display: flex;
                            border: 1px solid black;
                            width: 90%;
    
                            p{
                                margin: 0;
                                margin-top: 15px;
                                margin-bottom: 15px;
                                padding-top: 0;
                            }
    
                            .sort{
                                font-weight: bold;
    
                                input{
                                    margin: 0;
                                    margin-top: 6px;
                                    padding: 0;
    
                                    width: 32px;
                                    height: 32px;
    
                                    font-size: 13px;
                                    text-align: center;
                                }

                                i{
                                    margin-top: 15px;

                                    &:hover{
                                        cursor: pointer;
                                    }
                                }
                            }
    
                            .placeholder{
    
                                input{
                                    margin: 0;
                                    margin-top: 6px;
                                    padding: 0;
    
                                    width: 100%;
                                    height: 32px;
    
                                    font-size: 13px;
                                    text-align: center;
                                }
                            }

                        }


                        .item-base_name{
                            width: 10%;

                            font-size: 13px;
                            font-weight: 700;
                        }
                    }
                }
                
                
                .placeholder{
                    width: 25%;
                    text-align: center;           
                }

                .type{
                    width: 15%;
                    text-align: center;
                }

                .sort{
                    width: 15%;
                    text-align: center;
                }

                .name{
                    width: 30%;
                }

                .visible{
                    width: 15%;
                    text-align: center;
                    
                    .checkbox-plus{
                        vertical-align: text-top;
                        margin-top: 6px;

                        b{
                            left: -15px;
                        }
                    }
                }
            }

            .formula{
                .header{
                    margin: 0;
                    font-size: 15px;
                    font-weight: 600;
                }

                .block{
                    display: flex;
                    width: 600px;

                    p{
                        width: 20%;
                        margin: 0;
                        font-size: 14px;
                    }
                    input{
                        width: 80%;
                        height: 20px;
                    }
                }
            }
        }

        .setting-emails{
            .email-block{
                padding: 15px;
                width: 80%;
        
                h4{
                    margin-bottom: 10px;
                }
        
                .single-email-block{
                    display: grid;
                    position: relative;
        
                    input{
                        max-width: 90%;
                    }
                }
            }
        }
    }
}
