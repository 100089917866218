.layout-colors {
	margin: -16px 0 0 -16px;

	.list-item {
		padding-left: 16px;
		padding-top: 16px; } }

.color {
	text-align: center;
	cursor: pointer;

	input {
		@include hidden();

		&:checked + span > span {
			opacity: 1; } }

	&-preview {
		display: block;
		width: 38px;
		height: 38px;
		border-radius: 50%;
		border: 1px solid;
		position: relative;
		margin: 4px;

		span {
			display: block;
			width: 48px;
			height: 48px;
			border-radius: 50%;
			border: 1px solid;
			position: absolute;
			top: -6px;
			left: -6px;
			opacity: 0;
			transition: opacity .25s ease; } }

	&-label {
		display: block;
		margin-top: 8px;
		font-size: 1.2rem;
		color: $color-6; } }
