




































































































































































































































































































.admin-index{
    width: 82%;
    padding: 20px;
    overflow: auto;
    max-height: 91vh;

    .admin-index_header{
        position: relative;

        .save_btn{
            position: fixed;
            top: 70px;
            right: 20px;
            z-index: 100;
        }
    }

    .admin-index-slider{
        .slides{
            .slide{
                display: grid;
                position: relative;
                margin-bottom: 30px;

                .header{
                  margin-bottom: 10px;
                }

                img{
                  width: 50%;
                }
            }
        }
    }
    
    .admin-index-catalog{
      margin-top: 50px;
      margin-bottom: 50px;

      h4{
        margin-bottom: 10px;
      }

      .single-catalog{
        display: grid;
        margin-bottom: 10px;

        .header{
          margin-bottom: 10px;
        }
      }
    }

    .admin-index-about{
      display: grid;

      .header{
        margin-bottom: 20px;
      }
      .editor{
        margin-top: 20px;
        margin-bottom: 100px;
      }
    }

    .admin-index-subdivisions{
        .map{
          width: 1000px;
          
          .map-area{
            max-width: 90%;
          }
        }

        .subdivisions-block{
            display: flex;

            .subdivisions{
                width: 380px;
                margin-right: 50px;

                .header_block{
                    font-size: 15px;
                    font-weight: 600;
                    margin-bottom: 15px;
                }

                .addform{
                    display: flex;

                    input{
                        margin-right: 10px;
                    }

                    .button{
                        padding-top: 8px;
                        padding-bottom: 10px;
                    }
                }

                .subdivisions-list{

                    .list{
                        p{
                            padding: 10px;
                            padding-left: 20px;
                            font-size: 15px;
                            margin-bottom: 0;
                        }

                        li{
                          display: flex;
                          width: 100%;
                          justify-content: space-between;
                          padding-right: 20px;

                          i{
                            font-size: 13px;
                            margin-top: 15px;
                            float: right;

                            &:hover{
                              cursor: pointer;
                            }
                          }
                        }
                    }
                }
            }

            .productions{
                width: 380px;

                .header_block{
                    font-size: 15px;
                    font-weight: 600;
                    margin-bottom: 15px;
                }

                .addform{
                    display: flex;

                    input{
                        margin-right: 10px;
                    }

                    .button{
                        padding-top: 8px;
                        padding-bottom: 10px;
                    }
                }

                .subdivisions-list{

                    .list{
                        p{
                            padding: 10px;
                            padding-left: 20px;
                            font-size: 15px;
                            margin-bottom: 0;
                        }

                        li{
                          display: flex;
                          width: 100%;
                          justify-content: space-between;
                          padding-right: 20px;

                          i{
                            font-size: 13px;
                            margin-top: 15px;
                            float: right;

                            &:hover{
                              cursor: pointer;
                            }
                          }
                        }
                    }
                }
            }
        }
    }

    .admin-index-clients{
        margin-top: 50px;

        h4{
            margin-bottom: 10px;
        }
    }

    .admin-index-goods{
        margin-top: 50px;

        h4{
            margin-bottom: 10px;
        }
    }

    .admin-index-shares{
        margin-top: 50px;

        h4{
            margin-bottom: 10px;
        }
    }
}
