.dropdown {
	background: #fff;
	border-radius: 4px;
	box-shadow: 0 3px 35px rgba(#AAAAAA, .16);
	position: absolute;
	display: none;

	&.-active {
		display: block; }

	&::before {
		@include content();
		left: 35%;
		bottom: 100%;
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 0 7.5px 15px 7.5px;
		border-color: transparent transparent #fff transparent; }

	&-group {
		padding: 24px;

		&-title {
			font-weight: 700;
			font-size: 1.6rem;
			margin-bottom: 16px; } }

	&-group + &-group {
		@include lt(); }

	.layout-buttons {
		margin: -16px 0 0 -16px;

		.list-item {
			padding: 16px 0 0 16px;
			flex: 1; } }

	.button {
		padding-left: 10px;
		padding-right: 10px;
		width: 100%;
		white-space: nowrap;
		font-size: 1.4rem; } }
