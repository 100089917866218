.map {

	&-filters {
		padding-left: 33.33%;
		margin-bottom: 44px;

		&-list {
			.list-item {
				@include width(50%);
				font-weight: bold;
				color: $color-5;
				font-size: 2.4rem;

				&.-active {
					color: $baseColor; } } } }

	&-area {
		position: relative;
		max-width: 75%;
		margin: 0 auto;

		img {
			width: 100%; } }

	&-points {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0; }

	&-point {
		position: absolute;
		width: 16px;
		height: 16px;
		border-radius: 50%;
		cursor: pointer;
		//transform: translate(-50%, -50%)

		&::before,
		&::after {
			@include content();
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			border-radius: 50%;
			border: 1px solid transparent; }

		&::before {
			width: 26px;
			height: 26px;
			opacity: .3;
			animation: markerSmallInner 2s ease 0s infinite; }

		&::after {
			width: 36px;
			height: 36px;
			opacity: .1;
			animation: markerSmallOuter 2s ease .25s infinite; }

		&.map-point--large {
			width: 26px;
			height: 26px;

			&::before {
				width: 41px;
				height: 41px;
				animation: markerLargeInner 2s ease 0s infinite; }

			&::after {
				width: 56px;
				height: 56px;
				animation: markerLargeOuter 2s ease .25s infinite; } }

		&.map-point--red {
			background: #BE1E2D;

			&::before,
			&::after {
				border-color: #BE1E2D; } }

		&.map-point--blue {
			background: #295ED6;

			&::before,
			&::after {
				border-color: #295ED6; } }

		&.map-point--1 {
			top: 75.92%;
			left: 18.41%; }

		&.map-point--2 {
			top: 58.64%;
			left: 26%; }

		&.map-point--3 {
			top: 88.88%;
			left: 32.09%; }

		&.map-point--4 {
			top: 61.41%;
			left: 50.16%; }

		&.map-point--5 {
			top: 64.19%;
			left: 57.43%; }

		&.map-point--6 {
			top: 54.32%;
			left: 12.5%; }

		&.map-point--7 {
			top: 68.51%;
			left: 15.37%; }

		&.map-point--8 {
			top: 72.22%;
			left: 22.8%; }

		&.map-point--9 {
			top: 71.91%;
			left: 62.16%; } }

	&-locations {
		padding-left: 33.33%;
		margin-top: 42px;

		&-list {
			margin-top: -27px;
			font-size: 2.2rem;

			.list-item {
				@include width(50%);
				padding-left: 27px;
				position: relative;
				left: -27px;
				margin-top: 27px;

				&.-active-manufacture {
					color: #295ED6;

					svg {
						fill: #295ED6; } }

				&.-active-subdivision {
					color: #ED1C24;

					svg {
						fill: #ED1C24; } } }

			svg {
				fill: $color-6;
				width: 12px;
				height: 18px;
				position: absolute;
				left: 0;
				top: 0.45em; } } }

	@media (max-width: $mdm) {
		&-filters {
			&-list {
				.list-item {
					font-size: 1.8rem; } } }

		&-locations {
			&-list {
				font-size: 1.6rem;

				svg {
					width: 11px;
					height: 16px;
					top: 0.35em; } } } }

	@media (max-width: $mds) {
		&-area {
			max-width: 80%; }

		&-filters {
			padding-left: 20%; }

		&-locations {
			padding-left: 20%; } }

	@media (max-width: $mts) {
		&-area {
			max-width: 100%; }

		&-filters {
			padding-left: 0; }

		&-locations {
			padding-left: 0; } }

	@media (max-width: $mms) {
		&-filters {
			&-list {
				-webkit-overflow-scrolling: touch;
				flex-wrap: nowrap;
				overflow-x: scroll;
				overflow-y: hidden;
				margin-left: -15px;
				margin-right: -15px;

				.list-item {
					padding-left: 15px;
					padding-right: 15px;
					flex: none;
					max-width: none;
					width: auto; } } }

		&-locations {
			&-list {
				.list-item {
					@include width(100%); } } } } }

@keyframes markerSmallInner {
	0% {
		opacity: 0;
		width: 0;
		height: 0; }
	70% {
		opacity: .2; }
	100% {
		opacity: 0;
		width: 26px;
		height: 26px; } }

@keyframes markerSmallOuter {
	0% {
		opacity: 0;
		width: 0;
		height: 0; }
	70% {
		opacity: .2; }
	100% {
		opacity: 0;
		width: 36px;
		height: 36px; } }

@keyframes markerLargeInner {
	0% {
		opacity: 0;
		width: 0;
		height: 0; }
	70% {
		opacity: .2; }
	100% {
		opacity: 0;
		width: 41px;
		height: 41px; } }

@keyframes markerLargeOuter {
	0% {
		opacity: 0;
		width: 0;
		height: 0; }
	70% {
		opacity: .2; }
	100% {
		opacity: 0;
		width: 56px;
		height: 56px; } }
