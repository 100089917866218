






























































.pdf-preview-modal{
    .modal-container{
        height: 600px;
        overflow: hidden;
    }

    .embed_pdf_file{
        width: 400px;
        height: 480px;
    }

    .list-link{
        &:hover{
            color: #ED1C24;
        }
    }
}
