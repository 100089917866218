.success {
	text-align: center;
	font-size: 1.2rem;

	&-image {
		margin-bottom: 32px; }

	&-title {
		font-size: 1.8rem;
		margin-bottom: 16px;
		font-weight: bold; } }

.modal {
	&.modal--success {
		.modal-container {
			overflow: hidden;
			max-width: 330px;
			border-radius: 4px;
			box-shadow: 0 3px 22px rgba(#000, .16);
			padding: 0; }

		.modal-close {
			padding: 0 3.3rem 0 0;
			margin: 0;
			border: 0;
			background: url('../img/close-circle.svg') 100% 50% / 2.1rem 2.1rem no-repeat;
			font-size: 1.2rem;
			color: #353B48;
			line-height: 2.1rem;
			transition: opacity .25s ease;

			&:hover {
				opacity: .5; } }

		.modal-header {
			background: #F5F6FA;
			height: 48px;
			text-align: right;
			padding: 0 18px;
			display: flex;
			align-items: center;
			justify-content: flex-end; }

		.modal-body {
			padding: 60px 20px 85px 20px; } } }
