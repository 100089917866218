









































































































.about_company_nd_news{
    .news_block{
        margin-bottom: 200px;

        .header_block{
            margin-bottom: 120px;
            display: flex;
            flex-wrap: nowrap;
            justify-content: space-between;

            .news_header{
                font-weight: 600;
                font-size: 3.2rem;
                line-height: 3.8rem;
                margin: 0;
            }

            .news_header_button{
                border: 1px solid black;
                background-color: white;
                font-weight: 600;
                font-size: 1.8rem;
                line-height: 2.3rem;
                padding: 10px 20px;
            }
        }

        .news_list{
            display: flex;
            flex-wrap: nowrap;
            gap: 20px;

            .single_news{
                width: 33%;

                .single_news_data{
                    font-weight: 700;
                    font-size: 1.8rem;
                    line-height: 2.3rem;
                    color: #808080;
                    padding: 0 10px;
                    margin-bottom: 20px;
                }

                .single_news_name{
                    font-weight: 600;
                    font-size: 2.3rem;
                    line-height: 2.8rem;
                    padding: 0 10px;
                }

                img{
                    width: 100%;
                }

                &:hover{
                    cursor: pointer;
                }
            }
        }
    }
}

.custom_pagination{
    list-style-type: none;
    display: flex;
    gap: 16px;
    justify-content: flex-end !important;

    .pagination-item{
        button{
            background-color: #fff;
            border: unset;
            font-weight: 400;
            font-size: 22px;
        }
    }

    .pagination-item--active{
        button{
            background-color: #ED1C24;
            border-radius: 10px;
            color: #fff;
            padding: 2px 10px;
        }
    }
}
