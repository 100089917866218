.tooltip {
	padding: 16px 27px 16px 54px;
	color: $baseColor;
	opacity: 1;
	background: url('../img/info.svg') 20px 16px / 18px 18px no-repeat #EAEEF6;
	font-size: 1.2rem;
	max-width: 750px;
	text-align: left;
	border: 1px solid $color-6;
	z-index: 10;

	&-arrow {
		display: none; }

	&[x-placement="top"],
	&[x-placement="top-end"],
	&[x-placement="top-start"] {
		margin-bottom: 5px; }

	&[x-placement="bottom"],
	&[x-placement="bottom-end"],
	&[x-placement="bottom-start"] {
		margin-top: 5px; }

	&[x-placement="left"] {
		margin-right: 5px; }

	&[x-placement="right"] {
		margin-left: 5px; }

	@media (max-width: $mtm) {
		max-width: 250px; } }
