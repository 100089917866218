

































































































































































.admin_utm_page{
    .region_utm_select{
        height: 5.5rem; 
        padding-right: 35px !important;
    }

    i{
        color: red;
        &:hover{
            cursor: pointer;
        }
    }
}
