








































.single_address_block{
    position: relative;

    .icon_delete_address{
        color: #ED1C24;
        position: absolute;
        top: 55%;
        right: 10%;
        cursor: pointer;
    }
}
