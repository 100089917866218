






































































































































































































































































































































































































































































































































































































































































































































































































































.lk-single-order-page{
    margin-top: 50px;

    .button-back-block{
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;

        .button{
            display: flex;
            flex-wrap: nowrap;
            align-items: center;

            border: 1px solid #0000001A;
            border-radius: 10px;
            background-color: #fff;

            padding: 10px 20px;

            &:hover{
                background-color: #eeeeee;
            }

            svg{
                margin-right: 10px;
            }
        }

        .confirm_block_buttons{
            display: flex;
            flex-wrap: nowrap;
            gap: 10px;

            .button_confirm{
                color: #fff;
                background-color: black;

                &:hover{
                    background-color: #454545;
                }
            }

            .button_decline{
                &:hover{
                    color: #353B48;
                }
            }
        }
    }

    .lk-single-order-page_content{
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        padding-bottom: 30px;

        border-bottom: 1px solid #00000080;

        .order-status-button{
            color: #fff;
            padding: 4px 10px;
            border-radius: 100px;
            font-weight: 600;
            font-size: 15px;
            line-height: 18px;
        }

        .order-status-button_green{
            background-color: #039B24;
        }
        .order-status-button_lightgreen{
            background-color: #05DB41;
        }
        .order-status-button_lightblue{
            background-color: #4BB5E3;
        }
        .order-status-button_blue{
            background-color: #0071B5;
        }
        .order-status-button_purple{
            background-color: #BD01DC;
        }
        .order-status-button_black{
            background-color: black;
        }
        .order-status-button_red{
            background-color: #ED1C24;
        }
        .order-status-button_yellow{
            background-color: #C9A901;
        }
        .order-status-button_orange{
            background-color: #D59009;
        }
        .order-status-button_grey{
            background-color: #718093;
        }


        .left-part{
            margin-top: 28px;
            width: 20%;

            display: flex;
            flex-wrap: wrap;

            .order-number{
                color: black;
                font-weight: 700;
                font-size: 16px;
                line-height: 19px;
                margin-bottom: 19px;
                margin-top: 20px;
                width: 100%;
            }

            .company-name{
                color: #718093;
                font-weight: 700;
                font-size: 16px;
                line-height: 19px;
                width: 100%;
                margin-bottom: 30px;
            }

            .mid_part_row{
                display: flex;
                flex-wrap: nowrap;
                gap: 30px;
                margin-bottom: 10px;

                p{
                    margin-bottom: 0;
                }

                .mid_part_row-value{
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 19px;
                    color: black;
                }

                .mid_part_row-name{
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 21px;
                    color: #00000080;
                }
            }

            .manager_header_block{
                width: 100%;
                margin-bottom: 10px;

                p{
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 21px;
                    color: #00000080;
                }
            }

            .manager_subheader_block{
                width: 100%;
                margin-bottom: 30px;

                p{
                    color: black;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 19px;
                }
            }
        }

        .right-part{
            margin-top: 102px;

            display: flex;
            flex-direction: column;
            align-items: flex-end;
            
            .single_link{
                display: flex;
                flex-wrap: nowrap;
                align-items: center;
                text-align: end;

                margin-bottom: 20px;

                p{
                    margin-bottom: 0;
                    margin-right: 20px;

                    font-weight: 400;
                    font-size: 14px;
                    line-height: 21px;
                    color: black;
                }

                svg{
                    &:hover{
                        cursor: pointer;
                    }
                }
            }
            
            .button{
                border-radius: 10px;
                color: #fff;
                background-color: #171717;

                padding: 10px 20px;

                &:hover{
                    background-color: #454545;
                }
            }
        }
    }

    .order_details_block{
        margin-top: 33px;

        .buttons_block{
            display: flex;
            flex-wrap: wrap;
            gap: 10px;
            margin-bottom: 42px;

            .button{
                padding: 4px 20px;
                font-weight: 400;
                font-size: 17px;
                line-height: 22px;
                border-radius: 100px;
            }

            .-active{
                color: #fff;
                background-color: #ED1C24;
            }
        }

        .order-table{
            .order-table_headers{
                display: flex;
                flex-wrap: nowrap;
                align-items: center;
                gap: 25px;
                padding: 10px 0;


                p{
                    margin-bottom: 0;
                    color: #00000080;
                }

                .sub_table{
                    display: flex;
                    flex-wrap: nowrap;
                    align-items: center;
                }
            }

            .order-table_rows{
                .order-table_rows-single{
                    .details{
                        display: flex;
                        flex-wrap: nowrap;
                        align-items: center;
                        gap: 25px;
                        padding: 10px 0;
                    }

                    .sub_table{
                        display: flex;
                        align-items: center;
                        margin-bottom: 10px;
                    }

                    .order-table_bottom{
                        display: flex;
                        flex-wrap: nowrap;
                        align-items: center;
                        gap: 12px;
                        padding-bottom: 10px;
                        margin-top: 22px;

                        p{
                            margin-bottom: 0;
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 21px;
                            color: black;
                        }

                        &:hover{
                            cursor: pointer;
                        }
                    }

                    .subpositions_details{
                        .subpositions_details_once{
                            display: flex;
                            flex-wrap: nowrap;
                            align-items: center;
                            gap: 25px;
                            padding: 10px 0;
                        }
                    }
                }
            }
        }
    }
}
