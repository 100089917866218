.file {
	position: relative;
	overflow: hidden;
	width: 100%;
	cursor: pointer;

	input {
		@include hidden(); }

	span {
		display: block;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;

		@media (min-width: $mml + 1px) {
			border: 1px dotted $color-6;
			padding: 17px 10px 17px 35px;
			font-size: 1.4rem;
			color: $color-6;
			background: url('../img/upload.svg') 15px 50% / 13px 12px no-repeat; } }

	@media (min-width: $mml + 1px) and (max-width: $mdm) {
		span {
			font-size: 1.2rem;
			padding: 14px 10px 14px 35px; } }

	@media (max-width: $mml) {
		span {
			text-decoration: none;
			padding: 13px 15px 14px 15px;
			border: 0;
			border-radius: 0;
			text-align: center;
			transition: color .25s, background-color .25s, opacity .25s, border-color .25s;
			font-size: 1.4rem;
			font-weight: bold;
			background: $color-2;
			color: #fff; } } }
