.section--404 {
	margin-top: -22px;
	height: calc(100vh - 155px);
	background: url('../img/404.svg') 50% 50% no-repeat;

	.container {
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center; }

	@media (max-width: $mtm) {
		height: calc(100vh - 46px);
		margin-top: -16px; } }

.error-404 {
	text-align: center;
	margin-top: -84px;

	&-title {
		font-weight: bold;
		font-size: 17rem; }

	@media (max-width: $mms) {
		&-title {
			font-size: 14rem; } } }
