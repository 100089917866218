/* HERO OFFERS
 * -------------------------------------------------- */

.slider--hero-offers {
	margin-top: 32px;
	margin-bottom: 70px;

	.swiper-slide {
		//height: 470px
		height: vw(470px, 1440);
		min-height: 470px;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center; }

	.slider-title {
		margin-top: 76px;
		font-size: 7.2rem;
		color: #fff;
		font-weight: bold;
		text-shadow: 0 3px 6px rgba(#000, .16);
		line-height: 1.227272; }

	.slider-description {
		margin-top: 16px;
		color: #fff; }

	.slider-controls {
		max-width: 1920px;
		padding-left: 130px;
		width: 100%;
		left: 50%;
		transform: translateX(-50%);
		position: absolute;
		bottom: 53px;
		z-index: 1;
		display: flex;
		align-items: center; }

	.swiper-button-prev,
	.swiper-button-next,
	.swiper-pagination {
		position: static;
		padding: 0;
		margin: 0;
		text-align: left;
		font-size: 0; }

	.swiper-button-prev,
	.swiper-button-next {
		width: 12px;
		height: 9px;
		background: url('../img/slider-arrow.svg') 0 0 / 100% 100% no-repeat; }

	.swiper-button-prev {
		margin-right: 12px; }

	.swiper-button-next {
		margin-left: 12px;
		transform: rotate(180deg); }

	.swiper-pagination {
		.swiper-pagination-bullet {
			width: 12px;
			height: 12px;
			border: 1px solid transparent;
			border-radius: 50%;
			margin: 0 4px;
			opacity: 1;
			background: transparent;
			position: relative;
			transition: border-color .25s ease;

			&::before {
				@include content();
				width: 6px;
				height: 6px;
				background: #fff;
				border-radius: 50%;
				top: 50%;
				left: 50%;
				margin-top: -3px;
				margin-left: -3px; }

			&.swiper-pagination-bullet-active {
				border-color: #fff; } } }

	@media (max-width: $mdm) {
		.slider-title {
			font-size: 4.4rem; }

		.slider-controls {
			max-width: 1440px;
			padding-left: 93px; } }

	@media (max-width: $mtm) {
		.slider-controls {
			padding-left: 15px; } }

	@media (max-width: $mml) {
		.swiper-slide {
			height: 460px;
			min-height: 0; }

		.slider-title {
			margin-top: 64px;
			font-size: 2.4rem; }

		.slider-controls {
			bottom: 26px; } } }

/* OFFER
 * -------------------------------------------------- */

.offer {
	@include flex();

	&-image {
		@include width(65%);

		img {
			width: 100%; } }

	&-content {
		padding: 0 31px 0 53px;
		flex: 1; }

	@media (max-width: $mdxs) {
		display: block;

		&-image {
			@include width(100%);
			margin-bottom: 32px; }

		&-content {
			padding: 0; } } }
