.result {
	background: $color-2;
	color: #fff;

	&-header,
	&-body,
	&-footer {
		padding-left: 34px;
		padding-right: 34px; }

	&-header {
		padding-top: 30px;
		padding-bottom: 16px;
		border-bottom: 1px solid #FF8084; }

	&-body {
		padding-top: 32px;
		padding-bottom: 32px; }

	&-footer {
		padding-top: 16px;
		padding-bottom: 22px;
		border-top: 1px solid #FF8084; }

	&-title {
		font-weight: bold;
		font-size: 1.6rem; }

	&-group + &-group {
		margin-top: 24px; }

	&-label {
		font-size: 1.2rem; }

	&-value {
		font-weight: bold;
		font-size: 3.2rem;
		line-height: 1.2; }

	&-note {
		font-size: 1.2rem; }

	&-note + &-note {
		margin-top: 12px; }

	.edit {
		margin-left: 18px;

		svg {
			fill: #fff; } }

	@media (max-width: 1300px) {
		&-value {
			font-size: 2.4rem; } }

	@media (max-width: $mds) {
		&-header,
		&-body,
		&-footer {
			padding-left: 24px;
			padding-right: 24px; } }

	@media (max-width: $mml) {
		&-header,
		&-body {
			text-align: center; } } }
