.layout-events-page {
	display: block;
	overflow: hidden;
	@include no-inner();
	margin-top: -57px;

	.list-item {
		display: inline-block;
		margin-left: -.28em;
		width: 20%;
		@include inner-left();
		padding-top: 57px;
		vertical-align: top;

		&.list-item--clear {
			width: 100%;
			clear: both;
			padding-top: 0; }

		&.list-item--double {
				width: 40%;
				float: right; } }
	// 		@media (min-width: $mdxs + 1px)
	// 			+width(40%)
	// 			position: relative

	// 			.event
	// 				position: absolute
	// 				right: 0
	// 				top: 57px
	// 				left: 31px

	@media (max-width: $mdxs) {
		.list-item {
			//+width(25%)
			width: 25%;

			&.list-item--double {
				float: none;
				width: 25%; }

			&.list-item--empty {
				display: none; }

			&.list-item--clear {
				display: none; } } }

	@media (max-width: $mtl) {
		.list-item {
			//+width(50%)
			width: 50%;

			&.list-item--double {
				width: 50%; } } }

	@media (max-width: $mmm) {
		@include flex();
		@include list-scroll();

		.list-item {
			display: block;
			margin-left: 0; } } }

.layout-events {
	//+no-inner()
	margin-top: -57px;

	.list-item {
		@include width(20%);
		@include inner-right();
		padding-top: 57px;

		&.list-item--double {
			@include width(40%); } }

	@media (max-width: $mdxs) {
		margin-left: 0;

		.list-item {
			@include width(25%);

			&.list-item--double {
				@include width(25%); } } }

	@media (max-width: $mtl) {
		.list-item {
			@include width(50%);

			&.list-item--double {
				@include width(50%); } } }

	@media (max-width: $mtm) {
		.list-item {
			padding-right: 16px; } }

	@media (max-width: $mmm) {
		@include list-scroll(); } }

.event {
	&-header {
		margin-bottom: 32px; }

	&-footer {
		margin-top: 32px; }

	&-date {
		font-size: 1.4rem;
		color: $color-6; }

	&-title {
		font-size: 1.8rem;
		margin-bottom: 16px;
		font-weight: bold; }

	&-description {
		font-size: 1.6rem; }

	&-image {
		img {
			width: 100%; } }

	&-preview {
		margin-bottom: 20px;

		img {
			width: 100%; } }

	.button {
		padding-left: 22px;
		padding-right: 22px;
		width: auto; }

	&.event--with-image {
		@media (min-width: $mdxs + 1px) {
			position: relative;

			.event-header {
				margin: 0 0 -35% 0;
				position: relative;

				&::before {
					@include content();
					background: #fff;
					left: 0;
					bottom: -1px;
					width: 61.50627615%;
					height: calc(39% + 31px); } }

			.event-body,
			.event-footer {
				position: relative;
				z-index: 1;
				width: 61.50627615%;
				padding-left: 31px;
				padding-right: 31px; }

			.event-date {
				position: absolute;
				text-align: center;
				color: #fff;
				background: $color-1;
				right: 0;
				bottom: -1px;
				line-height: 84px;
				width: 38.49372385%;
				font-size: 1.8rem; } } }

	@media (max-width: $mdm) {
		&-date {
			font-size: 1.2rem; }

		&.event--with-image {
			.event-date {
				//bottom: -1px
				font-size: 1.2rem; } } }

	@media (max-width: $mdxs) {
		&-preview {
			display: none; }

		&.event--with-image {
			.event-image {
				display: none; } } } }
