.nd-about-company_page{
    .main_block{
        position: relative;
        margin-bottom: 120px;
        margin-top: 77px;

        .main_block_img{
            background-color: black;

            img{
                opacity: 0.6;
            }
        }

        .main_block_headers{
            position: absolute;
            left: 115px;
            bottom: 55px;
        }

        .main_block_header{
            background-color: #ED1C24;
            padding: 20px;
            width: 730px;
            margin-bottom: 37px;

            p{
                font-weight: 800;
                font-size: 2.3rem;
                line-height: 2.8rem;
                margin: 0;
                color: white;
            }
        }

        .main_block_subheader{
            background-color: white;
            padding: 20px;
            width: 730px;

            p{
                font-weight: 800;
                font-size: 1.8rem;
                line-height: 2.3rem;
                margin: 0;
                color: #150035;
            }
        }
    }

    .about_block{
        display: flex;
        flex-wrap: nowrap;
        margin-bottom: 200px;

        .left_block{
            width: 20%;

            p{
                margin: 0;
                font-weight: 600;
                font-size: 3.2rem;
                line-height: 3.8rem;
            }
        }
        .right_block{
            width: 80%;

            p{
                margin: 0;
                margin-bottom: 25px;
                font-weight: 700;
                font-size: 1.8rem;
                line-height: 2.3rem;
            }
        }
    }

    .progress_block{
        margin-bottom: 200px;

        .progress_list{
            display: flex;
            flex-wrap: nowrap;
            gap: 4rem;

            .progress_card{
                width: 22%;
                // box-shadow: 0px 4px 10px 0px #0000001A;
                padding: 60px 40px;
                border: 1px solid black;
                border-radius: 10px;
                text-align: start;

                img{
                    width: 8rem;
                    height: 8rem;
                    margin-bottom: 40px;
                }

                .progress_card_header{
                    font-weight: 700;
                    font-size: 2.6rem;
                    line-height: 3.1rem;
                    margin-bottom: 12px;

                    min-height: 6.2rem;
                }

                .progress_card_description{
                    font-weight: 500;
                    font-size: 1.4rem;
                    line-height: 1.8rem;
                }
            }
        }
    }

    .img_cards{
        margin-bottom: 200px;
        display: flex;
        flex-wrap: wrap;

        .img_card{
            width: 50%;
            display: flex;
            flex-wrap: nowrap;

            .half_block{
                width: 50%;

                .half_block_header{
                    margin: 0;
                    margin-bottom: 20px;
                    margin-top: 60px;
                    font-weight: 600;
                    font-size: 2.3rem;
                    line-height: 2.8rem;
                    padding: 0px 18px;
                } 

                .half_block_subheader{
                    margin: 0;
                    font-weight: 400;
                    font-size: 1.5rem;
                    line-height: 1.8rem;
                    padding: 0px 18px;
                }

                .half_block_image{
                    width: 100%;   
                }
            }
        }
    }

    .dictionary_block{
        margin-bottom: 200px;

        .dictionary_header{
            font-weight: 600;
            font-size: 3.2rem;
            line-height: 3.8rem;
            margin-bottom: 80px;
        }

        .dictionary_block_main{
            display: flex;
            flex-wrap: nowrap;
            gap: 40px;

            .left_block{
                width: 50%;
                display: flex;
                flex-wrap: nowrap;
                gap: 70px;

                .dictionary_list{
                    font-weight: 600;
                    font-size: 1.8rem;
                    line-height: 2.3rem;

                    li{
                        margin-bottom: 20px;

                        &:hover{
                            cursor: pointer;
                            color: #ED1C24;
                        }
                    }

                    .-active{
                        color: #ED1C24;
                    }
                }
            }

            .right_block{
                width: 50%;
                position: relative;

                .dictionary_main_image{
                    width: 100%;
                }

                .dictionary_redpoint_big{
                    position: absolute;
                    bottom: 26%;
                    right: 20%;
                }

                .dictionary_redpoint_small{
                    position: absolute;
                }

                .dictionary_redpoint_small_1{
                    top: 0;
                    left: 5%;
                }

                .dictionary_redpoint_small_2{
                    top: 5%;
                    left: 40%;
                }

                .dictionary_redpoint_small_3{
                    top: 35%;
                    right: 5%;
                }

                .dictionary_redpoint_small_4{
                    left: 30%;
                    bottom: 40%;
                }
            }
        }
    }

    .excursion_block{
        margin-block: 200px;

        .excursion_block_header{
            font-weight: 600;
            font-size: 3.2rem;
            line-height: 3.8rem;
            margin-bottom: 100px;
        }

        .images{
            position: relative;

            .icon_video_play{
                position: absolute;
                top: 40%;
                left: 42%;
                width: 170px;

                &:hover{
                    cursor: pointer;
                }
            }
        }
    }

    .slogan_block{
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        align-items: center;
        margin-bottom: 200px;

        .slogan_header{
            font-weight: 700;
            font-size: 76px;
            line-height: 82px;
            color: #ED1C24;
        }

        .slogan_subheader{
            font-weight: 700;
            font-size: 54px;
            line-height: 82px;
        }

        .button{
            width: 30%;
            margin-top: 42px;
            margin-bottom: 64px;
            border-radius: 40px;
        }
    }

    .comand_block{
        margin-bottom: 200px;

        .comand_block_header{
            font-weight: 600;
            font-size: 3.2rem;
            line-height: 3.8rem;
            margin-bottom: 50px;
        }

        .comand_block_description{
            font-weight: 700;
            font-size: 1.8rem;
            line-height: 2.3rem;
            margin-bottom: 25px;
        }

        .images{
            display: flex;
            flex-wrap: nowrap;
            gap: 20px;
            margin-top: 25px;

            img{
                width: 50%;
            }
        }
    }

    .news_block{
        margin-bottom: 200px;

        .header_block{
            margin-bottom: 120px;
            display: flex;
            flex-wrap: nowrap;
            justify-content: space-between;

            .news_header{
                font-weight: 600;
                font-size: 3.2rem;
                line-height: 3.8rem;
                margin: 0;
            }

            .news_header_button{
                border: 1px solid black;
                background-color: white;
                font-weight: 600;
                font-size: 1.8rem;
                line-height: 2.3rem;
                padding: 10px 20px;
            }
        }

        .news_list{
            display: flex;
            flex-wrap: wrap;
            gap: 20px;

            .single_news{
                // width: 32%;
                margin-bottom: 60px;

                .single_news_data{
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 20px;
                    color: #808080;
                    // padding: 0 10px;
                    margin-bottom: 20px;
                    margin-top: 10px;
                }

                .single_news_name{
                    font-weight: 600;
                    font-size: 2.3rem;
                    line-height: 2.8rem;
                    // padding: 0 10px;
                }

                img{
                    width: 100%;
                    max-height: 390px;
                }

                &:hover{
                    cursor: pointer;
                }
            }
        }
    }

    .history_block{
        margin-bottom: 200px;
        border: 1px solid black;
        padding: 60px;

        .history_block_header{
            font-weight: 600;
            font-size: 3.2rem;
            line-height: 3.8rem;
            margin-bottom: 60px;
        }
        
        .history_main_block{
            display: flex;
            flex-wrap: nowrap;
            align-items: flex-start;
            margin-bottom: 160px;

            .left_block{
                width: 55%;

                .history_default_text{
                    font-weight: 600;
                    font-size: 1.8rem;
                    line-height: 2.3rem;
                    margin: 0;
                }

                .history_special_text{
                    font-weight: 600;
                    font-size: 1.8rem;
                    line-height: 2.3rem;
                    color: #ED1C24;
                }
            }   

            .right_block{
                width: 45%;
                text-align: end;

                .history_year_number{
                    font-weight: 700;
                    font-size: 9rem;
                    line-height: 9.5rem;
                    color: #ED1C24;
                    margin: 0;
                }

                .history_year_text{
                    font-weight: 700;
                    font-size: 3.2rem;
                    line-height: 3.8rem;
                    color: #ED1C24;
                }
            }
        }

        .history_years_block{
            display: flex;
            flex-wrap: nowrap;

            .arrow_left_block{
                width: 5%;
                cursor: pointer;
            }

            .arrow_right_block{
                width: 5%;
                cursor: pointer;
            }

            .years{
                width: 90%;
                position: relative;

                .year_line_img{
                    position: absolute;
                    top: 8px;
                    left: 2.5%;
                    width: 95%;
                }

                .years_list{
                    display: flex;
                    flex-wrap: nowrap;
                    justify-content: space-around;

                    .single_year{
                        display: flex;
                        flex-direction: column;
                        align-items: center;

                        .single_year_number{
                            font-weight: 700;
                            font-size: 2.3rem;
                            line-height: 2.8rem;
                            color: #A9A9A9;
                        }

                        .single_year_number-active{
                            color: #ED1C24;
                        }

                        .default_eclipse{
                            width: 16px;
                            height: 16px;
                            margin-bottom: 8px;
                            margin-top: 1px;
                            z-index: 2;
                        }

                        .red_eclipse{
                            width: 24px;
                            height: 24px;
                            margin-bottom: 4px;
                            margin-top: -3px;
                            z-index: 2;
                        }
                    }
                }
            }
        }
    }
}