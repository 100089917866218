/* COMMON
 * -------------------------------------------------- */

.wrapper {
	position: relative;
	z-index: 1;
	overflow: hidden;

	@media (max-width: $mtm) {
		padding-top: 62px;
		overflow: visible; } }

.main {
	@media (max-width: $mtm) {
		overflow: hidden; } }

.phone,
.email {
	font-weight: bold;
	font-size: 2.2rem;
	color: $color-7;

	&:hover {
		color: $color-2; }

	@media (max-width: $mdm) {
		font-size: 1.8rem; }

	@media (max-width: $mds) {
		font-size: 1.6rem; }

	@media (max-width: $mdxs) {
		font-size: 1.4rem; }

	@media (max-width: $mml) {
		font-size: 1.8rem; } }

.icon {
	transition: fill .25s ease; }

.load-more {
	text-align: center;
	margin-top: 64px;

	&.load-more--left {
		text-align: left; }

	&.load-more--small {
		margin-top: 32px; }

	@media (max-width: $mmm) {
		margin-top: 32px; } }

/* BACKGROUND GRID
 * -------------------------------------------------- */

.background-grid {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 50%;
	width: 100%;
	max-width: 1920px;
	transform: translateX(-50%);
	z-index: 0;

	&::before,
	&::after {
		@include content();
		top: 0;
		bottom: 0;
		@include ll(); }

	&::before {
		left: 91px; }

	&::after {
		right: 91px; }

	&-outer {
		position: absolute;
		top: 0;
		left: 91px;
		bottom: 0;
		right: 91px;

		&::before,
		&::after {
			@include content();
			top: 0;
			bottom: 0;
			@include ll(); }

		&::before {
			left: 20%; }

		&::after {
			right: 20%; } }

	&-inner {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;

		&::before,
		&::after {
			@include content();
			top: 0;
			bottom: 0;
			@include ll(); }

		&::before {
			left: 40%; }

		&::after {
			right: 40%; } }

	@media (max-width: $mdm) {
		max-width: 1440px;

		&::before {
			left: 62px; }

		&::after {
			right: 62px; }

		&-outer {
			left: 62px;
			right: 62px; } }

	@media (max-width: $mdxs) {
		display: none; } }

/* NAVIGATION ASIDE
 * -------------------------------------------------- */

.navigation-aside {
	font-size: 1.8rem;
	font-weight: bold;

	.list-item + .list-item {
		@media (min-width: $mdxs + 1px) {
			margin-top: 22px; } }

	.list-item {
		&.-active {
			color: $color-2; }

		.list-link {
			&:hover {
				color: $color-2; } } }

	@media (max-width: $mdxs) {
		display: flex;
		overflow-x: auto;
		overflow-y: hidden;
		-webkit-overflow-scrolling: touch;

		.list-item {
			padding-left: 16px;
			padding-right: 16px;
			white-space: nowrap;

			&:last-child {
				padding-right: 16px; }

			br {
				display: none; } } }

	@media (max-width: $mtm) {
		margin-left: -15px;
		margin-right: -15px;

		.list-item {
			padding-left: 15px;
			padding-right: 15px;

			&:last-child {
				padding-right: 15px; } } }

	@media (max-width: $mml) {
		.list-item {
			color: $color-11;

			&.-active {
				color: $color-7; }

			.list-link {
				&:hover {
					color: $color-7; } } } } }

/* FORM CALLBACK
 * -------------------------------------------------- */

.section--form-callback {
	background: $color-4;
	padding-top: 240px;
	padding-bottom: 130px;

	&:last-child {
		margin-bottom: 128px; }

	@media (min-width: $mml + 1px) {
		.form-row > .form-item {
			@include width(33.33%); } }

	@media (max-width: $mdm) {
		padding-top: 140px;
		padding-bottom: 99px; }

	@media (max-width: $mmm) {
		padding-top: 64px;
		padding-bottom: 64px;

		&:not(.section--form-callback--visible) {
			display: none;

			& + .section {
				padding-top: 0 !important; } } } }

.row--form-callback {
	@include no-inner();

	.col:nth-child(1) {
		@include width(40%);
		@include inner-left(); }

	@media (max-width: $mdxs) {
		display: block;
		margin: 0;

		.col:nth-child(1) {
			@include width(100%);
			padding-left: 0;
			margin-bottom: 32px;

			br {
				display: none; } } } }

/* FORM QUESTION
 * -------------------------------------------------- */

.section--form-question {
	background: $color-4;
	padding: 81px 0 92px 0;

	&:last-child {
		margin-bottom: 128px; }

	.container {
		position: relative;

		&::before {
			@include content();
			left: 0;
			top: -90px;
			bottom: -92px;
			width: 100%; } }

	.form {
		margin-left: 40%;
		width: 40%;
		position: relative;
		z-index: 1; }

	.helper {
		position: absolute;
		bottom: 0;
		left: 15%; }

	@media (max-width: $mdm) {
		.container {
			&::before {
				bottom: -128px; } } }

	@media (max-width: $mds) {
		.container {
			&::before {
				top: 0; } } }

	@media (max-width: $mdxs) {
		br {
			display: none; }

		.container {
			&::before {
				display: none; } }

		.helper {
			display: none; }

		.form {
			margin-left: 0;
			width: auto;

			.form-row {
				margin-right: -20px; } } } }

/* FORM ORDER
 * -------------------------------------------------- */

.section--form-order {
	background: $color-4;
	position: relative;

	&::before,
	&::after {
		@include content();
		top: 0;
		height: 100%;
		background: $color-4;
		width: 10000%; }

	&::before {
		right: 100%; }

	&::after {
		left: 100%; }

	.form-row > .form-item {
		@include width(33.33%); }

	@media (max-width: $mtm) {
		padding-left: 15px !important;
		padding-right: 15px !important;

		&::before,
		&::after {
			display: none; } }

	@media (max-width: $mml) {
		margin-left: -15px;
		margin-right: -15px;

		.form-row > .form-item {
			@include width(100%); } } }

/* FORM ORDER 2
 * -------------------------------------------------- */

.section--form-order-2 {
	background: $color-4;
	position: relative;
	margin-left: -16px;

	.form-row > .form-item {
		@include width(33.33%); }

	.form {
		padding-left: 17%; }

	@media (max-width: $mds) {
		.form {
			padding-left: 0; } }

	@media (max-width: $mtm) {
		margin-left: 0;
		padding-left: 16px !important; }

	@media (max-width: $mml) {
		margin-left: -15px;
		margin-right: -15px;
		padding-left: 15px;
		padding-right: 15px !important;

		.form-row > .form-item {
			@include width(100%); } } }
