























































































































.message-error-block{
	background-color: lightcoral;
	border-radius: 10px;
	padding: 10px;
	margin-top: 10px;
	margin-bottom: 10px;
}
