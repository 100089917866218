/* FORM
 * -------------------------------------------------- */

.form {

	h3 {
		padding-top: 8px; }

	&-fieldset + &-fieldset {
		margin-top: 64px; }

	&-fieldset + &-fieldset--medium {
		margin-top: 32px; }

	&-group + &-group {
		margin-top: 24px; }

	&-group:first-child {
		h3 {
			padding-top: 0; } }

	&-row {
		@include flex();
		//margin-left: -28px
		position: relative;
		left: -28px;

		> .form-item {
			padding-left: 28px;
			flex: 1; }

		&--auto {
			> .form-item {
				flex: none; } }

		&--align-top {
			align-items: flex-start; }

		&--align-center {
			align-items: center; }

		&--align-bottom {
			align-items: flex-end; } }

	&-item {}

	&-label {
		font-size: 1.2rem;
		color: $color-6;

		&:not(:last-child) {
			margin-bottom: 9px; } }

	@media (max-width: $mds) {
		&-row {
			left: -20px;

			> .form-item {
				padding-left: 20px; } } }

	@media (max-width: $mml) {
		&-row {
			display: block;
			left: 0;
			margin-left: 0 !important;
			margin-right: 0 !important;

			> .form-item {
				padding-left: 0;
				width: 100%;
				max-width: 100%;
				flex: none; }

			> .form-item + .form-item {
				margin-top: 24px; } } }

	@media (max-width: $mmm) {
		&-fieldset + &-fieldset {
			margin-top: 32px; } } }
