



































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.max_width_span_text{
	&:before{
		max-width: 22rem;
		overflow: hidden;
	}
}
.max_width_span_text_with_arrows{
	&:before{
		max-width: 20rem;
		overflow: hidden;
	}
}
.cl-car-item{
	border: 2px solid #fff;
	margin-bottom: 8rem;
}
.cl-car-item:hover{
	border: 2px solid #EA292E;
	cursor: pointer;
}
.active_card{
	border: 2px solid #EA292E;
}
.mb-10{
	margin-bottom: 10rem;
}
.overh{
	overflow: hidden;
}
.position_parameters{
	display: flex;
	flex-wrap: wrap;
	width: 105rem;

	.good-col{
		width: 26rem;
		margin-right: 3rem;
		margin-top: 1rem;
	}

	.good-col_color{
		width: 105rem;
	}
}
