.container {
	width: 100%;
	max-width: 1920px;
	margin: 0 auto;
	padding-left: 91px;
	padding-right: 91px;

	.inner {
		padding-left: 39px;
		padding-right: 39px;

		.inner-wider {
			margin-left: -39px;
			margin-right: -39px; } }

	.wider {
		margin-left: -91px;
		margin-right: -91px; }

	@media (max-width: $mdm) {
		max-width: 1440px;
		padding-left: 62px;
		padding-right: 62px;

		.inner {
			padding-left: 31px;
			padding-right: 31px;

			.inner-wider {
				margin-left: -31px;
				margin-right: -31px; } }

		.wider {
			margin-left: -62px;
			margin-right: -62px; } }

	@media (max-width: $mds) {
		.inner {
			padding-left: 16px;
			padding-right: 16px;

			.inner-wider {
				margin-left: -16px;
				margin-right: -16px; } } }

	@media (max-width: $mtm) {
		padding-left: 15px;
		padding-right: 15px;

		.inner {
			padding-left: 0;
			padding-right: 0;

			.inner-wider {
				margin-left: 0;
				margin-right: 0; } } } }

.row {
	@include flex();

	> .col {
		flex: 1; }

	&.row--contentframe {
		@include no-inner();

		.col:nth-child(1) {
			@include width(40%);
			@include inner-left(); }

		.col:nth-child(2) {
			@include inner-right(); } }

	&.row--text {
		@include no-inner();

		.col {
			@include width(50%); }

		.col:nth-child(1) {
			@include inner-left();
			padding-right: 10%; }

		.col:nth-child(2) {
			text-align: right; } }

	@media (max-width: $mds) {
		&.row--contentframe {
			display: block;

			.col:nth-child(1) {
				@include width(100%);
				margin-bottom: $headMargin;

				h1,
				h2,
				h3,
				h4,
				h5,
				h6 {
					br {
						display: none; } } }

			.col:nth-child(2) {
				padding-left: 16px;
				padding-right: 0; } } }

	@media (max-width: $mtm) {
		&.row--contentframe {
			.col:nth-child(2) {
				padding-left: 0; } } } }

.list-offset {
	&.-col-1 {
		padding-left: 20%; }

	@media (max-width: $mds) {
		&.-col-1 {
			@include inner-left(); } } }

.list {
	list-style: none;
	padding: 0;
	margin: 0;

	> li {
		list-style: none; }

	&-link {
		display: block; }

	&--flex {
		@include flex(); }

	&--flex-equal {
		> .list-item {
			display: flex;

			> * {
				width: 100%;
				height: 100%; } } } }

.full-width {
	left: 50%;
	margin-left: -50vw;
	margin-right: -50vw;
	max-width: 100vw;
	position: relative;
	right: 50%;
	width: 100vw; }
