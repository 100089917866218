.constructor {
	position: relative;
	padding-bottom: 56.156%;
	min-height: 739px;

	&-main-image {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-repeat: no-repeat;
		background-position: 0 50%;
		background-size: 100% 100%; }

	&-panel {
		position: absolute;
		z-index: 10;
		min-width: 263px;
		width: 20%;
		height: 100%;
		right: 0;

		&-title {
			color: #fff;
			font-size: 2.4rem;
			font-weight: bold;
			line-height: 1.334; }

		&-main {
			width: 100%;
			height: 100%;
			padding: 0 20px 20px 20px;
			background: $color-7;
			position: relative;
			z-index: 10; }

		&-subs {
			position: absolute;
			z-index: 1;
			width: 100%;
			height: 100%;
			padding: 187px 20px 20px 20px;
			background: $color-4;
			top: 0;
			right: 0;
			transition: transform .25s ease;

			&.-active {
				transform: translateX(-100%); } }

		&-sub {
			display: none;

			&.-active {
				display: block;
				animation: subIn 1s forwards; } }

		&-header {
			height: 155px;
			margin-bottom: 32px;
			display: flex;
			align-items: flex-end; }

		&-body {}

		&-footer {
			margin-top: 64px;

			.button + .button {
				margin-top: 24px; } } }

	&-group + &-group {
		margin-top: 24px; }

	&-nav {
		color: #fff;
		font-size: 1.6rem;

		.list-item + .list-item {
			margin-top: 32px; }

		&-link {
			display: flex;
			align-items: center;

			&-icon {
				@include width(25px);
				margin-right: 32px;
				text-align: center;

				svg {
					display: block;
					fill: #fff;
					width: 25px;
					height: 25px; } }

			&-title {}

			&.-active,
			&:hover {
				color: $color-2;
				font-weight: bold;

				.icon {
					fill: $color-2; } }

			&.-changed {
				position: relative;

				&::after {
					@include content();
					top: 0;
					right: 0;
					width: 21px;
					height: 21px;
					border-radius: 50%;
					border: 1px solid $color-2; }

				&::before {
					@include content();
					top: 2px;
					right: 2px;
					width: 17px;
					height: 17px;
					border-radius: 50%;
					background: $color-2; } } } }

	.button {
		padding-left: 0;
		padding-right: 0;
		width: 100%; }

	.layout-colors {
		margin-left: -13px;
		margin-top: -13px;

		.list-item {
			padding-left: 13px;
			padding-top: 13px; } }

	@media (max-width: $mdm) {
		&-nav {
			font-size: 1.4rem;

			&-link {
				&-icon {
					@include width(18px);

					svg {
						width: 18px;
						height: 18px; } } } }

		&-main-image {
			background-size: auto 100%; } }

	@media (max-width: $mds) {
		min-height: 522px;

		&-panel {
			&-header {
				height: 100px; }

			&-subs {
				padding-top: 132px; } } }

	@media (max-width: $mml) {
		min-height: 507px;

		&-panel {
			display: none; } }

	@media (max-width: $mmm) {
		min-height: 340px;
		margin-left: -15px;
		margin-right: -15px; }

	@media (max-width: $mms) {
		min-height: 253px; } }

.-menu-constructor-opened {
	@media (max-width: $mml) {
		position: fixed;
		overflow: hidden;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;

		.header {
			z-index: 0; }

		.constructor-panel {
			position: fixed;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			z-index: 100;
			background: #fff;
			display: block;
			@include scroll();
			overflow: auto;
			padding-bottom: 46px;
			width: 100%;

			&-main {
				padding: 32px 15px;

				.constructor-panel-header {
					height: auto; }

				.constructor-panel-title {
					font-size: 1.8rem;

					br {
						display: none; } } }

			&-subs {
				padding: 32px 15px;

				&.-active {
					z-index: 10;
					transform: none; } } }

		.constructor-panel-toggle {
			background: $color-2;

			span {
				&:nth-child(1) {
					display: none; }

				&:nth-child(2) {
					display: block; } }

			&-button {
				background-color: $color-1;

				&::before {
					opacity: 0; }

				&::after {
					opacity: 1; } } } } }

.-menu-constructor-sub-opened {
	@media (max-width: $mml) {
		.constructor-panel-toggle-back {
			display: flex; } } }

.constructor-panel-toggle {
	display: none;
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	height: 46px;
	background: $color-6;
	color: #fff;
	z-index: 101;

	&-section {
		flex: 1;
		text-align: center;
		line-height: 46px;
		font-size: 1.6rem;

		span {
			margin-right: -46px;

			&:nth-child(1) {
				display: block; }

			&:nth-child(2) {
				display: none; } } }

	&-button {
		@include width(46px);
		height: 46px;
		position: relative;
		padding: 0;
		margin: 0;
		border: 0;
		background: transparent;

		&::before,
		&::after {
			@include content();
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%); }

		&::before {
			width: 18px;
			height: 11px;
			background: url('../img/navigation-toggle.svg') 0 0 /  100% 100% no-repeat;
			opacity: 1; }

		&::after {
			width: 14px;
			height: 14px;
			background: url('../img/navigation-close.svg') 0 0 /  100% 100% no-repeat;
			opacity: 0; } }

	@media (max-width: $mml) {
		display: flex; } }

.constructor-panel-toggle-back {
	display: none;
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	height: 46px;
	background: $color-2;
	color: #fff;
	z-index: 102;

	&-section {
		flex: 1;
		text-align: center;
		line-height: 46px;
		font-size: 1.6rem;

		span {
			margin-right: -46px; } }

	&-button {
		@include width(46px);
		height: 46px;
		position: relative;
		padding: 0;
		margin: 0;
		border: 0;
		background: $color-1;

		&::before {
			@include content();
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			width: 12px;
			height: 9px;
			background: url('../img/back.svg') 0 0 /  100% 100% no-repeat; } } }

.constructor-result-title {
	margin-bottom: 32px !important; }

.section--constructor {
	@media (max-width: $mds) {
		.row--contentframe {
			> .col:first-child {
				display: none; } } } }

@keyframes subIn {
	0% {
		opacity: 0; }
	100% {
		opacity: 1; } }
