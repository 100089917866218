.lk-page{
    margin-top: 10px;

    .lk-navigation-block{
        .lk-navigation-header-block{
            background-color: #ED1C24;
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
            justify-content: space-between;

            padding: 10px;

            .lk-navigation-header{
                font-weight: 700;
                font-size: 32px;
                line-height: 42px;
                color: #fff;
                margin-bottom: 0;
            }

            .right_part{
                display: flex;
                flex-wrap: nowrap;

                .notification_block{
                    background-color: #fff;
                    color: black;
                    border-radius: 10px;
                    padding: 4px 20px;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 16px;
                    margin-right: 10px;

                    p{
                        margin-bottom: 0;
                    }
                }

                .svg_block{
                    position: relative;
                    height: 24px;
    
                    .black_circle{
                        position: absolute;
                        top: 0;
                        right: 0;
                    }
    
                    .text_circle{
                        position: absolute;
                        top: 1px;
                        right: 3px;
                        color: #fff;
                        font-size: 11px;
                        line-height: 10px;
                    }
                }
            }
        }

        .lk-navigation-list-block{
            background-color: #353B48;
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
            
            padding: 14px 0;
            margin-bottom: 30px;

            .lk-navigation-list-once{
                position: relative;
                font-weight: 700;
                font-size: 16px;
                line-height: 24px;
                margin-right: 65px;
                color: #fff;

                .lk-navigation-list-once-name{
                    padding: 0 10px;
                    margin-bottom: 0;

                    &:hover{
                        cursor: pointer;
                    }
                }

                .lk-navigation-list-once_sublinks{
                    position: absolute;
                    top: 25px;
                    z-index: 1;

                    background-color: #353B48;
                    border-radius: 10px;
                    padding: 10px;
                    font-weight: 400;

                    p{
                        margin-bottom: 10px;

                        &:hover{
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }

    .lk-page_main{
        .lk-page_main-big-cards{
            display: flex;
            flex-wrap: wrap;
            // margin-bottom: 35px;

            &:hover{
                cursor: pointer;
            }
            
            .lk-page_main-big-cards_once{
                position: relative;
                border: 1px solid #00000033;
                border-radius: 20px;
                padding: 24px;
                margin-bottom: 20px;
                min-height: 296px;

                p{
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 22px;
                    color: #808080;
                    margin-bottom: 12px;
                }

                .lk-page_main-big-cards_once-header_block{
                    display: flex;
                    flex-wrap: nowrap;
                    align-items: center;
                    margin-bottom: 30px;

                    .lk-page_main-big-cards_once-header{
                        font-weight: 700;
                        color: black;
                        margin-bottom: 0;
                        margin-right: 12px;
                    }

                    .lk-page_main-big-cards_once_count{
                        background-color: #718093;
                        border-radius: 20px;
                        color: #fff;
                        padding: 2px 9px;
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 22px;
                        margin-bottom: 0;
                    }
                }

                .lk-page_main-big-cards_once-header{
                    font-weight: 700;
                    color: black;

                    margin-bottom: 30px;
                }

                .lk-page_main-big-cards_once-arrow-svg{
                    position: absolute;
                    top: 25px;
                    right: 25px;
                }

                .lk-page_main-big-cards_once-type-svg{
                    position: absolute;
                    bottom: 0;
                    right: 0;
                }

                .order_buttons{
                    display: flex;
                    flex-wrap: wrap;

                    .order_button_once{
                        color: #718093;
                        font-weight: 600;
                        font-size: 15px;
                        line-height: 18px;
                        background-color: #F5F6FA;

                        padding: 4px 10px;
                        border-radius: 100px;
                        margin-right: 8px;
                        margin-bottom: 8px;

                        &:hover{
                            background-color: #e9e9e9;
                        }
                    }
                }
            }
        }

        .lk-page_main-small-cards{
            display: flex;

            &:hover{
                cursor: pointer;
            }

            .lk-page_main-small-cards_once{
                border: 1px solid #00000033;
                border-radius: 20px;
                padding: 24px;

                .lk-page_main-small-cards_once-header{
                    font-weight: 700;
                    font-size: 22px;
                    line-height: 32px;
                    color: black;
                }
            }
        }
    }

    .lk-page_orders{
        .lk-page_orders-header{
            margin-top: 50px;
            margin-bottom: 10px;

            font-weight: 700;
            font-size: 32px;
            line-height: 48px;
            color: black;
        }
        .lk-page_orders-filters{
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 30px;

            .lk-page_orders-filters_ones{
                position: relative;

                input{
                    width: 100%;
                    color: #718093;
                    border: 1px solid #D6D6D6;
                    border-radius: 10px;
                    padding: 11px 12px;
                }
                
                .filter_button{
                    display: flex;
                    flex-wrap: nowrap;
                    align-items: center;
                    justify-content: space-between;

                    background-color: #F5F6FA;
                    color: black;

                    padding: 8px 20px;
                    border-radius: 10px;
                    margin-top: 10px;

                    p{
                        margin-bottom: 0;
                        font-weight: 600;
                        font-size: 18px;
                        line-height: 20px;
                    }

                    &:hover{
                        cursor: pointer;
                    }

                    .svg_filter{
                        margin-right: 4px;
                    }

                    .status_filter_block{
                        display: flex;
                        flex-wrap: nowrap;
                        align-items: center;
                    }
                }
                .filter_list{
                    display: flex;
                    flex-direction: column;
                    background-color: #fff;
                    border: 1px solid #D6D6D6;
                    border-radius: 10px;
                    padding: 8px 12px;
                    width: 120%;

                    position: absolute;
                    z-index: 5;

                    .filter_list_row{
                        display: flex;
                        flex-wrap: nowrap;
                        align-items: center;

                       .svg_checkbox{
                            margin-right: 10px;

                            &:hover{
                                cursor: pointer;
                            }
                       }
                    }
                }

                .range_block{
                    background-color: #fff;
                    border: 1px solid #D6D6D6;
                    border-radius: 10px;
                    box-shadow: 0px 8px 24px 0px #00000021;
                    padding: 24px;
                    width: 175%;

                    position: absolute;
                    top: 60px;

                    .vue-slider{
                        margin-top: 50px;
                        margin-bottom: 32px;
                        width: 90% !important;
                        margin-left: auto;
                        margin-right: auto;

                        .vue-slider-rail{
                            height: 8px;

                            .vue-slider-dot{
                                width: 24px !important;
                                height: 24px !important;

                                .vue-slider-dot-handle{
                                    border: 1px solid #ED1C24;
                                }
                            }
                        }

                        .vue-slider-process{
                            background-color: #ED1C24;
                        }

                        .vue-slider-dot-tooltip{
                            .vue-slider-dot-tooltip-inner{
                                background-color: #fff;
                                border-color: #fff;
                                box-shadow: 0px 4px 6px -2px #10182808;
                                box-shadow: 0px 12px 16px -4px #10182814;

                                color: #344054;
                                font-weight: 600;
                                font-size: 12px;
                                line-height: 18px;

                                // padding: 8px 12px;
                                height: 34px;
                                width: 59px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                            }
                        }
                    }

                    .range_inputs{
                        display: flex;
                        flex-wrap: nowrap;
                        gap: 19px;

                        .range_inputs_once{
                            position: relative;

                            label{
                                position: absolute;
                                top: 12.5px;
                                left: 12px;
                                color: #718093;


                            }

                            input{
                                padding-left: 53px;
                                color: #353B48;
                                font-weight: 700;
                                font-size: 16px;
                                line-height: 16px;
                            }
                        }
                    }
                }

                .mx-datepicker-range{
                    width: 100% !important;
                    height: 100%;

                    .mx-input-wrapper{
                        height: 100%;
                        .mx-input{
                            height: 100%;
                        }
                    }
                }

                .range_setted_values_block{
                    position: absolute;
                    top: 15.5px;
                    left: 12px;

                    display: flex;
                    flex-wrap: nowrap;

                    width: 90%;
                    overflow: hidden;

                    label{
                        color: #000513;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 16px;
                        margin-right: 10px;
                    }

                    p{
                        margin-bottom: 0;
                        margin-right: 10px;
                        font-weight: 700;
                        font-size: 16px;
                        line-height: 16px;
                        color: #353B48;
                    }
                }

                .filters_search_input{
                    padding-left: 36px;
                }

                .svg_search{
                    position: absolute;
                    top: 15.5px;
                    left: 12px;
                }
            }
        }
        .lk-page_orders-list{
            display: flex;
            flex-direction: column;

            overflow-x: auto;
            max-height: 710px;

            .lk-page_orders-ones{
                display: flex;
                flex-wrap: wrap;

                border: 1px solid #0000001A;
                border-radius: 20px;
                box-shadow: 0px 4px 20px 0px #0000000D;
                padding: 30px;
                margin-bottom: 24px;

                .lk-page_orders-ones_left-part{
                    display: flex;
                    // flex-direction: column;
                    flex-wrap: wrap;
                    width: 25%;
                    margin-right: auto;


                    .order-number{
                        color: black;
                        font-weight: 700;
                        font-size: 16px;
                        line-height: 19px;
                        margin-bottom: 12px;
                        width: 100%;
                    }

                    .company-name{
                        color: #718093;
                        font-weight: 700;
                        font-size: 16px;
                        line-height: 19px;
                        width: 100%;
                    }

                    .order-status-button{
                        color: #fff;
                        padding: 4px 10px;
                        border-radius: 100px;
                        font-weight: 600;
                        font-size: 15px;
                        line-height: 18px;
                        margin-bottom: 19px;
                    }
    
                    .order-status-button_green{
                        background-color: #039B24;
                    }
                    .order-status-button_lightgreen{
                        background-color: #05DB41;
                    }
                    .order-status-button_lightblue{
                        background-color: #4BB5E3;
                    }
                    .order-status-button_blue{
                        background-color: #0071B5;
                    }
                    .order-status-button_purple{
                        background-color: #BD01DC;
                    }
                    .order-status-button_black{
                        background-color: black;
                    }
                    .order-status-button_red{
                        background-color: #ED1C24;
                    }
                    .order-status-button_yellow{
                        background-color: #C9A901;
                    }
                    .order-status-button_orange{
                        background-color: #D59009;
                    }
                    .order-status-button_grey{
                        background-color: #718093;
                    }
                }

                .lk-page_orders-ones_mid-part{
                    width: 58%;

                    .mid_part_row{
                        display: flex;
                        flex-wrap: nowrap;
                        gap: 30px;
                        margin-bottom: 16px;
                        width: 50%;

                        p{
                            margin-bottom: 0;
                        }

                        .mid_part_row-value{
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 19px;
                            color: black;

                            width: 50%;
                        }

                        .mid_part_row-name{
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 21px;
                            color: #00000080;

                            width: 50%;
                        }
                    }
                }

                .lk-page_orders-ones_right-part{
                    width: 17%;
                    margin-left: auto;
                    display: flex;
                    flex-direction: column;

                    .right-part_subheader{
                        width: 100%;

                        p{
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 21px;
                            color: #00000080;
                        }
                    }

                    .right-part_manager{
                        width: 100%;

                        p{
                            color: black;
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 19px;
                        }
                    }
                }

                .order_button_block{
                    width: 100%;
                    display: flex;
                    justify-content: flex-end;

                    .order_button_row{
                        width: 17%;
                        margin-left: 83%;

                        .order-button{
                            color: #fff;
                            background-color: black;
                            padding: 10px 30px;
                            border-radius: 10px;
    
                            font-weight: 700;
                            font-size: 16px;
                            line-height: 24px;
    
                            display: flex;
                            flex-wrap: nowrap;
                            align-items: center;
                            gap: 20px;
    
                            &:hover{
                                cursor: pointer;
                            }
                        }
                    }

                }
            }
        }

        .empty_orders_block{
            margin-top: 142px;
            display: flex;
            flex-direction: column;
            align-items: center;

            .empty_orders_header{
                color: #718093;
                font-weight: 500;
                font-size: 32px;
                line-height: 48px;
                margin-top: 43px;
                margin-bottom: 0;
            }

            .empty_orders_subheader{
                color: #718093;
                font-weight: 400;
                font-size: 22px;
                line-height: 33px;
                margin-top: 10px;
                margin-bottom: 43px;
            }

            .button{
                border-radius: 10px;
                padding: 10px 16px;

                font-weight: 700;
                font-size: 16px;
                line-height: 27px;
            }
        }
    }

    .lk-page_companies{
        .lk-page_companies-header_buttons{
            display: flex;
            flex-wrap: nowrap;
            justify-content: space-between;
            margin-top: 40px;
            margin-bottom: 18px;

            .back_button{
                border: 1px solid #0000001A;
                border-radius: 10px;
                color: #000000;
                font-weight: 400;
                font-size: 17px;
                line-height: 22px;
                padding: 10px 20px;
                background-color: #fff;

                svg{
                    margin-right: 10px;
                }
            }

            .add_button{
                border-radius: 4px;
                color: #fff;
                font-weight: 700;
                font-size: 16px;
                line-height: 33px;
                padding: 10px 16px;
            }

            .lk-page_companies-header{
                margin-bottom: 0;
            }
        }

        .lk-page_companies-header{
            margin-bottom: 11px;

            font-weight: 700;
            font-size: 32px;
            line-height: 48px;
            color: black;
        }

        .lk-page_companies-subheader{
            color: #718093;
            font-weight: 700;
            font-size: 16px;
            line-height: 20px;
            margin-bottom: 28px;
        }

        .lk-page_companies-filters{
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 30px;

            .lk-page_companies-filters_ones{
                position: relative;

                input{
                    width: 100%;
                    color: #718093;
                    border: 1px solid #D6D6D6;
                    border-radius: 10px;
                    padding: 11px 12px;
                }
                
                .filter_button{
                    display: flex;
                    flex-wrap: nowrap;
                    align-items: center;
                    justify-content: space-between;

                    background-color: #F5F6FA;
                    color: black;

                    padding: 8px 20px;
                    border-radius: 10px;
                    margin-top: 10px;

                    p{
                        margin-bottom: 0;
                        font-weight: 600;
                        font-size: 18px;
                        line-height: 20px;
                    }

                    &:hover{
                        cursor: pointer;
                    }

                    .svg_filter{
                        margin-right: 4px;
                    }

                    .status_filter_block{
                        display: flex;
                        flex-wrap: nowrap;
                        align-items: center;
                    }
                }
                .filter_list{
                    display: flex;
                    flex-direction: column;
                    background-color: #fff;
                    border: 1px solid #D6D6D6;
                    border-radius: 10px;
                    padding: 8px 12px;
                    width: 120%;

                    position: absolute;
                    z-index: 5;

                    .filter_list_row{
                        display: flex;
                        flex-wrap: nowrap;
                        align-items: center;

                       .svg_checkbox{
                            margin-right: 10px;

                            &:hover{
                                cursor: pointer;
                            }
                       }
                    }
                }

                .filters_search_input{
                    padding-left: 36px;
                }

                .svg_search{
                    position: absolute;
                    top: 15.5px;
                    left: 12px;
                }

                .custom_select{
                    position: relative;
                    z-index: 100;
                    height: 100%;

                    .choosen_item{
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        border: 1px solid #D6D6D6;
                        border-radius: 10px;
                        padding: 2px 12px;
                        height: 100%;

                        &:hover{
                            cursor: pointer;
                        }

                        p{
                            margin-bottom: 0;
                            color: #757575;
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 20px;
                        }
                    }

                    .items{
                        position: absolute;
                        border: 1px solid #D6D6D6;
                        border-radius: 10px;
                        background-color: #fff;
                        max-height: 300px;
                        overflow-x: auto;

                        p{
                            padding: 6px 12px;
                            margin-bottom: 0;
                            color: #353B48;
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 20px;

                            &:hover{
                                background-color: #eaeaea;
                                cursor: pointer;
                            }
                        }
                    }
                }
            }
        }

        .lk-page_companies-button-block{
            display: flex;
            flex-wrap: nowrap;
            margin-bottom: 33px;

            .lk-page_companies-button-block_ones{
                background-color: #F5F6FA;
                border-radius: 100px;
                padding: 4px 20px;
                color: #000000;
                font-weight: 400;
                font-size: 16px;
                line-height: 22px;
                margin-right: 10px;

                &:hover{
                    cursor: pointer;
                }
            }

            .lk-page_companies-button-block_ones-active{
                background-color: #ED1C24;
                color: #fff;
            }
        }

        .lk-page_companies-list{

            .lk-page_companies-list_ones{
                margin-bottom: 20px;
                background-color: #F5F6FA;
                padding: 24px;

                .lk-page_companies-list_ones-header_row{
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 24px;

                    .left_part{
                        color: #718093;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 24px;
                    }
                    .right_part{
                        position: relative;

                        svg{
                            &:hover{
                                cursor: pointer;
                            }
                        }

                        .company_event_list{
                            position: absolute;
                            right: 20px;
                            top: 7px;
                            background-color: #fff;
                            border-radius: 10px;
                            z-index: 100;
                            width: 359px;
                            padding: 14px 10px;

                            .company_event_list_ones{
                                display: flex;
                                align-items: center;
                                gap: 10px;

                                p{
                                    margin-bottom: 0;
                                    color: black;
                                    font-weight: 400;
                                    font-size: 22px;
                                    line-height: 32px;
                                }

                                &:hover{
                                    cursor: pointer;
                                }
                            }
                        }
                    }
                }

                .lk-page_companies-list_ones-main_row{
                    display: flex;
                    flex-wrap: nowrap;

                    .left_part{
                        width: 33%;

                        .company_name{
                            color: #000000;
                            font-weight: 700;
                            font-size: 22px;
                            line-height: 33px;
                            margin-bottom: 6px;
                        }

                        .company_info{
                            color: #000000;
                            font-weight: 400;
                            font-size: 15px;
                            line-height: 24px;
                            margin-bottom: 6px;
                        }
                    }
                    .mid_part{
                        width: 33%;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-end;

                        .mid_part_row{
                            display: flex;
                            gap: 12px;
                            // justify-content: center;
                            margin-bottom: 6px;
                            padding-left: 25%;

                            .mid_part_row_name{
                                color: #000000;
                                font-weight: 400;
                                font-size: 15px;
                                line-height: 24px;
                                margin-bottom: 0;
                            }
                            .mid_part_row_value{
                                color: #000000;
                                font-weight: 700;
                                font-size: 18px;
                                line-height: 23px;
                                margin-bottom: 0;
                            }
                        }
                    }
                    .right_part{
                        width: 33%;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-end;
                        align-items: flex-end;

                        .button_block{
                            text-align: center;

                            .role_name{
                                color: #718093;
                                font-weight: 400;
                                font-size: 14px;
                                line-height: 16px;
                                margin-bottom: 4px;
                            }
    
                            .button{
                                background-color: #718093;
                                color: #fff;
                                border-radius: 10px;
                                padding: 10px 30px;
                                font-weight: 700;
                                font-size: 16px;
                                line-height: 24px;
                            }

                            .button-black{
                                color: #fff;
                                background-color: #000000;
                            }
                        }
                    }
                }
            }
        }

        .empty_orders_block{
            margin-top: 142px;
            display: flex;
            flex-direction: column;
            align-items: center;

            .empty_orders_header{
                color: black;
                font-weight: 500;
                font-size: 32px;
                line-height: 48px;
                margin-top: 43px;
                margin-bottom: 0;
            }

            .empty_orders_subheader{
                color: black;
                font-weight: 400;
                font-size: 22px;
                line-height: 33px;
                margin-top: 10px;
                margin-bottom: 43px;
            }

            .button{
                border-radius: 10px;
                padding: 10px 16px;

                font-weight: 700;
                font-size: 16px;
                line-height: 27px;
            }
        }

        .lk-page_companies-employees-table{
            .lk-page_companies-employees-table_headers{
                display: flex;
                flex-wrap: nowrap;
                background-color: #F5F6FA;
                padding: 8px 12px;
                gap: 10px;

                .lk-page_companies-employees-table_headers_once{
                    p{
                        color: #718093;
                        margin-bottom: 0;
                        font-weight: 700;
                        font-size: 12px;
                        line-height: 16px;
                    }
                }
            }

            .lk-page_companies-employees-table_body{
                display: flex;
                flex-wrap: nowrap;
                align-items: center;
                padding: 11px 12px;
                gap: 10px;

                .lk-page_companies-employees-table_body_once{
                    position: relative;

                    p{
                        color: #718093;
                        margin-bottom: 0;
                        font-weight: 500;
                        font-size: 18px;
                        line-height: 22px;
                    }

                    svg{
                        &:hover{
                            cursor: pointer;
                        }
                    }

                    .company_event_list{
                        position: absolute;
                        right: 20px;
                        top: 7px;
                        background-color: #fff;
                        border-radius: 10px;
                        z-index: 100;
                        width: 359px;
                        padding: 14px 10px;

                        .company_event_list_ones{
                            display: flex;
                            align-items: center;
                            gap: 10px;

                            p{
                                margin-bottom: 0;
                                color: black;
                                font-weight: 400;
                                font-size: 22px;
                                line-height: 32px;
                            }

                            &:hover{
                                cursor: pointer;
                            }
                        }
                    }

                    .company_event_list_low{
                        width: 213px;
                        top: unset;
                        right: 0;

                        .company_event_list_ones{
                            p{
                                color: #353B48;
                                font-size: 16px;
                                line-height: 20px;
                            }
                        }
                    }

                    .button-black{
                        color: #fff;
                        background-color: #050505;
                        border-radius: 10px;
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 27px;
                        padding: 10px 16px;

                        display: flex;
                        flex-wrap: nowrap;
                        align-items: center;
                        justify-self: flex-end;
                    }

                    .access_ones_header{
                        color: #718093;
                        font-weight: 500;
                        font-size: 18px;
                        line-height: 22px;
                    }

                    .access_ones_subheader{
                        color: #050505;
                        font-weight: 600;
                        font-size: 18px;
                        line-height: 22px;

                        &:hover{
                            cursor: pointer;
                        }
                    }
                }
            }
        }

        .lk-companies-modal-w{
            .modal-container{
                max-width: 1200px !important;
            }
        }

        .lk-companies-modal{
            .modal-container{
                border-radius: 5px;
                padding: 24px;
                max-width: 600px;

                .modal-body{
                    .modal-header-block{
                        display: flex;
                        flex-wrap: nowrap;
                        justify-content: space-between;
                        margin-bottom: 24px;
                        align-items: center;

                        .modal-header{
                            color: #000000;
                            margin-bottom: 0;
                            font-weight: 700;
                            font-size: 22px;
                            line-height: 33px;
                        }

                        .modal-header-success{
                            color: #008A27;
                        }
                        
                        .modal-header-error{
                            color: #ED1C24;
                        }

                        svg{
                            &:hover{
                                cursor: pointer;
                            }
                        }
                    }

                    .modal-subheader-block{
                        margin-bottom: 24px;

                        .modal-subheader{
                            color: #000000;
                            margin-bottom: 0;
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 24px;
                        }
                    }

                    .modal-input-block{
                        margin-bottom: 24px;
                        position: relative;

                        .modal-input-label{
                            color: #000000;
                            margin-bottom: 12px;
                            font-weight: 700;
                            font-size: 16px;
                            line-height: 24px;
                        }

                        .modal-input{
                            border: 1px solid #D6D6D6;
                            border-radius: 10px;
                            background-color: #fff;
                            color: #353B48;
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 22px;
                            padding: 10px;
                            width: 100%;

                            &:focus{
                                background-color: #F5F6FA;
                            }
                        }

                        .modal-textarea{
                            border: 1px solid #D6D6D6;
                            border-radius: 10px;
                            background-color: #fff;
                            color: #353B48;
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 22px;
                            padding: 10px;
                            width: 100%;

                            &:focus{
                                background-color: #F5F6FA;
                            }

                            &:read-only{
                                background-color: #F5F6FA;
                            }
                        }

                        .checkbox_block{
                            span{
                                color: #000000;
                                font-weight: 400;
                                font-size: 16px;
                                line-height: 24px;
                                padding-left: 20px;

                                &:before{
                                    width: 16px;
                                    height: 16px;
                                    top: 15%;
                                }

                                &:after{
                                    width: 10px;
                                    height: 10px;
                                    left: 3px;
                                    top: 27%;
                                }

                                &:hover{
                                    cursor: pointer;
                                }
                            }
                        }
                    }

                    .modal-companies-list{
                        position: absolute;
                        padding-top: 10px;
                        padding-bottom: 10px;
                        background-color: #fff;
                        border: 1px solid #0000001A;
                        border-radius: 5px;
                        z-index: 100;


                        .modal-companies-ones{
                            padding: 10px;
                            font-weight: 400;
                            font-size: 17px;
                            line-height: 22px;
                            color: #000000;

                            p{
                                margin-bottom: 0;
                            }

                            &:hover{
                                background-color: #F5F6FA;
                                cursor: pointer;
                            }
                        }
                    }

                    .modal-text-block{
                        margin-bottom: 14px;

                        .modal-text-ones{
                            margin-bottom: 10px;
                            display: flex;
                            flex-wrap: nowrap;

                            .modal-text-name{
                                color: #000000;
                                font-weight: 700;
                                font-size: 22px;
                                line-height: 32px;
                                width: 30%;
                                margin-bottom: 0;
                            }

                            .modal-text-value{
                                color: #000000;
                                font-weight: 400;
                                font-size: 22px;
                                line-height: 32px;
                                width: 70%;
                                margin-bottom: 0;
                            }
                        }
                    }

                    .modal-button-block{
                        display: flex;
                        justify-content: flex-end;
                        gap: 24px;

                        .button{
                            border-radius: 10px;
                            font-weight: 700;
                            font-size: 16px;
                            line-height: 27px;
                            padding: 10px 32px;
                        }
                    }
                }
            }
        }
    }

    .lk-notifications-page{
        .lk-page_notifications-header_buttons{
            display: flex;
            flex-wrap: nowrap;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 40px;

            .lk-page_notifications-header{
                color: black;
                font-weight: 700;
                font-size: 32px;
                line-height: 48px;
                margin-bottom: 0;
            }

            .lk-page_notifications-header_buttons_right-part{
                display: flex;
                flex-wrap: nowrap;
                align-items: center;
                gap: 20px;
                position: relative;

                .add_button{
                    border-radius: 10px;
                    padding: 10px 32px;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 27px;
                }

                svg{
                    &:hover{
                        cursor: pointer;
                    }
                }

                .tooltip-custom{
                    position: absolute;
                    padding: 4px;
                    border: 1px solid black;
                    background-color: #fff;
                    z-index: 100;
                    right: -150px;
                    top: 45px;

                    p{
                        margin-bottom: 0;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 16px;
                        color: black;
                    }
                }
            }
        }

        .lk-notifications-page-list{
            margin-bottom: 41px;

            .lk-notifications-page-list_once{
                padding: 10px;

                .lk-notifications-page-list_once-header_block{
                    display: flex;
                    flex-wrap: nowrap;
                    align-items: center;
                    gap: 8px;
                    margin-bottom: 12px;

                    p{
                        margin-bottom: 0;
                        color: black;
                        font-weight: 700;
                        font-size: 12px;
                        line-height: 18px;
                    }
                }

                .lk-notifications-page-list_once-message{
                    color: black;
                    font-weight: 400;
                    font-size: 22px;
                    line-height: 33px;
                    margin-bottom: 12px;
                }

                .lk-notifications-page-list_once-link{
                    color: #0071b5;
                    font-weight: 700;
                    font-size: 22px;
                    line-height: 33px;

                    &:hover{
                        cursor: pointer;
                        color: #038bdf;
                    }
                }
            }
        }
    }
}