.social {
	display: inline-flex;
	margin-left: -25px;

	.list-item {
		padding-left: 25px; }

	svg {
		width: 30px;
		height: 30px;
		fill: $color-6; }

	.list-link {
		&:hover {
			svg {
				fill: $color-2; } } } }
