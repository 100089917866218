
























































































.confirm_widget_modal{
    .modal-container{
        padding: 0 !important;
        max-width: 600px !important;
        max-height: 650px;
        overflow-x: auto;

        .modal_header{
            border-bottom: 1px solid rgba(82, 92, 105, .11);
            
            p{
                padding: 12px;
                color: black;
                font-size: 15px;
            }
        }

        .state{
            padding: 12px;

            .text_block{
                color: black;
                font-size: 15px;
            }

            .buttons_block{
                margin-top: 12px;
                display: flex;
                align-items: center;
                gap: 10px;

                .button{
                    width: 100%;
                    border-radius: 4px;
                    font-weight: 600;
                    font-size: 15px;
                    line-height: 22px;
                }
            }
        }

        .b24-window-close{
            position: absolute;
            top: 12px;
            right: 12px;
            width: 28px;
            height: 28px;
            border-radius: 100%;
            border: 0;
            -webkit-transition: opacity .3s;
            -o-transition: opacity .3s;
            transition: opacity .3s;
            opacity: .5;
            cursor: pointer;
            outline: 0;
            z-index: 25;
            background-color: #ffd110;
            background-color: rgba(237, 28, 36, 1);

            &:before{
                content: '';
                position: absolute;
                top: 50%;
                left: 50%;
                width: 1px;
                height: 15px;
                background-color: #fff;
                background-color: rgba(255, 255, 255, 1);
                -webkit-transform: translate(-50%, -50%) rotate(45deg);
                -ms-transform: translate(-50%, -50%) rotate(45deg);
                transform: translate(-50%, -50%) rotate(45deg);
            }

            &:after{
                content: '';
                position: absolute;
                top: 50%;
                left: 50%;
                width: 1px;
                height: 15px;
                background-color: #fff;
                background-color: rgba(255, 255, 255, 1);
                -webkit-transform: translate(-50%, -50%) rotate(45deg);
                -ms-transform: translate(-50%, -50%) rotate(45deg);
                transform: translate(-50%, -50%) rotate(-45deg);
            }

            &:hover{
                opacity: 1;
            }
        }
    }
}
