/* FOOTER
 * -------------------------------------------------- */

.footer {
	background: url('../img/footer.svg') 0 100% no-repeat;

	&-top,
	&-middle,
	&-bottom {
		@include lt(); }

	.row {
		> .col {
			padding: 43px 0 30px 31px;
			@include width(20%); } }

	&-middle {
		.row {
			> .col:last-child {
				display: none; } } }

	&-bottom {
		.row {
			> .col:nth-child(3) {
				@include width(40%); } }

		.label {
			font-size: 1.4rem; } }

	&-city {
		.choices {
			&.choices--location {
				.choices__list--single {
					font-size: 2.2rem;
					font-weight: bold;

					> .choices__item {
						padding-right: 35px;

						&::after {
							width: 20px;
							height: 20px;
							margin-top: -9px; } } } } } }

	@media (max-width: $mdm) {
		&-city {
			.choices {
				&.choices--location {
					.choices__list--single {
						font-size: 1.8rem; } } } }

		&-bottom {
			.label {
				font-size: 1.2rem; } } }

	@media (max-width: $mds) {
		.row {
			> .col {
				padding-left: 16px; } }

		&-city {
			.choices {
				&.choices--location {
					.choices__list--single {
						font-size: 1.6rem; } } } } }

	@media (max-width: $mdxs) {
		&-city {
			.choices {
				&.choices--location {
					.choices__list--single {
						font-size: 1.4rem; } } } } }

	@media (max-width: $mtm) {
		.row {
			> .col {
				@include width(25%); } }

		&-top {
			.row {
				> .col:first-child {
					@include width(100%);
					padding-bottom: 0; } } }

		.logotype {
			max-width: 18rem; }

		&-bottom {
			.row {
				> .col {
					@include width(100%);

					&:not(:first-child) {
						padding-top: 0; }

					&:nth-child(3) {
						@include width(100%); } } } } }

	@media (max-width: $mtm) {
		padding-bottom: 46px;

		.row {
			> .col {
				padding-left: 0; } } }

	@media (max-width: $mts) {
		&-middle {
			.row {
				> .col {
					@include width(33.33%);

					&:first-child {
						@include width(100%);
						padding-bottom: 0; } } } } }

	@media (max-width: $mml) {
		text-align: center;
		background: none;
		@include lt();

		&-top,
		&-middle,
		&-bottom {
			border-top: 0;

			.row {
				> .col {
					@include width(100%);
					padding: 32px 0 0 0 !important; } } }

		&-top {
			.row {
				> .col:last-child {
					display: none; } } }

		&-middle {
			.row {
				> .col:not(:first-child) {
					@include lt();
					margin-top: 32px; }

				> .col:last-child {
					display: block; } } }

		&-bottom {
			.row {
				> .col {
					&:first-child,
					&:last-child {
						@include lt();
						margin-top: 32px; } } }

			.label {
				font-size: 1.4rem; } }

		.logotype {
			margin: 0 auto; }

		&-city {
			.choices {
				&.choices--location {
					.choices__list--single {
						font-size: 1.8rem; }

					.choices__list--dropdown {
						left: 50%;
						margin-left: -114px;
						width: 228px; } } } } } }

/* DEVELOPER
 * -------------------------------------------------- */

.developer {
	font-size: 1.4rem;
	margin-bottom: 16px;
	color: $color-6;

	&-link {
		font-weight: bold;

		&:hover {
			color: $color-2; } }

	@media (max-width: $mdm) {
		font-size: 1.2rem; }

	@media (max-width: $mml) {
		font-size: 1.4rem;
		margin-bottom: 35px; } }

/* NAVIGATION
 * -------------------------------------------------- */

.navigation-footer {
	font-size: 1.4rem;

	> .list-item + .list-item {
		margin-top: 20px; }

	> .list-item {
		> .list-link {
			font-weight: bold; } }

	.sub {
		margin-top: 20px;
		font-size: 1.2rem;

		> .list-item + .list-item {
			margin-top: 20px; } }

	.list-link:hover {
		color: $color-2; } }
