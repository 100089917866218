.field {
	width: 100%;

	&-label {
		font-size: 1.2rem;
		color: $color-6;
		margin-bottom: 9px; }

	&-input {
		input,
		textarea {
			width: 100%;
			padding: 17px 17px;
			font-size: 1.4rem;
			color: $baseColor;
			border: 1px solid $color-6;
			background: transparent;
			font-weight: bold;
			border-radius: 0;
			appearance: none;

			&:hover {
				background: $color-4; }

			&:focus {
				background: transparent;
				border-color: $baseColor; }

			&:disabled {
				opacity: .5; }

			&::-webkit-input-placeholder {
				font-weight: normal;
				color: $color-6;
				opacity: 1; }

			&:-moz-placeholder {
				font-weight: normal;
				color: $color-6;
				opacity: 1; }

			&::-moz-placeholder {
				font-weight: normal;
				color: $color-6;
				opacity: 1; }

			&:-ms-input-placeholder {
				font-weight: normal;
				color: $color-6;
				opacity: 1; } }

		textarea {
			height: auto; } }

	&.field--error {
		.field-label {
			color: $color-2; }

		.field-input {
			input {
				color: $color-2;
				border-color: $color-2;

				&::-webkit-input-placeholder {
					color: $color-2; }

				&:-moz-placeholder {
					color: $color-2; }

				&::-moz-placeholder {
					color: $color-2; }

				&:-ms-input-placeholder {
					color: $color-2; } } } }

	&.field--location {
		.field-input {
			position: relative;

			input {
				padding-left: 66px;
				background-image: url('../img/search-button.svg');
				background-position: 24px 50%;
				background-repeat: no-repeat;
				background-size: 17.76px 17.76px; } }

		.field-clear {
			width: 13.44px;
			height: 13.44px;
			position: absolute;
			right: 22px;
			top: 50%;
			margin: -6.72px 0 0 0;
			border: 0;
			padding: 0;
			background: url('../img/remove.svg') 0 0 / 100% 100% no-repeat; } }

	@media (max-width: $mdm) {
		&-input {
			input,
			textarea {
				padding: 14px 17px;
				font-size: 1.2rem; } } } }
