.order {
	background: #fff;
	border-radius: 4px;
	box-shadow: 0 3px 45px rgba(#AAAAAA, .16);
	font-size: 1.8rem;
	position: relative;

	&.-active {
		margin-bottom: 80px;

		&:not(:last-child) {
			margin-bottom: 145px; }

		.order-header::before {
			transform: rotate(180deg); } }

	&-header {
		background: $color-4;
		position: relative;

		&::before {
			@include content();
			width: 16px;
			height: 10px;
			background: url('../img/order-arrow.svg') 0 0 / 100% 100% no-repeat;
			top: 50%;
			right: 34px;
			margin-top: -5px; } }

	&-body {}

	&-item {
		padding: 32px 0;

		&.order-item--labels {
			padding-bottom: 0;

			& + .order-item {
				border-top: 0;
				padding-top: 0; } } }

	&-row {
		display: flex;

		&.order-row--labels {
			margin-bottom: 12px; } }

	&-cell {
		flex: 1;

		&-label {
			display: none; }

		&:first-child {
			@include width(46%);
			padding-left: 35px; }

		&.order-cell--remove {
			@include width(50px); } }

	&-list {

		.order-row:not(.order-row--labels) {
			align-items: center; }

		.order-item {
			@include lt();

			&:not(:first-child) {
				.order-row--labels {
					display: none; } } } }

	&-status {
		color: $color-6; }

	&-count {
		font-size: 1.6rem; }

	&-product {
		display: flex;
		align-items: center;
		font-size: 1.6rem;

		&-image {
			@include width(39%); }

		&-title {
			flex: 1;
			padding-left: 20px; } }

	&-remove {
		width: 14px;
		height: 14px;
		padding: 0;
		margin: 0;
		border: 0;
		font-size: 0;
		display: block;
		background: url('../img/remove.svg') 0 0 / 100% 100% no-repeat; }

	&-controls {
		//margin-top: 32px
		position: absolute;
		bottom: -80px;
		right: 0;

		.layout-buttons {
			justify-content: flex-end; } }

	@media (max-width: $mds) {
		&-header {
			&::before {
				right: 16px; } }

		&-cell {
			font-size: 1.4rem;

			&:first-child {
				@include width(40%);
				padding-left: 16px; }

			&.order-cell--remove {
				@include width(30px); } }

		&-product {
			font-size: 1.4rem;

			&-image {
				@include width(30%); }

			&-title {
				padding-left: 10px; } }

		&-controls {
			.button {
				padding-left: 15px;
				padding-right: 15px; } } }

	@media (max-width: $mml) {
		margin-left: -15px;
		margin-right: -15px;
		box-shadow: none;

		&.-active {
			margin-bottom: 64px !important; }

		&-info {
			@include lb();
			margin-bottom: 32px; }

		&-controls {
			position: static;
			padding-left: 15px;
			padding-right: 15px;
			@include lt();
			padding-top: 64px; }

		&-item {
			&.order-item--labels {
				display: none; } }

		&-row {
			display: block; }

		&-cell {
			padding-top: 0;
			padding-bottom: 0;
			padding-left: 15px;
			padding-right: 15px;
			@include width(100%);

			&:not(:first-child) {
				padding-top: 32px; }

			&-label {
				display: block;
				margin-bottom: 16px; }

			&:first-child {
				@include width(100%);
				padding-left: 15px; }

			&.order-cell--remove {
				@include width(100%); } }

		&-remove {
			background: $color-4;
			display: inline-block;
			text-decoration: none;
			padding: 13px 27px 14px 27px;
			border: 0;
			border-radius: 0;
			text-align: center;
			transition: color .25s, background-color .25s, opacity .25s, border-color .25s;
			font-size: 1.4rem;
			font-weight: bold;
			width: 100%;
			height: auto; } } }
