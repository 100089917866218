.layout-cards {
	&.-col-2 {
		@include grid(2, 16px, 16px); }

	&.-col-3 {
		@include grid(3, 16px, 16px); }

	&.-col-4 {
		@include grid(4, 16px, 16px); }

	@media (max-width: $mds) {
		&.-col-4 {
			@include grid(3, 16px, 16px); } }

	@media (max-width: $mdxs) {
		&.-col-3 {
			@include grid(2, 16px, 16px); } }

	@media (max-width: $mtm) {
		&.-col-4 {
			@include grid(2, 16px, 16px); } }

	@media (max-width: $mmm) {
		&.-col-2 {
			@include grid(1, 16px, 16px); }

		&.-col-3 {
			@include grid(1, 16px, 16px); }

		&.-col-4 {
			@include grid(1, 16px, 16px); } } }

.card {
	position: relative;
	display: block;
	padding-bottom: 86.751%;
	background: #fff;
	border-radius: 4px;
	overflow: hidden;
	box-shadow: 0 3px 45px rgba(#AAAAAA, .16);
	min-height: 150px;

	&::before {
		@include content();
		left: 0;
		top: 0;
		bottom: 0;
		right: 0;
		background: #fff;
		transition: background .25s ease;
		z-index: 1; }

	&-arrow {
		position: absolute;
		top: 39px;
		right: 39px;
		z-index: 2;

		svg {
			fill: $color-2;
			width: 25px;
			height: 25px;
			transition: fill .25s ease; } }

	&-media {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		z-index: 0; }

	&-heading {
		position: absolute;
		bottom: 57px;
		left: 42px;
		z-index: 2; }

	&-icon {
		margin-bottom: 6px;

		svg {
			//width: 19%
			fill: $color-7;
			display: block; } }

	&-title {
		font-weight: bold;
		font-size: 1.6rem;
		min-height: 68px;
		transition: color .25s ease; }

	&-description {
		transition: color .25s ease;
		margin-bottom: 20px; }

	&.card--media {
		&::before {
			opacity: .9; } }

	&.card--small {
		.card-arrow {
			top: 20px;
			right: 20px;

			svg {
				width: 14px;
				height: 14px; } }

		.card-icon {
			margin-bottom: 16px;

			svg {
				width: auto; } }

		.card-heading {
			bottom: 23px;
			left: 16px; }

		.card-title {
			font-weight: normal;
			font-size: 1.6rem;
			min-height: 48px; }

		.card-description {
			font-size: 1.2rem;
			margin-bottom: 0; } }

	&.card--medium {
		padding-bottom: 85.05%;

		.card-heading {
			bottom: auto;
			top: 32px;
			left: 32px; }

		.card-icon {
			margin-bottom: 35px; }

		.card-title {
			//font-size: 2.4rem
			min-height: 0; }

		.card-description {
			margin-bottom: 0;
			margin-top: 16px; } }

	&.card--large {
		padding-bottom: 73.387%;

		.card-heading {
			bottom: auto;
			top: 32px;
			left: 32px; }

		.card-icon {
			margin-bottom: 47px; }

		.card-title {
			font-size: 2.4rem; } }

	&.card--wide {
		padding-bottom: 58.94%; }

	&.card--long {
		padding-bottom: 96.967%; }

	&.card--no-arrow {
		.card-arrow {
			display: none; } }

	&.card--selected,
	&:hover {
		&::before {
			background: $color-2; }

		.card-icon {
			svg {
				fill: #fff; } }

		.card-title {
			color: #fff; }

		.card-description {
			color: #fff; }

		.card-arrow {
			svg {
				fill: #fff; } } }

	&.card--cabinet {
		.card-arrow {
			top: 23px;
			right: 28px; }

		.card-icon {
			margin-bottom: 24px;

			svg {
				width: auto; } }

		.card-heading {
			bottom: 32px;
			left: 23px; } }

	@media (max-width: $mdm) {
		&-arrow {
			top: 30px;
			right: 30px;

			svg {
				width: 19px;
				height: 19px; } }

		&-title {
			font-size: 1.8rem;
			min-height: 50px; }

		&-heading {
			bottom: 42px;
			left: 32px; } }

	@media (max-width: $mds) {
		&-description {
			font-size: 1.4rem; }

		&.card--small {
			.card-arrow {
				top: 15px;
				right: 15px; }

			.card-heading {
				bottom: 16px;
				left: 16px; }

			.card-title {
				font-size: 1.4rem;
				min-height: 42px; } }

		&.card--medium {
			.card-heading {
				top: 22px;
				left: 22px; }

			.card-icon {
				margin-bottom: 16px; } }

		&.card--large {
			.card-icon {
				margin-bottom: 25px; }

			.card-title {
				font-size: 1.8rem; } } }

	@media (max-width: $mmm) {
		padding: 64px 26px 23px 26px !important;
		min-height: 250px;
		display: flex;
		align-items: flex-end;

		&-heading {
			position: relative;
			top: auto !important;
			bottom: auto !important;
			left: auto !important;
			right: auto !important; }

		&-description {
			br {
				display: none; } }

		&.card--small {
			min-height: 150px;
			padding-top: 37px !important; } } }
