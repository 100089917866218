



























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































    .cardProduct_row_info{
        justify-content: space-between;

        .description_col{
            margin-right: 4rem;
            max-width: 53%;
        }

        .props_col{
            margin-right: 4rem;
            max-width: 29%;
        }

        .docs_col{
            max-width: 18%;
        }
    }

    .document_button{
        background-color: #F5F6FA;
        padding: 2rem;
        cursor: pointer;
        margin-bottom: 3rem;

        i{
            font-size: 25px;
            margin-right: 3rem;
            // margin-top: 10px;
        }

        p{
            font-weight: 600;
        }
    }

    .pb-0{
        padding-bottom: 0 !important;
    }

    .mb-2{
		margin-bottom: 2rem !important;
	}

    .mb-4{
		margin-bottom: 4rem !important;
	}

    .show_char_button{
        font-size: 1.6rem;
        font-weight: normal;
        padding: 17px 0 17px 31px;
        margin-bottom: 0;
        text-decoration: underline;
        cursor: pointer;
    }
    .table_label_small_padding{
        padding-top: 17px !important;
        padding-bottom: 17px !important;
    }

    .nd-popular-card{
        margin-top: 100px;

        .product-header{
            margin-bottom: 20px;
        }

        .product-image{
            min-height: 230px;
            height: 230px;

            img{
                max-height: 230px;
            }
        }

    }

    .document_link_row{
        flex-wrap: nowrap;
        align-items: center;

        .icon{
            width: 20%;
        }
        .name{
            width: 80%;

            p{
                text-align: center;
            }
        }
    }
