/* LOGIN
 * -------------------------------------------------- */

.row--login {
	.col {
		&:nth-child(1) {
			@include inner-left();
			@include width(60%);
			padding-right: calc(20% - 28px); } }

	.form {
		//max-width: 68.9%
		margin-bottom: 64px; }

	@media (max-width: $mdxs) {
		.col {
			&:nth-child(1) {
				padding-right: 40px; } } }

	@media (max-width: $mtm) {
		display: block;

		.col {
			&:nth-child(1) {
				@include width(100%);
				padding-right: 0;
				margin-bottom: 96px; } }

		.form-row {
			left: 0;
			margin-left: -20px; }

		.form-row--login {
			display: flex;
			justify-content: space-between;
			align-items: center;

			> .form-item {
				flex: none;
				width: auto;
				max-width: none;
				margin-top: 0 !important; } } } }

.registration {
	background: url('../img/registration.svg') 100% 100% no-repeat $color-2;
	color: #fff;
	padding: 96px 32px;

	@media (max-width: $mtm) {
		padding: 64px 15px; } }

.social-auth {
	margin-left: -20px;
	align-items: center;

	.list-item {
		padding-left: 20px; }

	svg {
		fill: $color-6;

		&:hover {
			fill: $color-2; } }

	@media (max-width: $mmm) {
		@include grid(5, 15px, 15px);
		text-align: center;

		.list-item {
			padding-left: 15px; }

		svg {
			transform: scale(1.5); } } }

/* REGISTRATION
 * -------------------------------------------------- */

.row--registration {
	.col {
		&:nth-child(1) {
			padding-left: 20%;
			padding-right: 20%; } }

	.form {
		.form-row > .form-item {
			@media (min-width: $mml + 1px) {
				@include width(33.33%); } } }

	.form-group + .tabs--form,
	.tabs--form + .form-group {
		margin-top: 24px; }

	@media (max-width: $mds) {
		.col {
			&:nth-child(1) {
				padding-right: 0; } } }

	@media (max-width: $mdxs) {
		.col {
			&:nth-child(1) {
				padding-left: 16px; } } }

	@media (max-width: $mtm) {
		.col {
			&:nth-child(1) {
				padding-left: 0; } } } }

/* PASSWORD RECOVERY
 * -------------------------------------------------- */

.row--recovery {
	.col {
		&:nth-child(1) {
			@include width(60%);
			padding-left: 20%;
			padding-right: 20%; } }

	@media (max-width: $mdxs) {
		flex-direction: column-reverse;
		padding-left: 16px;

		.col {
			&:nth-child(1) {
				padding-left: 0;
				padding-right: 0;
				margin-top: 32px; } } }

	@media (max-width: $mtm) {
		padding-left: 0; }

	@media (max-width: $mml) {
		.col {
			&:nth-child(1) {
				@include width(100%); } } } }
