/* DATA
 * -------------------------------------------------- */

.data {
	&-label {
		font-weight: bold;
		margin-bottom: 10px;
		font-size: 1.8rem; }

	&-value {
		font-size: 1.6rem; }

	@media (max-width: $mmm) {
		&-label {
			font-size: 1.6rem; }

		&-value {
			font-size: 1.4rem; } } }
