.good {
	> .col {
		&:nth-child(1) {
			@include width(40%); }

		&:nth-child(2) {
			padding-left: 36px; } }

	&-group + &-group {
		margin-top: 22px; }

	&-group + &-info {
		margin-top: 46px; }

	&-group {
		&.good-group--row {
			@include flex();
			margin-left: -36px;
			position: relative;

			.good-col {
				@include width(33.33%);
				padding-left: 36px; }

			&.good-group--row--small {
				margin-left: -19px;

				.good-col {
					@include width(50%);
					padding-left: 19px; } } } }

	&-label {
		font-size: 1.2rem;
		color: $color-6;
		margin-bottom: 9px; }

	&-info {
		box-shadow: 0 3px 45px rgba(#AAAAAA, .16);
		background: #fff;

		@media (max-width: 1600px) {
			.value-text span {
				line-height: 48px; } }

		&-body {
			display: flex;
			justify-content: space-between; }

		&-col {
			padding: 51px 34px 40px 34px;

			&:not(:first-child) {
				border-left: 1px solid $color-5; } }

		&-footer {
			padding: 40px 34px;
			border-top: 1px solid $color-5; } }

	&-button {
		margin-top: 32px; }

	.info {
		margin-top: 4px;

		& + .button {
			margin-top: 32px; } }

	&.good--sidebar {
		> .col {
			&:nth-child(1) {
				@include width(53.41%); }

			&:nth-child(2) {
				padding-left: 36px;
				padding-top: 24px; } } }

	.layout-good-cards {
		&.-col-2 {
			@include grid(2, 16px, 16px, 16px); }

		&.-col-3 {
			@include grid(3, 16px, 16px, 16px); } }

	& + .good-info {
		margin-top: 64px; }

	@media (max-width: 1400px) {
		&-info {
			&-col {
				padding: 25px 16px 20px 16px; } } }

	@media (max-width: $mds) {
		&.good--sidebar {
			display: block;

			> .col {
				&:nth-child(1) {
					@include width(100%);
					margin-bottom: 32px; }

				&:nth-child(2) {
					padding-left: 0;
					padding-top: 0; } } } }

	@media (max-width: $mtl) {
		display: block;

		> .col {
			&:nth-child(1) {
				@include width(100%);
				margin-bottom: 32px; }

			&:nth-child(2) {
				padding-left: 0; } }

		&-group {
			&.good-group--row {
				margin-left: -16px;

				.good-col {
					padding-left: 16px; }

				&.good-group--row--small {
					margin-left: -16px;

					.good-col {
						padding-left: 16px; } } } } }

	@media (max-width: $mmm) {
		&-group {
			&.good-group--row {
				display: block;

				.good-col {
					@include width(100%);

					& + .good-col {
						margin-top: 24px; } }

				&.good-group--row--small {
					margin-left: 0;

					.good-col {
						@include width(100%);
						padding-left: 0; } } } }

		&-info {
			text-align: center;

			&-body {
				display: block; }

			&-col {
				@include width(100%);
				border-left: 0 !important;
				padding: 32px 0 0 0; }

			&-footer {
				padding: 32px 15px 24px 15px;
				border-top: 0; }

			.value {
				display: inline-flex;
				vertical-align: top; }

			.counter {
				display: inline-flex;
				vertical-align: top; } }

		.layout-good-cards {
			@include list-scroll(); } } }

.value {
	display: flex;
	align-items: center;
	white-space: nowrap;

	&-text {
		font-weight: bold;
		font-size: 3.2rem;
		line-height: 1;

		span {
			font-size: 4.4rem; } }

	&-measure {
		font-size: 1.2rem;
		margin-left: 10px; }

	@media (max-width: 1600px) {
		&-text {
			font-size: 2.2rem;

			span {
				font-size: 3.6rem; } } }

	@media (max-width: 1400px) {
		&-text {
			font-size: 2.2rem;

			span {
				font-size: 3.4rem; } } } }

.row--good-info {
	> .col {
		&:nth-child(1) {
			@include width(60%);
			padding-right: 31px; } }

	@media (max-width: $mtl) {
		display: block;

		> .col {
			&:nth-child(1) {
				@include width(100%);
				padding-right: 0;
				margin-bottom: 32px; } } } }

.table--good-properties {
	@media (min-width: $mml + 1px) {
		.table-cell {
			@include width(50%);
			text-align: right;
			padding-right: 31px;

			&.table-cell--th {
				font-size: 1.6rem;
				font-weight: normal;
				text-align: left;
				padding-right: 0; } } } }

.good-gallery,
.modal-good-gallery {
	margin-bottom: 16px;
	position: relative;

	.swiper-button-prev,
	.swiper-button-next {
		width: 72px;
		height: 56px;
		margin: 0;
		bottom: -8px;
		left: auto;
		top: auto;
		opacity: 1;
		outline: none;

		&::before {
			@include content();
			width: 12px;
			height: 9px;
			top: 50%;
			left: 50%;
			margin: -4.5px 0 0 -6px;
			background: url('../img/slider-arrow.svg') 0 0 / 100% 100% no-repeat; } }

	.swiper-button-next {
		right: -8px;
		background: $color-2;

		&::before {
			transform: rotate(180deg); } }

	.swiper-button-prev {
		right: 64px;
		background: $color-1; }

	@media (max-width: $mtm) {
		margin-left: -15px;
		margin-right: -15px;

		.swiper-button-prev,
		.swiper-button-next {
			display: none; }

		.swiper-slide {
			width: calc(100vw - 24px); } } }

.slider--good-gallery {}

.layout-good-gallery-thumbs {
	@include grid(3, 16px, 16px);

	@media (max-width: $mtm) {
		display: none; } }

.thumb {
	display: block;
	position: relative;

	&::before {
		@include content();
		opacity: 0;
		background: url('../img/plus.svg') 50% 50% / 17px 17px no-repeat rgba($color-2, .9);
		transition: opacity .25s ease;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%; }

	img {
		width: 100%; }

	&:hover {
		&::before {
			opacity: 1; } } }

.row--modal-gallery {
	height: 100%;

	> .col {
		height: 100%;

		&:nth-child(1) {
			padding-right: 50px; }

		&:nth-child(2) {
			@include width(205px); }

		&:nth-child(3) {
			@include width(210px);
			text-align: right; } }

	@media (max-width: $mds) {
		> .col {
			&:nth-child(1) {
				padding-right: 20px; }

			&:nth-child(2) {
				@include width(160px); }

			&:nth-child(3) {
				@include width(140px); } } } }


.modal-good-gallery {
	height: 100%;
	margin-bottom: 0; }

.slider--modal-good-gallery {
	height: 100%;

	.swiper-wrapper {
		height: 100%; }

	.swiper-slide {
		display: flex;
		align-items: center;
		justify-content: center;

		img {
			display: block;
			max-width: 100%;
			max-height: 100%;
			height: auto;
			width: auto; } } }

.slider--modal-good-gallery-thumbs {
	height: 100%;

	.swiper-slide {
		height: auto;
		opacity: .3;

		&.swiper-slide-thumb-active {
			opacity: 1; } } }
