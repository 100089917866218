.table {
	&-head {
		font-size: 1.6rem;
		margin-bottom: 24px; }

	&-body {
		background: $color-4;
		font-weight: bold;
		font-size: 2.4rem;

		.table-cell {
			padding-top: 24px;
			padding-bottom: 24px; } }

	&-foot {
		margin-top: 32px; }

	&-row {
		display: flex;
		position: relative;
		align-items: center;

		& + .table-row {
			&::before {
				@include content();
				left: 31px;
				right: 31px;
				top: 0;
				border-top: 1px solid $color-5; } } }

	&-cell {
		&:first-child {
			@include width(40%);
			@include inner-left(); }

		//&:last-child
		//	padding-right: 31px

		&:not(:first-child) {
			//+width(20%)
			flex: 1;
			@include inner-right(); }

		&.table-cell--th {
			font-size: 1.8rem; }

		&.table-cell--text {
			font-size: 1.6rem;
			font-weight: normal; } }

	@media (max-width: $mds) {
		&-head {
			font-size: 1.2rem; }

		&-body {
			font-size: 1.8rem; }

		&-cell {
			&.table-cell--th {
				font-size: 1.4rem; } }

		&-row {
			& + .table-row {
				&::before {
					left: 16px;
					right: 16px; } } } }

	@media (max-width: $mtm) {
		margin-left: -15px;
		margin-right: -15px;

		&-cell {
			&:first-child {
				padding-left: 15px; }

			&:not(:first-child) {
				padding-right: 15px; } } }

	@media (max-width: $mml) {
		&-head {
			display: none; }

		&-body {
			font-size: 1.6rem; }

		&-row {
			display: block;
			padding: 32px 0; }

		&-cell {
			@include width(100%);
			padding: 0 15px !important;

			&:first-child {
				@include width(100%);
				padding: 0 15px; }

			&.table-cell--th {
				font-size: 1.6rem; }

			&::before {
				content: attr(data-label);
				font-weight: normal;
				font-size: 1.2rem;
				display: block;
				margin-bottom: 16px;
				color: $color-6; } }

		&-cell + &-cell {
			margin-top: 32px; } } }

.total {
	&-price {
		display: flex;
		justify-content: flex-end;
		align-items: flex-end;

		&-label {
			@include width(20%); }

		&-value {
			@include width(20%);
			font-weight: bold;
			font-size: 2.4rem; } }

	&-buttons {
		margin-top: 32px;

		.layout-buttons {
			justify-content: flex-end;
			margin-left: 0;

			.list-item {
				padding-right: 32px;
				padding-left: 0;

				&:last-child {
					@include width(20%); } } } }

	&-description {
		margin-top: 64px;
		margin-left: 40%; }

	@media (max-width: $mdxs) {
		&-price {
			justify-content: flex-start;

			&-label,
			&-value {
				flex: none;
				width: auto;
				max-width: none; }

			&-label {
				padding-right: 20px; } }

		&-buttons {
			.layout-buttons {
				justify-content: flex-start;
				margin-left: -20px;

				.list-item {
					padding-left: 20px;
					padding-right: 0;

					&:last-child {
						flex: none;
						width: auto;
						max-width: none; } } } }

		&-description {
			margin-left: 0; } }

	@media (max-width: $mtm) {
		padding-left: 15px;
		padding-right: 15px;

		&-price {
			display: block;
			margin-bottom: 32px; }

		&-buttons {
			.layout-buttons {
				margin-top: -24px;

				.list-item {
					margin-top: 24px; } } } }

	@media (max-width: $mms) {
		&-buttons {
			.layout-buttons {
				margin-top: 0; } } } }
