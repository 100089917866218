







































































































































































































































































.nlk-vertical-scroll{
	overflow-y: auto;
}
@media only screen and (max-width: 1600px){
	.nlk-table-order{
		min-width: 60rem !important;
	}
	.nlk-accordion{
		min-width: 60rem !important;
	}
}
@media only screen and (min-width: 1200px) and (max-width: 1400px){
	.nlk-vertical-scroll{
		max-height: 32rem;
	}
}
@media only screen and (min-width: 1400px) and (max-width: 1800px){
	.nlk-vertical-scroll{
		max-height: 60rem;
	}
}
@media only screen and (min-width: 1800px){
	.nlk-vertical-scroll{
		max-height: 73rem;
	}
}
.try_custom_width{
	.field_data{
		width: 23.6rem !important;
	}
}
.orders_page{
	.nlk-order-header__top{
		justify-content: space-around;
	}

	.pl-0{
		padding-left: 0 !important;
	}

	.pr-0{
		padding-right: 0 !important;
	}

	.mar-0{
		margin: 0 !important;
	}

	.first_tab{
		width: 12rem;
	}

	.first_column{
		width: 15%;
	}

	.second_tab{
		width: 12rem;
	}

	.second_column{
		width: 15%;
	}

	.third_tab{
		width: 12rem;
	}

	.third_column{
		width: 15%;
	}

	.fourth_tab{
		width: 12rem;
	}

	.fourth_column{
		width: 15%;
	}

	.fiveth_tab{
		width: 12rem;
	}

	.fiveth_column{
		width: 15%;
	}

	.sixth_tab{
		width: 12rem;
	}

	.sixth_column{
		width: 15%;
	}

	.seventh_column{
		width: 5%;
	}
}
