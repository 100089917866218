.tabs.tabs--history {
	.tabs-title {
		margin-bottom: 64px;
		@include inner-left();

		.tabs-item {
			margin-right: 38px;
			font-size: 1.4rem;
			padding: 0 35px; } }

	@media (max-width: $mml) {
		.tabs-title {
			flex-wrap: nowrap;
			margin-left: -15px;
			margin-right: -15px;
			overflow-x: auto;
			overflow-y: hidden;
			-webkit-overflow-scrolling: touch;
			//white-space: nowrap
			justify-content: flex-start;
			margin-top: 0;
			padding-bottom: 15px;
			margin-bottom: 17px;

			br {
				display: block; }

			.tabs-item {
				padding: 0 15px;
				background: none;
				font-size: 1.8rem;
				color: $color-5;
				flex: none;
				margin: 0;
				line-height: 1.2;
				height: auto;
				text-align: left;

				&:hover {
					background: none;
					color: $color-5; }

				&.-active {
					font-size: 1.8rem;
					color: $color-6;
					background: none; } } } } }

.history {
	&-header {
		@include flex();
		margin-bottom: 24px; }

	&-item {
		&-header,
		&-body {
			@include flex(); }

		&-header {
			font-weight: bold;
			font-size: 1.8rem;
			transition: color .25s ease;
			position: relative;

			&::after {
				content: '+';
				position: absolute;
				right: 0;
				width: 11px;
				text-align: center;
				color: $baseColor; }

			&:hover {
				color: $color-2; } }

		&-body {
			padding-top: 35px;
			display: none;

			.history-col:first-child {
				align-self: flex-end;
				color: $color-2; } }

		&.-active {
			.history-item-header {
				color: $color-2;

				&::after {
					content: '-'; } }

			.history-item-body {
				@include flex(); } } }

	&-item + &-item {
		margin-top: 35px; }

	&-col {
		&:nth-child(1) {
			@include inner-left();
			@include width(20%); }

		&:nth-child(2) {
			@include width(40%); } }

	@media (max-width: $mtm) {
		.history-item-body {
			.history-col {
				&:nth-child(1) {
					font-size: 1.2rem; } } }

		.history-col {
			&:nth-child(2) {
				flex: 1;
				max-width: none;
				width: auto; } } }

	@media (max-width: $mml) {
		.history-item-body {
			.history-col {
				&:nth-child(1) {
					display: none; } } } }

	@media (max-width: $mmm) {
		display: flex;
		flex-wrap: nowrap;
		margin-left: -15px;
		margin-right: -15px;
		overflow-x: auto;
		overflow-y: hidden;
		-webkit-overflow-scrolling: touch;
		padding-bottom: 30px;
		margin-bottom: -30px;

		&-item {
			padding: 0 15px;
			@include width(calc(100vw - 40px));

			&:last-child {
				@include width(100vw);
				padding-right: 40px; }

			& + .history-item {
				margin-top: 0; } }

		&-header {
			display: none; }

		&-item-header {
			display: block;
			color: $color-7 !important;

			&::after {
				display: none; }

			.history-col:first-child {
				margin-bottom: 16px; } }

		&-item-body {
			display: block !important;
			padding-top: 25px; }

		&-col {
			&:nth-child(1),
			&:nth-child(2) {
				@include width(100%); } } } }
