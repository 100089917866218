

























































































































































.popular_product_swiper{
    .swiper-button-prev{
        left: -40px;
    }

    .swiper-button-next{
        right: -40px;
    }
}
