
























































































































































































































































































































.block_svg{
    display: flex;
    justify-content: space-between;

    .input_skat{
        display: flex;
        flex-direction: column;
    }

    .mb-2{
        margin-bottom: 2rem;
    }
}
