.row--faq {
	.col {
		&:nth-child(1) {
			@include inner-left();
			@include width(40%);
			display: flex;
			flex-direction: column;
			justify-content: space-between;

			p {
				font-size: 1.8rem;
				font-weight: bold; }

			a {
				color: $color-2; } }

		&:nth-child(2) {} }

	.accordion--faq {
		.accordion-item + .accordion-item {
			margin-top: 26px; }

		.accordion-item {
			&.-active {
				.accordion-header::after {
					transform: rotate(180deg); }

				.accordion-body {
					display: block; } } }

		.accordion-header {
			font-weight: bold;
			font-size: 2.3rem;
			position: relative;
			padding-right: 20px;

			&::after {
				@include content();
				width: 12px;
				height: 7px;
				background: url('../img/faq-arrow.svg') 0 0 / 100% 100% no-repeat;
				top: .5em;
				right: 0; } }

		.accordion-body {
			display: none;
			padding-top: 20px;
			padding-bottom: 20px; } }

	@media (max-width: $mdm) {
		.accordion--faq {
			.accordion-header {
				font-size: 1.8rem; } } }

	@media (max-width: $mtm) {
		display: block;

		.col {
			&:nth-child(1) {
				display: block;

				p {
					display: none; } } } }

	@media (max-width: $mmm) {
		.accordion--faq {
			display: flex;
			overflow-x: auto;
			overflow-y: hidden;
			margin-left: -15px;
			margin-right: -15px;
			-webkit-overflow-scrolling: touch;

			.accordion-item {
				@include width(calc(100vw - 40px));
				margin-top: 0 !important;
				padding-left: 15px;
				padding-right: 0;

				&:last-child {
					@include width(calc(100vw));
					padding-right: 40px; } }

			.accordion-header {
				&::after {
					display: none; } }

			.accordion-body {
				display: block !important; } } } }
