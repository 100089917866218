


















































































































































































































































































.nd-vacancy_main_page{
    .question_form_block{
        background-color: #353B48;
        padding: 60px;
        margin-bottom: 150px;

        display: flex;
        flex-wrap: nowrap;

        .question_form_block_header{
            font-size: 5.6rem;
            line-height: 7.2rem;
            color: white;
            margin-bottom: 27px;
            font-weight: 700;
        }

        .left_part{
            width: 50%;
        }

        .right_part{
            width: 50%;
        }

        input{
            width: 100%;
            margin-bottom: 26px;
            border-radius: 10px;
            background-color: #353B48;
            color: white;
            padding: 10px 20px;
            border: 1px solid white;
        }

        textarea{
            width: 100%;
            margin-bottom: 26px;
            border-radius: 10px;
            background-color: #353B48;
            color: white;
            padding: 20px;
            border: 1px solid white;
        }

        .button{
            width: 100%;
            border-radius: 10px;
            padding: 10px 40px;

            font-size: 2.3rem;
            line-height: 2.8rem;
        }
    }
}   

.custom_interview_avatar_block{
    height: 22.1em;
    overflow: hidden;
    border-radius: 50%;
    margin-bottom: 60px;
}

@media (max-width: 1699px) {
    .custom_interview_avatar_block{
        height: 17.1em;
    }
}
