
























































































































































































































































































































































@media only screen and (max-height: 700px){
	.custom_height_from_height_screen{
		height: 100% !important;
	}
}
.vh-100{
	height: 100vh;
}
.pr-20{
	padding-right: 20px !important;
}
.nlk-header-custom{
	display: flex;

	.nlk-header__item{
		flex: auto;
		padding-left: .5rem;
		position: relative;
		padding-bottom: 0;

		.nlk-header__name{
			width: 21rem;
			cursor: pointer;
		}

		.custom_list{
			position: absolute;
			top: 6rem;
			left: 0;
			background: #fff;
			width: 21rem;
			z-index: 99;

			padding: 0.5rem;
			border: 1px solid grey;
			// border-radius: 0.5rem;
		}
	}

	.nlk-header__item-user{
		flex: unset;
		justify-content: end;
		padding-top: 0.8rem !important;
	}

	.nlk-list-links{
		display: flex;

		.list-item{
			margin-right: 2rem;
			margin-bottom: 0;
			position: relative;

			.custom_list{
				position: absolute;
				top: 2rem;
				left: 0;
				background: #fff;
				width: 25rem;
				z-index: 99;
			}
		}

		.span-for-list-link{
			position: relative;
			// width: 11rem;
			padding-right: 4rem;
			color: inherit;
			font-weight: 700;
			line-height: 1.25;

			.nlk-accordion-btn_active{
				transform: rotate(-180deg);
			}
		}
	}

	a{
		text-decoration: none;
		border: unset !important;

		&:hover{
			color: #EA292E;
		}
	}

	.active{
		color: #EA292E;
	}
}

.test_row_manager{
	display: flex;
	// justify-content: flex-end;
	margin-top: 1.4rem;
	// position: absolute;
	// right: 0;

	.test_row_manager_item{
		margin-right: 2rem;
	}
}
