.-page-checkout {
	@media (min-width: $mml + 1px) {
		.wrapper {
			overflow: visible; }

		.layout-bside {
				position: sticky;
				top: 20px;
				align-self: flex-start; } } }

.form--checkout {
	//max-width: 470px
	margin-bottom: 64px;
	margin-right: -19px;

	.form-group {
		//max-width: 527px
		max-width: 66.67%;

		&.form-group--full {
			max-width: none; }

		&.form-group--field {
			//max-width: 455px
			max-width: 53.48%; } }

	.button--primary {
		min-width: 170px; }

	.button--default {
		min-width: 135px; }

	@media (max-width: $mds) {
		margin-right: 0;

		.form-group {
			max-width: none;

			&.form-group--field {
				max-width: none; } } } }

.checkout-result {
	.result-label {
		margin-bottom: 10px; }

	.result-value {
		font-size: 1.6rem; }

	@media (max-width: $mml) {
		display: none; } }

.-menu-checkout-opened {
	@media (max-width: $mml) {
		position: fixed;
		overflow: hidden;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;

		.header {
			z-index: 0; }

		.checkout-menu {
			position: fixed;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			z-index: 100;
			background: #fff; }

		.checkout-menu-toggle {
			background: $color-2;

			span {
				&:nth-child(1) {
					display: none; }

				&:nth-child(2) {
					display: block; } } }

		.checkout-menu-toggle-button {
			background-color: $color-1;

			&::before {
				opacity: 0; }

			&::after {
				opacity: 1; } }

		.checkout-result {
			display: block;
			width: 100%;
			height: calc(100% - 46px);
			background: #fff;
			color: $color-7;
			@include scroll();
			overflow: auto;

			svg {
				fill: $color-7; }

			.result-header,
			.result-body {
				text-align: left; }

			.result-header {
				background: $color-4;
				border-bottom-color: transparent; }

			.result-footer {
				border-top-color: $color-5; } } } }

.checkout-menu-toggle {
	display: none;
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	height: 46px;
	background: $color-6;
	color: #fff;
	z-index: 10;

	&-section {
		flex: 1;
		text-align: center;
		line-height: 46px;
		font-size: 1.6rem;

		span {
			margin-right: -46px;

			&:nth-child(1) {
				display: block; }

			&:nth-child(2) {
				display: none; } } }

	&-button {
		@include width(46px);
		height: 46px;
		position: relative;
		padding: 0;
		margin: 0;
		border: 0;
		background: transparent;

		&::before,
		&::after {
			@include content();
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%); }

		&::before {
			width: 18px;
			height: 11px;
			background: url('../img/navigation-toggle.svg') 0 0 /  100% 100% no-repeat;
			opacity: 1; }

		&::after {
			width: 14px;
			height: 14px;
			background: url('../img/navigation-close.svg') 0 0 /  100% 100% no-repeat;
			opacity: 0; } }

	@media (max-width: $mml) {
		display: flex; } }

.row--checkout-group {
	justify-content: space-between;

	.col {
		flex: none;
		min-width: 20%; }

	@media (max-width: $mtm) {
		display: block;

		br {
			display: none; }

		> .col + .col {
			margin-top: 32px; }

		.col {
			min-width: 0; } } }

.row--checkout-buyer {
	@media (max-width: $mtm) {
		display: block;

		br {
			display: none; }

		> .col + .col {
			margin-top: 32px; } } }

.row--checkout-group-payment {
	@media (max-width: $mtm) {
		display: block;

		> .col + .col {
			margin-top: 32px; } } }

.row--payment-cards {
	opacity: .45;
	align-items: center; }

.row--checkout-finish {
	> .col {
		&:nth-child(1) {
			@include width(40%);
			@include inner-left();
			@include inner-right();

			img {
				margin-bottom: 24px; }

			h5 {
				margin-bottom: 10px; }

			p {
				margin-bottom: 10px; } } }

	@media (max-width: $mdxs) {
		display: block;

		> .col {
			&:nth-child(1) {
				@include width(100%);
				margin-bottom: 96px; } } } }

.row--checkout-info {
	> .col {
		&:nth-child(1) {
			@include width(45%); } }

	@media (max-width: $mmm) {
		display: block;

		> .col {
			&:nth-child(1) {
				@include width(100%);
				margin-bottom: 32px; } } } }

.checkout-total-price {
	&-label {
		font-size: 1.2rem;
		margin-bottom: 5px; }

	&-value {
		line-height: 1;
		font-size: 1.2rem;

		span {
			font-weight: bold;
			font-size: 4.4rem; } } }
