






















































































































































































.order_confirm_modal{
    .modal-container{
        padding: 24px !important;
        max-width: 549px !important;
        position: relative;

        .modal-button-close{
            position: absolute;
            top: 24px;
            right: 24px;

            &:hover{
                cursor: pointer;
            }
        }

        .form{
            .modal_header{
                font-weight: 700;
                font-size: 22px;
                line-height: 33px;
                margin-bottom: 24px;
                color: black;
            }

            .modal_message{
                font-weight: 400;
                font-size: 14px;
                line-height: 21px;
                margin-bottom: 24px;
                color: black;
            }

            .button--default{
                width: 100%;

                color: black;
                background-color: #fff;
                border: 1px solid black;
                border-radius: 10px;

                padding: 12px 31px;
                margin-bottom: 12px;

                &:hover{
                    background-color: #eeeeee;
                }
            }

            .button--primary{
                padding: 12px 31px;
                margin-bottom: 24px;
                border-radius: 10px;
            }

            .confirm_code_button{
                width: 100%;
            }

            .confirm_modal_button{
                float: inline-end;
            }

            .input_confirm_code{
                width: 100%;
                margin-bottom: 24px;

                font-weight: 400;
                font-size: 17px;
                line-height: 22px;
                color: #353B48;

                border: 1px solid #D6D6D6;
                border-radius: 10px;
                padding: 10px;
            }
        }
    } 
    
    .link_resend_code{
        font-weight: 400;
        font-size: 16px;
        line-height: 21px;
        color: #718093;
        float: inline-end;

        &:hover{
            cursor: pointer;
        }
    }
}
