


































































































.news_single_block{
    width: 80%;
}
