





































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.mw-25{
	.choices__inner{
		width: 35rem;

		.choices__list--single{
			.choices__item {
				max-width: 34rem;

				&:after{
					right: 17px !important;
				}
			}
		}
	}
}
.front_calculatorRoof_page{
	.cl-grid__left{
		max-width: 46rem !important;
	}
}
