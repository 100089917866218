





























































































.gallery_slider_section{
    .swiper-slide {
        width: auto !important;
        img{
            width: auto;
        }
    }
}
