.object-gallery {
	position: relative;

	&-wrapper {
		position: relative; }

	&-thumbs {
		width: 13.56%;
		height: 100%;
		overflow: hidden;
		position: absolute;
		top: 0;
		left: 0; }

	&-slider {
		flex: 1;
		margin-right: -62px;
		margin-left: 13.56%;
		padding-left: 8px; }

	&-description {
		position: absolute;
		bottom: 0;
		right: 0;
		background: #fff;
		z-index: 10;
		padding: 64px 47px;
		max-width: 456px; }

	@media (max-width: 1300px) {
		&-slider {
			margin-right: 0; }

		&-description {
			position: static;
			max-width: 100%;
			padding: 40px 0 0 0; } }

	@media (max-width: $mml) {
		&-wrapper {
			margin-left: -15px;
			margin-right: -15px; }

		&-thumbs {
			visibility: hidden;
			width: 1px;
			height: 1px;
			overflow: hidden;
			opacity: 0; }

		&-slider {
			margin-left: 0;
			padding-left: 15px;
			padding-right: 15px; }

		&-description {
			padding-top: 24px;

			h4 {
				display: none; }

			b,
			strong {
				display: block;
				font-size: 1.2rem;
				color: $color-6;
				margin-bottom: 16px;
				font-weight: normal; } } } }

.slider--object-gallery-thumbs {
	height: 100%;

	.swiper-slide {
		cursor: pointer;
		overflow: hidden; } }

.slider--object-gallery {
	height: 100%;

	.swiper-pagination {
		display: none; }

	@media (max-width: $mml) {
		overflow: visible;

		.swiper-slide {
			padding-left: 5px;
			padding-right: 5px;
 }			//width: calc(100vw - 52px)

		.swiper-pagination {
			display: block;
			position: absolute;
			bottom: 100%;
			right: 0;
			font-size: 1.2rem;
			color: $color-6;
			text-align: right;
			margin-bottom: 32px; } } }
