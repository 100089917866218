














































.offer_modal{
    .block_buttons{
        display: flex;
        justify-content: space-around;
    }
}
