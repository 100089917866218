























































































































































































































































































































































































































.progress_block_ac{
    .progress_list{
        justify-content: space-between !important;
        gap: unset !important;

        .progress_card{
            width: 23% !important;
            padding: 40px 40px !important;

            .progress_card_header{
                font-size: 2.3rem !important;
                line-height: 2.8rem !important;
            }

            .progress_card_description{
                font-size: 1.3rem !important;
            }
        }
    }
}
