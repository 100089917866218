.counter {
	display: flex;
	align-items: center;
	width: 161px;

	&-minus,
	&-plus {
		@include width(48px);
		height: 48px;
		background: $color-4;
		text-align: center;
		padding: 0;
		margin: 0;
		border: 0;
		font-size: 3rem; }

	input {
		height: 48px;
		padding: 0;
		border: 0;
		background: transparent;
		text-align: center;
		font-size: 4.4rem;
		@include width(65px);
		font-weight: bold;
		line-height: 48px; } }
