.swiper-container {
	box-sizing: inherit;

	*,
	*::before,
	*::after {
		box-sizing: inherit;
		outline: none; }

	img {
		width: 100%; } }

.swiper-button-prev,
.swiper-button-next {
	&.swiper-button-disabled {
 } }		//opacity: .5 !important
