.nd_auth_input{
    background: #F5F6FA;
    box-shadow: 0px 4px 8px 3px #00000014 inset;
    padding: 14px 16px 14px 16px;
    gap: 4px;
    border-radius: 5px!important;

    border: unset !important;
    font-size: 17px;
    line-height: 21px;
    font-weight: 600;
}

.error_input{
    border: 1px solid #ED1C24 !important;
}

.nd_auth_input[type=password]:not(:placeholder-shown){
    font: small-caption;
    font-size: 20px;
    line-height: 21px;
}

.button_nd_default{
    background-color: #B6B6B0;
    font-size: 1.5rem;
    line-height: 1.8rem;
    font-weight: 700;

    padding: 5px 43px 5px 43px;
    gap: 10px;
    border: unset !important;
    border-radius: 5px;

    width: 47.5%;
}

.button_nd_classic{
    background-color: white;
    font-size: 1.5rem;
    line-height: 1.8rem;
    font-weight: 700;

    padding: 5px 43px 5px 43px;
    gap: 10px;
    border: 1px solid black !important;
    border-radius: 5px;

    width: 47.5%;
}

.button_nd_success{
    background-color: white;
    color: #2CBA76;
    font-size: 1.5rem;
    line-height: 1.8rem;
    font-weight: 700;
    padding: 5px 16px 5px 16px;
    gap: 10px;
    border-radius: 5px;

    border: 1px solid #2CBA76 !important;
    width: 47.5%;
}

.activate_button{
    width: 100%;
    padding: 15px 27px;
}

.nd_button{
    font-size: 1.8rem;
    line-height: 2.2rem;
    font-weight: 700;
}

.nd_icon_check{
    width: 24px;
    margin-top: -3px;
}

.nd-br{
    border-radius: 5px !important;
}

.nd_auth{
    @media (min-width: 1600px) and (max-width: 1699px) {
        .container-nd {
            padding-left: 6.25rem !important;
            padding-right: 22.25rem !important;
        }
    }

    @media (min-width: 1700px) and (max-width: 1900px) {
        .container-nd {
            padding-left: 24.25rem !important;
            padding-right: 28.25rem !important;
        }
    }

    @media (min-width: 1900px) {
        .container-nd {
            padding-left: 32.95rem !important;
            padding-right: 36.95rem !important;
        }
    }

    .mb-16{
        margin-bottom: 1.6rem !important;
    }

    .mb-24{
        margin-bottom: 2.4rem !important;
    }

    .mb-20{
        margin-bottom: 2rem !important;
    }

    .pb-24{
        padding-bottom: 2.4rem !important;
    }

    .pb-0{
        padding-bottom: 0 !important;
    }

    .auth_left_block{
        box-shadow: 2px 1px 8px 2px #0000001F;

        margin-top: 10rem;
        padding: 2rem;
        padding-bottom: 0;

        .form{
            padding-bottom: 2.4rem;
        }

        .heading{
            margin-bottom: 2.4rem;

            .heading-section{
                p{
                    font-size: 2rem;
                    font-weight: 700;
                    line-height: 2.5rem;
                }
            }
        }

        .subheading{
            margin-bottom: 2.4rem;

            .second_step_info{
                font-size: 1.6rem;
                font-weight: 400;
                line-height: 1.9rem;

                margin-bottom: 0;
            }

            .second_step_value{
                font-size: 1.7rem;
                font-weight: 600;
                line-height: 2.1rem;
            }
        }   

        .button{
            width: 100%;
        }

        .form-row{
            width: 106%;
        }

        .auth_sended_message{
            font-size: 1.6rem;
            line-height: 1.9rem;
            font-weight: 400;

            margin-top: 12px;
        }

        .forget_password_link{
            font-size: 1.4rem;
            line-height: 1.7rem;
            font-weight: 500;
            color: #333333B2;

            margin-top: 16px;
            text-align: right;
            cursor: pointer;
        }
    }

    .auth_right_block{
        .title{
            color: #ED1C24;
            font-size: 2rem;
            line-height: 2.5rem;
            font-weight: 700;
            text-align: center;
            margin-bottom: 12px;

            cursor: pointer;
        }

        .subtitle{
            color: #333333;
            font-size: 1.4rem;
            line-height: 1.7rem;
            font-weight: 600;
        }
    }

    .registry_phone_field{
        display: flex;
        justify-content: space-between;

        .field-input{
            width: 47.5%;
        }
    }

    .auth_icon_back{
        width: 16px;
        margin-bottom: 30px;
        cursor: pointer;
    }

    .checkbox_nd{
        position: absolute;
        z-index: 1;

        .check_false{
            padding: 3px;
        }

        img{
            width: 2.4rem;
            cursor: pointer;
        }
    }

    .modal-container{
        padding: 0 !important;
        border-radius: 5px;

        .auth_left_block{
            margin-top: 0;
        }
    }

    .phone_message_error{
        font-size: 1.2rem;
        line-height: 1.2rem;
        font-weight: 400;
        color: #ED1C24;

        margin-top: 12px;
    }

    .phone_message_link{
        font-size: 1.2rem;
        line-height: 1.2rem;
        font-weight: 400;

        margin-top: 12px;
        margin-bottom: 5px;

        &:hover{
            cursor: pointer;
            text-decoration: underline;
        }
    }
}