.index__admin{
        display: grid;
        position: relative;
        margin-top: 5px;
        margin-bottom: 5px;
        
        input {
            margin-top: 5px;
            margin-bottom: 5px;
            margin-right: 5px;
            width: 100%;
            padding: 17px;
            font-size: 1.4rem;
            color: #353b48;
            border: 1px solid #718093;
            background: transparent;
            font-weight: 700;
            border-radius: 0;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
        }
        span{
            font-weight: bolder;
            margin-top: 10px;
        }
        p{
            margin-left: 20px;
            padding-top: 10px;
            font-size: 11px;
        }
        button{
            width: 200px;
            margin-top: 5px;
            margin-bottom: 5px;
            background: #ed1c24;
            color: #fff;
            display: inline-block;
            text-decoration: none;
            padding: 15px 27px;
            border: 0;
            border-radius: 0;
            text-align: center;
            transition: color .25s,background-color .25s,opacity .25s,border-color .25s;
            font-size: 1.8rem;
            font-weight: 700;
            margin-right: 2px;
        }
        select{
            border-radius: 5px;
            height: 35px;
            background-color: white;
            margin-top: 5px;
            border: 1px solid #ccc;
        }
        .picker{
            margin-right: 5px;
        }
        label{
            font-size: 14px;
            font-weight: bolder;
        }

        .save_btn{
            z-index: 999 !important;
        }
        
    }
.index__del{
    right: 0;
    position: absolute;
    color: red;
    cursor: pointer;
    z-index: 300;
    &:hover{
        color: #ff4a4a;
    }
}

label.fl__img {
    display: block;
    cursor: pointer;
}

label.fl__img::before {
    display: inline-block;
    padding-right: 5px;
    vertical-align: top;
    content: 'Загрузить изображение';
    margin-bottom: 5px;
   font-weight: bolder;
   background: #ed1c24;
    color: #fff;
    display: inline-block;
    text-decoration: none;
    padding: 15px 27px;
    border: 0;
    border-radius: 0;
    text-align: center;
    transition: color .25s,background-color .25s,opacity .25s,border-color .25s;
    font-size: 1.8rem;
    font-weight: 700;
}

label [type=file] {
    display: none;
}

label.fl__doc {
    display: block;
    cursor: pointer;
}

label.fl__doc::before {
    display: inline-block;
    padding-right: 5px;
    vertical-align: top;
    content: 'Загрузить документ';
    padding: 0.7em 1.5em;
    margin-bottom: 5px;
    background-color:  #e2e2e2;
   font-weight: bolder;
   background: #ed1c24;
    color: #fff;
    display: inline-block;
    text-decoration: none;
    padding: 15px 27px;
    border: 0;
    border-radius: 0;
    text-align: center;
    transition: color .25s,background-color .25s,opacity .25s,border-color .25s;
    font-size: 1.8rem;
    font-weight: 700;
}

label.fl__preview::before {
    content: 'Загрузить превью';
}

label.fl__imgs {
    display: block;
    cursor: pointer;
}

label.fl__imgs::before {
    display: inline-block;
    padding-right: 5px;
    vertical-align: top;
    content: 'Загрузить дополнительные изображения';
    margin-top: 5px;
    margin-bottom: 5px;
   font-weight: bolder;
   background: #ed1c24;
    color: #fff;
    display: inline-block;
    text-decoration: none;
    padding: 15px 27px;
    border: 0;
    border-radius: 0;
    text-align: center;
    transition: color .25s,background-color .25s,opacity .25s,border-color .25s;
    font-size: 1.8rem;
    font-weight: 700;
}

.index_admin-imgs{
    width:200px; 
    height:70px; 
    float:left; 
    margin: 0 15px 15px 0; 
    padding: 10px;
}