













































































.client{
    display: grid;
    margin-top: 30px;
    margin-right: 30px;
    border: 1px solid grey;
    border-radius: 15px;
    padding: 10px;
    max-width: 45%;

    h5{
        margin-bottom: 10px;
    }

    img{
        width: 245px;
        height: 245px;
    }

    .upload-input{
        margin-bottom: 10px;
    }

    .client-delete-button{
        width: 100%;
    }

    .label_fileupload{
        margin-left: 0;
    }
}
