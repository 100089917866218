.coatings-page{
    .banner{
        position: relative;

        img{
            width: 100%;
        }

        .breadcrumbs{
            position: absolute;
            top: 24px;
            width: 100%;
        }
    }

    .coatings-card-block{
        margin-top: 71px;
        margin-bottom: 100px;

        .block-header{
            font-weight: 400;
            font-size: 3.1rem;
            line-height: 3.8rem;

            margin-bottom: 72px;
        }

        .coating-cards{
            display: flex;
            flex-wrap: wrap;

            .c-card{
                display: flex;
                width: 30%;
                margin-bottom: 75px;
                margin-right: 38px;
                
                cursor: pointer;

                .left_part{
                    width: 40%;
                    position: relative;

                    @media (max-width: 1599px) {
                        .main_img{
                            height: 90%;
                        }
                    }

                    @media (min-width: 1600px) and (max-width: 1699px) {
                        .main_img{
                            height: 100%;
                        }
                    }

                    @media (min-width: 1700px) {
                        .main_img{
                            height: 90%;
                        }
                    }


                    .main_img{
                        z-index: 2;
                        position: relative;

                        border-radius: 9px;
                    }

                    .border_img{
                        position: absolute;
                        top: 20px;
                        left: 20px;
                    }
                }
                .right_part{
                    width: 60%;
                    padding-left: 40px;
                    position: relative;

                    .header{
                        font-weight: 700;
                        font-size: 16px;
                        line-height: 21px;

                        margin-top: 19px;
                        margin-bottom: 20px;
                        min-height: 42px;
                    }
                    .description_text{
                        font-weight: 400;
                        font-size: 13px;
                        line-height: 20px;

                        max-height: 80px;
                        overflow: hidden;
                    }

                    .line_img{
                        position: absolute;
                        top: 68px;
                        left: 17px;

                        width: 90%;
                    }
                }
            }
        }
    }

    .table_char_block{
        margin-bottom: 100px;
        .custom_table{
            .headers{
                display: flex;
                flex-wrap: nowrap;

                border-bottom: 2px solid #B9B9B9;

                div:first-child{
                    width: 14%;
                }

                div{
                    font-weight: 600;
                    font-size: 1.2rem;
                    line-height: 15px;

                    width: 11%;
                    text-align: center;
                    padding: 10px 4px;
                }

                div:not(:last-child){
                    border-right: 2px solid #B9B9B9;
                }
            }

            .bg_grey{
                background-color: #f7f7f7;
            }

            .rows{
                display: flex;
                flex-wrap: nowrap;

                div:first-child{
                    width: 14%;
                }

                div{
                    font-weight: 400;
                    font-size: 1.07rem;
                    line-height: 15px;

                    width: 11%;
                    padding: 10px 4px;
                }

                div:not(:first-child){
                    text-align: center;
                }

                div:not(:last-child){
                    border-right: 2px solid #B9B9B9;
                }
            }

            .rows:not(:last-child){
                border-bottom: 2px solid #B9B9B9;
            }

            .eclipse_block{
                display: flex;
                justify-content: center;
                width: 100% !important;

                img{
                    margin-right: 3px;
                    height: 8px;
                    width: 8px;
                }
            }
        }
    }

    .coating-single-page{
        .gallery_slider{
            margin-bottom: 354px;
        }

        .breadcrumbs{
            .container{
                padding-left: 0 !important;
            }
        }

        .page{
            display: flex;
            flex-wrap: nowrap;

            .navigation_block{
                width: 20%;
                margin-right: 69px;

                .navigation_header{
                    margin-top: 56px;
                    font-weight: 400;
                    font-size: 22px;
                    line-height: 27px;
                }
                .navigation_list{
                    margin-top: 58px;
                    margin-left: 0;
                    list-style-type: none;

                    li{
                        font-weight: 700;
                        font-size: 23px;
                        line-height: 34px;
                        margin-bottom: 20px;

                        &:hover{
                            cursor: pointer;
                            color: #ED1C24;
                        }
                    }

                    .active{
                        color: #ED1C24;
                    }
                }
            }

            .main_block{
                width: 80%;
                margin-top: 147px;

                .main_description{
                    display: flex;
                    flex-wrap: nowrap;

                    margin-bottom: 60px;

                    .left_part{
                        width: 55%;

                        margin-right: 60px;
                    }
                    .right_part{
                        width: 45%;

                        .coating_header{
                            font-weight: 700;
                            font-size: 28px;
                            line-height: 35px;

                            margin-bottom: 20px;
                        }
                        .coating_description{
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 23px;
                        }
                    }
                }

                .colors_block_row{
                    margin-bottom: 100px;

                    .color_header{
                        font-weight: 700;
                        font-size: 28px;
                        line-height: 35px;

                        margin-bottom: 20px;
                    }
                    
                    .colors_block{
                        display: flex;
                        flex-wrap: wrap;
    
                        .color_block{
                            width: 30%;
                            display: flex;
                            flex-wrap: nowrap;
                            margin-bottom: 40px;
                            margin-right: 25px;
                            height: 70px;
    
                            .left_part{
                                width: 70px;
                                margin-right: 10px;
                                position: relative;
    
                                .color_vector{
                                    position: absolute;
                                    bottom: 0;
                                }
                            }
    
    
                            .color_name{
                                font-weight: 600;
                                font-size: 17px;
                                line-height: 22px;
    
                                margin-bottom: 10px;
                            }
    
                            .color_subname{
                                font-weight: 400;
                                font-size: 13px;
                                line-height: 18px;
                            }
                        }
                    }
                }


                .char_block{
                    width: 800px;
                    margin-bottom: 200px;

                    .char_header{
                        font-weight: 700;
                        font-size: 28px;
                        line-height: 35px;

                        margin-bottom: 20px;
                    }

                    .char{
                        display: flex;
                        flex-wrap: nowrap;
                        justify-content: space-between;

                        padding-top: 10.5px;
                        padding-bottom: 10.5px;

                        margin-bottom: 20px;
                        border-bottom: 1px solid #ededed;

                        .reddot{
                            width: 8px;
                            height: 8px;

                            margin-top: 8px;
                            margin-right: 8px;
                        }

                        .eclipse_block{
                            img{
                                margin-right: 3px;
                            }
                        }

                        .left_part{
                            display: flex;
                            flex-wrap: nowrap;
                        }

                        .char_name{
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 24px;
                        }

                        .char_value{
                            font-weight: 700;
                            font-size: 17px;
                            line-height: 22px;
                        }
                    }
                }
            }
        }
    }
}

.admin-coatings-page{
    display: block;

    .admin-index_header{
        margin-bottom: 25px;
    }

    .buttons-block{
        display: grid;
        grid-template-columns: 1fr 0.2fr 0.2fr 0.2fr;
        margin-bottom: 25px;

        .button{
            padding: 11px;
            font-size: 15px;
            margin-right: 20px;
        }
    }

    .main_block{
        .single-coating-block{
            .color_list{
                display: grid;
                grid-template-columns: 1fr 1fr 1fr 1fr 1fr;

                margin-top: 20px;
                margin-bottom: 20px;

                .single_color{
                    text-align: center;
                    display: flex;
                    flex-wrap: wrap;
                    width: 100%;
                    justify-content: center;

                    p{
                        padding-top: 0;
                        margin-left: 0;
                        margin-bottom: 5px;
                        font-size: 13px;
                        font-weight: 400;
                        width: 100%;
                    }

                    .checkbox-plus{
                        margin-right: 20px;

                        .check_false{
                            padding: 3px;
                        }
                    }

                    .background_color_block{
                        height: 20px;
                        width: 100px;
                    }
                }
            }

            .form_new_char{
                display: flex;
                margin-top: 20px;
                margin-bottom: 20px;

                .button{
                    margin-left: 20px;
                    padding: 6px;
                    font-size: 15px;
                }
            }

            .delete_button{
                margin-left: 20px;
                padding: 11px;
                font-size: 15px;
                margin-left: 0;
                margin-top: 20px;
                margin-bottom: 50px;
            }

            .char_list{
                margin-bottom: 20px;
                
                .single_char{
                    display: flex;
                    flex-wrap: nowrap;
                    width: 100%;

                    input{
                        padding: 10px;
                        font-size: 13px;
                        height: 40px;
                        width: 25%;

                        margin-right: 20px;
                    }

                    .button{
                        padding: 6px;
                        font-size: 15px;
                        height: 40px;
                    }
                }
            }

            .region_list{
                display: flex;
                flex-wrap: wrap;

                .single_region{
                    display: flex;
                    justify-content: space-between;

                    border: 1px solid black;
                    border-radius: 5px;
                    align-items: center;
                    padding: 15px;

                    margin-right: 20px;

                    p{
                        margin-bottom: 0;
                        margin-left: 0;
                        padding-top: 0;
                        margin-right: 5px;
                    }

                    i{
                        color: red;
                        cursor: pointer;
                    }
                }
            }

            .coating_main_img{
                height: 200px;

                margin-bottom: 20px;
                margin-right: 20px;
            }

            .list_imgs{
                display: flex;
                flex-wrap: wrap;

                .single_img{
                    position: relative;

                    i{
                        color: red;
                        cursor: pointer;

                        position: absolute;
                        top: 5px;
                        right: 25px;
                    }
                }
            }
        }

        input{
            padding: 10px;
            font-size: 13px;
            height: 40px;

            margin-bottom: 20px;
        }

        .fl__img{
            &:before{
                padding: 11px;
                font-size: 15px;

                margin-bottom: 20px;
            }
        }
    }
}

.gallery_slider_section{
    position: relative;

    .swiper-button-prev {
        position: absolute;
        z-index: 1;
        opacity: .5;
        transition: opacity .15s ease;
        width: 3%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        margin: 0;
        height: auto;
        transform: rotate(0deg);
        background: #fff;
        opacity: .85;
        left: 46px;
        top: 0;
        height: 100%;
    }

    .swiper-button-next {
        position: absolute;
        z-index: 1;
        opacity: .5;
        transition: opacity .15s ease;
        width: 3%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        margin: 0;
        height: auto;
        transform: rotate(0deg);
        background: #fff;
        opacity: .85;
        right: 0;
        top: 0;
        height: 100%;
    }
}

.admin-coating-colors-page{
    display: block;

    .admin-index_header{
        margin-bottom: 25px;
    }

    .main_block{
        .button_block{
            display: grid;
            grid-template-columns: 1fr 0.2fr;

            margin-bottom: 25px;

            .button{
                padding: 11px;
                font-size: 15px;
            }
        }

        .nlk-table{
            tr{
                td{
                    text-align: center;

                    i{
                        &:hover{
                            cursor: pointer;
                            color: #FC4A51;
                        }
                    }

                    input{
                        padding: 10px;
                        font-size: 12px;
                        width: 50%;
                    }

                    p{
                        margin-left: 0;
                    }
                }
            }

            .color-td{
                display: flex;
                justify-content: center;
                align-content: center;
                flex-wrap: wrap;
            }
        }

        .color_code_block{
            width: 100px;
            height: 20px;
        }
    }
}

.color-create-modal{
    .form{
        input{
            padding: 10px;
            font-size: 12px;
            margin-bottom: 20px;
        }

        .button{
            padding: 11px;
            font-size: 15px;
        }

        .fl__img{
            &:before{
                padding: 11px;
                font-size: 15px;
            }
        }
    }
}

.admin-coating-chars-page{
    display: block;

    .admin-index_header{
        margin-bottom: 25px;
    }

    .main_block{
        .button_block{
            display: grid;
            grid-template-columns: 1fr 0.2fr;

            margin-bottom: 25px;

            .button{
                padding: 11px;
                font-size: 15px;
            }
        }

        .nlk-table{
            tr{
                td{
                    text-align: center;

                    i{
                        &:hover{
                            cursor: pointer;
                            color: #FC4A51;
                        }
                    }

                    input{
                        padding: 10px;
                        font-size: 12px;
                        width: 50%;
                    }

                    p{
                        margin-left: 0;
                    }
                }
            }
        }
    }
}