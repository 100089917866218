//.rub
//	font-family: $ff_rouble

//.fckjustify
//	[style*='justify']
//		text-align: left !important

.text-strike {
	position: relative;
	z-index: 1;
	overflow: hidden;
	text-align: center;

	&::before,
	&::after {
		content: '';
		display: inline-block;
		width: 100%;
		height: 1px;
		background: #000000;
		z-index: -1;
		position: relative;
		vertical-align: middle; }

	&::before {
		right: 20px;
		margin: 0 0 0 -100%; }

	&::after {
		left: 20px;
		margin: 0 -100% 0 0; } }

.text-overflow {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis; }

.text-left {
	text-align: left; }

.text-center {
	text-align: center; }

.text-right {
	text-align: right; }
