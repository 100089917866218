
































































































































































































.single_utm_tr{
    td{
        padding: 0.7rem;
        border: 1px solid #F4F3F8;
        height: 45px;
    }
}
