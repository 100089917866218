




















































































.swiper-slide{
  .item--brands{
    width: 100%;
    filter: grayscale(100%); 

    img{
      width: 75%;
    }

    &:hover{
      filter: none;
    }
  }
}
