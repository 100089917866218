// .section--costs
// 	overflow: hidden

// .slider--costs
// 	overflow: visible

.layout-costs {
	@include grid(3, 16px, 16px);

	@media (max-width: $mtm) {
		@include grid(1, 16px, 16px); }

	@media (max-width: $mmm) {
		@include list-scroll(); } }

.cost {
	padding: 35px 38px 45px 38px;
	background: #fff;
	box-shadow: 0 3px 45px rgba(#AAAAAA, .16);
	border-radius: 4px;
	overflow: hidden;
	position: relative;

	&-media {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		background-position: 100% 100%;
		background-repeat: no-repeat; }

	&-body {
		position: relative;
		z-index: 1; }

	&-title {
		font-size: 3.2rem;
		font-weight: bold; }

	&-description {
		font-size: 1.8rem;
		font-weight: bold; }

	&-button {
		margin-top: 45px; }

	@media (max-width: $mtl) {
		padding-left: 16px; } }
