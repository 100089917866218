.search {
	width: 100%;
	max-width: 84.2%;
	transition: box-shadow .25s ease;
	margin: 0 auto;
	position: relative;

	&-field {
		position: relative;
		width: 100%;
		z-index: 1; }

	&-input {
		width: 100%;
		height: 48px;
		padding: 17px 0 17px 23px;
		background: $color-4;
		color: $color-7;
		font-size: 1.2rem;
		margin: 0;
		border: 0;
		border-radius: 4px;
		font-weight: bold;

		&::-webkit-input-placeholder {
			font-weight: normal;
			color: $color-6;
			opacity: 1; }

		&:-moz-placeholder {
			font-weight: normal;
			color: $color-6;
			opacity: 1; }

		&::-moz-placeholder {
			font-weight: normal;
			color: $color-6;
			opacity: 1; }

		&:-ms-input-placeholder {
			font-weight: normal;
			color: $color-6;
			opacity: 1; } }

	&-submit {
		position: absolute;
		width: 27px;
		height: 27px;
		background: url('../img/nd-icons/loupe.png') 0 0 / 100% 100% no-repeat;
		border: 0;
		padding: 0;
		margin: 0;
		top: 45%;
		margin-top: -9px;
		right: 26px;

		&-label {
			display: block;
			opacity: 0;
			position: absolute;
			right: 100%;
			margin-right: 25px;
			font-size: 1.2rem;
			line-height: 18px;
			top: 0;
			color: $color-6;
			padding-left: 25px;
			border-left: 1px solid $color-5;
			transition: opacity .25s ease;
			pointer-events: none; } }

	&-dropdown {
		position: absolute;
		top: -8px;
		right: -8px;
		bottom: -8px;
		left: -8px;
		padding: 8px;
		border-radius: 4px; }

	&-results {
		display: none;
		margin-top: 56px;
		max-height: 570px;
		overflow: auto;
		-webkit-overflow-scrolling: touch;
		@include scroll(); }

	&-result + &-result {
		@include lt(); }

	&.search--focus {
		.search-dropdown {
			box-shadow: 0 3px 22px rgba(#000, .16);
			background: #fff; }

		.search-submit-label {
			opacity: 1; }

		&.search--results {
			.search-dropdown {
				bottom: auto;
				padding-bottom: 0; }

			.search-results {
				display: block; } } }

	@media (max-width: $mdxs) {
		&-results {
			max-height: 360px; } } }

.search-result {
	display: flex;
	align-items: center;
	padding: 35px 28px 35px 17px;
	text-align: left;

	&-image {
		@include width(116px);
		margin-right: 16px; }

	&-title {
		flex: 1;
		font-size: 1.8rem;
		font-weight: bold;
		color: $color-7;

		&:hover {
			color: $color-2; } }

	&-price {
		@include width(150px);

		&-label {
			font-size: 1.2rem; }

		&-value {
			font-size: 1.8rem;
			font-weight: bold;
			color: $color-7; } }

	&-button {
		flex: 0 0 70px;

		.button {
			width: 100%;

			svg {
				margin-right: 6px; } } }

	@media (max-width: $mds) {
		&-image {
			@include width(90px); }

		&-title {
			font-size: 1.4rem; } }

	@media (max-width: $mdxs) {
		padding: 35px 16px;

		&-image {
			@include width(50px); }

		&-title {
			font-size: 1.2rem;
			padding-right: 10px; }

		&-price {
			flex: 1;
			max-width: none;
			width: auto;
			&-value {
				font-size: 1.2rem; } } } }


// mobile

.layout-search-results-mobile {
	.list-item + .list-item {
		@include lt();
		paddin-top: 15px;
		margin-top: 15px; } }

.search-result-mobile {
	color: $color-7;

	&-row {
		@include flex();
		align-items: center;
		justify-content: space-between; }

	&-row + &-row {
		margin-top: 16px; }

	&-image {
		@include width(40%); }

	&-title {
		flex: 1; }

	&-title {
		padding-left: 14px; }

	&-price {
		&-label {
			font-size: 1.2rem;
			margin-bottom: 5px; }

		&-value {
			font-size: 1.8rem;
			font-weight: bold; } }

	&-button {
		@include width(71px);

		.button {
			width: 100%;
			padding-top: 11px;
			padding-bottom: 12px;

			svg {
				margin: 0; } } } }
