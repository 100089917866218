.layout {
	@include flex();

	&-aside {
		position: relative;
		z-index: 3; }

	&-section {
		flex: 1; }

	&-group + &-group {
		margin-top: 32px; }

	&-content {
		//margin-left: -48px
		padding-left: calc(20% - 4px); }

	&.layout--catalog {
		.layout-aside {
			@include width(calc(25% - 12px)); }

		.layout-section {
			padding-left: 16px; } }

	&.layout--page {
		.layout-aside {
			@include width(calc(25% - 12px));
			@include inner-left(); }

		.layout-section {
			padding-left: 16px; } }

	&.layout--page-wide {
		.layout-aside {
			@include width(40%); }

		.layout-section {
			padding-left: 0; } }

	&.layout--section-gap {
		.layout-section {
			padding-left: 31px; } }

	&.layout--cabinet {
		.layout-aside {
			@include inner-left();
			@include width(calc(25% - 12px)); }

		.layout-section {
			padding-left: 21px;

			.inner {
				padding-left: 27%;
				margin-left: calc(-6.5% - 12px); } } }

	&.layout--checkout {
		.layout-aside {
			@include width(20%);
			@include inner-left(); }

		.layout-bside {
			@include width(20%);
			order: 3; }

		.layout-section {
			padding-right: 19px; } }

	@media (max-width: $mds) {
		&-content {
			padding-left: 16px; }

		&.layout--cabinet {
			.layout-section {
				@include inner-left();

				.inner {
					padding-left: 0;
					margin-left: 0; } } } }

	@media (max-width: $mdxs) {
		&.layout--page {
			display: block;

			.layout-aside {
				@include width(100%);
				padding-left: 0;
				margin-bottom: 32px; } }

		&.layout--page-wide {
			display: block;

			.layout-aside {
				@include width(100%);
				padding-left: 0;
				margin-bottom: 32px; } }

		&.layout--section-gap {
			.layout-section {
				padding-left: 16px; } }

		&.layout--checkout {
			.layout-aside {
				@include width(100%);
				padding-left: 0;
				margin-bottom: 32px; }

			.layout-bside {
				@include width(30%); }

			.layout-section {
				padding-right: 19px;
				padding-left: 16px; } }

		&.layout--cabinet {
			.layout-aside {
				@include width(30%); }

			.layout-section {
				padding-left: 20px; } } }

	@media (max-width: $mtm) {
		&-content {
			padding-left: 0; }

		&.layout--page {
			.layout-section {
				padding-left: 0; } }

		&.layout--catalog {
			display: block;

			.layout-aside {
				@include width(100%);

				.layout-group:not(:first-child) {
					display: none; } }

			.layout-section {
				padding-left: 0; } }

		&.layout--checkout {
			.layout-section {
				padding-left: 0; } }

		&.layout--cabinet {
			display: block;

			.layout-aside {
				@include width(100%); }

			.layout-section {
				padding-left: 0; } } }

	@media (max-width: $mml) {
		&.layout--checkout {
			display: block;

			.layout-section {
				padding-right: 0; }

			// .layout-bside
 } } }			// 	display: none

.layout {
	.section {
		padding: 96px 0;

		.inner {
			padding-left: 48px;
			padding-right: 0;

			.inner-wider {
				margin-left: -48px;
				margin-right: 0; } } }

	.table:not(.table--good-properties) {
		margin-left: -48px;

		.table-cell:first-child {
			padding-left: 48px;
			@include width(calc(46% + 4px)); }

		&.table--good-text-images {
			.table-cell:first-child {
				@include width(calc(24% + 4px)); } } }

	.heading {
		margin-right: 0;
		margin-left: -48px;

		&-section {
			padding-left: 48px; } }

	.slider--advantages {
		margin-left: -48px;
		padding-left: calc(20% + 8px); }

	.list-wrapper {
		margin-left: -48px;
		padding-left: calc(20% + 8px); }

	.download {
		&-section {
			margin-left: -13%; } }

	.row--good-info > .col:nth-child(1) {
		padding-right: 16px;
		@include width(52%); }

	@media (max-width: $mds) {
		.section {
			.inner {
				padding-left: 16px;

				.inner-wider {
					margin-left: -16px; } } }

		.table:not(.table--good-properties) {
			margin-left: -16px;

			.table-cell:first-child {
				padding-left: 16px; } }

		.heading {
			margin-left: -16px;

			&-section {
				padding-left: 16px; } }

		.list-offset {
			&.-col-1 {
				padding-left: 16px; } } }

	.row--good-info {
		display: block;

		> .col:nth-child(1) {
			padding-right: 0;
			@include width(100%);
			margin-bottom: 32px; } }

	@media (max-width: $mtm) {
		.section {
			.inner {
				padding-left: 0;

				.inner-wider {
					margin-left: 0; } } }

		.list-offset {
			&.-col-1 {
				padding-left: 0; } } }

	@media (max-width: $mts) {
		.list-offset {
			&.-col-1 {
				padding-left: 0; } } }

	@media (max-width: $mml) {
		.table:not(.table--good-properties) {
			&.table--good-text-images {
				.table-cell:first-child {
					@include width(100%); } } } } }
