.section--videos {
	overflow: hidden; }

.slider--videos {
	overflow: visible;

	@media (max-width: $mmm) {
		.swiper-slide {
			width: calc(100vw - 56px); } } }

.layout-videos {
	.list-item + .list-item {
		margin-top: 31px; } }

.video {
	display: block;
	background: #fff;
	box-shadow: 0 3px 45px rgba(#AAAAAA, .16);
	border-radius: 4px;
	overflow: hidden;

	&-preview {
		position: relative;
		padding-bottom: 64.253%;
		background-position: center center;
		background-repeat: no-repeat;
		background-size: cover; }

	&-body {
		padding: 23px 39px 37px 39px; }

	&-button {
		position: absolute;
		left: 0;
		bottom: 0;
		color: #fff;
		background: url('../img/video-play.svg') 38px 50% / 24px 27px $color-2 no-repeat;
		width: 212px;
		line-height: 81px;
		padding-left: 81px; }

	&-date {
		font-size: 1.2rem;
		margin-bottom: 13px; }

	&-title {
		font-size: 1.8rem;
		font-weight: bold; }

	&.video--large {
		.video-preview {
			padding-bottom: 37.234%; }

		.video-button {
			font-size: 0;
			width: 54px;
			height: 62px;
			top: 50%;
			left: 50%;
			background: url('../img/video-play-large.svg') 0 0 / 100% 100% no-repeat;
			margin-top: -31px;
			margin-left: -27px; } }

	@media (max-width: $mds) {
		&-title {
			font-size: 1.6rem; }

		&-body {
			padding: 11px 19px 18px 19px; } }

	@media (max-width: $mmm) {
		&-title {
			font-weight: normal; }

		&-body {
			padding: 16px 24px; }

		&-button {
			font-size: 0;
			width: 54px;
			height: 62px;
			top: 50%;
			left: 50%;
			background: url('../img/video-play-large.svg') 0 0 / 100% 100% no-repeat;
			margin-top: -31px;
			margin-left: -27px; } } }
