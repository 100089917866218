





















































































.basket_not_empty_region_modal{
    .buttons_block{
        display: flex;
        gap: 20px;

        button{
            width: 100%;
        }
    }
}
