/* HERO
 * -------------------------------------------------- */

.hero {
	margin-top: -22px;

	> .container {
		> .inner {
			position: relative;
			width: 100%; } }

	&-message {
		width: calc(25% - 12px);
		position: absolute;
		right: 0;
		z-index: 2;
		top: 41px;

		.message-title,
		.message-description {
			margin-bottom: 16px; } }

	@media (max-width: $mds) {
		&-message {
			width: calc(33.33% - 12px); } }

	@media (max-width: $mtm) {
		&-message {
			display: none; } } }

.slider--hero {
	.swiper-slide {
		height: 543px;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center; }

	.slider-title {
		margin-top: 67px;
		font-size: 7.2rem;
		color: #fff;
		font-weight: bold;
		text-shadow: 0 3px 6px rgba(#000, .16);
		line-height: 1.227272; }

	.slider-controls {
		max-width: 1920px;
		padding-left: 130px;
		width: 100%;
		left: 50%;
		transform: translateX(-50%);
		position: absolute;
		bottom: 126px;
		z-index: 1;
		display: flex;
		align-items: center; }

	.swiper-button-prev,
	.swiper-button-next,
	.swiper-pagination {
		position: static;
		padding: 0;
		margin: 0;
		text-align: left;
		font-size: 0; }

	.swiper-button-prev,
	.swiper-button-next {
		width: 12px;
		height: 9px;
		background: url('../img/slider-arrow.svg') 0 0 / 100% 100% no-repeat; }

	.swiper-button-prev {
		margin-right: 12px; }

	.swiper-button-next {
		margin-left: 12px;
		transform: rotate(180deg); }

	.swiper-pagination {
		.swiper-pagination-bullet {
			width: 12px;
			height: 12px;
			border: 1px solid transparent;
			border-radius: 50%;
			margin: 0 4px;
			opacity: 1;
			background: transparent;
			position: relative;
			transition: border-color .25s ease;

			&::before {
				@include content();
				width: 6px;
				height: 6px;
				background: #fff;
				border-radius: 50%;
				top: 50%;
				left: 50%;
				margin-top: -3px;
				margin-left: -3px; }

			&.swiper-pagination-bullet-active {
				border-color: #fff; } } }

	@media (max-width: $mdm) {
		.swiper-slide {
			height: 409px; }

		.slider-title {
			margin-top: 105px;
			font-size: 4.4rem; }

		.slider-controls {
			max-width: 1440px;
			padding-left: 93px;
			bottom: 89px; } }

	@media (max-width: $mds) {
		.slider-title {
			margin-top: 55px;
			font-size: 3.8rem; } }

	@media (max-width: $mtm) {
		.slider-controls {
			padding-left: 16px; } }

	@media (max-width: $mml) {
		.swiper-slide {
			height: 522px; }

		.slider-title {
			font-size: 2.4rem; }

		.slider-controls {
			bottom: 188px; } } }

.section--hero-message {
	display: none;

	.button {
		width: auto; }

	@media (max-width: $mtm) {
		display: block; } }

/* CATALOG CARDS
 * -------------------------------------------------- */

.section--cards {
	margin-top: -55px;
	position: relative;
	z-index: 2;
	overflow: hidden;

	&.section--cards-tabs {
		margin-top: -135px; }

	@media (max-width: $mml) {
		margin-top: -162px; } }

.slider--cards {
	overflow: visible;

	@media (max-width: $mmm) {
		.swiper-slide {
			width: calc(100vw - 56px); } } }

/* ABOUT
 * -------------------------------------------------- */

.row--about {
	@include no-inner();

	.col:nth-child(1) {
		@include width(40%);
		@include inner-left(); }

	h2 {
		white-space: nowrap; }

	@media (max-width: $mds) {
		display: block;

		h2 {
			white-space: normal; }

		.col:nth-child(1) {
			@include width(100%);
			margin-bottom: 96px; } } }

/* BRANDS
 * -------------------------------------------------- */

.section--brands {
	overflow: hidden;
	padding-bottom: 0 !important;

	h2 {
		margin-bottom: 32px; } }

.slider--brands {
	overflow: visible;

	img {
		filter: grayscale(100%);
		transition: filter .25s ease;

		&:hover {
			filter: grayscale(0%); } } }

/* PRODUCTS
 * -------------------------------------------------- */

.section--products {
	overflow: hidden; }

.layout {
	.section--products {
		overflow: visible;
		//position: relative
		//margin-left: -16px
		//padding-left: 16px
		//margin-right: -100%

		//.slider-wrapper
		//	max-width: 983px

		//&::before
		//	+content()
		//	right: 100%
		//	margin-right: 16px
		//	width: 10000%
		//	top: 0
		//	bottom: 0
		//	background: #fff
 } }		//	z-index: 2

.slider--products,
.slider--product-products {
	overflow: visible;

	@media (max-width: $mmm) {
		.swiper-slide {
			width: calc(100vw - 56px); } } }


/* EVENTS
 * -------------------------------------------------- */

// .section--events

// .slider--events
// 	.swiper-slide
// 		width: 20%
// 		padding-right: 31px

// 		&.slide--double
// 			width: 40%

// 	@media (max-width: $mdxs)
// 		.swiper-slide
// 			width: 25%
// 			padding-right: 0
// 			padding-left: 16px

// 			&.slide--double
// 				width: 25%
