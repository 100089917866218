.-menu-catalog-opened {
	@media (max-width: $mtm) {
		position: fixed;
		overflow: hidden;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;

		.header {
			z-index: 0; }

		.menu {
			position: fixed;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			z-index: 100;
			background: #fff;

			.menu-toggle {
				background: $color-2;

				span {
					&:nth-child(1) {
						display: none; }

					&:nth-child(2) {
						display: block; } }

				&-button {
					background-color: $color-1;

					&::before {
						opacity: 0; }

					&::after {
						opacity: 1; } } }

			.menu-list {
				display: block;
				border-radius: 0;
				height: 100%;
				@include scroll();
				overflow: auto;
				padding-bottom: 46px; } } } }

.menu {
	&-toggle {
		display: none;
		position: fixed;
		bottom: 0;
		left: 0;
		right: 0;
		height: 46px;
		background: $color-6;
		color: #fff;
		z-index: 10;

		&-section {
			flex: 1;
			text-align: center;
			line-height: 46px;
			font-size: 1.6rem;

			span {
				margin-right: -46px;

				&:nth-child(1) {
					display: block; }

				&:nth-child(2) {
					display: none; } } }

		&-button {
			@include width(46px);
			height: 46px;
			position: relative;
			padding: 0;
			margin: 0;
			border: 0;
			background: transparent;

			&::before,
			&::after {
				@include content();
				left: 50%;
				top: 50%;
				transform: translate(-50%, -50%); }

			&::before {
				width: 18px;
				height: 11px;
				background: url('../img/navigation-toggle.svg') 0 0 /  100% 100% no-repeat;
				opacity: 1; }

			&::after {
				width: 14px;
				height: 14px;
				background: url('../img/navigation-close.svg') 0 0 /  100% 100% no-repeat;
				opacity: 0; } } }

	&-title {
		padding-left: 31px;
		margin-bottom: 24px;
		font-size: 1.8rem;
		font-weight: bold; }

	&-list {
		background: #fff;
		border-radius: 4px;
		box-shadow: 0 3px 45px rgba(#AAAAAA, .16);
		overflow: hidden;

		> .list-item {
			> .list-link {
				padding: 22px 31px;
				font-weight: bold;
				font-size: 1.6rem;
				background: $color-4;
				position: relative;
				padding-right: 68px;

				.list-button {
					position: absolute;
					top: 0;
					right: 0;
					bottom: 0;
					width: 68px;
					border: 0;
					padding: 0;
					margin: 0;
					background: url('../img/menu-arrow.svg') 50% 50% no-repeat;
					transform: rotate(180deg); } }

			&.-opened {
				> .list-link {
					.list-button {
						transform: rotate(0); } }

				> .sub {
					display: block; } } }

		.sub {
			display: none;
			padding: 26px 0;
			font-size: 1.4rem;

			> .list-item + .list-item {
				margin-top: 16px; }

			> .list-item {
				> .list-link {
					padding: 0 31px; }

				&:hover,
				&.-active {
					> .list-link {
						color: $color-2; } } } } }

	@media (max-width: $mtm) {
		&-title {
			display: none; }

		&-list {
			display: none; }

		&-toggle {
			display: flex; } } }
