




















































































































































































































































































































.lk-profile-page{
    .lk-page_profile-header{
        color: black;
        font-weight: 700;
        font-size: 32px;
        line-height: 48px;
        margin-top: 10px;
        margin-bottom: 30px;
    }

    .profile_block{
        padding: 40px;
        margin-bottom: 20px;
        position: relative;
        background-color: #FAFAFA;

        .profile_block_header{
            color: black;
            font-weight: 700;
            font-size: 22px;
            line-height: 33px;
        }

        .profile_block_change_link{
            color: #0071B5;
            font-weight: 500;
            font-size: 22px;
            line-height: 33px;

            position: absolute;
            top: 40px;
            right: 40px;

            &:hover{
                cursor: pointer;
            }
        }

        .input_block{
            margin-bottom: 40px;
            width: 500px;

            label{
                color: black;
                font-weight: 700;
                font-size: 18px;
                line-height: 27px;
                margin-bottom: 8px;
            }

            input{
                border: 1px solid #D6D6D6;
                border-radius: 10px;
                color: #353B48;
                font-weight: 400;
                font-size: 16px;
                line-height: 22px;
                padding: 11px 10px;
                width: 100%;
            }

            .checkbox_block{
                display: flex;
                gap: 30px;

                label{
                    span{
                        color: black;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 24px;
                        padding-left: 24px;

                        &:before{
                            width: 16px;
                            height: 16px;
                            left: 4px;
                            top: 4px;
                        }

                        &:after{
                            width: 10px;
                            height: 10px;
                            left: 7px;
                            top: 7px;
                        }
                    }
                }
            }

            .mx-datepicker{
                width: 100%;

                input{
                    height: 46px;
                }
            }
            
            .input_row{
                display: flex;
                gap: 4px;

                .button{
                    color: #fff;
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 22px;
                    padding: 11px 19px;
                    border-radius: 10px;
                    width: 50%;
                }
            }

            .accept_code_block{
                display: flex;
                gap: 8px;
                align-items: center;
                margin-top: 8px;

                p{
                    margin-bottom: 0;
                    color: #06BA38;
                    font-weight: 700;
                    font-size: 18px;
                    line-height: 27px;
                }
            }

            .unconfirmed_code_block{
                display: flex;
                gap: 8px;
                align-items: center;
                margin-top: 8px;

                .fas{
                    color: #ED1C24;
                }

                p{
                    margin-bottom: 0;
                    color: #ED1C24;
                    font-weight: 700;
                    font-size: 18px;
                    line-height: 27px;
                }
            }
        }


        .profile_block_setting_link{
            color: #718093;
            font-weight: 400;
            font-size: 22px;
            line-height: 33px;

            &:hover{
                cursor: pointer;
            }
        }

        .profile_exit_link{
            color: #ED1C24;
            font-weight: 700;
            font-size: 22px;
            line-height: 33px;

            &:hover{
                cursor: pointer;
            }
        }
    }
}
