
















































































































.custom_basket_full{
  .max-w12{
    max-width: 12%;
  }
  .max-w10{
    max-width: 10%;
  }

  .cart-cell:first-child{
    max-width: 30% !important;
  }
}
