


















































































































































































































































































































































































































































































































































.p-rel{
    position: relative;
}
.icon-show-password{
    position: absolute;
    top: 1.5rem;
    right: 10px;

    &:hover{
        cursor: pointer;
        color: #ed1c24;
    }
}
.icon-show-password-active{
    color: #ed1c24;

    &:hover{
        color: black;
    }
}
