.admin-profile{
    padding: 20px;
    margin-top: 5px;

    .admin-profile_header{
        margin-bottom: 2rem;
    }

    .admin-profile_content{
        .profile-img{
            margin-bottom: 1rem;

            img{
                height: 17rem;
                width: 17rem;
            }
        }

        .profile-name{
            margin-bottom: 1rem;

            input{
                font-size: 1.2rem;

                padding: 16px 17px;

                border: 1px solid #718093;
                background: transparent;
                font-weight: 700;
            }
        }

        .profile-message_block{
            font-size: 1.2rem;
            border-radius: 10px;
            position: relative;
            min-height: 4.4rem;

            .close-btn{
                position: absolute;
                right: 5px;
                top: 5px;

                height: 1rem;
                width: 1rem;

                &:hover{
                    cursor: pointer;
                }
            }

            .success-message{
                color: #155724;
                background-color: #d4edda;
                border-color: #c3e6cb;
            }

            .error-message{
                color: #721c24;
                background-color: #f8d7da;
                border-color: #f5c6cb;
            }

            p{
                padding: 1.3rem;
                margin-bottom: 0;
            }
        }

        .profile-password{
            display: flex;
            flex-direction: column;
            margin-top: 1rem;

            input{
                margin-top: 1rem;
                font-size: 1.2rem;

                padding: 16px 17px;

                border: 1px solid #718093;
                background: transparent;
                font-weight: 700;
            }

            button{
                margin-top: 1rem;
            }
        }
    }
}