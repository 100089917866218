.calculator {
	display: flex;

	&-section {
		flex: 1;
		@include inner-left(); }

	&-aside {
		@include width(22.62%); }

	&-row {
		display: flex; }

	&-form,
	&-preview {
		flex: 1; }

	&-form {
		.form-row {
			margin-left: -28px;
			position: static; } }

	&-preview {
		text-align: center;

		div {
			padding: 0 30px; } }

	@media (max-width: $mds) {
		&-aside {
			@include width(21.16%); }

		&-form {
			.form-row {
				margin-left: -20px; } } }

	@media (max-width: $mdxs) {
		&-aside {
			@include width(30%); }

		&-row {
			flex-direction: column-reverse; }

		&-section {
			padding-right: 20px; }

		&-preview {
			margin-bottom: 32px; } }

	@media (max-width: $mml) {
		flex-wrap: wrap;

		&-section {
			@include width(100%);
			padding-right: 0; }

		&-aside {
			@include width(100%);
			margin-top: 64px; } } }
