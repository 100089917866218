.container-l{
    margin: auto;
}
.navbar-header-block{
    display: flex;
    align-items: center;
}

.mr-2{
    margin-right: 20px;
}

@media (max-width: 1600px) {
    .container-l{
        max-width: 1159px;
    }

    .slider-controls{
        max-width: 1159px !important;
        padding-left: 0 !important;
    }

    .col-1{
        width: 78.25px;
    }
    .col-2{
        width: 176.5px;
    }
    .col-3{
        width: 274.75px;
    }
    .col-4{
        width: 373px;
    }
    .col-5{
        width: 471.25px;
    }
    .col-6{
        width: 569.5px;
    }
    .col-7{
        width: 667.75px;
    }
    .col-8{
        width: 766px;
    }
    .col-9{
        width: 864.25px;
    }
    .col-10{
        width: 962.5px;
    }
    .col-11{
        width: 1060.75px;
    }
    .col-12{
        width: 1159px;
    }


    .navbar-header-block{
        .navbar_city_block{
            .open_region_block{
                .open_region_row{
                    .point_icon{
                        height: 21px;
                        width: 21px;
                    }
    
                    .navbar_city_name{
                        margin-right: 4px;
                    }
                }
            }
    
            .work_button{
                margin-left: 10px;
                font-size: 13px;
                line-height: 19px;
                padding: 4px 8px;
            }
        }

        .list-nd{
            .list-item-nd{
                padding-right: 6px;
            }
        }
    }
}

@media (min-width: 1601px) { 
    .container-l{
        max-width: 1444px;
    }

    .slider-controls{
        max-width: 1444px !important;
        padding-left: 0 !important;
    }

    .col-1{
        width: 102px;
    }
    .col-2{
        width: 224px;
    }
    .col-3{
        width: 346px;
    }
    .col-4{
        width: 468px;
    }
    .col-5{
        width: 590px;
    }
    .col-6{
        width: 712px;
    }
    .col-7{
        width: 834px;
    }
    .col-8{
        width: 956px;
    }
    .col-9{
        width: 1078px;
    }
    .col-10{
        width: 1200px;
    }
    .col-11{
        width: 1322px;
    }
    .col-12{
        width: 1444px;
    }

    .navbar-header-block{
        .navbar_city_block{
            .work_button{
                padding: 4px 17px;
            }
        }
    }
}


