.layout-products {
	&.-col-3 {
		@include grid(3, 32px, 16px); }

	&.-col-4 {
		@include grid(4, 32px, 16px); }

	@media (max-width: $mds) {
		&.-col-3 {
			@include grid(2, 32px, 16px); }

		&.-col-4 {
			@include grid(3, 32px, 16px); } }

	@media (max-width: $mts) {
		&.-col-4 {
			@include grid(2, 32px, 16px); } }

	@media (max-width: $mmm) {
		@include list-scroll(); } }

.product {
	background: #fff;
	box-shadow: 0 3px 45px rgba(#AAAAAA, .16);
	padding: 37px 33px;
	position: relative;

	img {
		width: 100%; }

	&-label {
		position: absolute;
		top: 19px;
		left: 21px;
		background: $color-2;
		color: #fff;
		font-weight: bold;
		padding: 7px 21px;
		font-size: 1.4rem; }

	&-link {
		display: block; }

	&-title {
		font-size: 1.8rem;
		font-weight: bold;
		margin-bottom: 23px;

		&:hover {
			color: $color-2; } }

	&-price {
		font-size: 2.4rem;
		font-weight: bold; }

	&-header {
		margin-bottom: 90px; }

	&-footer {
		margin-top: 32px;

		.button {
			width: 100%; } } }
