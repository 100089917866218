.navigation {
	position: fixed;
	top: 0;
	left: 0;
	width: 64px;
	bottom: 0;
	z-index: 201;
	overflow: hidden;
	color: #fff;
	font-size: 1.6rem;
	background: $color-7;
	transition: width .25s ease;

	&-main {
		position: absolute;
		z-index: 1;
		top: 0;
		left: 0;
		bottom: 0;
		width: 285px;

		display: flex;
		flex-direction: column;

		&-header {
			height: 64px;
			background: $color-7; }

		&-body {
			flex: 1;
			background: $color-7; }

		&-footer {
			//background: $color-2
			height: 27.612%; }

		&-button-toggle {
			padding: 0;
			margin: 0;
			border: 0;
			line-height: 64px;
			height: 64px;
			width: 100%;
			background: transparent;
			text-align: left;
			display: flex;
			align-items: center;

			&-icon {
				display: block;
				@include width(64px);
				height: 64px;
				background: $color-6;
				position: relative;

				&::before,
				&::after {
					@include content();
					left: 50%;
					top: 50%;
					transform: translate(-50%, -50%); }

				&::before {
					width: 25px;
					height: 16px;
					background: url('../img/navigation-toggle.svg') 0 0 /  100% 100% no-repeat;
					opacity: 1; }

				&::after {
					width: 17px;
					height: 17px;
					background: url('../img/navigation-close.svg') 0 0 /  100% 100% no-repeat;
					opacity: 0; } }

			&-title {
				display: block;
				padding-left: 16px;
				flex: 1;

				span {
					display: none;

					&:nth-child(1) {
						display: block; } } } }

		&-button-catalog {
			padding: 0;
			margin: 0;
			border: 0;
			background: $color-2;
			width: 100%;
			height: 100%;
			position: relative;

			svg {
				fill: #fff;
				width: 16px;
				height: 12px; }

			span {
				display: none;

				&:nth-child(1) {
					display: block;
					text-align: left;
					font-weight: bold;
					transform: rotate(-90deg);
					position: absolute;
					left: -4px;
					top: 45%; }

				&:nth-child(2) {
					svg {
						transform: rotate(180deg);
						margin-left: 17px; } }

				&:nth-child(3) {
					transform: translateX(-28px);
					svg {
						margin-right: 41px; } } } } }

	&-sub {
		position: absolute;
		z-index: 0;
		top: 0;
		left: 0;
		bottom: 0;
		width: 100vw;
		background: url('../img/navigation-sub.svg') left 117px bottom -38px no-repeat $color-2;
		padding-left: 285px;

		&-body {
			padding: 21px;
			@include scroll();
			height: 100%; } }

	/* SHORT */
	&.navigation--short {
		width: 285px;

		.navigation-main-button-toggle {
			&:hover {
				.navigation-main-button-toggle-icon {
					&::before {
						opacity: 0; }

					&::after {
						opacity: 1; } }

				.navigation-main-button-toggle-title {
					span {
						&:nth-child(1) {
							display: none; }
						&:nth-child(2) {
							display: block; } } } } }

		.navigation-main-button-catalog {
			span {
				&:nth-child(1) {
					display: none; }
				&:nth-child(2) {
					display: block; } } }

		.layout-navigation-main {
			.navigation-link {
				&.navigation-link--back {
					svg {
						transform: none; } } } } }

	/* FULL */
	&.navigation--full {
		width: 100% !important;

		.navigation-main-button-catalog {
			background: transparent;

			span {
				&:nth-child(1) {
					display: none; }
				&:nth-child(2) {
					display: none; }
				&:nth-child(3) {
					display: block; } } }

		.navigation-sub {
			background-position: left 117px bottom -38px; } }

	@media (max-width: $mdm) {
		font-size: 1.4rem;
		width: 46px;

		&-main {
			width: 255px;

			&-button-toggle {
				line-height: 46px;
				height: 46px;

				&-icon {
					@include width(46px);
					height: 46px;

					&::before {
						width: 18px;
						height: 11px; }

					&::after {
						width: 13.44px;
						height: 13.44px; } } }

			&-button-catalog {
				svg {
					width: 12px;
					height: 9px; }

				span {
					&:nth-child(1) {
						left: -7px; } } }

			&-header {
				height: 46px; } }

		&-sub {
			padding-left: 255px; }

		&.navigation--short {
			width: 255px; } }

	@media (max-width: $mtm) {
		display: none !important; } }

/* MAIN */
.layout-navigation-main {
	display: flex;
	flex-direction: column;
	height: 100%;

	.list-item {
		flex: 1; }

	.navigation-link {
		display: flex;
		align-items: center;
		height: 100%;

		&-icon {
			@include width(64px);
			text-align: center; }

		&-title {
			padding-left: 17px; }

		svg {
			display: block;
			fill: #fff;
			margin: 0 auto;
			width: 25px;
			height: 25px; }

		&:hover {
			color: $color-2;

			svg {
				fill: $color-2; } }

		&.navigation-link--back {
			.navigation-link-title {
				font-size: 1.4rem; }

			svg {
				transform: rotate(180deg);
				width: 16px;
				height: 12px; } } }

	@media (max-width: $mdm) {
		.navigation-link {
			&-icon {
				@include width(46px); }

			svg {
				width: 18px;
				height: 18px; }

			&.navigation-link--back {
				.navigation-link-title {
					font-size: 1.2rem; }

				svg {
					width: 12px;
					height: 9px; } } } } }

/* SUB */
.layout-navigation-sub {
	@include flex();
	height: 100%;

	.list-item {
		@include width(33.33%);
		border-left: 1px solid #E67E82;
		border-top: 1px solid #E67E82;
		padding: 10px 13px;

		&:nth-child(-n+3) {
			border-top: 0; }

		&:nth-child(3n+1) {
			border-left: 0; } }

	.navigation-link {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 100%;
		text-align: center;

		svg {
			width: 60px;
			height: 60px;
			fill: #fff; }

		&-icon {
			margin-bottom: 18px; }

		&-title {
			font-weight: bold;
			font-size: 1.8rem; }

		&:hover {
			background: rgba(#fff, .1); } }

	@media (max-width: $mds) {
		.navigation-link {
			&-title {
				font-size: 1.4rem; } } } }
