.section--house {
	@media (max-width: $mtl) {
		display: none; } }

.section--house-mobile {
	display: none;

	@media (max-width: $mtl) {
		display: block; } }

/* HOUSE
 * -------------------------------------------------- */

.house {
	position: relative;
	overflow: hidden;
	border-radius: 4px;

	img {
		width: 100%; }

	&-images {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;

		img {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			opacity: 0;
			transition: opacity .25s ease;

			&.-active {
				opacity: 1; } } }

	&-descriptions {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		width: 40%;
		z-index: 1;
		background: rgba($color-1, .56);
		padding-top: 7.295%;
		@include inner-left();
		@include inner-right();
		color: #fff; }

	&-description {
		display: none;

		h2 {
			margin-bottom: 32px; }

		&.-active {
			display: block;
			animation: fadeIn .25s forwards; } }

	&-points {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0; }

	&-point {
		position: absolute;
		user-select: none;

		&-number {
			width: 57px;
			height: 57px;
			background: $color-1;
			color: #fff;
			text-align: right;
			padding: 7px 13px;
			font-weight: bold;
			font-size: 1.4rem;
			cursor: pointer;
			transition: background-color .25s ease; }

		&-title {
			height: 57px;
			width: 138px;
			background: #fff;
			top: 0;
			position: absolute;
			font-weight: bold;
			font-size: 1.4rem;
			padding: 7px 21px;
			visibility: hidden;
			opacity: 0;
			transition: opacity .25s ease; }

		&:hover {
			.house-point-number {
				background: $color-2; } }

		&.-active {
			.house-point-number {
				background: $color-2; }

			.house-point-title {
				opacity: 1;
				visibility: visible; } }

		&.house-point--1 {
			top: 13%;
			left: 45%;

			.house-point-title {
				left: 57px; } }

		&.house-point--2 {
			top: 19%;
			left: 89%;

			.house-point-title {
				right: 57px; } }

		&.house-point--3 {
			top: 45%;
			left: 76%;

			.house-point-title {
				left: 57px; } }

		&.house-point--4 {
			top: 60%;
			left: 93%;

			.house-point-title {
				right: 57px; } } }

	@media (max-width: 1300px) {
		&-descriptions {
			padding-top: 31px; } }

	@media (max-width: $mds) {
		font-size: 1.4rem; } }

@keyframes fadeIn {
	0% {
		opacity: 0; }
	100% {
		opacity: 1; } }

/* HOUSE CALCULATOR
 * -------------------------------------------------- */

.layout-house-calculators {
	margin: -16px 0 0 0;

	.list-item {
		padding: 16px 0 0 16px;
		@include width(50%); }

	@media (max-width: $mtm) {
		margin-left: -16px; }

	@media (max-width: $mmm) {
		@include list-scroll(); } }

.house-calculator {
	padding: 64px 20px 20px 20px;
	background: #fff;
	border-radius: 4px;
	box-shadow: 0 3px 45px rgba(#AAAAAA, .16);
	display: flex;
	flex-direction: column;

	&-title {
		font-weight: bold;
		font-size: 1.8rem;
		margin-bottom: 25px; }

	&-description {
		flex: 1; }

	&-button {
		margin-top: 32px; } }
