





































































































.modal-container_users{
    max-width: 1000px !important;

    .df{
        display: flex;
    }

    .fa-trash{
        cursor: pointer;
    }
}
