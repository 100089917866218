.vacancy_navbar{
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;

    margin-bottom: 110px;
    margin-top: 25px;
    border-bottom: 1px solid black;

    .logo_block{
        display: flex;
        flex-wrap: nowrap;
        align-items: center;

        img{
            width: 82%;
            padding-bottom: 10px;

            &:hover{
                cursor: pointer;
            }
        }
    }

    .link_block{
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        gap: 120px;

        font-weight: 400;
        font-size: 1.6rem;
        line-height: 2.2rem;
        color: #353B48;

        padding-top: 34px;
        padding-bottom: 38px;

        p{
            margin-bottom: 0;

            &:hover{
                cursor: pointer;
                color: #ED1C24;
            }
        }
    }

    .city_block{
        display: flex;
        justify-content: flex-end;

        padding-top: 34px;
        padding-bottom: 38px;
        width: 19%;

        font-weight: 700;
        font-size: 1.6rem;
        line-height: 2.2rem;
        color: #353B48;

        p{
            margin-bottom: 0;
            padding-right: 15px;

            &:hover{
                cursor: pointer;
                color: #ED1C24;
            }
        }

        .choices__item--selectable{
            font-weight: 700;
            font-size: 1.6rem;
            line-height: 2.2rem;

            color: black;

            padding-right: 0 !important;

            &:after{
                display: none !important;
            }
            // &:hover{
            //     cursor: pointer;
            //     color: #ED1C24;
            // }
        }

        .choices__list--dropdown{
            left: unset;
            right: 0;
        }
    }
}

.vacancy_footer{
    background-color: #353B48;
    padding-top: 60px;
    padding-left: 64px;
    padding-right: 65px;
    padding-bottom: 45px;

    .vacancy_footer_block{
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;

        .left_part{
            .footer_logo_vacancy{
                margin-bottom: 54px;
                cursor: pointer;
            }

            .vacancy_footer_link{
                font-weight: 400;
                font-size: 1.9rem;
                line-height: 2.4rem;
                color: white;
                margin-bottom: 20px;

                &:hover{
                    cursor: pointer;
                    color: #ED1C24;
                }
            }

            .button{
                border-radius: 40px;
            }
        }

        .right_part{
            display: flex;
            flex-direction: column;

            .right_part_header{
                font-weight: 700;
                font-size: 2.3rem;
                line-height: 2.8rem;
                color: white;

                margin-bottom: 40px;
            }

            input{
                border: 1px solid #FFFFFF;
                padding: 20px;
                font-size: 1.9rem;
                line-height: 2.5rem;   
                border-radius: 10px;
                margin-bottom: 26px;
            }

            .button{
                font-weight: 500;
                font-size: 2.8rem;
                line-height: 3.3rem;
                border-radius: 10px;
            }
        }
    }
}

.nd-vacancy_main_page{
    .main_block{
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        align-items: center;
        margin-bottom: 125px;

        .vacancy_header{
            margin-top: 50px;
            font-weight: 500;
            font-size: 76px;
            line-height: 82px;
            color: #ED1C24;
        }

        .vacancy_subheader{
            font-weight: 400;
            font-size: 54px;
            line-height: 82px;
        }

        .button{
            width: 20%;
            margin-top: 30px;
            margin-bottom: 64px;
            border-radius: 40px;
        }

        .photo_block{
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
            gap: 36px;

            .image1{
                height: 27rem;
            }

            .image2{
                height: 22rem;
            }

            .image3{
                height: 19rem;
            }

            .image4{
                height: 22rem;
            }

            .image5{
                height: 27rem;
            }
        }
    }

    .progress_block{
        margin-bottom: 60px;

        .progress_header{
            font-weight: 600;
            font-size: 4.4rem;
            line-height: 5rem;

            margin-bottom: 15px;
        }

        .progress_description{
            font-weight: 400;
            font-size: 1.8rem;
            line-height: 2.2rem;

            margin-bottom: 54px;
        }

        .progress_list{
            display: flex;
            flex-wrap: nowrap;
            gap: 4rem;

            .progress_card{
                width: 25%;
                box-shadow: 0px 4px 10px 0px #0000001A;
                padding: 60px 40px;
                border: 1px solid #0000001A;
                border-radius: 10px;
                text-align: start;

                img{
                    width: 8rem;
                    height: 8rem;
                    margin-bottom: 40px;
                }

                .progress_card_header{
                    font-weight: 700;
                    font-size: 2.6rem;
                    line-height: 3.1rem;
                    margin-bottom: 12px;

                    // min-height: 6.2rem;
                }

                .progress_card_description{
                    font-weight: 500;
                    font-size: 1.4rem;
                    line-height: 1.8rem;
                }
            }
        }
    }

    .video_link{
        width: 100%;
        position: relative;
        margin-bottom: 150px;

        .video{
            width: 100%;
            &:hover{
                cursor: pointer;
            }
        }

        .video_icon{
            position: absolute;
            top: 42%;
            left: 45%;
            width: 10%;

            &:hover{
                cursor: pointer;
            }
        }

        .video_link_name{
            position: absolute;
            bottom: 57px;
            left: 36%;

            font-weight: 600;
            font-size: 28px;
            line-height: 35px;
            color: white;
        }
    }

    .advantages_block{
        margin-bottom: 150px;

        .advantages_block_header{
            font-weight: 700;
            font-size: 4.4rem;
            line-height: 5rem;

            margin-bottom: 87px;
        }

        .advantages{
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            gap: 40px;

            .single_advantage{
                width: 15%;
                display: flex;
                flex-direction: column;
                align-items: center;
                text-align: center;

                margin-bottom: 60px;

                img{
                    width: 130px;
                    height: 130px;

                    margin-bottom: 20px;
                }

                .single_advantage_name{
                    font-weight: 500;
                    font-size: 1.9rem;
                    line-height: 2.4rem;
                }
            }
        }
    }

    .general_block{
        margin-bottom: 235px;

        .general_block_header{
            font-weight: 700;
            font-size: 4.4rem;
            line-height: 5rem;
        }
        .general_block_description{
            font-weight: 600;
            font-size: 2.3rem;
            line-height: 2.8rem;
            font-style: italic;
            text-align: justify;
            // width: 55%;
        }
        .block_with_image{
            display: flex;
            flex-wrap: nowrap;
            margin-top: 125px;
            margin-left: 16%;

            box-shadow: 0px 4px 50px 0px #0000001A;
            border: 1px solid #0000001A;
            border-radius: 40px;
            
            .left_part{
                width: 70%;
                text-align: end;

                .general_quote{
                    font-weight: 400;
                    font-size: 2.3rem;
                    line-height: 2.8rem;
                    font-style: italic;
                    padding-top: 36px;
                    padding-left: 187px;
                    padding-right: 52px;
                    margin-bottom: 9px;
                }
                .general_name{
                    font-weight: 600;
                    font-size: 2.3rem;
                    line-height: 2.8rem;
                    margin-bottom: 74px;
                    padding-right: 52px;
                }
            }
            .right_part{
                width: 30%;
                position: relative;

                .general_image{
                    position: absolute;
                    bottom: -20px;
                }
            }
        }
    } 

    .banner_block{
        position: relative;
        margin-bottom: 150px;
        
        .banner_header{
            position: absolute;
            top: 42%;
            left: 10%;
            width: 12em;

            font-weight: 700;
            font-size: 4.4rem;
            line-height: 5rem;

            color: white;
        }
        .banner_image{
            width: 100%;
        }
    }

    .questions_block{
        margin-bottom: 150px;

        .question_block_header{
            font-weight: 700;
            font-size: 4.4rem;
            line-height: 5rem;
            margin-bottom: 60px;
        }

        .questions_list{
            margin-bottom: 40px;

            .question_row{
                display: flex;
                flex-wrap: nowrap;
                justify-content: space-between;
                border-bottom: 1px solid black;

                .text_block{
                    .question_name{
                        font-weight: 500;
                        font-size: 2.3rem;
                        line-height: 2.8rem;
                        margin-bottom: 0;
                        padding-left: 10px;
                        padding-top: 23px;
                        padding-bottom: 23px;
                    }
                    .question_answer{
                        font-weight: 500;
                        font-size: 2.3rem;
                        line-height: 2.8rem;
                        // opacity: 50% !important;
                        color: #999c9f;

                        padding-left: 10px;
                        padding-top: 20px;
                        padding-bottom: 40px;
                        padding-right: 150px;
                    }
                }

                i{
                    font-size: 30px;
                    padding-top: 20px;
                    padding-bottom: 20px;
                    padding-right: 10px;

                    &:hover{
                        cursor: pointer;
                    }
                }
            }
        }

        .button{
            // width: 100%;
            border-radius: 10px;
        }
    }

    .interview_block{
        margin-bottom: 150px;

        .interview_header_block{
            display: flex;
            flex-wrap: nowrap;
            justify-content: space-between;
            margin-bottom: 80px;

            .interview_header{
                font-weight: 700;
                font-size: 4.4rem;
                line-height: 5rem;
                margin-bottom: 0;
            }

            .block_arrows{
                display: flex;
                flex-wrap: nowrap;
                align-items: center;
                gap: 120px;

                svg{
                    &:hover{
                        cursor: pointer;
                    }
                }

                img{
                    width: 28%;
                    height: 60%;

                    &:hover{
                        cursor: pointer;
                    }
                }
            }
        }

        .interview_main_block{
            display: flex;
            flex-wrap: nowrap;
            gap: 66px;

            .left_part{
                width: 27%;
                display: flex;
                flex-direction: column;
                text-align: start;

                .interviewer_name{
                    font-weight: 600;
                    font-size: 3.2rem;
                    line-height: 3.8rem;
                    margin-bottom: 10px;
                }

                .interviewer_description{
                    font-weight: 400;
                    font-size: 1.9rem;
                    line-height: 2.4rem;
                }

                .interviewer_avatar{
                    width: 100%;
                    margin-bottom: 60px;
                }
            }

            .right_part{
                width: 83%;

                .interview_text{
                    font-weight: 400;
                    font-size: 2.3rem;
                    line-height: 2.8rem;

                    br{
                        display: none;
                    }
                }
            }
        }
    }
}

@media (min-width: 1700px) {
    .interview_main_block{
        .right_part{
            .interview_text{
                font-size: 3.2rem !important;
                line-height: 4.2rem !important;
            }
        }
    }
}

.resume_block{
    margin-bottom: 184px;

    .resume_header{
        font-weight: 700;
        font-size: 4.4rem;
        line-height: 5rem;
        margin-bottom: 16px;
    }

    .resume_subheader{
        font-weight: 400;
        font-size: 2.3rem;
        line-height: 2.8rem;
        margin-bottom: 59px;
    }

    .resume_form_block{
        box-shadow: 0px 4px 10px 0px #0000000D;
        border: 1px solid #0000001A;
        border-radius: 40px;
        padding: 40px;

        .resume_form_row{
            margin-bottom: 40px;

            label{
                font-weight: 600;
                font-size: 2.3rem;
                line-height: 2.8rem;
                margin-bottom: 10px;
            }

            input{
                width: 100%;
                border: 1px solid #0000001A;
                border-radius: 10px;
                padding: 20px;

                font-size: 1.9rem;
                line-height: 2.5rem;
            }

            .upload_resume_icon{
                width: 48px;
                height: 48px;
            }

            .upload_resume_text{
                font-weight: 600;
                font-size: 1.8rem;
                line-height: 2.3rem;
                margin-bottom: 10px;
            }

            .upload_resume_filename{
                font-weight: 600;
                font-size: 2.3rem;
                line-height: 2.8rem;
                margin-bottom: 10px;
                opacity: 0.5;
            }
        }

        .resume_form_row_full{
            margin-bottom: 40px;
            display: flex;
            flex-wrap: nowrap;
            gap: 40px;

            div{
                width: 33%;
            }

            label{
                font-weight: 600;
                font-size: 2.3rem;
                line-height: 2.8rem;
                margin-bottom: 10px;
            }

            input{
                width: 100%;
                border: 1px solid #0000001A;
                border-radius: 10px;
                padding: 20px;

                font-size: 1.9rem;
                line-height: 2.5rem;
            }

            .upload_resume_icon{
                width: 48px;
                height: 48px;
            }

            .upload_resume_text{
                font-weight: 600;
                font-size: 2.3rem;
                line-height: 2.8rem;
                margin-bottom: 10px;
            }

            .upload_resume_filename{
                font-weight: 600;
                font-size: 1.8rem;
                line-height: 2.3rem;
                margin-bottom: 10px;
                opacity: 0.5;
            }
        }

        .ai-center{
            align-items: center;
        }

        .button{
            // width: 100%;
            border-radius: 10px;
            margin-bottom: 40px;
            padding: 10px 40px;

            font-weight: 500;
            font-size: 2.2rem;
            line-height: 4.4rem;
        }

        .description_form{
            font-weight: 400;
            font-size: 1.8rem;
            line-height: 2.4rem;
            color: #00000080;
            // text-align: center;
        }
    }
}

.vacancies_page{
    .vacancies_header{
        font-weight: 700;
        font-size: 4.4rem;
        line-height: 5rem;
        margin-bottom: 20px;
    }

    .search_input_block{
        position: relative;

        .search_icon_vacancy{
            position: absolute;
            top: 10px;
            left: 20px;

            &:hover{
                cursor: pointer;
            }
        }

        .search_input{
            font-weight: 600;
            font-size: 1.7rem;
            line-height: 2.2rem;
    
            border: 1px solid #00000066;
            border-radius: 10px;
            width: 100%;
            padding: 15px;
            padding-left: 62px;
            margin-bottom: 56px;
        }
    }

    .main_block{
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        gap: 60px;
        margin-bottom: 55px;

        .left_part{
            width: 20%;

            .filter_list{
                margin-bottom: 11px;

                .filter_header{
                    border: 1px solid #000000;
                    border-radius: 10px;
                    padding: 11px 20px;

                    display: flex;
                    flex-wrap: nowrap;
                    justify-content: space-between;
                    align-items: center;

                    font-weight: 600;
                    font-size: 1.7rem;
                    line-height: 2.2rem;

                    p{
                        margin-bottom: 0;
                    }

                    &:hover{
                        cursor: pointer;
                    }
                }

                .filter_body{
                    border: 1px solid #0000001A;
                    border-radius: 10px;
                    padding: 10px;
                    
                    p{
                        margin-bottom: 8px;
                        font-weight: 400;
                        font-size: 1.5rem;
                        line-height: 1.8rem;

                        &:hover{
                            cursor: pointer;
                            color: #ED1C24;
                        }
                    }

                    .-active{
                        color: #ED1C24;
                    }
                }
            }
        }

        .right_part{
            width: 80%;

            .vacancy_row{
                box-shadow: 0px 4px 10px 0px #0000000D;
                border: 1px solid #0000001A;
                border-radius: 10px;
                padding: 40px;
                display: flex;
                flex-wrap: nowrap;
                margin-bottom: 20px;

                .text_block_vacancy{
                    margin-left: 20px;

                    .name{
                        font-weight: 600;
                        font-size: 2.8rem;
                        line-height: 3.3rem;
                        margin-bottom: 12px;
                        max-width: 400px;
                    }

                    .city{
                        font-weight: 600;
                        font-size: 1.6rem;
                        line-height: 1.8rem;
                        color: #00000080;
                    }
                }

                .info_block_vacancy{
                    margin-left: auto;
                    text-align: end;

                    .summary{
                        font-weight: 600;
                        font-size: 2.8rem;
                        line-height: 3.3rem;
                        margin-bottom: 74px;
                    }

                    .button{
                        border-radius: 40px;
                    }
                }
            }
        }
    }
}

.vacancy_single_page{
    .vacancy_single_page_header{
        font-weight: 600;
        font-size: 4.4rem;
        line-height: 5rem;
        margin-bottom: 38px;
    }

    .main_block{
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        gap: 200px;
        margin-bottom: 250px;

        .left_part{
            width: 70%;

            .left_single{
                p{
                    margin-bottom: 4px;
                }

                p:first-child{
                    display: none;
                }

                strong{
                    font-weight: 700;
                    font-size: 2.8rem;
                    line-height: 3.3rem;
                    color: #ED1C24;
                    margin-bottom: 4px;
                }

                .left_single_header{
                    font-weight: 700;
                    font-size: 2.8rem;
                    line-height: 3.3rem;
                    color: #ED1C24;
                    margin-bottom: 4px;
                }

                .left_single_list{
                    font-weight: 600;
                    font-size: 1.9rem;
                    line-height: 2.4rem;
                }

                ul{
                    font-weight: 600;
                    font-size: 1.9rem;
                    line-height: 2.4rem;
                    color: black;

                    strong{
                        color: black;
                        font-weight: 600;
                        font-size: 1.9rem;
                        line-height: 2.4rem;
                    }

                    li{
                        &:before{
                            background: black;
                        }
                    }
                }
            }
        }

        .right_part{
            width: 30%;
            box-shadow: 0px 4px 10px 0px #0000000D;
            border: 1px solid #0000001A;
            border-radius: 10px;
            padding: 40px;
            height: 100%;

            .info_block{
                margin-bottom: 32px;

                .info_name{
                    font-weight: 400;
                    font-size: 1.9rem;
                    line-height: 2.4rem;
                    padding: 10px;
                    color: #00000080;
                    margin-bottom: 0;
                }

                .info_value{    
                    font-weight: 600;
                    font-size: 1.9rem;
                    line-height: 2.4rem;
                    padding: 10px;
                    margin-bottom: 0;
                }
            }

            .single_page_buttons_block{
                display: flex;
                flex-direction: column;
                width: 90%;
            }

            .button{
                border-radius: 40px;
                margin-bottom: 14px;
            }

            .button--default{
                background-color: #353B48;
                color: white;

                &:hover{
                    background-color: #ED1C24;
                }
            }
        }
    }
}