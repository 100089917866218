


































































































































































.border_wo_left-top{
    border-left: unset !important;
    border-top: unset !important;
}
.border_wo_right-top{
    border-right: unset !important;
    border-top: unset !important;
}
.border_wo_top{
    border-top: unset !important;
}
.border_wo_left-bottom{
    border-left: unset !important;
    border-bottom: unset !important;
}
.border_wo_right-bottom{
    border-right: unset !important;
    border-bottom: unset !important;
}
.border_wo_bottom{
    border-bottom: unset !important;
}
.border_wo_left{
    border-left: unset !important;
}
.border_wo_right{
    border-right: unset !important;
}
.border_wo_left-right{
    border-left: unset !important;
    border-right: unset !important;
}
