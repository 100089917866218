.layout-objects-page {
	.list-item + .list-item {
		margin-top: 62px; }

	@media (max-width: $mmm) {
		margin-right: -15px;

		.list-item + .list-item {
			margin-top: 24px; } } }

.layout-objects {
	margin: 0 0 0 -92px;

	.list-item {
		@include width(50%);
		padding-left: 92px; }

	@media (max-width: $mdm) {
		margin: 0 0 0 -67px;

		.list-item {
			padding-left: 67px; } }

	@media (max-width: $mtm) {
		margin: 0 0 0 -32px;

		.list-item {
			padding-left: 32px; } }

	@media (max-width: $mmm) {
		@include list-scroll('object'); } }

.object {
	display: block;
	position: relative;

	&-title {
		background: #fff;
		padding: 32px 0 24px 35px;
		max-width: 335px;
		position: absolute;
		left: 0;
		bottom: -1px;
		font-size: 1.8rem;
		font-weight: bold;
		width: 100%;

		&::before {
			@include content();
			right: 100%;
			top: 0;
			bottom: 0;
			width: 16px;
			background: $color-2; } }

	&-image {
		img {
			width: 100%; } }

	@media (max-width: $mds) {
		&-title {
			font-size: 1.6rem;
			padding: 16px 0 12px 16px; } }

	@media (max-width: $mts) {
		&-title {
			font-size: 1.4rem;
			max-width: 270px; } }

	@media (max-width: $mmm) {
		&-title {
			&::before {
				width: 15px; } }

		&-image {
			overflow: hidden;

			img {
				height: 265px;
				width: auto;
				max-width: none; } } } }
