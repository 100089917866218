




































































































































































































































.lk-instructions-page{
    .lk-page_instructions-header{
        margin-top: 50px;
        margin-bottom: 30px;

        font-weight: 700;
        font-size: 32px;
        line-height: 48px;
        color: black;
        margin-bottom: 30px;
    }
    .lk-instructions_content{
        .inst_once{
            .inst_row{
                padding: 17.5px 12px;
                display: flex;
                align-items: center;
                justify-content: space-between;

                &:hover{
                    cursor: pointer;
                }

                p{
                    color: black;
                    font-weight: 700;
                    font-size: 22px;
                    line-height: 33px;
                }

                .download_button{
                    background-color: #171717;
                    display: flex;
                    gap: 24px;
                    align-items: center;
                    padding: 10px 24px;  
                    border: unset;
                    border-radius: 10px;
                    
                    p{
                        color: #fff;
                        font-weight: 700;
                        font-size: 16px;
                        line-height: 27px;
                    }
                }
            }

            .instruction_list{
                padding: 40px;

                .inst_download_once{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-bottom: 20px;

                    p{
                        color: black;
                        font-weight: 500;
                        font-size: 22px;
                        line-height: 33px;
                    }

                    button{
                        background-color: #171717;
                        display: flex;
                        gap: 24px;
                        align-items: center;
                        padding: 10px 24px;  
                        border: unset;
                        border-radius: 10px;

                        p{
                            color: #fff;
                            font-weight: 700;
                            font-size: 16px;
                            line-height: 27px;
                        }
                    }
                }
            }
        }
    }
}
