.post {
	overflow: hidden;

	&-image {
		float: right;
		width: 50%;
		text-align: right;

		img {
 } }			//width: 100%

	&-title {
		overflow: hidden;
		margin-bottom: 64px;
		@include inner-left(); }

	&-text {
		@include inner-left();
		padding-right: 10%;
		overflow: hidden; }

	&.post--news {
		.post-image {
			@include inner-right(); } }

	@media (max-width: $mtm) {
		&-text {
			padding-right: 20px; } }

	@media (max-width: $mts) {
		display: flex;
		flex-direction: column;

		&-title {
			order: 0;
			margin-bottom: 32px; }

		&-image {
			float: none;
			width: auto;
			text-align: left;
			order: 1;
			margin-bottom: 32px; }

		&-text {
			order: 2;
			padding-right: 0; } } }
