.layout-buttons {
	margin-left: -32px;

	.list-item {
		padding-left: 32px; }

	@media (max-width: $mds) {
		margin-left: -20px;

		.list-item {
			padding-left: 20px; } }

	@media (max-width: $mml) {
		&.layout-buttons--links {
			margin-left: -15px;
			margin-right: -15px;
			overflow-x: auto;
			overflow-y: hidden;
			-webkit-overflow-scrolling: touch;

			.list-item {
				padding-left: 15px;
				padding-right: 15px; }

			.button {
				padding: 0;
				margin: 0;
				background: none;
				font-size: 1.8rem;

				&.button--primary {
					color: $color-6; }

				&.button--default {
					color: $color-11; } } } }

	@media (max-width: $mmm) {
		&:not(.layout-buttons--links) {
			display: block;

			.list-item + .list-item {
				margin-top: 24px; } } } }

.button {
	display: inline-block;
	text-decoration: none;
	padding: 15px 27px;
	border: 0;
	border-radius: 0;
	text-align: center;
	transition: color .25s, background-color .25s, opacity .25s, border-color .25s;
	font-size: 1.8rem;
	font-weight: bold;
	//height: 57px
	//line-height: 57px

	svg {
		fill: currentColor; }

	&.button--default {
		background: $color-4;
		color: $color-7;

		&:hover {
			background: $color-2;
			color: #fff; }

		&:active {
			background: $color-1;
			color: #fff; } }

	&.button--primary {
		background: $color-2;
		color: #fff;

		&:hover {
			background: $color-10;
			color: #fff; }

		&:active {
			background: $color-1;
			color: #fff; } }

	&.button--light {
		background: #fff;
		color: $color-7;

		&:hover {
			background: $color-10;
			color: #fff; }

		&:active {
			background: $color-1;
			color: #fff; } }

	&.button--cart {
		padding-left: 10px;
		padding-right: 10px;

		svg {
			width: 25px;
			height: 25px;
			display: inline-block;
			vertical-align: middle;
			margin-top: -5px;
			margin-right: 10px; } }

	@media (max-width: $mdm) {
		font-size: 1.4rem;
		padding-top: 13px;
		padding-bottom: 14px; }

	@media (max-width: $mmm) {
		width: 100%;
		min-width: 0; } }
