





















































































































































































































































































































































































.nlk-accordion-body-custom{
    display: block !important;

    .nlk-status__list{
        display: flex;
        margin-bottom: 2rem;

        li{
            margin-right: 2rem;
        }
    }
}
