













































































































































.delete_user_modal{
    .modal-container{
        padding: 24px !important;
        max-width: 606px !important;
        border-radius: 5px;

        .header_block{
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 24px;

            p{
                color: black;
                font-weight: 700;
                font-size: 22px;
                line-height: 33px;
                margin-bottom: 0;
            }

            svg{
                &:hover{
                    cursor: pointer;
                }
            }
        }

        .content_block{
            margin-bottom: 24px;

            .confirm_text{
                color: black;
                font-weight: 400;
                font-size: 22px;
                line-height: 33px;
            }

            input{
                border: 1px solid #D6D6D6;
                border-radius: 10px;
                color: #353B48;
                font-weight: 400;
                font-size: 16px;
                line-height: 22px;
                padding: 11px 10px;
                width: 100%;
            }
        }

        .buttons_block{
            display: flex;
            justify-content: flex-end;
            gap: 24px;

            .button{
                border-radius: 10px;
            }
        }

        .resend_code_block{
            margin-top: 24px;

            p{
                color: #718093;
                font-weight: 400;
                font-size: 16px;
                line-height: 21px;
                float: right;
                margin-bottom: 0;

                &:hover{
                    cursor: pointer;
                }
            }
        }
    }
}
