




























































































































































































































































































































































































































































































































































































































































































































































































































































































































#app{
    position: relative;
    overflow-x: hidden !important;   
}

.shadow_block{
    position: absolute;
    background: rgba(33,33,33,.3);
    height: 100%;
    width: 100vw;
    z-index: 100;
    opacity: 1;
}

html{
    scroll-behavior: smooth;
}

.admin-layout{
    display: flex;
}

.custom_widget{
    position: fixed;
    bottom: 50px;
    right: 50px;
    z-index: 100;

    .widget_button{
        background-color: #ED1C24;
        width: 56px;
        height: 56px;
        border-radius: 100px;
        cursor: pointer;

        display: flex;
        align-items: center;
        justify-content: center;
    }

    .widget_buttons_block{
        display: flex;
        flex-direction: column;
        align-items: center;

        .widget_button{
            width: 45px;
            height: 45px;
            margin-bottom: 20px;
        }
    }

    .opened_widget_button{
        background-color: #d6d6d6;
    }

    .widget_form_callback{
        border: 4px solid #ED1C24;
        background-color: #fff;
        margin-bottom: 20px;
        width: 350px;
        position: relative;

        .error_border{
            border: 1px solid red !important;
        }

        .header_block{
            border-bottom: 1px solid rgba(82, 92, 105, 0.11);

            p{
                margin-bottom: 0;
                padding: 14px 31px;

                color: black;
                font-size: 14px;
                text-align: center;
            }
        }

        .body_block{
            padding: 14px 31px;
            display: flex;
            flex-direction: column;

            .input_block{
                position: relative;

                label{
                    position: absolute;
                    color: #9ca0a5;
                    font-size: 13px;
                    top: 8px;
                    left: 11px;
                }

                input{
                    margin-bottom: 15px;

                    background-color: #f5f5f5;
                    padding-left: 10px;
                    padding-right: 35px;
                    border: unset;
                    border-radius: 4px;
                    height: 52px;
                    padding-top: 0;

                    color: black;
                }

                .custom_input{
                    position: relative;
                    margin-bottom: 15px;

                    .button_block{
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        background-color: #f5f5f5;
                        padding-left: 10px;
                        padding-right: 10px;
                        border: unset;
                        border-radius: 4px;
                        height: 52px;
                        padding-top: 0;

                        color: black;

                        p{
                            margin-bottom: 0;
                        }

                        &:hover{
                            cursor: pointer;
                        }
                    }

                    .custom_list{
                        position: absolute;
                        overflow-x: auto;
                        max-height: 200px;
                        width: 100%;
                        z-index: 1000;

                        .list_once{
                            background-color: #fff;
                            border-bottom: 1px solid rgb(218, 218, 218);

                            color: black;
                            padding: 11px;
                            font-size: 16px;

                            &:hover{
                                cursor: pointer;
                                background-color: rgb(242, 242, 242);
                            }
                        }
                    }
                }
            }

            .button{
                padding: 15px 20px;
                font-size: 15px;
                border-radius: 4px;
            }
        }

        .b24-window-close{
            position: absolute;
            top: 12px;
            right: 23px;
            width: 28px;
            height: 28px;
            border-radius: 100%;
            border: 0;
            -webkit-transition: opacity .3s;
            -o-transition: opacity .3s;
            transition: opacity .3s;
            opacity: .5;
            cursor: pointer;
            outline: 0;
            z-index: 25;
            background-color: #ffd110;
            background-color: rgba(237, 28, 36, 1);

            &:before{
                content: '';
                position: absolute;
                top: 50%;
                left: 50%;
                width: 1px;
                height: 15px;
                background-color: #fff;
                background-color: rgba(255, 255, 255, 1);
                -webkit-transform: translate(-50%, -50%) rotate(45deg);
                -ms-transform: translate(-50%, -50%) rotate(45deg);
                transform: translate(-50%, -50%) rotate(45deg);
            }

            &:after{
                content: '';
                position: absolute;
                top: 50%;
                left: 50%;
                width: 1px;
                height: 15px;
                background-color: #fff;
                background-color: rgba(255, 255, 255, 1);
                -webkit-transform: translate(-50%, -50%) rotate(45deg);
                -ms-transform: translate(-50%, -50%) rotate(45deg);
                transform: translate(-50%, -50%) rotate(-45deg);
            }

            &:hover{
                opacity: 1;
            }
        }
    }

    .widget_form_review{
        width: 450px;

        input{
            width: 100%;
        }

        .widget_checkbox{
            margin-bottom: 10px;

            span{
                font-size: 13px;
            }
            input{
                margin-right: 10px;
                width: auto;
            }
        }
    }

    .success_message_block{
        position: relative;
        background-color: #a1bd52;
        color: black;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 20px;

        .success_message_icon{
            width: 169px;
            height: 169px;
            margin-bottom: 26px;
            margin-top: 35px;
        }

        p{
            font-size: 18px;
            line-height: 33px;
            opacity: .8;
            font-weight: 500;
            width: 80%;
            text-align: center;
            margin-bottom: 35px;
        }

        .b24-window-close{
            position: absolute;
            top: 12px;
            right: 23px;
            width: 28px;
            height: 28px;
            border-radius: 100%;
            border: 0;
            -webkit-transition: opacity .3s;
            -o-transition: opacity .3s;
            transition: opacity .3s;
            opacity: .5;
            cursor: pointer;
            outline: 0;
            z-index: 25;
            background-color: #ffd110;
            background-color: rgba(237, 28, 36, 1);

            &:before{
                content: '';
                position: absolute;
                top: 50%;
                left: 50%;
                width: 1px;
                height: 15px;
                background-color: #fff;
                background-color: rgba(255, 255, 255, 1);
                -webkit-transform: translate(-50%, -50%) rotate(45deg);
                -ms-transform: translate(-50%, -50%) rotate(45deg);
                transform: translate(-50%, -50%) rotate(45deg);
            }

            &:after{
                content: '';
                position: absolute;
                top: 50%;
                left: 50%;
                width: 1px;
                height: 15px;
                background-color: #fff;
                background-color: rgba(255, 255, 255, 1);
                -webkit-transform: translate(-50%, -50%) rotate(45deg);
                -ms-transform: translate(-50%, -50%) rotate(45deg);
                transform: translate(-50%, -50%) rotate(-45deg);
            }

            &:hover{
                opacity: 1;
            }
        }
    }
}
