.section--contacts-map {
	#map {
		margin-left: 64px;
		margin-bottom: 32px; }

	@media (max-width: $mdm) {
		#map {
			margin-left: 46px; } }

	@media (max-width: $mds) {
		#map {
			margin-left: 0; } } }

.catalog-controls--contacts {
	@media (max-width: $mmm) {
		//display: flex
		margin-left: 0 !important;

		.catalog-control {
			padding-left: 0 !important; }

		.catalog-control + .catalog-control {
			margin-top: 16px; } } }

.layout-map-contacts {
	margin-top: -64px;
	margin-left: 20%;

	.list-item {
		@include width(50%);
		padding-top: 64px; }

	@media (max-width: $mdm) {
		margin-left: 16px; }

	@media (max-width: $mds) {
		margin-left: 0;

		.list-item {
			@include width(100%); } } }

.map-contact {
	&-title {
		font-size: 1.8rem;
		font-weight: bold;
		margin-bottom: 18px; }

	&-address,
	&-time {
		padding-left: 36px;
		position: relative;

		&::before {
			@include content();
			left: 0;
			top: .25em; } }

	&-address {
		&::before {
			width: 14.34px;
			height: 18px;
			background: url('../img/map-address.svg') 0 0 no-repeat; } }

	&-time {
		margin-top: 18px;

		&::before {
			width: 18px;
			height: 18px;
			background: url('../img/map-time.svg') 0 0 no-repeat; } } }
