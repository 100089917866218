


































































































.callback-modal{
    .modal-container{
        border-radius: 10px;
        padding: 20px !important;
        width: 359px;

        .header_block{
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 40px;

            p{
                margin-bottom: 0;
                font-weight: 700;
                font-size: 2.2rem;
                line-height: 2.4rem;
            }

            img{
                &:hover{
                    cursor: pointer;
                }
            }
        }

        .callback_modal_form{
            display: flex;
            flex-direction: column;

            label{
                font-weight: 600;
                font-size: 1.8rem;
                line-height: 2.4rem;
                margin-bottom: 4px;
            }

            input{
                margin-bottom: 10px;
                padding: 10px 20px;
                border: 1px solid #d5d5d5;
                border-radius: 10px;
            }

            .button{
                padding: 8px 10px;
                border-radius: 10px;
                font-weight: 700;
                font-size: 1.8rem;
                line-height: 2.4rem;
            }
        }

        .cl-check{
            margin-top: 10px;
            margin-bottom: 20px !important;

            span{
                color: #353B48;
                font-weight: 400;
                font-size: 1.2rem;
                line-height: 1.6rem;

                &:before{
                    width: 1.6rem;
                    height: 1.6rem;
                    border-radius: 2px;
                }
            }
        }
    }
}    
