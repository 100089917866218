







































































































.admin-left-menu{
    border-left: 2px solid #a0a0a0;
    border-right: 2px solid #a0a0a0;
    height: 91vh;
    width: 18%;
    background-color: #e2e2e2;

    .admin-list{
        list-style: none;
        padding: 0;
        margin: 0; 

        font-size: 15px;
        font-weight: 600;

        overflow-x: auto;
        max-height: 100%;

        li{
          padding: 10px;
          padding-left: 25px;

          &:hover{
            background-color: #fff;
            cursor: pointer;
          }
        }

        .-active{
          color: #ed1c24;
        }
    }
}
