.download {
	display: flex;

	&-title {
		flex: 1;
		padding-top: 48px; }

	&-section {
		flex: 1;
		background: $color-4;
		position: relative; }

	&-image {
		position: absolute;
		left: 0;
		top: 50%;
		transform: translate(-50%, -50%); }

	&-content {
		padding: 50px 0 68px 50%;

		b,
		strong {
			font-size: 1.8rem; } }

	@media (max-width: $mts) {
		display: block;

		&-title {
			padding-top: 0;
			margin-bottom: 32px; }

		&-section {
			background: none;
			margin: 0 !important; }

		&-image {
			display: none; }

		&-content {
			padding: 0;

			br {
				display: none; } } } }
