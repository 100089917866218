.article {
	hyphens: auto;

	table {
		width: 100%;
		margin-bottom: $textMargin;

		td,
		th {
			border: 1px solid #ccc;
			padding: 10px; } }

	.table {
		margin-bottom: $textMargin;
		overflow: auto;

		table {
			margin-bottom: 0; }

		&:last-child {
			margin-bottom: 0; }

		& + h1,
		& + h2,
		& + h3,
		& + h4,
		& + h5,
		& + h6 {
			margin-top: $textHeadMargin; } }

	img {
		&[style*='float: left'],
		&[align='left'] {
			margin: 0 15px 15px 0;
			float: left; }

		&[style*='float: right'],
		&[align='right'] {
			margin: 0 0 15px 15px;
			float: right; } } }
