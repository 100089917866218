/*!
 * Moonkake 7.0.1
 *
 * https://github.com/detectiveshelby/moonkake
 */

/* Config */

@import "config/base";

/* Font */

@import "font/font";

/* Core */

@import "config/moonkake";

/* Vendor */

//@import "magnific-popup/dist/magnific-popup"
// @import "@fancyapps/fancybox/dist/jquery.fancybox"
@import "swiper/swiper.scss";
@import "~photoswipe/src/css/main.scss";
@import "~photoswipe/src/css/default-skin/default-skin";

/* Common */

// @import "components/*"
@import "components/_grid.sass";
@import "components/accordion.sass";
@import "components/address.sass";
@import "components/advantage.sass";
@import "components/article.sass";
@import "components/breadcrumb.sass";
@import "components/button.sass";
@import "components/calculator.sass";
@import "components/card.sass";
@import "components/checkbox.sass";
@import "components/color.sass";
@import "components/cost.sass";
@import "components/counter.sass";
@import "components/data.sass";
@import "components/description.sass";
@import "components/download.sass";
@import "components/dropdown.sass";
@import "components/edit.sass";
@import "components/event.sass";
@import "components/field.sass";
@import "components/file.sass";
@import "components/form.sass";
@import "components/gallery.sass";
@import "components/group.sass";
@import "components/heading.sass";
@import "components/helper.sass";
@import "components/house.sass";
@import "components/info.sass";
@import "components/iterator.sass";
@import "components/label.sass";
@import "components/layout.sass";
@import "components/map.sass";
@import "components/menu.sass";
@import "components/message.sass";
@import "components/modal.sass";
@import "components/navigation.sass";
@import "components/note.sass";
@import "components/object.sass";
@import "components/order.sass";
@import "components/pagination.sass";
@import "components/post.sass";
@import "components/product.sass";
@import "components/result.sass";
@import "components/review.sass";
@import "components/search.sass";
@import "components/section.sass";
@import "components/select.sass";
@import "components/slider.sass";
@import "components/social.sass";
@import "components/state.sass";
@import "components/subhero.sass";
@import "components/success.sass";
@import "components/switch.sass";
@import "components/table.sass";
@import "components/tabs.sass";
@import "components/text.sass";
@import "components/tooltip.sass";
@import "components/user.sass";
@import "components/video.sass";

@import "partials/common.sass";
@import "partials/footer.sass";
@import "partials/header.sass";

/* Pages */

@import "pages/404.sass";
@import "pages/auth.sass";
@import "pages/cabinet.sass";
@import "pages/cart.sass";
@import "pages/catalog.sass";
@import "pages/checkout.sass";
@import "pages/company.sass";
@import "pages/constructor.sass";
@import "pages/contacts.sass";
@import "pages/faq.sass";
@import "pages/index.sass";
@import "pages/object.sass";
@import "pages/offer.sass";
@import "pages/partner.sass";
@import "pages/pay_delivery.sass";
@import "pages/product.sass";

@import './components/admin/offers.scss';
@import './components/admin/documents.scss';
@import './components/admin/contacts.scss';
@import './components/admin/index.scss';
@import './components/admin/becomePartner.scss';
@import './components/admin/settings.scss';
@import './components/admin/profile.scss';

/* Custom Styles */

@import './components/custom_styles/main.scss';

/* New Design */

@import './components/new_design.scss';
@import './components/new-design-info.scss';
@import './components/new-design-auth.scss';
@import './components/coatings.scss';
@import './components/pages/vacancy.scss';
@import './components/pages/about_company.scss';

@import './components/new-grid.scss';


@import './components/pages/lk.scss';


