.inner-nd{
    padding-left: 0 !important;
}


.about_company-info_page{
    .calculator-page-nd{
        margin-bottom: 100px;
    }

    .home_popular_product_block{
        .main_header{
            font-weight: 700;
            font-size: 2.1rem;
            line-height: 2.5rem;
            margin-bottom: 40px;
        }
        
        h2{
            font-size: 2.1rem;
            line-height: 2.5rem;
            font-weight: 700;
        }
    }

    .home_objects_block{
        .header{
            font-size: 2.1rem;
            line-height: 2.5rem;
        }
    }

    .home_excursion_block{
        .header{
            font-size: 2.1rem;
            line-height: 2.5rem;
        }
    }

    .home_brands_block{
        h2{
            font-size: 2.1rem;
            line-height: 2.5rem;
            font-weight: 700;
        }
    }


    .about_company-info_page-sp_banner{
        margin-bottom: 50px;

        .banner_block{
            position: relative;

            .main_banner{
                width: 100%;
            }
    
            .text_block{
                color: #fff;
                position: absolute;
                top: 2rem;
                right: 97px;
    
                .main_header{
                    font-size: 5.7rem;
                    font-weight: 700;
                    line-height: 6rem;
    
                    margin-bottom: 8px;
                }
        
                .subheader{
                    font-size: 17px;
                    font-weight: 600;
                    line-height: 21px;
                }
            }
        }

    }

    .about_company-info_page-sp-evosteel_banner{
        margin-bottom: 50px;

        .banner_block{
            position: relative;

            .main_banner{
                width: 100%;
            }
            
            .sub_banner{
                position: absolute;
                top: 4rem;
                left: 10rem;
            }
        }
    }

    .proflist_banner{
        .text_block{
            left: 3rem;
            
            .main_header{
                font-size: 3.6rem !important;
                line-height: 4.4rem !important;
                color: #575756;
            }
            .subheader{
                font-size: 1.4rem !important;
                line-height: 1.7rem !important;
                color: #575756;
            }
        }
    }

    .about_company-info_page-sp_cards{
        margin-top: 50px;
        margin-bottom: 100px;

        .main_header{
            font-weight: 700;
            font-size: 2.1rem;
            line-height: 2.5rem;
            margin-bottom: 40px;
        }

        .cards_description{
            font-weight: 300;
            font-size: 1.4rem;
            line-height: 1.8rem;

            p{
                margin-bottom: 20px;
            }
        }

        .cards-block{
            display: flex;
            justify-content: space-between;

            .card-sp{
                width: 32.5%;
                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
                border-radius: 5px;
                overflow: hidden;
                position: relative;

                .arrow{
                    position: absolute;
                    top: 19px;
                    right: 14px;
                }

                .main_img{
                    width: 100%;
                    cursor: pointer;
                }

                .text_block{
                    padding: 20px;

                    .main_header{
                        text-align: center;
                        font-weight: 600;
                        font-size: 17px;
                        line-height: 21px;

                        margin-bottom: 15px;
                    }

                    .subheader{
                        font-weight: 300;
                        font-size: 12px;
                        line-height: 14px;
                    }
                }
            }
        }
    }

    .about_company-info_page-sp_advantages{
        margin-bottom: 100px;

        .main_header{
            font-weight: 700;
            font-size: 2.1rem;
            line-height: 2.5rem;
            margin-bottom: 40px;
        }

        .main_block{
            display: flex;

            .left_part{
                width: 35%;

                .list-advantage{
                    margin-left: 0;
                    margin-top: 35px;

                    .li-advantage{
                        display: flex;
                        margin-bottom: 14px;

                        &.-active{
                            .advantage_icon-div{
                                width: 16px;
                            }

                            p{
                                width: 100%;
                            }
                        }

                        .advantage_icon-div{
                            height: 18px;
                            width: 15px;

                            margin-right: 14px;
                        }

                        .advantage_icon{
                            height: 18px;
                            width: 15px;

                            margin-right: 14px;
                        }

                        p{
                            font-weight: 600;
                            font-size: 17px;
                            line-height: 18px;

                            cursor: pointer;
                            border-bottom: 1px solid #000000;
                        }
                    }
                }
            }

            .right_part{
                width: 65%;
                position: relative;

                .main_img{
                    width: 100%;
                }

                .text_block{
                    position: absolute;
                    top: 0;
                    left: 0;
                    color: #fff;
                    width: 75%;
                    height: 100%;
                    background: #333333C2;

                    backdrop-filter: blur(10px);
                    padding: 20px 80px 20px 30px;

                    p{
                        margin-bottom: 5px;
                        font-size: 1.4rem;
                        line-height: 17px;
                        font-weight: 300;
                    }

                    .advantage_header{
                        font-weight: 400;
                    }

                    .mt-40{
                        margin-top: 4rem;
                    }
                }
            }
        }
    }

    .profile_advantages{
        margin-top: 50px;
    }

    .about_company-info_page-sp_type-profiles{
        margin-top: 100px;
        margin-bottom: 100px;

        .main_header{
            font-weight: 700;
            font-size: 2.1rem;
            line-height: 2.5rem;
            margin-bottom: 40px;
        } 

        .cards_proftypes{
            display: flex;
            justify-content: space-around;
            flex-wrap: wrap;

            .card_proftype{
                width: 32%;
                text-align: center;
                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                border-radius: 5px;
                margin-bottom: 20px;
                position: relative;
                cursor: pointer;

                img{
                    margin-top: 35px;
                    margin-bottom: 38px;
                    // padding: 0 15px;
                }

                .profile_type_img{
                    width: 60%;
                }

                p{
                    font-weight: 600;
                    font-size: 20px;
                    line-height: 25px;
                    margin-bottom: 38px;
                }

                .question_icon{
                    position: absolute;
                    top: 2rem;
                    right: 2rem;

                    margin-top: 0;
                    margin-bottom: 0;
                    cursor: pointer;
                }
            }
        }

        .profile_proftypes{
            justify-content: center;

            .card_proftype{
                margin-left: 10px;
                margin-right: 10px;
                width: 31%;
            }
        }
    }

    .about_company-info_page-sp_keys-connections{
        margin-bottom: 100px;

        .main_header{
            font-weight: 700;
            font-size: 2.1rem;
            line-height: 2.5rem;
            margin-bottom: 40px;
        }

        .cards_keycon{
            display: flex;
            justify-content: space-around;

            .card_keycon{
                width: 23.5%;
                padding: 35px 14px;
                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
                border-radius: 5px;
                text-align: center;

                img{
                    margin-bottom: 38px;
                    
                }

                .card_header{
                    font-weight: 600;
                    font-size: 20px;
                    line-height: 25px;
                }

                .card_subheader{
                    font-weight: 600;
                    font-size: 22px;
                    line-height: 25px;
                    margin-bottom: 20px;
                    text-align: start;
                }

                .keycon_advantage_list{
                    font-weight: 300;
                    font-size: 16px;
                    line-height: 19.5px;
                    text-align: start;
                }
            }
        }
    }

    .about_company-info_page-sp_description{
        margin-bottom: 100px;

        .main_header{
            font-weight: 700;
            font-size: 2.1rem;
            line-height: 2.5rem;
            margin-bottom: 40px;
        }

        .description_block{
            display: flex;

            .left_part{
                width: 50%;
                
                img{
                    margin-top: 30px;
                }
            }

            .right_part{
                width: 50%;

                font-weight: 600;
                font-size: 17px;
                line-height: 21px;

                img{
                    width: 66px;
                    height: 66px;

                    margin-right: 14px;
                }

                .description_once{
                    display: flex;

                    margin-bottom: 22px;

                    p{
                        margin: auto;
                        margin-left: 0;
                    }
                }
            }
        }
    }

    .about_company-info_page-sp-evosteel_description{
        margin-bottom: 100px;

        .main_header{
            font-weight: 700;
            font-size: 2.1rem;
            line-height: 2.5rem;
            margin-bottom: 40px;
        }

        .description_block{
            display: flex;

            .left_part{
                width: 50%;
            }

            .right_part{
                width: 50%;

                .description_text{
                    font-weight: 700;
                    font-size: 2rem;
                    line-height: 2.5rem;
                }
                
                .description_subtext{
                    font-weight: 300;
                    font-size: 1.4rem;
                    line-height: 1.7rem;
                }
            }
        }
    }
}

.catalog-nd{
    .top_level_category_block{
        margin-top: 50px;
    }

    .calculator-nd{
        margin-bottom: 100px;
    }

    .card-catalog-nd{
        position: relative;
        display: block;
        padding: 30px;
        padding-bottom: 60px;
        background: #fff;
        border-radius: 3px;
        overflow: hidden;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
        min-height: 400px;
        height: 100%;

        .card-heading{
            position: relative;
            left: 0;
            top: 0;
            height: 100%;

            .card-icon{
                margin-bottom: 20px;

                .product-preview-img{
                    object-fit: cover;
                    height: 85px;
                    width: 100%;
                    max-width: 140px;
                }
            }

            .card-title{
                font-size: 1.7rem;
                font-weight: 600;
                line-height: 2.1rem;
                min-height: unset;
                margin-bottom: 20px;
            }

            .card-list{
                list-style-type: none;
                font-weight: 300;
                font-size: 1.4rem;
                line-height: 1.6rem;
                color: #000000;
                margin-left: 0;

                .card-li{
                    margin-bottom: 15px;

                    &:hover{
                        color: red;
                    }
                }
            }

            .link_more{
                position: absolute;
                bottom: -30px;
                left: 0;

                .local_block{
                    display: flex;
                    justify-content: space-between;

                    font-size: 1.4rem;
                    line-height: 1.6rem;
                    color: #B1B1B1;

                    min-width: 156px;

                    p{
                        margin-bottom: 0;
                    }

                    img{
                        width: 17px;
                        height: 14px;
                    }
                }
            }
        }
    }

    .about_company-info_page-sp_banner{
        margin-bottom: 50px;

        .banner_block{
            position: relative;

            .main_banner{
                width: 100%;

                // opacity: 0.6;
            }
    
            .text_block{
                color: #fff;
                position: absolute;
                top: 2rem;
                right: 20rem;
    
                .main_header{
                    font-size: 3.9rem;
                    font-weight: 700;
                    line-height: 4.1rem;
    
                    margin-bottom: 8px;
                }
        
                .subheader{
                    font-size: 17px;
                    font-weight: 600;
                    line-height: 21px;
                }

                .button{
                    width: 100%;
                    border-radius: 3px;
                }
            }
        }

        .banner_block_proflist{
            .text_block{
                top: 1.5rem;
                left: 3rem;

                .main_header{
                    font-size: 3.6rem !important;
                    line-height: 4.4rem !important;
                    color: #575756;
                    margin-bottom: 0;
                }

                .button{
                    width: 50%;
                }
            }
        }

    }
}

.about_company_nd{
    .main_block{
        padding: 0;
        margin-bottom: 100px;
        
        .post{
            .post-title{
                margin-top: 50px;
                margin-bottom: 50px;
                padding-left: 0;
    
                font-weight: 700;
                font-size: 2.1rem;
                line-height: 2.5rem;
            }
    
            .post-text{
                padding-left: 0;
                padding-right: 0;
    
                font-weight: 300;
                font-size: 1.4rem;
                line-height: 1.5rem;
    
                p{
                    margin-bottom: 0;
                }
            }
        }
    }


    .history_block{
        padding: 0;

        .heading{
            margin-bottom: 50px;

            .heading-section{
                padding-left: 0;
    
                font-weight: 700;
                font-size: 2.1rem;
                line-height: 2.5rem; 
            }
        }

        .tabs--buttons{
            .tabs-title{
                padding-left: 0;
                margin-bottom: 50px;

                .tabs-item{
                    width: 33.3%;
                }
            }

            .tabs-content{
                .tabs-item{
                    .single_history{
                        .history-item-header{
                            background-color: #F5F6FA;
                            padding-top: 17px;
                            padding-bottom: 17px;
                            margin-top: 20px;

                            font-weight: 700;
                            font-size: 1.8rem;
                            line-height: 2rem;

                            &::after{
                                right: 40px;
                            }
                        }

                        .history-item-body{
                            padding-top: 30px;

                            .history-col-description{
                                width: 70%;
                                max-width: 70%;
                                flex: 0 0 70%;

                                font-weight: 300;
                                font-size: 1.4rem;
                                line-height: 1.5rem;
                            }
                        }
                    }
                }
            }
        }
    }

    .home_excursion_block{
        .header{
            font-size: 2.1rem;
            line-height: 2.5rem;
        }
    }

    .about_company-video_block{
        margin-top: 100px;
        margin-bottom: 100px;
        
        .main_header{
            font-weight: 700;
            font-size: 2.1rem;
            line-height: 2.5rem;
        }

        .videos{
            display: flex;
            justify-content: space-between;

            .modal-iframe{
                width: 49%;
                padding-bottom: 350px;

                iframe{
                    width: 100%;
                    height: 350px;
                }
            }
        }
    }

    .about_company-info_block{
        margin-bottom: 100px;
        
        .main_header{
            font-weight: 700;
            font-size: 2.1rem;
            line-height: 2.5rem;
        }

        .info_cards{
            display: flex;
            justify-content: space-around;

            .info_card{
                width: 31%;
                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                border-radius: 3px;

                img{
                    width: 100%;
                }

                .card_header{
                    font-weight: 600;
                    font-size: 2rem;
                    line-height: 2.5rem;
                    text-align: center;
                    margin-top: 20px;
                    margin-bottom: 25px;
                }

                .card_description{
                    padding-left: 20px;
                    padding-right: 20px;

                    font-weight: 300;
                    font-size: 1.4rem;
                    line-height: 1.5rem;
                }

                .button_block{
                    padding-left: 20px;
                    padding-right: 20px;
                    margin-bottom: 50px;
                    
                    .button{
                        width: 100%;
                    }
                }
            }
        }
    }
}