




































































































































































































.nlk-form{
	.field-file{
		.field-file__btn{
			display: inline-block;

			&:hover{
				cursor: pointer;
			}
		}
	}
}
