.pagination {
	margin: 62px 0 0 -36px;
	justify-content: center;

	.list-item {
		margin-left: 36px;

		.list-link {
			background: $color-4;
			text-align: center;
			line-height: 48px;
			min-width: 48px;
			font-size: 1.4rem;
			font-weight: bold; }

		&.-prev,
		&.-next {
			.list-link {
				min-width: 109px;

				svg {
					width: 16px;
					height: 12px;
					fill: $baseColor; } } }

		&.-active,
		&:hover {
			.list-link {
				color: #fff;
				background: $color-2;

				svg {
					fill: #fff; } } } }

	@media (max-width: $mds) {
		margin-left: -16px;

		.list-item {
			margin-left: 16px; } }

	@media (max-width: $mmm) {
		display: none; } }
