/*Банер на главной под шапкой*/
@media (max-width: 1600px) {
	.slider--hero .swiper-slide {
		height: auto;
		background-size: 100%;
		background-position: top;
		padding-bottom: 30%;
	}
}

@media (max-width: 992px) {
	.slider--hero .swiper-slide {
		height: auto;
		background-size: 100%;
		background-position: top;
		padding-bottom: 30% !important;
	}
}

@media (max-width: 960px) {
	.main>.hero:first-child {
		margin-top: 0;
	}

	.slider--hero .swiper-slide {
		height: auto;
		background-size: 100%;
		background-position: bottom;
		padding-bottom: 30% !important;
	}

	.wrapper {
		padding-top: 46px
	}
}
/*Банер на главной под шапкой*/

/*Главная акции*/
@media (max-width: 992px) {
	.layout-events .list-item[data-v-2e6e5046] {
		max-width: 100% !important;
	}
}
/*Главная акции*/

/*Заголовки над картой*/
@media (max-width: 992px) {
	.map-filters-list .list-item {
		font-size: 1.6rem;
	}
}
/*Заголовки над картой*/

/*Продуктовый слайдер картинки*/
@media (max-width: 992px) {
	.section--products .product-header {
		margin-bottom: 20px;
	}

	.section--products .product-image {
		height: auto;
		min-height: auto;
	}
}
/*Продуктовый слайдер картинки*/

/* Слайдер на странице акции*/
@media (max-width: 1600px) {

	.slider--hero-offers .swiper-slide {
		height: auto;
		min-height: auto;
		background-repeat: no-repeat;
		background-size: 100%;
		background-position: top;
		padding: 3% 0 21%;
	}

	.slider--hero-offers .swiper-slide .slider-title {
		margin-top: 0
	}
}

@media (max-width: 960px) {
	.slider--hero-offers .swiper-slide {
		padding: 3% 0 18%;
	}
}

@media (max-width: 960px) {
	.slider--hero-offers .swiper-slide {
		padding: 3% 0 18%;
	}
}

@media (max-width: 560px) {
	.slider--hero-offers .swiper-slide {
		padding: 0% 0 18%;
	}

	.slider--hero-offers .slider-description {
		margin-top: 0;
		font-size: 14px
	}
}
/* Слайдер на странице акции*/

/*Скаты в кровле*/
@media (max-width: 960px) {
	.craf-grid .block_skat {
		padding: 1.5rem;
	}

	.craf-grid .block_skat .cl-row-stapper {
		flex-direction: column;
		align-items: flex-start;
		gap: 2rem;
		margin: 0;
		margin-top: -1rem;
	}

	.craf-grid .skat_left_block {
		width: 100% !important
	}
}
/*Скаты в кровле*/