// .layout-iterators
// 	margin-left: 20%

// 	.list-item
// 		+width(25%)

.layout-iterators {
	@include grid(4, 0, 0);

	@media (max-width: $mtm) {
		@include grid(3, 0, 0); }

	@media (max-width: $mmm) {
		@include list-scroll(); } }

.section--iterators {
	overflow: hidden; }

// .slider--iterators
// 	margin-left: 20%
// 	overflow: visible

// 	.swiper-slide
// 		height: auto

.iterator {
	display: block;
	position: relative;
	height: 100%;
	padding: 20px 0 40px 0;

	&::before,
	&::after {
		@include content();
		width: 45px;
		height: 45px;
		transition: opacity .25s ease;
		opacity: 0; }

	&::before {
		border-top: 1px solid $color-2;
		border-left: 1px solid $color-2;
		top: 0;
		left: -45px; }

	&::after {
		border-right: 1px solid $color-2;
		border-bottom: 1px solid $color-2;
		bottom: 0;
		right: 45px; }

	&-content {
		padding-right: 31px; }

	&-number {
		font-size: 27.3rem;
		line-height: 1;
		font-weight: bold;
		color: $color-4;
		margin-left: -31px;
		margin-bottom: -0.4em;
		transition: color .25s ease; }

	&-title {
		font-size: 2.3rem;
		font-weight: bold;
		margin-bottom: 16px; }

	&:hover {
		&::before,
		&::after {
			opacity: 1; }

		.iterator-number {
			color: rgba($color-2, .2); } }

	@media (max-width: $mdm) {
		&-title {
			font-size: 1.8rem; } }

	@media (max-width: $mtm) {
		&-number {
			margin-left: 0; } }

	@media (max-width: $mmm) {
		br {
			display: none; } } }
