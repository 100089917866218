.state {
	&-item {
		display: none; }

	&:not(.state--done) {
		.state-item {
			&:first-child {
				display: block; } } }

	&.state--done {
		.state-item {
			&:last-child {
				display: block; } } } }
