































































































.admin-calculators-content{
    .buttons{
     
        .btn-default{
            background: #efefef;
            color: black;
            font-size: 1.4rem;
            width: 25rem;

            &:hover{
                background: #ee3b2b;
                color: #fff;
            }
        }

        .-active{
            background: #ee3b2b;
            color: #fff;
        }
    }
    .blocks{
        margin-top: 4rem;

        .block-step{
            margin-bottom: 3rem;
        }
    }
}
.one_tr_name_block{
    position: relative;

    .one_tr_icon_delete{
        position: absolute;
        color: red;
        right: 15px;
        cursor: pointer;
    }
}
.block_length_and_width{
    display: flex;
}
