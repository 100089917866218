.tabs {
	&-title {
		@include flex();

		.tabs-item {
			cursor: pointer;

			&.-active {} } }

	&-content {
		.tabs-item {
			display: none;

			&.-active {
				display: block;
				animation: tabsIn 1s forwards; } } }

	&.tabs--buttons {
		.tabs-title {
			margin-bottom: 32px;

			.tabs-item {
				text-decoration: none;
				padding: 0 25px;
				border: 0;
				border-radius: 0;
				text-align: center;
				transition: color .25s, background-color .25s, opacity .25s, border-color .25s;
				font-size: 1.4rem;
				font-weight: bold;
				height: 48px;
				line-height: 48px;
				background: $color-4;
				color: $color-7;
				margin-right: 12px;

				&:hover {
					background: $color-2;
					color: #fff; }

				&.-active {
					background: $color-2;
					color: #fff; } } } } }

@keyframes tabsIn {
	0% {
		opacity: 0; }
	100% {
		opacity: 1; } }
