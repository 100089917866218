.container-nd{
    padding-left: 13.25rem !important;
    padding-right: 13.25rem !important;
}

@media (min-width: 1600px) and (max-width: 1699px) {
    .container-nd{
        padding-left: 22.25rem !important;
        padding-right: 22.25rem !important;
    }
    .nd-about-company_page{
        .breadcrumbs{
            .container{
                padding-left: 0 !important;
            }
        }
    }
}

@media (min-width: 1700px) and (max-width: 1900px) {
    .container-nd{
        padding-left: 28.25rem !important;
        padding-right: 28.25rem !important;
    }
    .nd-about-company_page{
        .breadcrumbs{
            .container{
                padding-left: 0 !important;
            }
        }
    }
}

@media (min-width: 1900px) {
    .container-nd{
        padding-left: 36.95rem !important;
        padding-right: 36.95rem !important;
    }
    .nd-about-company_page{
        .breadcrumbs{
            .container{
                padding-left: 0 !important;
            }
        }
    }
}

@media (min-width: 1600px) and (max-width: 1699px) {
    .cardProduct_page{
        .container-nd{
            padding-left: 9.25rem !important;
            padding-right: 22.25rem !important;
        }
    }
    .nd-about-company_page{
        .breadcrumbs{
            .container{
                padding-left: 0 !important;
            }
        }
    }
}

@media (min-width: 1600px) and (max-width: 1699px) {
    .coatings-page{
        .breadcrumbs{
            .container-nd{
                padding-left: 9.25rem !important;
                padding-right: 22.25rem !important;
            }
        }
        .container-nd{
            padding-left: 9.25rem !important;
            padding-right: 0 !important;
        }
    }

    .nd-about-company_page{
        .breadcrumbs{
            .container-nd{
                padding-left: 9.25rem !important;
                padding-right: 22.25rem !important;
            }

            .container{
                padding-left: 0 !important;
            }
        }
        .container-nd{
            padding-left: 9.25rem !important;
            padding-right: 0 !important;
        }
    }
}


@media (max-width: 1600px) {
    .header-nd{
        padding-left: 8.25rem !important;
        padding-right: 8.25rem !important;
    }

    .header-bottom-nd{
        .container-nd{
            padding-left: 8.25rem !important;
            padding-right: 8.25rem !important;
        }

        .header-phone{
            .phone{
                font-size: 1.5rem;
            }
        }
    }
    .nd-about-company_page{
        .breadcrumbs{
            .container{
                padding-left: 0 !important;
            }
        }
    }
}

.header-nd{
    display: flex;
    // justify-content: space-between;
    align-items: center;

    padding-left: 9.25rem;

    .right-part{
        display: flex;

        width: 309px;

        padding-top: 20px;
        padding-bottom: 18px;

        margin-right: 5px;

        align-items: center;

        .auth_user{
            display: flex;
            flex-direction: column;
        }

        p{
            font-weight: 600;
            margin-bottom: 0;
            line-height: 18.29px;
            font-size: 15px;
        }

        .login_link{
            &:hover{
                cursor: pointer;
                color: #ED1C24;
            }
        }

        .nd-icon{
            height: 35px;
            width: 35px;

            margin-right: 20px;
            cursor: pointer;
        }

        .full_basket_icon{
            width: 39px;
        }
    }
}

.list-nd{
    display: flex;
    align-items: flex-start;
    line-height: 19.5px;

    // width: 47.5%;

    .list-item-nd{
        // padding-right: 20px;
        padding-right: 12px;
        padding-top: 28px;
    }

    .list-link{
        padding: 0;
    }
}

.header-bottom-nd{
    // padding-top: 1.4rem !important;
    // padding-bottom: 1.9rem !important;
    padding: 7px !important;

    .header-logotype{
        text-align: start;
    }

    .container-nd{
        padding-left: 9.25rem;
    }
}

.hero-nd{
    .container{
        max-width: 1920px !important;
    }

    @media (min-width: 1600px) {
        .slider--hero{
            .swiper-slide{
                height: 470px;
            }
        } 
    }

    @media (min-width: 1800px) {
        .slider--hero{
            .swiper-slide{
                height: 530px;
            }
        } 
    }

    @media (min-width: 1950px) {
        .slider--hero{
            .swiper-slide{
                height: 580px;
            }
        } 
    }

    @media (min-width: 2200px) {
        .slider--hero{
            .swiper-slide{
                height: 745px;
            }
        } 
    }

    .hero-message{
        background: rgba(#fff, 0.65);

        // max-width: 400px;
        // width: 400px;

        width: 100%;
        max-height: 300px;

        // height: 300px;

        // top: 95px !important;

        padding: 2.5rem;

        right: 5px;
    }

    .message-title{
        font-weight: 900 !important;
        color: #ED1C24;
        line-height: 31.69px !important;
        font-size: 26px !important;
        margin-bottom: 2.3rem !important;
    }

    .message-description{
        font-weight: 700;
        font-size: 20px;
        line-height: 24.38px;

        padding-right: 25px;

        color: #000000;
        margin-bottom: 2.3rem !important;

        text-align: center;
        width: 326px !important;
        margin-left: auto !important;
        margin-right: auto !important;

        .ql-align-center{
            margin: 0 !important;
        }
    }

    .message-button{
        width: 80% !important;

        font-size: 20px !important;
        line-height: 24.38px !important;

        padding: 12px 15.49px !important;
    }
}

.nd-cards{
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    .nd-card{
        width: 23.75%;
        height: 100%;
        margin-right: 1%;
        // margin-bottom: 28px;

        .card{
            border-radius: 3px;
            padding-bottom: 38%;
            margin: 0 !important;
            margin-bottom: 21.52px !important;

            .bg1{
                padding-top: 20.5px;
                padding-left: 26.5px;
                padding-right: 26.5px;
            }

            .card-heading{
                left: 0;
                width: 100%;
                bottom: 30px;

                .card-title{
                    font-weight: 600;
                    font-size: 20px;
                    line-height: 24.38px;
                    text-align: center;
                    padding: 0 28px;
                    min-height: 40px !important;
                }
            }

            .card-arrow{
                top: 10px !important;
                right: 10px !important;

                svg{
                    height: 23px;
                    width: 23px;
                }
            }
        }
    }
}
.calculator-page-nd{
    .calculator-nd{
        display: flex;
        width: 100%;
    
        .left_part{
            width: 57.6%;
            position: relative;
            
            .main_img{
                max-height: 406px;
                height: 406px;
            }

            img{
                width: 100%;
                height: 100%;
            }

            .icon_card{
                background-color: #fff;
                border-radius: 10px;
                padding-left: 30px;
                padding-top: 12.5px;
                padding-right: 45px;
                height: 49px;
                
                p{
                    font-size: 16px;
                    line-height: 19.5px;
                    font-weight: 300;
                }
            }

            .block_for_first{
                display: flex;
                position: absolute;
                top: 6rem;
                right: 10rem;
                align-items: center;

                img{
                    width: 67px;
                    height: 69px;
                    cursor: pointer;
                    z-index: 100;
                }  

                .icon_card{
                    margin-right: -4.5rem;
                }
            }

            .block_for_two{
                display: flex;
                position: absolute;
                top: 7rem;
                left: 10rem;
                align-items: center;

                img{
                    width: 67px;
                    height: 69px;
                    cursor: pointer;
                    z-index: 100;
                } 

                .icon_card{
                    margin-left: -4.5rem;
                    padding-left: 5rem;
                }
            }

            .block_for_three{
                display: flex;
                position: absolute;
                bottom: 7rem;
                left: 40%;
                align-items: center;

                img{
                    width: 67px;
                    height: 69px;
                    cursor: pointer;
                    z-index: 100;
                } 

                .icon_card{
                    margin-left: -4.5rem;
                    padding-left: 5rem;
                }
            }
        }
    
        .right_part{
            width: 42.4%;
            padding-left: 25px;
    
            .main_header{
                font-weight: 700;
                font-size: 20px;
                line-height: 24.38px;
                margin-bottom: 2rem;
                margin-top: 5rem;
            }
    
            .main_description{
                font-weight: 300;
                font-size: 12px;
                line-height: 13px;
                margin-bottom: 3rem;
            }
    
            .sub_header{
                font-weight: 700;
                font-size: 20px;
                line-height: 24.38px;
                margin-bottom: 2rem;
            }
    
            .sub_description{
                font-weight: 300;
                font-size: 12px;
                line-height: 13px;
                margin-bottom: 5rem;
            }

            .button{
                width: 281px;
                // margin-bottom: 50px;

                font-size: 20px;
                line-height: 24.38px;
            }
        }
    }
}

.form_callback_nd{
    color: #FFFFFF;
    padding: 0;

    .bg_pd{
        background-color: #333333;
        padding-top: 60px;
        padding-bottom: 60px;
    }

    .header{
        font-weight: 700;
        font-size: 32px;
        line-height: 39.01px;

        padding-top: 30px;
    }

    .subheader{
        font-weight: 300;
        font-size: 19px;
        line-height: 23px;
    }

    .field-label{
        color: #D9D9D9;
    }

    .field-input{
        input{
            border-radius: 5px;
            color: #D9D9D9;
            border-color: #D9D9D9;

            &:hover{
                background-color: #858181;
            }
        }
    }

    .form_block{
        display: flex;
        
        // .col{
        //     width: 50%;
        //     max-width: 50%;
        // }

        .left_part{
            width: 42.8%;
        }

        .right_part{
            width: 57.2%;
        }

        .form-group-nd{
            display: flex;
            margin-bottom: 44px;

            .form-item-nd{
                width: 50%;
            }

            .form-item-nd_left{
                margin-right: 36px;
            }
        }

        .form-item-nd{
            .button--primary{
                border: 1px solid #ED1C24;

                &:hover{
                    border: 1px solid #fc4a51;
                }
            }

            button{
                width: 100%;

                font-size: 20px;
                line-height: 24.38px;
                font-size: 700;

                &:disabled{
                    border: 1px solid #fff;
                }
            }
        }
    }
}

.about_company_page-nd{
    padding: 100px 0;

    .company-nd{
        display: flex;
    }

    .left_part{
        width: 42.2%;
        padding-right: 20px;

        .header{
            font-weight: 700;
            font-size: 24px;
            line-height: 29.26px;
            margin-top: 50px;
            margin-bottom: 40px;
        }

        .subheader{
            font-weight: 300;
            font-size: 14px;
            line-height: 22px;

            max-height: 114px;
            margin-bottom: 31px;

            p{
                margin: 0;
            }
            
            // white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        button{
            margin-bottom: 50px;
            width: 284.58px;

            padding: 12px 13.29px;

            font-size: 20px;
            line-height: 24.38px;
            font-weight: 700;
        }
    }

    .right_part{
        width: 57.8%;

        img{
            width: 100%;
            height: 100%;
        }
    }
}



.home_build_block{
    padding-bottom: 100px;
    padding-top: 0;

    .header{
        font-weight: 700;
        font-size: 20px;
        line-height: 24.38px;
        margin-bottom: 38px;
    }

    .build_cards{
        display: flex;
        justify-content: space-between;

        .build_card{
            width: 32.3%;
            padding-bottom: 100px;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
            display: flex;
            flex-wrap: wrap;
            text-align: center;
            flex-direction: column;
            position: relative;
            
            img{
                margin: auto;
                margin-top: 55px;
                margin-bottom: 54px;
                width: 180px;
                height: 171px;
            }

            p{
                text-align: center;
            }

            .card_header{
                font-weight: 700;
                font-size: 20px;
                line-height: 24.38px;
                margin-bottom: 29px;
                padding: 0 30px;
            }

            .card_description{
                font-weight: 300;
                font-size: 12px;
                line-height: 13px;
                margin-bottom: 54px;
                padding: 0 30px;
            }

            .button{
                width: 340px;

                background-color: #BE1622;
                color: #FFFFFF;

                position: absolute;
                bottom: 20px;
                left: 6%;

                font-size: 18px;
                line-height: 21.94px;
                font-weight: 700;
            }
        }
    }
}



.home_objects_block{
    padding-top: 0px;
    padding-bottom: 100px;

    .header{
        font-weight: 700;
        font-size: 20px;
        line-height: 24.38px;
        margin-bottom: 28px;
    }

    .objects_imgs{
        display: flex;
        flex-wrap: wrap;

        img{
            width: 33.3%;
        }
    }

    .button{
        background-color: #333333;
        color: #FFFFFF;
        width: 100%;

        padding: 7px !important;
        font-size: 20px;
        line-height: 24.38px;
        font-weight: 700;
    }
}

.home_brands_block{
    padding-top: 0px;

    h2{
        font-size: 20px !important;
        line-height: 24.38px !important;
        font-weight: 700 !important;
    }
}

.home_excursion_block{
    padding: 100px 0;

    .header{
        font-weight: 700;
        font-size: 20px;
        line-height: 24.38px;
        margin-bottom: 31px;
    }

    img{
        cursor: pointer;
    }
}

.footer-nd{
    background: unset;
    background-color: #353B48;
    color: #FFFFFF;

    @media (min-width: 1600px) and (max-width: 1900px) {
        .pdl-40{
            padding-left: 0 !important;
        }
    }

    @media (max-width: 1600px) {
        .pdl-40{
            padding-left: 40px !important;
        }
    }

    .list-item{
        margin-top: 0 !important;
    }

    .logotype{
        margin-top: 30px;
        width: 65%;

        img{
            max-width: 100%;
            cursor: pointer;
        }
    }

    .footer_contacts{
        font-weight: 500;
        font-size: 1.3rem;
        line-height: 2.4rem;
        padding-bottom: 20px;

        display: flex;
        flex-direction: column;

        a{
            text-decoration: none;
            border-bottom: unset;
        }

        p{
            margin-bottom: 0;
        }

        .contact_header{
            margin-bottom: 20px;
            font-weight: 500;
            font-size: 13px;
            line-height: 24px;
        }

        .contact_email_header{
            font-weight: 600;
            font-size: 13px;
            line-height: 16px;
            margin-bottom: 9px;
            margin-top: 10px;
        }

        .footer_phone_link{
            font-weight: 700;
            font-size: 24px;
            line-height: 33px;
        }

        .open_region_row{
            position: relative;
            display: flex;
            flex-wrap: nowrap;
            background-color: #fff;
            border-radius: 10px;
            align-items: center;
            color: #353B48;
            width: fit-content;
            padding: 4px 12px;
            margin-bottom: 8px;

            font-weight: 600;
            font-size: 14px;
            line-height: 21px;

            &:hover{
                cursor: pointer;
            }

            .point_icon{
                width: 24px;
                height: 24px;
                margin-right: 4px;
            }

            .arrow_icon{
                width: 16px;
                height: 16px;
                margin-left: 10px;
            }

            .regions_list_block{
                padding: 22px;

                .regions_header{
                    margin-bottom: 10px;
                    font-size: 18px;
                    line-height: 24px;
                }
            }
        }
    }

    .footer_block-nd{
        .label{
            font-weight: 600;
            font-size: 1.6rem;
            line-height: 2.4rem;
            color: #fff;

            margin-bottom: 20px;
        }

        .list-link{
            font-weight: 500;
            font-size: 1.3rem;
            line-height: 2.4rem;
            // color: #fff;
        }

        .col{
            max-width: 33.3%;
            flex: 0 0 33.3%;
            padding-left: 0;
            padding-right: 20px;

            padding-bottom: 30px !important;
            padding-top: 0px !important;
        }

        .two_cols{
            display: flex;
            flex-wrap: wrap;
        }

        .subcol{
            width: 50%;
            padding-right: 20px;
        }

        .left_part_footer{
            .button{
                background-color: inherit;
                border: 1px solid #fff;
                width: 80%;
                margin-bottom: 25px;
            }

            img{
                width: 55px;
                height: 55px;

                cursor: pointer;
            }

            .footer-city{
                margin-bottom: 25px;

                .is-open{
                    .choices__list--single{
                        &:after{
                            transform: rotate(180deg);
                        }
                    } 
                }

                .choices__list--single{
                    font-weight: 700;
                    font-size: 20px;
                    line-height: 25px;
                    text-align: center;

                    border: 1px solid #FFFFFF !important;

                    width: 25rem;
                    padding-top: 10px;
                    padding-bottom: 10px;

                    .choices__item{
                        border: unset;
                        color: #fff;
                        line-height: 25px;
                        height: unset;
                    }

                    &:after{
                        content: '';
                        display: block;
                        position: absolute;
                        position: absolute;
                        right: 14px;
                        top: 50%;
                        margin-top: -3.5px;
                        width: 11px;
                        height: 7px;
                        background: url('~@/img/select-basic.svg') 0 0/100% 100% no-repeat;
                    }
                }

                .choices__list--dropdown{
                    background: #fff;
                    color: #353B48;
                    
                }
            }
        }
    }

    .footer-bottom-nd{
        border: unset;
        
        .label{
            color: #fff;
            font-weight: 300;
            font-size: 1.1rem;
        }

        .container-l{
            .row{
                border-top: 3px solid #fff;

                .col{
                    padding-left: 0;
                }
            }
        }
    }
}


.home_promo_block{
    padding-bottom: 100px;
    padding-top: 0;

    img{
        width: 100%;
        // height: 212px;
    }

    .button{
        width: 100%;
        padding: 7px !important;

        font-size: 12px;
        line-height: 14.63px;
        font-weight: 600;
    }
}

.home_cards_block{
    padding-bottom: 100px;
}

.home_popular_product_block{
    padding: 0;
    margin-bottom: 100px;

    .heading-section{
        h2{
            font-size: 20px !important;
            line-height: 24.38px !important;
            font-weight: 700 !important;
        }
    }

    .popular_swiper{
        position: relative;

        .swiper-button-prev{
            position: absolute;
            z-index: 1;
            opacity: 0.5;
            transition: opacity 0.15s ease;
            width: 3%;
            top: 0;
            bottom: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            margin: 0;
            height: auto;
            transform: rotate(0deg);

            background: #fff;
            opacity: 0.85;
        }

        .swiper-button-next{
            position: absolute;
            z-index: 1;
            opacity: 0.5;
            transition: opacity 0.15s ease;
            width: 3%;
            top: 0;
            bottom: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            margin: 0;
            height: auto;
            transform: rotate(0deg);

            background: #fff;
            opacity: 0.85;
        }

        .swiper-slide{
            display: flex;
            width: 25% !important;
            height: auto;
            margin-right: 15.66px;

            .section--products--item{
                min-width: 100%;
                width: 100%;

                .product{
                    height: 100%;
                    position: relative;
                    padding-bottom: 120px;

                    .product-footer{
                        position: absolute;
                        bottom: 30px;
                        width: 80%;
                    }
                }
            }
        }
    }
}

.list-item-breadcrumbs_nd{
    &:before{
        content: '';
        margin: 0 21px;
        width: 7px;
        height: 14px;
        background: url('~@/img/breadcrumbs-arrow.svg') 0 0 no-repeat;
        display: inline-block;
        vertical-align: middle;
    }
}


.catalog-nd{
    .card{
        padding-bottom: 92%;
    
        .card-heading{
            top: 5px;
        
            .card-title{
                padding-right: 15px;
            }

            .card-icon{
                svg{
                    width: 120px;
                    height: 120px;

                    transform: scale(0.5);
                }
            }
        }
    }
}