
















































































































































.document-act-modal{
    .modal-container{
        padding: 24px !important;
        max-width: 395px !important;
        border-radius: 5px;

        .header_modal{
            color: black;
            font-weight: 700;
            font-size: 22px;
            line-height: 33px;
            margin-bottom: 13px;
        }

        .message_modal{
            color: black;
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            margin-bottom: 13px;
        }

        .custom_select_modal{
            margin-bottom: 13px;

            label{
                color: black;
                font-weight: 400;
                font-size: 14px;
                line-height: 21px;
                margin-bottom: 13px;
            }

            .custom_select{
                position: relative;

                .select_button{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    border: 1px solid #D6D6D6;
                    border-radius: 10px;
                    color: #718093;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 22px;
                    padding: 11px 10px;
                    
                    &:hover{
                        cursor: pointer;
                    }

                    p{
                        margin-bottom: 0;
                    }
                }

                .select_list{
                    position: absolute;
                    border: 1px solid #0000001A;
                    border-radius: 5px;
                    padding-top: 10px;
                    background-color: #fff;
                    width: 100%;
                    max-height: 200px;
                    overflow-x: auto;

                    .select_once{
                        margin-bottom: 10px;
                        color: black;
                        font-weight: 400;
                        font-size: 17px;
                        line-height: 22px;
                        padding: 10px;

                        &:hover{
                            background-color: #F5F6FA;
                            cursor: pointer;
                        }
                    }
                }
            }
        }
        
        .button{
            color: #fff;
            font-weight: 700;
            font-size: 16px;
            line-height: 27px;
            padding: 10px 32px;
            border-radius: 10px;
            float: right;
        }
    }
}
