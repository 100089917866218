.background-grid_no-before::before, .background-grid_no-after::after {
	display: none;
}
.list-item_mt-auto {
	margin-top: auto;
}

.section-nlk {
	font-family: Montserrat,serif;
}

.nlk-header {
	display: flex;
	border-radius: 4px;
	background: #F5F6FA;
	border: 1px solid #E5ECF5;
	font-size: 1.4rem;
	white-space: nowrap;
	margin-bottom: 1.7rem;
}

.nlk-header__item {
	padding-top: 1.4rem;
	padding: 1.4rem 1rem 1.9rem 3.8rem;
	font-weight: 700;
	line-height: 1.25;
	flex: 1 0 0px
}

.nlk-header__item span {
	font-weight: 500;
	color: #8D97A3;
	display: block;
	margin-bottom: 1.1rem;
}

.nlk-header__name {
	display: flex;
	align-items: center;
	width: 100%;
}

.nlk-header__ava {
	width: 3.4rem;
	height: 3.4rem;
	margin-right: 1.7rem;
	overflow: hidden;
	border-radius: 50%;
}

.nlk-header__ava img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.section-nlk__grid {
	display: flex;
}

.nlk-aside {
	background: #F5F6FA;
	box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.05);
	border-radius: 4px;
	width: 34rem;
	flex-shrink: 0;
	padding: 1.5rem 0 3.8rem;
	margin-right: 1.6rem;
}

.nlk-aside-list {
	background: transparent;
	border-radius: 0;
	box-shadow: none;
}

.nlk-aside-list > li.list-item > a, .nlk-aside-list > li.list-item > a.list-link {
	display: block;
	text-decoration: none;
	border: none;
	padding: 1.2rem 3.1rem;
	font-size: 1.6rem;
	font-weight: 700;
}

.nlk-aside-list > li.list-item > a:hover, 
.nlk-aside-list > li.list-item > a.list-link:hover, 
.nlk-aside-list > li.list-item.active > a, 
.nlk-aside-list > li.list-item.active > a.list-link {
	color: #EA292E;
}

.nlk-aside-list > li.list-item > .sub {
	background: #fff;
	font-size: 1.5rem;
	padding: 1.6rem 0;
}

.nlk-aside-list > li.list-item_last {
	margin-top: 2.4rem;
}

.nlk-manager {
	background: #727F90;
	border-radius: 4px;
	padding: 1.9rem 2.1rem;
	min-height: 14.6rem;
	font-size: 1.1rem;
	color: #fff;
	display: flex;
}

.nlk-manager__ava {
	width: 5.6rem;
	height: 5.6rem;
	border-radius: 50%;
	margin-right: 2.2rem;
	flex-shrink: 0;
	overflow: hidden;
}

.nlk-manager__ava img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.nlk-manager b {
	font-size: 1.2rem;
	font-weight: 700;
	display: block;
	margin-bottom: 1.5rem;
}

.nlk-manager_m-40-35-0 {
	margin: 4rem 3.5rem 0;
}

.nlk-manager__email {
	margin-top: 1.5rem;
	display: block;
	font-weight: 700;
	color: #343A46;
}

.nlk-content {
	flex-grow: 1;
	padding-top: 1.2rem;
}

.nlk-content__title {
	font-size: 1.6rem;
	font-weight: 700;
	text-transform: uppercase;
	margin-bottom: 4rem;
}

.nlk-content__title.mb-30 {
	margin-bottom: 3rem;
}

.nlk-content__title.mb-50 {
	margin-bottom: 5rem;
}

.nlk-content__title.mb-80 {
	margin-bottom: 8rem;
}

.nlk-content-company {
	margin-left: -1rem;
	margin-right: -1rem;
	margin-bottom: -2rem;
	display: flex;
	flex-wrap: wrap;
}

.nlk-content-company_mb-120 {
	margin-bottom: 12rem;
}

.nlk-content-company__item {
	margin: 0 1rem 2rem;
	width: calc(33.333% - 2rem);
}

.nlk-content-company__label {
	background: #F5F6FA;
	font-size: 1.4rem;
	color: #9BA4AF;
	padding: 1rem 2.7rem;
}

.nlk-content-company__item p {
	font-weight: 700;
	margin: 0;
	padding: 1rem 2.7rem 0.3rem;
}

.nlk-h2 {
	font-size: 1.4rem;
	font-weight: 700;
	margin-bottom: 1rem;
}

.nlk-h2_mb-10 {
	margin-bottom: 1rem;
}

.nlk-table {
	font-size: 1.4rem;
	line-height: 1.2;
}

.nlk-table td {
	border: 1px solid #F4F3F8;
	height: 45px;
}

.nlk-table th {
	color: #8D97A3;
	text-align: center;
	padding-bottom: 1rem;
}

.nlk-table_w-80p {
	width: 100%;
	max-width: 80%;
}

.nlk-table_w-100 {
	width: 100%;
}

.nlk-table_tac {
	text-align: center;
}

.nlk-table_fs-12 {
	font-size: 1.2rem;
}

.nlk-table_border-collapse {
	border-collapse: initial;
	border-spacing: 0 1rem;
}

.nlk-table_border-collapse th {
	padding-bottom: 0rem;
}

.nlk-table_border-collapse td:not(:last-child) {
	border-right: none;
}

.nlk-table_p-5 td {
	padding: 0.5rem;
}

.nlk-table_p-7 td {
	padding: 0.7rem;
}

.nlk-table_p-13 td {
	padding: 1.3rem;
}

.nlk-table_th-fz-14 th {
	font-size: 1.4rem;
	font-weight: 500;
}

.nlk-form {
	display: flex;
	flex-direction: column;
}

.nlk-form_w-960 {
	max-width: 960px;
}

.nlk-form .button--primary {
	margin-right: auto;
	margin-top: 2rem;
}

.nlk-form .checkbox-plus {
	margin-top: 2rem;
}

.nlk-form__col-3 {
	display: flex;
	flex-wrap: wrap;
	margin-left: -1.2rem;
	margin-right: -1.2rem;
}

.nlk-form__col-3 > * {
	width: calc(33.333% - 2.4rem);
	margin: 0 1.2rem 2.6rem;
}

.nlk-form__col-2 {
	display: flex;
	flex-wrap: wrap;
	margin-left: -0.9rem;
	margin-right: -0.9rem;
}

.nlk-form__col-2 > * {
	width: calc(50% - 1.8rem);
	margin: 0 0.9rem 2.6rem;
}

.nlk-form .field-input input {
	padding: 16px 17px
}

.nlk-form .field-input textarea {
	padding: 16px 17px;
	min-height: 135px;
}

.field-file {
	margin-top: 3rem;
}

.field-file__item {
	display: none;
}

.field-file__btn {
	border: none;
	background: #F5F6FA;
	font-size: 1.6rem;
	font-weight: 700;
	padding: 1.2rem 1.5rem;
	line-height: 1.3;
}

.field-file__btn:hover {
	opacity: 0.8;
}

.nlk-content-claim {
	display: flex;
}

.nlk-content-claim .nlk-form {
	width: 62.6rem;
	margin-right: 6rem;
}

.nlk-content-claim__text {
	color: #727F92;
	font-size: 1.4rem;
	line-height: 1.3;
}

.nlk-content-claim__text .nlk-h2 {
	margin-bottom: 2rem;
}

.nlk-content-claim__text ol > li:not(:last-child) {
	margin-bottom: 2rem;
}

.nlk-content-claim__text ul {
	list-style: none;
	padding: 0;
	margin: 0;
	margin-top: 1rem;
}

.nlk-content-claim__text ul li {
	padding: 0;
}

.nlk-content-claim__text ul li::before {
	display: none;
}

.nlk-content-claim__text ul li:not(:last-child) {
	margin-bottom: 0.5rem;
}

.mb-120 {
	margin-bottom: 12rem;
}

.nlk-filter {
	display: flex;
	margin-bottom: 5rem;
}

.nlk-filter > * {
	width: auto;
}

.nlk-filter > *:not(:last-child) {
	margin-right: 3rem;
}

.nlk-filter .flex {
	display: flex;
	flex-direction: column;
}

.nlk-filter .flex_g-1 {
	flex-grow: 1;
}

.nlk-filter .field_mr-15 {
	margin-right: 1.5rem;
}

.field_data {
	width: 30.6rem;
}

.nlk-filter .field-label {
	font-size: 1.4rem;
	font-weight: 700;
	color: #343A46;
}

.field_data .field-input {
	position: relative;
}

.field_data .field-input::before {
	content: '';
	display: block;
	height: 55px;
	width: 1px;
	position: absolute;
	z-index: 1;
	top: 0;
	right: 55px;
	background: #718093;
}

.nlk-filter input {
	padding: 16px 17px;
}

.field_data input {
	background-color: #fff;
	padding: 7px 10px 8px 17px;
	height: 55px;
}
.field_data input::-webkit-calendar-picker-indicator {
	width: 36px;
	height: 38px;
	background-image: url(../img/data.svg);
	background-size: contain;
	padding: 0;
}

.nlk-table_pl-35 tr td:first-child:not(:last-child) {
	padding-left: 3.5rem;
}

.filter-btn {
	border: none;
	font-size: 1.4rem;
	font-weight: 700;
	padding: 1.6rem 4.3rem;
	background: #F5F6FA;
	margin-top: auto;
	max-width: 17rem;
	transition: 0.2s;
}

.filter-btn:hover {
	color: #fff;
	background: #ed1c24;
}

.nlk-filter-info {
	margin-left: auto;
	display: flex;
	align-items: flex-end;
}

.nlk-filter-info__item {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	font-size: 1.6rem;
	font-weight: 700;
}

.nlk-filter-info__item span {
	font-size: 1.4rem;
	color: #8D97A3;
	margin-bottom: 2.7rem;
}

.nlk-filter-info__item:first-child {
	padding-right: 3.3rem;
}

.nlk-filter-info__item:last-child {
	border-left: 0.3rem solid #343A46;
	padding-left: 4.8rem;
}

.field_flex-bottom {
	display: flex;
	align-items: flex-end;
}

.nlk-field-select select {
	width: 100%;
	padding: 16px 17px;
	font-size: 1.4rem;
	color: #353b48;
	border: 1px solid #718093;
	background: transparent;
	font-weight: 700;
	border-radius: 0;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	font-weight: 400;
	min-width: 16.9rem;
	background-image: url(../img/select-basic.svg);
	background-repeat: no-repeat;
	background-size: 1.1rem;
	background-position: calc(100% - 1.7rem) 50%;
}

.nlk-field-select select:hover {
	background-color: #f5f6fa;
}

.w-169 {
	width: 16.9rem;
}

.nlk-accordion {
	margin-bottom: 1.5rem;
}

.nlk-order-header {
	display: block;
	width: 100%;
	border: 0.1rem solid #343A46;
	padding: 0 5.6rem 0 0.9rem;
	position: relative;
}

.nlk-order-header__top {
	display: flex;
	border-bottom: 0.1rem solid #737E92;
	font-size: 1.4rem;
	font-weight: 700;
	text-align: center;
	padding: 1.4rem 6rem 0.7rem 3rem;
	line-height: 1.2;
}

.nlk-order-header__top > *:nth-child(1) {
	margin-right: 6rem;
}

.nlk-order-header__top > *:nth-child(2) {
	margin-right: 12rem;
}

.nlk-order-header__top > *:nth-child(3) {
	margin-right: 13.5rem;
}

.nlk-order-header__top > *:nth-child(4) {
	margin-right: 7.5rem;
}

.nlk-order-header__top > *:nth-child(6) {
	margin-left: auto;
}

.nlk-order-header__top small {
	font-weight: 400;
	display: block;
	font-size: 1.4rem;
}

.nlk-order-header__bottom {
	display: flex;
	justify-content: space-between;
	font-size: 1.2rem;
	line-height: 1.2;
	font-weight: 700;
	padding: 1.1rem 6rem 2.3rem 3rem;
}

.nlk-accordion-btn {
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	width: 5.6rem;
	height: 100%;
	position: absolute;
	top: 0;
	right: 0;
	z-index: 2;
	background: transparent;
	padding: 0;
	border: none;
}

.nlk-accordion-btn img {
	transition: 0.3s;
	width: 1.7rem;
}

.nlk-accordion-item.-active img {
	transform: rotate(-180deg);
}

.nlk-accordion-body {
	display: none;
}

.nlk-accordion-body.-active {
	display: flex;
	width: 100%;
}

.color-green {
	color: #4C9F4A;
}

.border-green {
	border-color: #4C9F4A;
}


.nlk-accordion-body {
	background: #F5F6FA;
	padding: 2.3rem 1.6rem 3rem 3.9rem;
	font-size: 1.4rem;
	line-height: 1.2;
}

.nlk-status {
	flex-shrink: 0;
	margin-right: 2.5rem;
	color: #8D97A3;
}

.nlk-status__title {
	margin-bottom: 2.5rem;
}

.nlk-status__list {
	list-style: none;
	margin: 0;
	padding: 0;
}

.nlk-status__list li {
	margin-bottom: 0.5rem;
}

.nlk-status__list a {
	text-decoration: none;
	border: none;
}

.nlk-status__list .active a {
	font-weight: 700;
	color: #353B48;
}

.nlk-accordion-table {
	flex-grow: 1;
}

.nlk-accordion-table table {
	width: 100%;
	font-size: 1.2rem;
	line-height: 1.2;
	text-align: center;
}

.nlk-accordion-table th {
	font-size: 1.2rem;
	font-weight: 400;
	color: #8D97A3;
	text-align: center;
	padding-bottom: 1rem;
}

.nlk-accordion-table td {
	padding: 0.3rem 0.9rem;
	border: 0.1rem solid #B9BEC8;
	height: 3.1rem;
}

.ml-auto {
	margin-left: auto;
}

.color-gray {
	color: #8D97A3;
}

.border-red {
	border-color: #EA292E;
}

.color-red {
	color: #EA292E;
}

.border-orange {
	border-color: #FA832E;
}

.color-orange {
	color: #FA832E;
}

.border-purple {
	border-color: #872EFA;
}

.color-purple {
	color: #872EFA;
}

@media only screen and (max-width: 1600px) {
	.nlk-aside {
		width: 25%;
		flex: 1 0 25%
	}

	.nlk-content {
		width: calc(75% - 1.6rem);
		flex: 1 0 calc(75% - 1.6rem);
	}

	.nlk-order-header__top, .nlk-order-header__bottom {
		padding-left: 2rem;
		padding-right: 2rem;
		font-size: 1rem;
	}

	.nlk-order-header__top > *:nth-child(1) {
		margin-right: 4rem;
	}

	.nlk-order-header__top > *:nth-child(2) {
		margin-right: 8rem;
	}

	.nlk-order-header__top > *:nth-child(3) {
		margin-right: 10rem;
	}

	.nlk-order-header__top > *:nth-child(4) {
		margin-right: 5rem;
	}

	.nlk-table_th-fz-14 th {
		font-size: 1.2rem;
	}

	.nlk-order-header__top small {
		font-size: 1rem;
	}

	.nlk-accordion-body {
		padding-left: 1.6rem;
	}

	.nlk-horizontal-scroll {
		overflow-x: auto;
		max-width: 100%;
	}

	.nlk-horizontal-scroll >* {
		min-width: 900px;
	}

	.nlk-accordion-table table {
		font-size: 1rem;
	}

	.nlk-content-company__item {
		margin: 0 1rem 2rem;
		width: calc(50% - 2rem);
	}

	.nlk-form_w-960 {
		max-width: 100%;
	}

	.nlk-content-claim .nlk-form {
		width: 50%;
		margin-right: 1.5rem;
	}

	.nlk-form__col-2 > * {
		width: calc(100% - 1.8rem);
	}

	.nlk-table {
		font-size: 1.2rem;
	}

	.nlk-filter input {
		height: 5.5rem;
	}

	.nlk-filter .filter-btn {
		height: 5.5rem;
	}

	.nlk-filter-info__item {
		font-size: 1.4rem;
		flex-shrink: 0;
	}

	.nlk-filter-info__item span {
		font-size: 1.2rem;
		margin-bottom: 1rem;
	}

	.nlk-filter-info__item:first-child {
		padding-right: 1rem;
	}

	.nlk-filter-info__item:last-child {
		padding-left: 1rem;
	}

	.nlk-aside-list > li.list-item > a, .nlk-aside-list > li.list-item > a.list-link {
		padding-left: 1rem;
		padding-right: 1rem;
		font-size: 1.4rem;
	}

	.nlk-manager_m-40-35-0 {
		margin-left: 0.5rem;
		margin-right: 0.5rem;
	}

}

@media only screen and (max-width: 1200px) {
	.nlk-header__item {
		padding-left: 1rem;
	}

	.nlk-content__title.mb-50 {
		margin-bottom: 2rem;
	}

	.nlk-filter {
		margin: 0 -1rem 3rem -1rem;
		flex-wrap: wrap;
	}

	.nlk-filter > * {
		flex: 1 0 calc(50% - 2rem);
		margin-left: 1rem !important;
		margin-right: 1rem !important;
		margin-bottom: 1rem;
	}

	.nlk-filter > * > * {
		width: 100%;
	}

	.nlk-manager_m-40-35-0 {
		padding: 1rem;
		min-height: 10rem;
	}

	.nlk-manager__ava {
		width: 3rem;
		height: 3rem;
		margin-right: 1rem;
	}

	.nlk-manager b {
		margin-bottom: 0.5rem;
	}

	.nlk-content-company__item {
		font-size: 1.4rem;
	}

	.nlk-content-company_mb-120 {
		margin-bottom: 5rem;
	}

	.nlk-content-claim {
		display: block;
		padding-bottom: 3rem;
	}

	.nlk-content-claim .nlk-form {
		width: 100%;
		margin-right: 0;
		margin-bottom: 3rem;
	}

	.nlk-content__title.mb-80 {
		margin-bottom: 5rem;
	}

	.mb-120 {
		margin-bottom: 6rem;
	}

	.nlk-table_p-7 td {
		padding: 0.5rem;
	}

	.nlk-filter-info__item {
		width: auto;
	}
}

@media only screen and (max-width: 992px) {
	.nlk-header__item {
		font-size: 1.2rem;
	}

	.section-nlk__grid {
		/* flex-direction: column-reverse; */
		flex-direction: column;
	}

	.nlk-aside, .nlk-content {
		width: 100%;
		flex: 1 0 100%;
		margin-left: 0;
		margin-right: 0;
	}

	.nlk-aside-list {
		display: block;
	}
	
	.nlk-aside-list > li.list-item > a, .nlk-aside-list > li.list-item > a.list-link {
		padding: 0.2rem 0.5rem;
		font-size: 1.2rem;
	}

	.nlk-aside-list > li.list-item > .sub {
		padding: 0.5rem 0;
	}

	.nlk-aside-list.menu-list .sub>.list-item>.list-link {
		padding: 0 0.5rem;
		font-size: 1.2rem;
	}

	.nlk-aside-list.menu-list .sub>.list-item+.list-item {
		margin-top: 0.5rem;
	}

	.nlk-aside-list > li.list-item_last {
		margin-top: 0rem;
	}

	.nlk-manager_m-40-35-0 {
		min-height: 5rem;
		margin-top: 2rem;
	}
	
	.nlk-manager__email {
		margin-top: 0.5rem;
	}

	.nlk-aside {
		padding-bottom: 1rem;
	}

	.nlk-form__col-3 > * {
		width: calc(100% - 2.4rem);
	}

	.nlk-order-header {
		padding-left: 4rem;
		padding-right: 0.5rem;
	}

	.nlk-accordion-btn {
		width: 4rem;
		right: initial;
		left: 0;
	}
}

@media only screen and (max-width: 767px) {
	.nlk-header {
		flex-direction: column;
	}

	.nlk-header__item {
		padding-bottom: 1rem;
		padding-top: 1rem;
	}

	.nlk-header__item span {
		margin-bottom: 0.3rem;
	}

	.nlk-accordion-body {
		font-size: 1.2rem;
	}

	.nlk-status {
		margin-right: 1.5rem;
	}

	.nlk-table_mobil-none {
		display: none;
	}

	.nlk-order-header__top {
		justify-content: space-between;
	}

	.nlk-order-header__top > * {
		margin-right: initial !important;
		padding-left: 0.5rem;
		padding-right: 0.5rem;
	}

	.nlk-order-header__top, .nlk-order-header__bottom {
		padding-left: 0;
		padding-right: 0;
	}

	.nlk-order-header__top > *:nth-child(6) {
		margin-left: initial;
	}

	.nlk-content-company__item {
		width: calc(100% - 2rem);
	}
}

@media only screen and (max-width: 576px) {
	.nlk-filter > * {
		flex: 1 0 calc(100% - 2rem);
	}
}

/* Calculator */
.wrapper-no-hidden {
	overflow: initial;
}
.sticky-grid {
	padding: 0 0 10rem;
	display: flex;
}

.sticky-grid__content {
	flex-grow: 1;
}

.sticky-grid__aside {
	width: 24.5%;
	margin-left: 32px;
	flex-shrink: 0;
}

.sticky-box {
	position: sticky;
	top: 0;
	width: 100%;
}

.cal-seo {
	font-size: 1.6rem;
	line-height: 1.35;
}

.cal-seo__h3 {
	font-size: 1.8rem;
	font-weight: 700;
	margin-bottom: 3rem;
}

.sticky-box {
	background: #fff;
	box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.05);
	border-radius: 4px;
	padding: 3rem 4rem;
	font-size: 14px;
}

.sticky-box__title {
	font-size: 22px;
	font-weight: 700;
	margin-bottom: 2.5rem;
}

.sticky-box__list {
	border-bottom: 1px solid #F5F6FA;
	margin: 0;
	padding: 0;
	margin-bottom: 1.5rem;
	padding-bottom: 2rem;
}

.sticky-box__list li {
	display: flex;
	justify-content: space-between;
	margin-bottom: 1.3rem;
}

.sticky-box__list li:last-child {
	margin-bottom: 0;
}

.sticky-box__bottom {
	display: flex;
	align-items: baseline;
	justify-content: space-between;
	margin-bottom: 4.5rem;
}

.sticky-box__bottom b {
	font-size: 22px;
	font-weight: 700;
}

.sticky-box .button {
	display: block;
	margin: 0 auto 5rem;
}

.sticky-box__dow {
	border-bottom: 1px solid #F5F6FA;
}

.sticky-box__dow, .sticky-box__link {
	display: flex;
	align-items: center;
	padding: 1.6rem 1.5rem;
}

.sticky-box__ic {
	flex-shrink: 0;
	width: 24px;
	height: 24px;
	margin-right: 3rem;
	display: flex;
	align-items: center;
	justify-content: center;
}

.cl-breadcrumbs {
	margin-bottom: 6rem;
}

.cl__h2-title {
	display: flex;
	align-items: center;
	margin-bottom: 4rem;
	font-weight: 700;
	font-size: 18px;
	line-height: 1.2;
}

.cl__h2-title_mb-80 {
	margin-bottom: 8rem;
}

.cl__h2-title span {
	color: #EA292E;
	border-left: 5px solid #EA292E;
	padding-left: 0.9rem;
	padding-right: 0.9rem;
	min-width: 5rem;
}

.cl-top-option {
	display: flex;
}

@media only screen and (min-width: 993px) {
	.cl-top-option {
		margin-left: -3.5rem;
		margin-right: -3.5rem;
	}
}

.cl-icon-radio {
	display: flex;
	flex-direction: column;
	align-items: center;
	flex: 1 0 0px;
	cursor: pointer;
}

.cl-icon-radio:not(:last-child) {
	margin-right: 1.1rem;
}

.cl-icon-radio input {
	display: none;
}

.cl-icon-radio__btn {
	margin-top: auto;
	width: 100%;
	height: 80px;
	font-size: 14px;
	font-weight: 700;
	border: none;
	background: #F5F6FA;
	transition: 0.2s;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
}

.cl-icon-radio__btn:hover {
	background: #EA292E;
	color: #fff;
}

.cl-icon-radio__icon {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 16rem;
	height: 16rem;
	margin-bottom: 4rem;
}

.cl-icon-radio__icon img {
	/* position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	opacity: 1; */
}

.cl-icon-radio__icon .cl-icon-radio__img-2 {
	display: none;
}

.cl-icon-radio input:checked ~ .cl-icon-radio__btn {
	background: #EA292E;
	color: #fff;
}

.cl-icon-radio input:checked ~ .cl-icon-radio__icon .cl-icon-radio__img-1 {
	display: none;
}

.cl-icon-radio input:checked ~ .cl-icon-radio__icon .cl-icon-radio__img-2 {
	display: block;
}

.mb-70 {
	margin-bottom: 7rem;
}

.cl-hr {
	margin: 5.5rem 0;
	width: 100%;
	height: 1px;
	background: #F5F6FA;
}

@media only screen and (min-width: 993px) {
	.cl-hr {
		margin-left: -3.5rem;
		margin-right: -3.5rem;
	}

	.cl-prod-item {
		margin-left: -3.5rem;
	}
}

.cl-row {
	display: flex;
}

.cl-row-group_mr-23:not(:last-child) {
	margin-right: 2.3rem !important;
}

.cl-row-group_mr-90:not(:last-child) {
	margin-right: 9rem;
}

.cl-row-btns {
	display: flex;
}

.cl-rowbtn-radio {
	display: block;
}

.cl-rowbtn-radio:not(:last-child) {
	margin-right: 1.1rem;
}

.cl-rowbtn-radio input {
	display: none;
}

.cl-rowbtn-radio > span {
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	font-size: 14px;
	font-weight: 700;
	background: #F5F6FA;
	height: 5.2rem;
	min-width: 14.4rem;
	transition: 0.2s;
	padding-left: 3rem;
	padding-right: 3rem;
}

.cl-rowbtn-radio input:checked + span {
	color: #fff;
	background: #EA292E;
}

.mb-50 {
	margin-bottom: 5rem !important;
}

.mb-30 {
	margin-bottom: 3rem;
}

.cl-grid {
	display: flex;
	flex-wrap: wrap;
	padding-bottom: 5rem;
}

.cl-grid p {
	font-size: 14px;
	line-height: 1.3;
	margin-bottom: 6rem;
}

.cl-grid__left {
	max-width: 56rem;
	margin-right: auto;
}

.cl-input-group {
	display: flex;
	width: 100%;
	max-width: 37rem;
	position: relative;
}

.cl-input-group input {
	width: 100%;
	height: 5.5rem;
	padding: 2.5rem 1rem 1rem 6.2rem;
	font-size: 14px;
}

.cl-input-group span {
	position: absolute;
	width: 3.7rem;
	height: 3.7rem;
	top: 50%;
	left: 0.6rem;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #F5F6FA;
	transition: 0.2s;
	transform: translateY(-50%);
	font-size: 14px;
	font-weight: 700;
}

.cl-input-group span::before {
	content: attr(data-title);
	font-weight: 400;
	color: #727F92;
	position: absolute;
	white-space: pre;
	left: 100%;
	margin-left: 1.9rem;
	transition: 0.2s;
}

.cl-input-group input:focus + span,
/* .cl-input-group input:valid + span, */
.cl-input-group input.valid + span {
	background: #EA292E;
	color: #fff;
}

.cl-input-group input:focus + span::before,
.cl-input-group input:valid + span::before,
.cl-input-group input.valid + span::before {
	font-size: 10px;
	transform: translateY(-1.3rem);
}

.cl-rowbtn-radio-big > span {
	height: 6.4rem;
	width: 22rem;
	text-align: center;
	line-height: 1.2;
	position: relative;
}

.cl-rowbtn-radio-big .cl-bal {
	top: 0.5rem;
	right: 0.4rem;
	
}

.cl-rowbtn-radio-big .cl-bal__icon {
	border: 1px solid #CBCEDB;
	color: #CBCEDB;
	background: transparent;
}

.cl-bal {
	position: absolute;
	font-weight: 400;
	font-size: 14px;
	width: 2rem;
	height: 2rem;
}

.cl-bal__icon {
	width: 2rem;
	height: 2rem;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #fff;
	background: #EA292E;
}

.cl-bal__text {
	background: #fff;
	padding: 1.5rem;
	border-radius: 2px;
	z-index: 2;
	position: absolute;
	top: 100%;
	left: -1rem;
	color: #000;
	font-size: 10px;
	margin-top: 1rem;
	width: 23rem;
	filter: drop-shadow(0px 0px 1px #D0D3DF);
	text-align: left;
	opacity: 0;
	visibility: collapse;
	transition: 0.2s;
}

.cl-bal__text::before {
	content: '';
	display: block;
	width: 0.6rem;
	height: 0.6rem;
	border-top: 0.6rem solid #fff;
	border-right: 0.6rem solid transparent;
	transform: rotate(45deg);
	position: absolute;
	bottom: 100%;
	left: 1.7rem;
	margin-bottom: -0.3rem;
	color: #727F92;
}

.cl-bal__title {
	font-weight: 700;
}

.cl-bal:hover .cl-bal__text {
	opacity: 1;
	visibility: visible;
}

.w-205 {
	width: 205px;
}

.mt-80 {
	margin-top: 8rem;
}

.cl-color-grid {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	gap: 3.3rem
}

@media only screen and (max-width: 992px) {
	.cl-color-grid {
		grid-template-columns: repeat(3, 1fr);
	}
}

@media only screen and (max-width: 768px) {
	.cl-color-grid {
		grid-template-columns: repeat(2, 1fr);
	}
}

.cl-check {
	display: flex;
}

.cl-check input {
	display: none;
}

.cl-check > span {
	display: flex;
	align-items: center;
	font-size: 1.6rem;
	line-height: 1.3;
}

.cl-check > span::before {
	content: '';
	display: block;
	width: 2.4rem;
	height: 2.4rem;
	border: 1px solid #727F92;
	margin-right: 1.5rem;
	flex-shrink: 0;
	background: #fff;
	transition: 0.2s;
}

.cl-check input:checked + span::before {
	background: #EA292E;
	box-shadow: inset 0px 0px 0px 4px #fff;
}

.cl-check .color {
	width: 7.9rem;
	height: 4.2rem;
	margin-right: 2.5rem;
}

.cl-prod-item {
	background: #fff;
	box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.05);
	border-radius: 4px;
	margin-bottom: 3rem;
}

.cl-prod-item-row {
	display: flex;
	align-items: center;
	position: relative;
}

.cl-prod-item-row:not(:last-child) {
	border-bottom: 1px solid #F5F6FA;
}

.cl-prod-item-row.p-30 {
	padding: 3rem;
}

.cl-prod-item-row.pt-57 {
	padding-top: 5.7rem;
}

.btn-close {
	border: none;
	background: url(../img/close.svg);
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center;
	width: 1.7rem;
	height: 1.7rem;
}

.cl-prod-item__img {
	width: 9rem;
	margin-right: 3rem;
}

.cl-prod-item__text {
	margin-right: auto;
	padding-right: 2rem;
	line-height: 1.3;
}

.cl-prod-item__text a {
	font-size: 14px;
}

.cl-prod-item__title {
	font-size: 16px;
	font-weight: 700;
	margin-bottom: 1.6rem;
}

.cl-prod-item__last {
	display: flex;
	align-items: center;
	padding-right: 4.7rem;
	position: relative;
}

.cl-prod-item__price {
	font-size: 22px;
	font-weight: 700;
}

.cl-prod-item__last .btn-close {
	position: absolute;
	right: 0;
	top: 50%;
	transform: translateY(-50%);
}

.cl-prod-item-row_bottom {
	display: flex;
	font-size: 16px;
	padding: 3.6rem 7.4rem 4rem 4rem;
}

.flex_jcsb {
	justify-content: space-between;
}

.cl-prod-item__all {
	display: flex;
	align-items: baseline;
}

.cl-prod-item__lab {
	margin-right: 2rem;
}

.mt-65 {
	margin-top: 6.5rem;
}

.cl-wrapper {
	background: #FFFFFF;
	box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.05);
	border-radius: 4px;
	margin-bottom: 6rem;
	padding-bottom: 1px;
}

.cl-wrapper__text {
	padding: 2.6rem 4rem 4rem;
	line-height: 16px;
	line-height: 1.3;
}

.cl-wrapper__gray {
	background: #F5F6FA;
	padding: 1.4rem 4rem;
	display: flex;
	align-items: center;
	flex-wrap: wrap;
}

.cl-wrapper__gray > *:not(:last-child) {
	margin-right: 2rem;
}

.cl-check_gray > span::before{
	background: #F5F6FA;
}

.cl-check_gray input:checked + span::before{
	box-shadow: inset 0px 0px 0px 4px #F5F6FA;
}

.cl-wrapper__row {
	padding: 3rem 4rem;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	font-size: 14px;
}

.cl-wrapper__row .stepper {
	margin: 0 2rem;
}

.stepper {
	display: flex;
	width: 18.6rem;
	height: 5.5rem;
}

.stepper-btn {
	flex-shrink: 0;
	width: 5.5rem;
	height: 5.5rem;
	background: #F5F6FA;
	border: none;
	display: flex;
	align-items: center;
	justify-content: center;
}

.stepper-input {
	width: 7.6rem;
	height: 5.5rem;
	border: 1px solid #727F92;
	font-size: 14px;
	text-align: center;
}

.legenda {
	display: flex;
	align-items: center;
}

.legenda span {
	width: 3.6rem;
	height: 3.6rem;
	flex-shrink: 0;
	margin-right: 2.3rem;
}

.cl-wrapper-conf {
	margin: 3rem;
	max-width: 44.8rem;
	display: flex;
	padding-top: 3rem;
	position: relative;
}

.cl-wrapper-conf__first, .cl-wrapper-conf__last {
	width: 0.8rem;
	height: 0.9rem;
	background: #EA292E;
}

.cl-wrapper-conf__first {
	margin-right: 0.2rem;
}

.cl-wrapper-conf__last {
	margin-left: 0.2rem;
}

.cl-wrapper-conf__item {
	width: 41.8rem;
	height: 0.9rem;
	background: #EA292E;
	position: relative;
}

.cl-wrapper-conf__item span {
	position: absolute;
	font-size: 14px;
	bottom: 100%;
	margin-bottom: 1.2rem;
}

.cl-wrapper-conf__i {
	position: absolute;
	bottom: 0;
	height: 2.1rem;
	width: 0.4rem;
	background: #fff;
}

.cl-wrapper-conf__i::before {
	content: '';
	width: 1.1rem;
	height: 0.9rem;
	background: #EA292E;
	position: absolute;
	top: 0;
	left: 50%;
	transform: translateX(-50%);
}

.cl-rowbtn-radio_medium > span{
	height: 6rem;
	width: 20.6rem;
}

.mb-20 {
	margin-bottom: 2rem;
}

.cl-input-group_no input{
	padding-left: 1.5rem;
}

.cl-input-group_no span {
	font-size: 0;
	width: 0;
	background: transparent;
	left: 1.5rem;
}

.cl-input-group_no span::before {
	font-size: 14px;
	left: 0;
	margin: 0;
}

.mb-80 {
	margin-bottom: 8rem;
}

.mb-110 {
	margin-bottom: 11rem;
}

.pt-160 {
	padding-top: 16rem;
}

.cl-wrapper-check {
	padding-left: 4.8rem;
}

.cl-check > span {
	position: relative;
	padding-right: 4rem;
}

.cl-check  .cl-bal {
	right: 0;
}

.cl-car {
	display: flex;
	max-width: 100%;
	position: relative;
}

.cl-car__btn {
	width: 3rem;
	height: 3rem;
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
}

.cl-car-prev {
	left: -5rem;
}

.cl-car-next {
	right: -5rem;
}

.cl-car__wrapper {
	display: flex;
	max-width: 100%;
}

.cl-car-item {
	width: 30.2rem;
	height: 28rem;
	background: #FFFFFF;
	box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.05);
	padding: 1.5rem 1.5rem 2rem;
	display: flex;
	flex-direction: column;
	font-size: 16px;
	text-align: center;
}

.cl-car-item__img {
	margin-bottom: 2rem;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-grow: 1;
}

.cl-car-item:not(:last-child) {
	margin-right: 2.5rem;
}

.mb-60 {
	margin-bottom: 6rem;
}

.cl-prod-item-row_full {
	padding: 4rem;
}

.cl-prod-item-row_full .cl-prod-item__img {
	width: 20rem;
}

.cl-prod-item-row_full .cl-prod-item__last {
	padding: 0;
}

.ml-180 {
	margin-left: 18rem;
}

.cl-prod-item__list {
	display: flex;
	margin: 0;
	padding: 0;
	flex-wrap: wrap;
	list-style: none;
	font-size: 14px;
}

.cl-prod-item__list li:not(:last-child) {
	padding-right: 1.5rem;
	margin-right: 1.5rem;
	border-right: 1px solid #000;
}

.cl-prod-item-row.p-32 {
	padding-top: 3rem;
	padding-bottom: 3rem;
}

.cl-prod-item__price.fs-18 {
	font-size: 1.8rem;
}

.cl-prod-item-row_full.p-30-16 {
	padding: 3rem 3rem 3rem 1.6rem;
}

.cl-prod-item-row_full .cl-prod-item__img {
	width: 21rem;
}

.mr-70 {
	margin-right: 7rem;
}

.cl-prod-item-row_full .cl-prod-item__last.pr-40 {
	padding-right: 4rem;
}

.mt-120 {
	margin-top: 12rem;
}

.cl-row-flex {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
}

.cl-row-flex .cl__h2-title {
	margin: 0;
	margin-right: 10rem;
}

.cl-row-stapper {
	display: flex;
	align-items: center;
}

.cl-row-stapper > span {
	margin-right: 1.6rem;
	font-weight: 700;
	font-size: 14px;
}

.w-180 {
	width: 18rem;
}

.mt-0 {
	margin-top: 0;
}

.cl-img-radio:not(:last-child) {
	margin-right: 1rem;
}

.cl-img-radio input {
	display: none;
}

.cl-img-radio > span {
	background: #fff;
	border: 1px solid transparent;
	display: flex;
	width: 46rem;
	height: 26.4rem;
	display: flex;
	flex-direction: column;
	text-align: center;
	font-size: 14px;
	font-weight: 700;
	padding: 1.5rem;
	transition: 0.2s;
	cursor: pointer;
	position: relative;
}

.cl-img-radio > span::before {
	content: '';
	width: 2.4rem;
	height: 1.8rem;
	display: block;
	position: absolute;
	top: 2rem;
	left: 1.5rem;
	background: url(../img/check.svg) no-repeat center;
	background-size: contain;
	transition: 0.2s;
	opacity: 0;
}

.cl-img-radio__img {
	flex-grow: 1;
	width: 100%;
	margin-top: 1.5rem;
	margin-bottom: 3rem;
	display: flex;
	align-items: center;
	justify-content: center;
}

.cl-img-radio input:checked + span, .cl-img-radio > span:hover {
	border-color: #EA292E;
	color: #EA292E;
}

.cl-img-radio input:checked + span::before {
	opacity: 1;
}

.pt-50 {
	padding-top: 5rem;
}

.fs-14 {
	font-size: 1.4rem;
}

.max-1180 {
	max-width: 1180px;
}

.cl-wrapper-check_gap-60 .cl-check:not(:last-child) {
	margin-right: 2rem;
}

.craf-grid {
	display: flex;
	flex-wrap: wrap;
}

.craf-grid__left {
	flex-shrink: 0;
	width: 60rem;
	padding-right: 15rem;
}

.craf-wrapper {
	width: 77rem;
	height: 36.4rem;
	background: #F5F6FA;
}

.fs-22 {
	font-size: 22px;
}

.mt-70 {
	margin-top: 7rem;
}

@media only screen and (min-width: 993px) {
	.cl-prod-item__img.ml-30 {
		margin-left: 3rem;
	}

	.cl-prod-item__img.mr-150 {
		margin-right: 15rem;
	}

	.cl-prod-item__img.mr-85 {
		margin-right: 8.5rem;
	}

	.cl-prod-item__lab {
		margin-right: 13rem;
	}

	.cl-wrapper__gray > *:not(:last-child) {
		margin-right: 11.5rem;
	}

	.cl-wrapper-conf {
		margin: 4.6rem 3rem 8.8rem 8.3rem;
	}

	.cl-wrapper__row .stepper {
		margin: 0 11.5rem 0 2rem;
	}

	.cl-wrapper-check_gap-60 .cl-check:not(:last-child) {
		margin-right: 6rem;
	}
}

.cl-car__wrapper {
	flex-wrap: wrap;
}

.cl-grid {
	max-width: 100%;
}

.cl-grid >* {
	min-width: 0;
}

@media only screen and (max-width: 1700px) {
	.sticky-box {
		padding: 3rem 1.5rem;
	}

	.sticky-grid__aside {
		width: 30%;
		margin-left: 1.5rem;
	}

	.cl-wrapper__gray > *:not(:last-child) {
		margin-right: 2rem;
	}

	.cl-wrapper__row .stepper {
		margin-right: 3rem;
	}
}

@media only screen and (max-width: 1600px) {
	.cl-top-option {
		margin-left: 0;
		margin-right: 0;
	}

	.cl-icon-radio {
		max-width: 300px;
		min-width: initial;
	}

	.cl-grid {
		flex-wrap: nowrap;
	}

	.cl-prod-item__img.mr-150 {
		margin-left: 0;
		margin-right: 2rem;
	}

	.cl-prod-item {
		margin-left: 0;
	}

	.cl-wrapper__text, .cl-wrapper__row {
		padding-left: 2rem;
		padding-right: 2rem;
	}

	.cl-wrapper-conf {
		margin-left: 2rem;
		margin-bottom: 5rem;
		margin-top: 2rem;
	}

	.cl-prod-item-row {
		min-width: 500px;
	}

	.cl-prod-item-row.p-30, .cl-prod-item-row_bottom {
		padding-left: 2rem;
		padding-right: 2rem;
	}

	.cl-prod-item__price {
		font-size: 18px;
		white-space: pre;
	}

	.cl-prod-item__lab {
		margin-right: 2rem;
	}

	.cl-grid__left {
		padding-right: 2rem;
	}

	.cl-prod-item-row_full {
		padding: 3rem 2rem;
	}

	.cl-prod-item__img.mr-85 {
		margin-right: 2rem;
	}

	.cl-prod-item-row_full .cl-prod-item__img {
		width: 15rem;
		margin-right: 2rem;
	}

	.ml-180 {
		margin-left: 2rem;
	}

	.sticky-box__bottom b {
		font-size: 18px;
	}

	.cl-row-flex .cl__h2-title, .cl-row-group_mr-90:not(:last-child) {
		margin-right: 2rem;
	}

	.cl-wrapper-check_gap-60 .cl-check:not(:last-child) {
		margin-right: 2rem;
	}

	.craf-grid__left {
		padding-right: 0rem;
		padding-bottom: 4rem;
	}

	.craf-wrapper {
		max-width: 100%;
	}
}

@media only screen and (max-width: 1400px) {
	.cl-wrapper__gray, .cl-wrapper__row {
		flex-direction: column;
		align-items: flex-start;
	}

	.cl-wrapper__gray > *, .cl-wrapper__row > * {
		margin-right: 0;
		margin-bottom: 1.5rem;
	}

	.cl-wrapper__gray > *:last-child,
	.cl-wrapper__row > *:last-child {
		margin-bottom: 0;
	}

	.cl-wrapper__row .stepper {
		margin: 0 0 1.5rem;
	}

	.cl-wrapper__gray {
		padding-left: 2rem;
		padding-right: 2rem;
	}

	.cl-hr {
		margin-left: 0;
		margin-right: 0;
	}
	
}

@media only screen and (max-width: 1300px) {
	.cl-icon-radio__btn {
		height: 6rem;
	}

	.cl-color-grid .cl-check > span {
		padding-right: 0;
	}

	.cl-check .color {
		width: 3rem;
		height: 3rem;
	}

	.cl-bal__text {
		left: 50%;
		transform: translateX(-50%);
	}

	.cl-bal__text::before {
		left: 50%;
		transform: translateX(-50%) rotate(45deg);
	}

	.cl-prod-item-row_bottom, .cl-prod-item__title {
		font-size: 14px;
	}

	.cl-prod-item__price.fs-18, .cl-prod-item__price {
		font-size: 16px;
	}

	.sticky-box__dow, .sticky-box__link {
		padding-left: 0;
		padding-right: 0;
	}

	.cl-row {
		flex-wrap: wrap;
	}

	.cl-row.mobil-colum {
		flex-direction: column;
	}

	.cl-row.mobil-colum > * {
		margin: 0;
	}

	.cl-row.mobil-colum .cl-row-group, .cl-row.mobil-colum .cl-row-stapper {
		flex-direction: column;
		align-items: flex-start;
	}

	.cl-row.mobil-colum .cl-row-group > *,
	.cl-row.mobil-colum .cl-row-stapper > * {
		margin-bottom: 2rem;
	}

	.cl-row.mobil-colum .cl-row-stapper > *:last-child {
		margin-bottom: 0;
	}

	.cl-hr {
		margin-top: 3rem;
		margin-bottom: 3rem;
	}

	.cl-img-radio > span {
		width: calc(46rem / 1.5 );
    height: calc(26.4rem / 1.5 );
	}

	.cl-img-radio__img {
		margin-top: 0;
		margin-bottom: 1rem; 	
	}
}
@media only screen and (max-width: 992px) {
	.cl-grid {
		display: block;
		padding-bottom: 2rem;
	}

	.cl-grid__left {
		padding-right: 0;
		padding-bottom: 4rem;
	}

	.cl-row {
		flex-direction: column;
		align-items: flex-start;
	}

	.cl-row > * {
		margin-bottom: 1.5rem;
		margin-right: 0;
	}

	.cl-row > *:last-child {
		margin-bottom: 0;
	}

	.cl-prod-item-row.pt-57 {
		padding-top: 3rem;
	}

	.cl-prod-item-row_bottom {
		padding-top: 2rem;
		padding-bottom: 2rem;
	}

	.sticky-grid {
		flex-direction: column-reverse;
	}

	.sticky-grid__aside {
		width: 100%;
		margin: 0 0 4rem;
	}

	.cl-icon-radio {
		min-width: 20rem;
	}

	.cl-icon-radio__icon {
		width: 10rem;
		height: 10rem;
		margin-bottom: 2rem;
	}

	.cl-icon-radio__btn {
		height: 5rem;
		margin-bottom: 1rem;
	}

	.cl-row-btns {
		margin-bottom: 2rem;
	}

	.cl-bal__text {
		left: 0;
		transform: translateX(-80%)
	}

	.cl-bal__text::before {
		left: 84%;
		transform: translateX(-50%) rotate(45deg);
	}

	.mb-110, .mb-80, .cl__h2-title_mb-80, .mb-70 {
		margin-bottom: 4rem;
	}

	.pt-160, .pt-50 {
		padding-top: 0;
	}

	.mt-120, .mt-80 {
		margin-top: 4rem;
	}

	.craf-wrapper, .craf-grid__right {
		width: 100%;
	}

	.cl-prod-item-row.p-32 {
		padding-top: 2rem;
		padding-bottom: 2rem;
	}
}
@media only screen and (max-width: 767px) {
	.cl-prod-item .w-205 {
		width: auto;
	}

	.cl-prod-item-row {
		min-width: 60rem;
	}

	.cl-prod-item__list li:not(:last-child) {
		border: none;
		padding-right: 0;
	}

	.cal-seo {
		font-size: 14px;
	}

	.craf-grid__left {
		width: 100%;
	}

	.cl-color-grid {
		gap: 1.5rem
	}

	.cl-check .color {
		margin-right: 1rem;
	}

	.craf-wrapper {
		height: 33vh;
	}
}

@media only screen and (max-width: 576px) {

}

/* Calculator */