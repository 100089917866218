























































































































































































































































































































































































































































































































































































































































































.custom_textarea{
    width: 100%;
}

.single_vacancy_admin_block{
    .button{
        padding: 11px;
        font-size: 15px;
        margin-right: 20px;
    }
}

.header_create_block{
    font-weight: 600;
    font-size: 20px !important;
    line-height: 25px !important;
    margin-left: 0 !important;
}

.progress_list_block{
    .progress_elements{
        display: flex;
        gap: 20px;

        .progress_elem{
            position: relative;
            border: 1px solid black;
            border-radius: 10px;
            padding: 20px;
            width: 23%;

            .progress_elem_name{
                margin-left: 0;
                margin-bottom: 10px;
                font-size: 15px;
            }
            .progress_elem_description{
                margin-left: 0;
                margin-bottom: 10px;
                font-size: 15px;
            }
            // .progress_elem_image{

            // }

            i{
                position: absolute;
                top: 10px;
                right: 10px;
                color: #ED1C24;
                cursor: pointer;
            }
        }
    }
}

.advantages_list_block{
    display: flex;
    gap: 20px;
    flex-wrap: wrap;

    .single_advantage{
        position: relative;
        border: 1px solid black;
        border-radius: 10px;
        padding: 20px;
        width: 23%;
        margin-bottom: 20px;

        .single_advantage_name{
            margin-left: 0;
            margin-bottom: 10px;
            font-size: 15px;
        }

        i{
            position: absolute;
            top: 10px;
            right: 10px;
            color: #ED1C24;
            cursor: pointer;
        }
    }
}

.question_list_block{
    display: flex;
    flex-wrap: wrap;
    gap: 20px;

    .single_question_block{
        position: relative;
        border: 1px solid black;
        border-radius: 10px;
        padding: 20px;
        width: 23%;
        margin-bottom: 20px;

        .question_block_question{
            margin-left: 0;
            margin-bottom: 10px;
            font-size: 17px;
        }

        .question_block_answer{
            margin-left: 0;
            margin-bottom: 10px;
            font-size: 15px;
            color: grey;
        }

        i{
            position: absolute;
            top: 10px;
            right: 10px;
            color: #ED1C24;
            cursor: pointer;
        }
    }
}

.interview_list_block{
    display: flex;
    flex-wrap: wrap;
    gap: 20px;

    .single_interview{
        position: relative;
        border: 1px solid black;
        border-radius: 10px;
        padding: 20px;
        width: 23%;
        margin-bottom: 20px;

        .single_interview_name{
            margin-left: 0;
            margin-bottom: 10px;
            font-size: 17px;
            text-align: center;
        }

        .single_interview_post{
            margin-left: 0;
            margin-bottom: 10px;
            font-size: 15px;
            text-align: center;
        }

        i{
            position: absolute;
            top: 10px;
            right: 10px;
            color: #ED1C24;
            cursor: pointer;
        }
    }
}
