/* NAVIGATION
 * -------------------------------------------------- */

.navigation-cabinet {

	&-title {
		display: none; }

	&-list {
		background: #fff;
		border-radius: 4px;
		box-shadow: 0 3px 45px rgba(#AAAAAA, .16);
		overflow: hidden;
		padding: 9px 0;
		font-size: 1.6rem;

		.list-item + .list-item {
			@include lt(); }

		.list-item {
			&.-active,
			&:hover {
				.list-link {
					color: $color-2;

					svg {
						fill: $color-2; } } }

			&.-active {
				.list-link {
					font-weight: bold; } } }

		.list-link {
			@include flex();
			align-items: center;
			color: $color-6;
			padding: 23px 19px;

			svg {
				@include width(18px);
				fill: $color-6; }

			span {
				flex: 1;
				padding-left: 30px; }

			&:hover {
				cursor: pointer; } } }

	@media (max-width: $mdm) {
		&-list {
			font-size: 1.4rem; } }

	@media (max-width: $mtm) {
		display: none;

		&-title {
			display: block;
			font-weight: bold;
			font-size: 1.6rem;
			padding: 24px 31px; }

		&-list {
			box-shadow: none;
			padding: 0;
			border-radius: 0;

			.list-item + .list-item {
				border-top: 1px solid #fff; }

			.list-link {
				font-weight: bold;
				color: $color-7 !important;
				font-size: 1.6rem;
				padding: 24px 31px;
				background: $color-4;

				svg {
					fill: $color-7 !important; } }

			.list-item {
				&.-active,
				&:hover {
					.list-link {
						background: $color-5; } } } } } }

.navigation-cabinet-menu-toggle {
	display: none;
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	height: 46px;
	background: $color-6;
	color: #fff;
	z-index: 101;

	&-section {
		flex: 1;
		text-align: center;
		line-height: 46px;
		font-size: 1.6rem;

		span {
			margin-right: -46px;

			&:nth-child(1) {
				display: block; }

			&:nth-child(2) {
				display: none; } } }

	&-button {
		@include width(46px);
		height: 46px;
		position: relative;
		padding: 0;
		margin: 0;
		border: 0;
		background: transparent;

		&::before,
		&::after {
			@include content();
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%); }

		&::before {
			width: 18px;
			height: 11px;
			background: url('../img/navigation-toggle.svg') 0 0 /  100% 100% no-repeat;
			opacity: 1; }

		&::after {
			width: 14px;
			height: 14px;
			background: url('../img/navigation-close.svg') 0 0 /  100% 100% no-repeat;
			opacity: 0; } }

	@media (max-width: $mtm) {
		display: flex; } }

.-menu-cabinet-opened {
	@media (max-width: $mtm) {
		position: fixed;
		overflow: hidden;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;

		.header {
			z-index: 0; }

		.navigation-cabinet {
			position: fixed;
			top: 0;
			left: 0;
			bottom: 46px;
			right: 0;
			z-index: 100;
			background: #fff;
			display: block;
			@include scroll();
			overflow: auto; }

		.navigation-cabinet-menu-toggle {
			background: $color-2;

			span {
				&:nth-child(1) {
					display: none; }

				&:nth-child(2) {
					display: block; } }

			&-button {
				background-color: $color-1;

				&::before {
					opacity: 0; }

				&::after {
					opacity: 1; } } } } }

/* ORDERS
 * -------------------------------------------------- */

.accordion--orders {
	.accordion-item + .accordion-item {
		margin-top: 24px; }

	.accordion-body {
		display: none; }

	.accordion-item {
		&.-active {
			.accordion-body {
				display: block; } } } }

/* FORM
 * -------------------------------------------------- */

.form--cabinet {
	@media (min-width: $mml + 1px) {
		.form-row > .form-item {
				@include width(33.333%); } } }

.row--cabinet-form-subscribe {
	.col {
		&:nth-child(1) {
			@include width(66.67%); } }

	@media (max-width: $mdxs) {
		display: block;

		.col {
			&:nth-child(1) {
				@include width(100%);
				margin-bottom: 26px; } } }

	@media (max-width: $mtm) {
		.col {
			&:nth-child(1) {
				br {
					display: none; } } } } }

/* PERSONAL MANAGER
 * -------------------------------------------------- */

.personal-manager {
	position: relative;
	background: $color-4;

	&-image {
		position: absolute;
		left: 0;
		bottom: 0;
		width: 46.5%;
		padding-right: 16px;

		img {
			width: 100%;
			max-height: 730px;
			max-width: 438px; } }

	&-section {
		padding: 64px 45px 64px 46.5%; }

	h6 {
		margin-bottom: 24px; }

	.form {
		margin-bottom: 32px;

		.form-item--email {
			max-width: 260px; } }

	.helper {
		position: absolute;
		bottom: 63px;
		left: 15%;

		&-description {
			font-size: 1.2rem; } }

	@media (max-width: 1300px) {
		.helper {
			left: 30px; } }

	@media (max-width: $mdxs) {
		.helper {
			position: static;
			background: none;
			padding-left: 0;
			margin-bottom: 32px;

			&::before {
				display: none; }

			&-section {
				padding: 0; }

			&-title {
				font-size: 1.4rem; }

			&-description {
				margin-top: 16px;
				font-size: 1.2rem; } }

		&-image {
			display: none; }

		&-section {
			padding: 64px 32px; } }

	.form {
		.form-item--email {
			max-width: none; } }

	@media (max-width: $mml) {
		margin-left: -15px;
		margin-right: -15px;

		&-section {
			padding: 24px 15px;

			h6 {
				font-size: 1.8rem; } } } }

/* MODAL
 * -------------------------------------------------- */

.form--partner {
	@media (min-width: $mml + 1px) {
		margin-right: -28px; } }

/* CARDS
 * -------------------------------------------------- */

.layout-cards--cabinet {
	@media (max-width: $mmm) {
		@include list-scroll(); } }
