.modal {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(#000, .5);
	z-index: 1000;
	text-align: center;
	display: none;
	opacity: 0;
	overflow: auto;
	-webkit-overflow-scrolling: touch;
	backface-visibility: hidden;
	outline: none;
	transition: opacity .3s ease;
	padding: 2rem;

	&::before {
		content: '';
		display: inline-block;
		height: 100%;
		vertical-align: middle;
		margin-left: -.28em; }

	&-container {
		width: 100%;
		max-width: 70rem;
		margin: 0 auto;
		padding: 4rem;
		background: #fff;
		position: relative;
		display: inline-block;
		vertical-align: middle;
		text-align: left; }

	// &-close
	// 	position: absolute
	// 	top: 0
	// 	right: 0
	// 	width: 4rem
	// 	height: 4rem
	// 	background: transparent
	// 	text-decoration: none
	// 	border: 0
	// 	padding: 0
	// 	cursor: pointer
	// 	color: #343434
	// 	font-weight: bold
	// 	font-size: 2rem
	// 	line-height: 4rem
	// 	transition: opacity .25s ease

	// 	&:hover
	// 		opacity: .5

	&-iframe {
		position: relative;
		padding-bottom: 56.25%;
		overflow: hidden;

		iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%; } }

	&-image {
		img {
			display: block; } }

	&.modal--opened {
		display: block; }

	&.modal--visible {
		opacity: 1; }

	&.modal--video {
		.modal-container {
			max-width: 110rem;
			padding: 0;
			box-shadow: 0 5px 5px rgba(#000, .25);
			background: #000; }

		.modal-close {
			top: -4rem;
			right: -1.5rem;
			position: absolute;
			width: 4rem;
			height: 4rem;
			background: transparent;
			text-decoration: none;
			border: 0;
			padding: 0;
			cursor: pointer;
			color: #343434;
			font-weight: bold;
			font-size: 2rem;
			line-height: 4rem;
			transition: opacity .25s ease;

			&:hover {
				opacity: .5; } } }

	&.modal--image {
		.modal-container {
			position: static;
			width: auto;
			max-width: 100%;
			padding: 0;
			box-shadow: 0 5px 5px rgba(#000, .25);
			margin: 0 auto;
			color: #fff;
			background: transparent; }

		.modal-close {
			top: 0;
			right: 0;
			color: #fff;
			position: absolute;
			width: 4rem;
			height: 4rem;
			background: transparent;
			text-decoration: none;
			border: 0;
			padding: 0;
			cursor: pointer;
			font-weight: bold;
			font-size: 2rem;
			line-height: 4rem;
			transition: opacity .25s ease;

			&:hover {
				opacity: .5; } } }

	&.modal--gallery {
		padding: 0;
		background: #fff;

		.modal-container {
			padding: 112px 120px 112px 95px;
			max-width: 100%;
			height: 100%;
			background: transparent; }

		@media (max-width: $mds) {
			.modal-container {
				padding: 40px; } } }

	&.modal--form {
		.modal-container {
			max-width: 480px;
			padding: 64px 35px; }

		.modal-close {
			position: absolute;
			top: 46px;
			right: 35px;
			width: 18px;
			height: 18px;
			background: url('../img/modal-close.svg') 0 0 / 100% 100% no-repeat;
			padding: 0;
			margin: 0;
			font-size: 0;
			border: 0; }

		&.modal--form-wide {
			.modal-container {
				max-width: 790px; } } }

	&.modal--reviews {
		.modal-container {
			max-width: 790px;
			padding: 64px 35px; }

		.modal-close {
			position: absolute;
			top: 46px;
			right: 35px;
			width: 18px;
			height: 18px;
			background: url('../img/modal-close.svg') 0 0 / 100% 100% no-repeat;
			padding: 0;
			margin: 0;
			font-size: 0;
			border: 0; } }

	@media (max-width: $mmm) {
		&.modal--form {
			.modal-container {
				padding: 51px 18px 32px 18px; }

			.modal-close {
				top: 32px;
				right: 24px; } } }

	@media (max-width: $mms) {
		padding: 0; } }
