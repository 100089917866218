

























































































































































































































































































.important_table_fs{
	font-size: 1.2rem !important;
}
.table_custom_border{
	td{
		border-color: #718093 !important;
	}
}
.custom-multi-select{
	.multiselect__select{
		top: 8px;
	}

	.multiselect__tags{
		border-radius: 0 !important;
		min-height: 5.5rem;
		border-color: #718093;

		.multiselect__tag{
			background: #EA292E;

			.multiselect__tag-icon{
				&:hover{
					background: black;
				}

				&:after{
					color: white;
				}
			}
		}

		.multiselect__placeholder{
			padding-top: 6px;
		}
	}
}
.wo-before{
	&:before{
		display: none !important;
	}
}
.tr_border_color_orange{
	td{
		border-color: #FA832E !important;
	}
}
.tr_border_color_red{
	td{
		border-color: #EA292E !important;
	}
}
.tr_border_color_green{
	td{
		border-color: #4C9F4A !important;
	}
}
.tr_border_color_purple{
	td{
		border-color: #872EFA !important;
	}
}
