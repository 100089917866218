.dropdown.header-dropdown.-active {
	display: none;
}

.hero .container {
	max-width: 100%;
	width: 100%;
	padding-right: 0;
}
.hero .container .inner {
	position: relative;
}

.hero-message {
	top: 30px;
	right: 30px;
	padding: 30px;
	border-radius: 4px;
	box-shadow: 0 2px 6px hsl(0deg 0% 0% / 50%);
	max-width: 350px;
	text-align: center;
	font-family: 'Montserrat' !important;
	z-index: 10;
}

.hero-message .message-title {
	font-size: 1.8rem;
	font-weight: 700;
	margin-bottom: 0;
	font-family: 'Montserrat' !important;
}

.hero-message .message-description {
	margin-bottom: 30px;
}

.hero-message .button {
	font-size: 1.4rem;
	font-weight: 700;
	padding: 1.4rem 27px;
	width: auto;
}

.slider--hero {
	position: relative;
}

.slider--hero .swiper-button-prev,
.slider--hero .swiper-button-next {
	position: absolute;
	z-index: 1;
	opacity: 0.5;
	transition: opacity 0.15s ease;
	width: 15%;
	top: 0;
	bottom: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	margin: 0;
	height: auto;
	transform: rotate(0deg);
	background: transparent;
}

.slider--hero .swiper-button-prev:hover,
.slider--hero .swiper-button-next:hover {
	opacity: 1;
}

.slider--hero .swiper-button-prev svg,
.slider--hero .swiper-button-next svg {
	width: 20px;
	height: 20px;
}

.slider--hero .swiper-button-next .sr-only {
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	white-space: nowrap;
	position: absolute;
}

.card {
	box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
	border-radius: 4px;
	padding-bottom: 100%;
}

.section--cards .card--media {
	margin: 0.5rem !important;
}

.section--cards {
	overflow: hidden;
}

.section--cards-row {
	display: flex;
}

.section--cards-row .col-4_3 {
	min-width: 23vw;
}

.card::before {
	display: none;
}

.card .bannerBottomCarousel .bg1 {
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	z-index: 1;
	opacity: 1;
	transition: opacity .4s ease;
}

.card .bannerBottomCarousel .bg2 {
	transition: background .2s ease;
	z-index: 2;
}

.card .bannerBottomCarousel .content {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.card .card-heading {
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	transition: background .2s ease;
}

.card-arrow {
	transition: .2s ease;
}

.card .card-title {
	position: absolute;
	font-weight: 700;
	font-size: 1.6rem;
	transition: color .25s ease;
	margin-bottom: 0.7rem;
	bottom: 42px;
	left: 0px;
	background-color: rgba(53, 59, 72, 0.9);
	color: #fff;
	padding: 10px;
	max-width: 230px;
	width: 100%;
	text-align: center;
	transition: background .2s ease;
	min-height: initial;
}

.card:hover .card-title {
	background: transparent;
}

.card:hover .card-arrow {
	fill: #fff
}

.card:hover .card-heading {
	background: #ed1c24;
}

.section--events .heading {
	margin-bottom: 3rem;
}
.section--events .heading-section {
	padding-left: 0;
}

.layout-events {
	margin: 0;
}

.layout-events .card-list-item {
	display: flex;
	flex-direction: column;
	min-width: 0;
	word-wrap: break-word;
	background-color: #fff;
	background-clip: border-box;
	box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
	padding: 0;
	margin-right: 30px;
	position: relative;
}

.card-list-item .card-img {
	border-bottom-right-radius: calc(0.25rem - 1px);
	border-bottom-left-radius: calc(0.25rem - 1px);
	border-top-left-radius: calc(0.25rem - 1px);
	border-top-right-radius: calc(0.25rem - 1px);
	flex-shrink: 0;
	width: 100%;
	height: auto;
}

.card-list-item .card-img-overlay {
	color: #fff;
	position: absolute;
	top: 0;
	left: 0;
	bottom: unset;
	right: unset;
	background-color: rgba(53, 59, 72, 0.8);
	padding: 1.25rem;
	border-radius: calc(0.25rem - 1px);
}

.card-list-item .mb-4, .card-list-item .my-4 {
	margin-bottom: 1.5rem !important;
}

.card-list-item .mb-5, .card-list-item .my-5 {
	margin-bottom: 3rem !important;
}

.card-list-item h5 {
	font-size: 1.6rem;
}

.card-list-item .card-title {
	min-height: initial;
}

.layout-events .list-item {
	padding: 0;
	margin-right: 30px;
	flex: 0 0 20%;
	max-width: 18.3%;
	display: flex;
	flex-direction: column;
}

.section--events .container {
	padding-right: 15px;
}

.layout-events .list-item:last-child {
	margin-right: 0;
}

.card-event {
	height: 100%;
	box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
	display: flex;
	flex-direction: column;
	background: #fff;
	position: relative;
}

.card-event .card-header {
	padding: 0;
	font-weight: 700;
	color: #fff;
	overflow: hidden;
	background-color: unset;
	border-bottom: 0;
}

.card-event .card-header:first-child {
	border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-event .card-date {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	padding: 5px;
	text-align: center;
	background-color: rgba(53, 59, 72, 0.9);
}

.card-event .card-body {
	-ms-flex: 1 1 auto;
	flex: 1 1 auto;
	min-height: 1px;
	padding: 1.25rem;
}

.card-event .card-title {
	font-size: 1.8rem;
	margin-bottom: 16px;
	font-weight: 700;
}

.card-event .card-footer:last-child {
	border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-event .card-footer .button {
	width: 100%;
}

.card-list-item-mobil-block {
	display: none !important;
}

.section--brands, .section--products {
	overflow: hidden;
}

.section--brands .container,
.section--products .container {
	padding-right: 15px;
}

.row--brands {
	display: flex;
	margin-right: -10px;
}

.item--brands {
	margin-right: 10px;
	min-width: calc(20% - 10px);
	width: calc(20% - 10px);
	display: flex;
	align-items: center;
	justify-content: center;
	flex-shrink: 0;
}

.section--products--row {
	display: flex;
}

.section--products--item {
	min-width: 17vw;
	margin-right: 30px;
	flex-shrink: 0;
}

.section--products--item .product {
	box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

@media only screen and (max-width: 992px) {
	.section--events .heading {
		display: flex;
	}
	.section--events .heading-aside:not(.heading-aside--visible) {
		display: block;
	}

	.layout-events {
		flex-wrap: wrap;
		margin: 0 !important;
		padding-top: 0 !important;
		padding-bottom: 0 !important;
		overflow-x: hidden;
		display: block;
	}

	.section--events .heading-bottom {
		display: none;
	}

	.layout-events .list-item {
		width: 100% !important;
		flex: 1 0 100% !important;
		margin: 0 0 3rem 0 !important;
		padding: 0 !important;
		max-width: 100% !important;
	}

	.layout-events .list-item:last-child {
		margin-bottom: 0;
	}

	.card-title {
		min-height: initial !important;
	}

	.card-list-item {
		display: none !important;
	}

	.card-list-item-mobil-block {
		display: flex !important;
	}

	.slider--hero .swiper-slide {
		height: auto;
		padding-bottom: 50%;
	}

	.section--cards {
		margin-top: -77px;
	}

	.section--cards .card {
		padding: 0 !important;
		padding-bottom: 100% !important;
	}

	.section--cards .card-heading {
		position: absolute;
		top: 0 !important;
		left: 0 !important;
		bottom: initial !important;
		right: initial !important;
	}
}

@media only screen and (max-width: 767px) {

	.section--cards-row .col-4_3 {
		min-width: 23vw;
		min-width: 85vw;
		width: 85vw;
	}

	.section--products--item {
		min-width: 80vw;
		margin-right: 30px;
		flex-shrink: 1;
	}

	.section--products--item .product {
		max-width: 100%;
	}

	.item--brands {
		min-width: 45vw;
		width: 45vw;
		flex-shrink: 1;
	}

}