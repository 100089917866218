.row--partners {
	.col {
		&:nth-child(1) {
			@include width(40%);
			@include inner-left(); } }

	@media (max-width: $mtm) {
		display: block;

		.col {
			&:nth-child(1) {
				@include width(100%);
				margin-bottom: 58px;

				br {
					display: none; } } } }

	@media (max-width: $mml) {
		.col {
			&:nth-child(1) {
				display: flex;
				flex-direction: column-reverse;

				h3 {
					margin-bottom: 0;
					margin-top: 24px; } } } } }


#map {
	height: 420px;
	background: #ccc;
	border-radius: 4px;
	overflow: hidden;
	box-shadow: 0 3px 45px rgba(#AAAAAA, .16);

	@media (max-width: $mtm) {
		margin-left: -15px;
		margin-right: -15px;
		border-radius: 0; }

	@media (max-width: $mmm) {
		height: 400px; } }

.layout-map-cities {
	display: none;
	margin-top: 24px;
	font-size: 1.6rem;

	.list-item {
		padding-left: 27px;
		position: relative;
		margin-top: 32px; }

	svg {
		fill: $color-6;
		width: 12px;
		height: 18px;
		position: absolute;
		left: 0;
		top: 0.45em; }

	@media (max-width: $mtm) {
		display: block; } }
