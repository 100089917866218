


























































































































































































































































.block-fence{
    .mb-10{
        margin-bottom: 10rem;
    }
    .cl-top-option{
        margin-bottom: 10rem;

        .cl-icon-radio{
            max-width: 200px;
        
            .cl-icon-radio__btn{
                height: 60px;
            }
        }
    }

    .block_array{
        margin-top: 20px;

        span{
            font-size: 15px;
        }
    }

    .search-field{
        width: 50%;

        .search-input{
            width: 100%;
            height: 48px;
            padding: 17px 0 17px 23px;
            background: #f5f6fa;
            color: #353b48;
            font-size: 1.2rem;
            margin: 0;
            border: 0;
            border-radius: 4px;
            font-weight: 700;
        }
        .search-submit{
            position: absolute;
            width: 18px;
            height: 18px;
            background: url('~@/img/search-button.svg') 0 0/100% 100% no-repeat;
            border: 0;
            padding: 0;
            margin: 0;
            top: 50%;
            margin-top: -9px;
            right: 26px;
        }
    }

    .block-elements{
        width: 50rem;
        
        .search-results{
            display: block;

            .search-result{
                padding: 15px;
                
                .button{
                    padding: 5px;
                }
            }
        }
    }

    .nlk-table{
        .search-field{
            width: 100%;
        }
        .left-td{
            width: 40%;
        }

        td{
            border: 1px solid #bdbec1;
        }

        .block-elements{
            position: absolute;
            background: #fff;
            z-index: 100;
            width: 63rem;

            .search-results{
                margin-top: 0;
                
            }
        }
    }
}
