























































































































































.document-preview{
  position: absolute;
  top: 30px;
  left: 30%;
  z-index: 2;

  img{
    width: 55%;
  }
}

.document-card-title{
  bottom: 10px !important;
  z-index: 5 !important;
}

.new-preview-width{
  img{
    width: 80%;
  }
}

@media (min-width: 1700px) {
  .new-preview-width{
    img{
      width: 100%;
    }
  }
}


.documents_page{
    .document_card{
      position: relative;
      border-radius: 4px;
      background-color: #fff;

      padding-left: 0 !important;
      // padding-top: 0 !important;
      margin-top: 16px;
      margin-left: 16px;

      width: 31.8%;
      flex: 0 0 31.8% !important;
      max-width: 31.8% !important;

      box-shadow: 0 3px 45px rgba(170, 170, 170, 0.16);

      &:hover{
          background-color: #ED1C24;

          .card-title{
              color: #fff;
          }

          .card-arrow{
              svg{
                fill: #fff; 
              }
          }
      }

      .card-title{
        padding-left: 30px;
      }

      .card-arrow{
          position: absolute;
          top: 39px;
          right: 39px;
          z-index: 2;

          svg{
              fill: #ED1C24;
              width: 25px;
              height: 25px;
              transition: fill .25s ease;
          }
      }

      .new-preview-width{
          display: flex;
          justify-content: center;

          margin-bottom: 20px;

          .preview_img{
            width: 55%;
            margin: auto;
            margin-top: 30px;

            max-height: 222px;
            min-height: 180px;
          }
      }
    }
}
