


















































































































.disable-select{
    .choices__list--dropdown{
        display: none !important;
    }
}
.choices-footer{
    max-width: 21rem;
}
