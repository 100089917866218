

























































































































































































































































































































































































































































































































































































































































































.mt-3{
    margin-top: 3rem;
}
.label_red_error{
    color: red !important;
}
.select_red_error{
    .choices__item--selectable{
        border-color: red !important;
    }

    input{
        border-color: red !important;
    }
}
.color-preview{
    img{
        width: 38px;
        height: 38px;
        border-radius: 50%;
    }
}

.nd_good_col{
    position: relative;
    min-height: 10rem;

    .field-input{
        position: absolute;
        bottom: 0;
        width: 86%;
    }
}
