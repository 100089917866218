.accordion {
	&-header {
		cursor: pointer; }

	&-body {
 }		//display: none

	&-item {
		&.-active {
			.accordion-body {
				//display: block
				animation: accordionIn 1s forwards; } } } }

@keyframes accordionIn {
	0% {
		opacity: 0; }
	100% {
		opacity: 1; } }
