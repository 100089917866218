.user {
	@include flex();
	align-items: center;

	&-image {
		@include width(93px);
		margin-right: 32px; }

	&-section {
		flex: 1; }

	&-title {
		font-weight: bold;
		font-size: 2.4rem;
		line-height: 1.333333; }

	&-info {
		margin-top: 8px; }

	@media (max-width: $mmm) {
		display: block;

		&-image {
			display: none; }

		&-title {
			font-size: 1.6rem; }

		&-info {
			font-size: 1.4rem;
			margin-top: 10px; } } }
