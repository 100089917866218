






























































































































































.vue-tooltip-theme{
    background-color: #000000 !important;
    color: #ffffff !important;
    border-radius: 15px;
    max-width: 500px !important;
    max-height: 240px !important;
    overflow: hidden;
}
