.message {
	background: #fff;
	padding: 92px 39px 39px 39px;
	border-radius: 4px;
	box-shadow: 0 3px 45px rgba(#AAAAAA, .16);

	&-title {
		margin-bottom: 24px;
		font-size: 2.4rem; }

	&-description {
		margin-bottom: 32px; }

	&-button {
		width: 100%; }

	&-note {
		margin-top: 32px;
		font-size: 1.2rem;
		color: $color-6; }

	&-phone {
		margin-top: 16px;
		display: block;
		color: $color-6;
		font-size: 1.6rem;
		font-weight: bold;

		&:hover {
			color: $color-2; } }

	@media (max-width: $mdm) {
		padding: 53px 30px 24px 30px;

		&-title {
			font-size: 1.8rem; } }

	@media (max-width: $mtm) {
		padding: 64px 20px 20px 20px; } }
